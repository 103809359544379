import React, { ReactNode, RefObject } from "react";
import {
  AnimatedBlockRenderProperty,
  AnimatedRenderProperty,
  RenderProperty,
} from "../../views/RenderProperty";
import { createUseStyles } from "react-jss";
import { customTypography, typography } from "../../basics/typography";
import { Col, Container, onBreakpoint, rem, Row } from "../../basics/layout";
import { spacings } from "../../basics/spacings";
import { Button, ButtonWrapper } from "../Button/Button";
import { BreadcrumbComponent } from "../BreadcrumbComponent/BreadcrumbComponent";
import KeyVisualSectionData from "../../models/content/KeyVisualSectionData";
import { colors } from "../../basics/colors";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { SingleImageProperty } from "../../models/Property";
import {
  AnimatedRevealBlock,
  AnimatedRevealInline,
} from "../AnimatedReveal/AnimatedReveal";
import { LocalizedLabel } from "../../hooks/LocalizationContext";
import { useTracking } from "../../hooks/useTracking";
import { createPortal } from "react-dom";

const useStyles = createUseStyles({
  keyVisual: {
    margin: `${spacings.m} 0 ${spacings.xxl}`,
  },
  title: typography.h1,
  description: {
    ...customTypography(
      { ...typography.textDefault, marginBottom: 0 },
      {},
      { marginBottom: 0 }
    ),
  },
  imageWrapper: {
    position: "relative",
    boxSizing: "content-box",

    "&:nth-child(2)": {
      width: "26%",
      marginTop: "-26%",
      top: "-60%",
      right: -16,
      border: "16px solid white",
      borderRightWidth: 0,
      marginLeft: "auto",
      ...onBreakpoint("sm", {
        marginTop: "-48%",
        top: 0,
        width: "79%",
        borderWidth: 24,
        left: "calc(-20% - 27px)",
        marginLeft: "unset",
      }),
    },

    "&:nth-child(3)": {
      display: "none",
      width: "calc(40% + 16px)",
      right: -32,
      marginTop: "calc(-35% - 1vw)",
      marginLeft: "auto",
      ...onBreakpoint("sm", {
        display: "block",
      }),
      ...onBreakpoint("lg", {
        marginTop: "-35%",
      }),
    },
  },

  textColumn: {
    order: 1,
    ...onBreakpoint("sm", {
      order: 0,
    }),
  },
  highlights: {
    marginTop: rem(spacings.sam),
  },
  highlightItem: {
    ...customTypography(
      {
        ...typography.textDefault,
        color: colors.gray60,
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: rem(spacings.xs),
      },
      {},
      {
        marginBottom: rem(spacings.xs),
      }
    ),
  },
  highlightIcon: {
    ...FAIconStyles({
      width: 32,
      height: 32,
    }),
    marginRight: rem(spacings.s),
    color: colors.lightGreen40,
  },
  buttons: {
    display: "inline-block",
    marginTop: rem(spacings.m),
    "&:not(:last-child)": {
      marginRight: rem(spacings.m),
    },
  },
  contactUsButton: {
    marginRight: rem(spacings.m),
    marginTop: rem(spacings.xl),
  },
  mobileAndTabletOnly: {
    display: "block",
    ...onBreakpoint("md", {
      display: "none",
    }),
  },
  desktopOnly: {
    display: "none",
    ...onBreakpoint("md", {
      display: "flex",
    }),
  },
});

export function KeyVisual(
  props: KeyVisualSectionData & {
    contactListRef: RefObject<HTMLDivElement>;
    keyVisualRef: RefObject<HTMLDivElement>;
    headerButtonPortalRef?: RefObject<HTMLDivElement>;
    isUserRetention?: boolean;
    buttonPosition?: number;
  }
) {
  const styles = useStyles();
  const tracking = useTracking();
  const checkIfValidImage = (adaptiveimage?: SingleImageProperty) =>
    adaptiveimage?.value?.urlLarge ||
    adaptiveimage?.value?.urlMedium ||
    adaptiveimage?.value?.urlSmall;
  const images = [
    ...(checkIfValidImage(props.primaryImage) ? [props.primaryImage] : []),
    ...(checkIfValidImage(props.secondaryImage) ? [props.secondaryImage] : []),
    ...(checkIfValidImage(props.tertiaryImage) ? [props.tertiaryImage] : []),
  ];

  const primaryButton = props.buttons?.value?.find(
    (b) => b.buttonType === "primary"
  );

  const headerPortalElement = props.headerButtonPortalRef?.current;
  const HeaderPortalWrapper = headerPortalElement
    ? (props: { children: ReactNode }) =>
        createPortal(<>{props.children}</>, headerPortalElement)
    : () => null;

  return (
    <Container>
      <div className={styles.keyVisual} data-testid="KeyVisual">
        <Container isFluid>
          <Row>
            <Col className={styles.mobileAndTabletOnly}>
              <BreadcrumbComponent />
            </Col>
            <Col sm={6} className={styles.textColumn}>
              <BreadcrumbComponent className={styles.desktopOnly} />
              <h1 className={styles.title}>
                <AnimatedRenderProperty value={props.headline} />
              </h1>

              <Row>
                <Col sm={10}>
                  {props.description && (
                    <AnimatedBlockRenderProperty
                      value={props.description}
                      className={styles.description}
                      revealDelay={100}
                      movement={false}
                    />
                  )}

                  {(props.highlights?.value?.length || 0) > 0 && (
                    <AnimatedRevealBlock revealDelay={200} movement={false}>
                      <div className={styles.highlights}>
                        {props.highlights?.value?.map((item, idx) => (
                          <div key={idx} className={styles.highlightItem}>
                            <FAIcon
                              icon={(item.icon || undefined) as IconProp}
                              className={styles.highlightIcon}
                            />
                            {item.text}
                          </div>
                        ))}
                      </div>
                    </AnimatedRevealBlock>
                  )}

                  <div ref={props.keyVisualRef}>
                    {!primaryButton && props.isUserRetention && (
                      <AnimatedRevealInline revealDelay={300} movement={false}>
                        <Button
                          onClick={() => {
                            tracking.event(
                              "Key Visual",
                              "CTA click",
                              "Contact us",
                              ""
                            );
                            props.contactListRef.current?.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                          className={styles.contactUsButton}
                        >
                          <LocalizedLabel section="Contact" label="ContactUs" />
                        </Button>
                      </AnimatedRevealInline>
                    )}

                    {props.buttons?.value?.map((button, idx) => (
                      <AnimatedRevealBlock
                        key={idx}
                        revealDelay={300 + 50 * idx}
                        className={styles.buttons}
                      >
                        <ButtonWrapper
                          {...button}
                          trackingFunction={() =>
                            tracking.event(
                              "Key Visual",
                              "CTA click",
                              button.link?.[0]?.text || "Missing button text",
                              button.link?.[0]?.href || "Missing button link"
                            )
                          }
                        />
                      </AnimatedRevealBlock>
                    ))}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col smStart={7} sm={5}>
              <AnimatedRevealBlock
                movement={false}
                className={styles.imageWrapper}
                revealDelay={300}
              >
                {images.map((image, idx) => (
                  <div className={styles.imageWrapper} key={idx}>
                    <RenderProperty value={image} />
                  </div>
                ))}
              </AnimatedRevealBlock>
            </Col>
          </Row>
        </Container>
      </div>
      <HeaderPortalWrapper>
        {!primaryButton && props.isUserRetention && (
          <Button
            onClick={() => {
              tracking.event("Key Visual", "CTA click", "Contact us", "");
              props.contactListRef.current?.scrollIntoView({
                behavior: "smooth",
              });
            }}
            size="s"
          >
            <LocalizedLabel section="Contact" label="ContactUs" />
          </Button>
        )}
        {primaryButton ? (
          <ButtonWrapper
            {...primaryButton}
            size="s"
            trackingFunction={() =>
              tracking.event(
                "Key Visual",
                "CTA click",
                primaryButton.link?.[0]?.text || "Missing button text",
                primaryButton.link?.[0]?.href || "Missing button link"
              )
            }
          />
        ) : null}
      </HeaderPortalWrapper>
    </Container>
  );
}
