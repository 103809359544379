import React, { ReactNode, useEffect, useRef, useState } from "react";
import { onBreakpoint, rem } from "../../basics/layout";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { colors } from "../../basics/colors";
import { customTypography, typography } from "../../basics/typography";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { animations } from "../../basics/animations";
import FocusTrap from "focus-trap-react";
import { MODALS_ROOT_ID, NO_SCROLLING_CLASS } from "../../App";
import { useDeviceType, isDeviceResOrLower } from "../../hooks/useDeviceType";
import { createPortal } from "react-dom";
import { spacings } from "../../basics/spacings";
import { StringProperty } from "../../models/Property";
import { RenderProperty } from "../../views/RenderProperty";
import { isClientSide } from "../../basics/isClientSide";

const CLOSE_BUTTON_SIZE = 50;
const CLOSE_BUTTON_SPACING = 12;

export interface ModalProps {
  modalOpened: boolean;
  closeModal: () => void;
  onLeftArrow?: () => void;
  onRightArrow?: () => void;
  children?: ReactNode;
  allowMobile?: boolean;
  className?: string;
}

const modalStyles = createUseStyles({
  ...animations.simpleFadeIn,
  ...animations.simpleFadeOut,
  modal: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    zIndex: 101,
    backgroundColor: "rgba(0, 0, 0, 0.95)",
    "@supports (backdrop-filter: blur(5px))": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    overflow: "auto",
    backdropFilter: "blur(5px)",
  },
  modalRevealAnimation: ({ isOpened }) => ({
    animation: isOpened
      ? "$simpleFadeIn 0.3s ease"
      : "$simpleFadeOut 0.3s ease",
  }),
  closeButton: {
    position: "absolute",
    top: CLOSE_BUTTON_SPACING,
    right: CLOSE_BUTTON_SPACING,
    cursor: "pointer",
    zIndex: 101,
    width: CLOSE_BUTTON_SIZE,
    height: CLOSE_BUTTON_SIZE,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid transparent`,
    font: "inherit",
    color: colors.white,
    textAlign: "inherit",
    background: "none",
    opacity: 0.85,
    transition: "opacity 0.3s ease",
    outline: "none",
    "&:hover": {
      opacity: 1,
      transition: "opacity 0.3s ease",
    },
    "&:focus": {
      border: `1px solid ${colors.white}`,
      borderRadius: "5px",
    },
  },
  closeIcon: {
    ...FAIconStyles({
      width: 32,
      height: 32,
      ...onBreakpoint("sm", {
        width: 55,
        height: 55,
      }),
      ...onBreakpoint("md", {
        width: 70,
        height: 70,
      }),
    }),
  },
  closeIconLabel: {
    ...typography.visuallyHidden,
  },
});

export function Modal(props: ModalProps) {
  const [isModalRendered, setIsModalRendered] = useState(false);
  const modalWrapperRef = useRef<HTMLDivElement>(null);
  const root =
    (isClientSide() && document.getElementsByTagName("html")[0]) || undefined;
  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");

  const styles = modalStyles({
    isOpened: props.modalOpened,
  });

  function onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      props.closeModal();
    }

    if (event.keyCode === 37 && props.onLeftArrow) {
      props.onLeftArrow();
    }

    if (event.keyCode === 39 && props.onRightArrow) {
      props.onRightArrow();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => document.removeEventListener("keydown", onKeyDown, false);
  });

  useEffect(() => {
    if (!root) return;

    if (isModalRendered) {
      root.classList.add(NO_SCROLLING_CLASS);
    } else {
      root.classList.remove(NO_SCROLLING_CLASS);
    }
    return () => {
      root.classList.remove(NO_SCROLLING_CLASS);
    };
  }, [isModalRendered]);

  useEffect(() => {
    if (props.modalOpened && (!isMobile || props.allowMobile))
      setIsModalRendered(true);
  });

  const onAnimationEnd = () => {
    if (!props.modalOpened) setIsModalRendered(false);
  };

  const portalContainer =
    (isClientSide() && document.querySelector(`#${MODALS_ROOT_ID}`)) ||
    undefined;

  return portalContainer
    ? createPortal(
        <>
          {isModalRendered && (
            <FocusTrap>
              <div
                ref={modalWrapperRef}
                className={cn(
                  styles.modal,
                  styles.modalRevealAnimation,
                  props.className
                )}
                onAnimationEnd={() => onAnimationEnd()}
                onClick={(e) =>
                  e.target === modalWrapperRef.current && props.closeModal()
                }
                data-testid="Modal"
              >
                <button
                  onClick={() => props.closeModal()}
                  className={styles.closeButton}
                >
                  <span className={styles.closeIconLabel}>Close</span>
                  <FAIcon icon="times" className={styles.closeIcon} />
                </button>
                {props.children}
              </div>
            </FocusTrap>
          )}
        </>,
        portalContainer
      )
    : null;
}

const useGenericBodyStyles = createUseStyles({
  container: {
    width: "97%",
    maxWidth: 830,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: colors.white,
    borderRadius: "10px",
    maxHeight: `calc(100% - ${
      (CLOSE_BUTTON_SIZE + CLOSE_BUTTON_SPACING * 2) * 2
    }px)`,
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: rem(spacings.m),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    background: colors.gray10,
  },
  heading: customTypography(
    typography.h4,
    { marginBottom: 0 },
    { marginBottom: 0 },
    { marginBottom: 0 }
  ),
  content: {
    margin: `${spacings.m}`,
    overflowY: "auto",
  },
});

export function GenericModalBodyContainer(props: {
  heading: string | StringProperty;
  children: ReactNode;
}) {
  const styles = useGenericBodyStyles();

  return (
    <article className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.heading}>
          <RenderProperty value={props.heading} />
        </h2>
      </header>
      <div className={styles.content}>{props.children}</div>
    </article>
  );
}
