import React, { useState, useContext, useEffect } from "react";
import { colors } from "../../../basics/colors";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, Row, rem } from "../../../basics/layout";
import { FAIcon } from "../../FAIcon/FAIcon";
import ContactPersonBlockData from "../../../models/content/ContactPersonBlockData";
import {
  RenderProperty,
  showPropertyOnEdit,
} from "../../../views/RenderProperty";
import ContactLocationBlockData from "../../../models/content/ContactLocationBlockData";
import { ContactCardsBlock } from "./ContactListBlock";
import { personCardStyles } from "./PersonCard";
import { locationCardStyles } from "./LocationCard";
import cn from "classnames";
import ContactGenericBlockData from "../../../models/content/ContactGenericBlockData";
import { Button, isExternal } from "../../Button/Button";
import {
  isDeviceResOrHigher,
  useDeviceType,
  isDeviceResOrLower,
} from "../../../hooks/useDeviceType";
import { LocalizedLabel } from "../../../hooks/LocalizationContext";
import { Modal } from "../../Modal/Modal";
import { GlobalContentStoreContext } from "../../../hooks/GlobalContentStore";
import StartPageData from "../../../models/content/StartPageData";
import { FormContainer } from "../Forms/FormContainer";
import { spacings } from "../../../basics/spacings";
import { useTracking } from "../../../hooks/useTracking";
import FormContainerBlockData from "../../../models/content/FormContainerBlockData";
import { createSlider } from "../../Slider/Slider";
import SliderType from "react-slick";

const modalStyles = createUseStyles({
  cardCarousel: {
    ...onBreakpoint("md", {
      width: "calc(100% - 118px)",
      margin: "0 auto",
      top: "50%",
      transform: "translateY(-50%)",
    }),
    "& .slick-track": {
      display: "flex",
    },
    "& .slick-slide": {
      height: "auto",
      padding: "0 5px",
      "& > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      width: 48,
      height: 48,
      "&:before": {
        color: colors.white,
        fontSize: "3rem",
      },
      border: `1px solid transparent`,
      "&:focus": {
        border: `1px solid ${colors.white}`,
        borderRadius: "5px",
      },
    },
    "& .slick-prev": {
      left: -60,
      "&:before": {
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f053"',
      },
    },
    "& .slick-next": {
      right: -60,
      "&:before": {
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f054"',
      },
    },
    "& .slick-dots": {
      bottom: -40,
      "& button:before, & .slick-active button:before": {
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f111"',
        fontWeight: 900,
        fontSize: "1rem",
        color: colors.white,
      },
      "& button": {
        border: `1px solid transparent`,
        "&:focus": {
          border: `1px solid ${colors.white}`,
          borderRadius: "5px",
        },
      },
    },
  },
  form: {
    background: colors.white,
    padding: `${spacings.l} ${spacings.s.divide(2)} ${spacings.l} ${
      spacings.s
    }`,
    borderRadius: "10px",
    "& > *": {
      height: "100%",
      overflowY: "auto",
      paddingRight: rem(spacings.s.divide(2)),
    },
    "& .Form__Element:first-child": {
      marginTop: 0,
      "& h3": {
        marginBottom: 0,
      },
    },
    ...onBreakpoint("md", {
      height: "90vh",
      padding: `${spacings.xl} ${spacings.xl.divide(2)} ${spacings.xl} ${
        spacings.xl
      }`,
      "& > *": {
        paddingRight: rem(spacings.xl.divide(2)),
      },
    }),
  },
  formModal: {
    ...onBreakpoint("sm", {
      paddingTop: rem(spacings.xxl),
      alignItems: "baseline",
    }),
    ...onBreakpoint("md", {
      paddingTop: 0,
      alignItems: "center",
    }),
  },
  formMobileModal: {
    paddingTop: rem(spacings.xxl),
    alignItems: "baseline",
  },
});

export function ContactListOverlay(props: {
  contacts: Array<ContactCardsBlock>;
  modalOpened: boolean;
  closeModal: () => void;
  initialSlide: number;
}) {
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const tracking = useTracking();
  const styles = modalStyles();

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  const Slider = createSlider();
  const [slider, setSlider] = useState<SliderType | null>(null);

  const deviceType = useDeviceType();
  const tabletAtLeast = isDeviceResOrHigher(deviceType, "tablet");
  const isMobile = isDeviceResOrLower(deviceType, "mobile");
  const [isFormMobileOpen, setFormMobileOpen] = useState(false);
  const [currentContactIndex, setCurrentContactIndex] = useState(
    props.initialSlide
  );

  useEffect(() => {
    setFormMobileOpen((s) => s && isMobile);
  }, [isMobile]);

  useEffect(() => {
    setCurrentContactIndex(props.initialSlide);
  }, [props.initialSlide]);

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: tabletAtLeast,
    initialSlide: props.initialSlide,
    beforeChange: (_: number, newIndex: number) =>
      setCurrentContactIndex(newIndex),
  };

  if (!startPageData) return null;
  const currentContact = props.contacts?.[currentContactIndex];

  return (
    <Modal
      modalOpened={props.modalOpened}
      closeModal={props.closeModal}
      onLeftArrow={() => slider?.slickPrev()}
      onRightArrow={() => slider?.slickNext()}
      className={cn(
        styles.formModal,
        isFormMobileOpen && styles.formMobileModal
      )}
      allowMobile
    >
      <Container>
        <Row>
          {!isFormMobileOpen && (
            <Col
              sm={8}
              smStart={2}
              md={6}
              mdStart={null}
              lg={5}
              style={{ width: "100%" /* due to FF */ }}
            >
              {Slider && (
                <Slider
                  ref={(c) => setSlider(c)}
                  {...carouselSettings}
                  className={styles.cardCarousel}
                >
                  {props.contacts?.map((i, idx) => (
                    <CardOverlay
                      {...i}
                      key={idx}
                      openFormMobile={() => setFormMobileOpen(true)}
                      startPageData={startPageData}
                    />
                  ))}
                </Slider>
              )}
            </Col>
          )}
          {(!isMobile || (isMobile && isFormMobileOpen)) && (
            <Col md={5} lg={6} className={styles.form}>
              {startPageData?.contactOverlayWebform?.expandedValue && (
                <FormContainer
                  data={
                    startPageData.contactOverlayWebform
                      .expandedValue as FormContainerBlockData
                  }
                  emailValue={currentContact?.email.value || ""}
                  trackingFunction={() =>
                    tracking.event(
                      "Contacts",
                      "Form submission",
                      currentContact?.email.value || ""
                    )
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
    </Modal>
  );
}

function CardOverlay(
  props: ContactCardsBlock & {
    openFormMobile: () => void;
    startPageData: StartPageData;
  }
) {
  return (
    <>
      {props.contentType[1] === "ContactPersonBlock" ||
      props.contentType[1] === "ContactGenericBlock" ? (
        <PersonCardOverlay {...props} />
      ) : (
        <LocationCardOverlay {...props} />
      )}
    </>
  );
}

function PersonCardOverlay(
  props: ContactPersonBlockData &
    ContactGenericBlockData & {
      openFormMobile: () => void;
      startPageData: StartPageData;
    }
) {
  const styles = personCardStyles();
  const {
    firstName,
    lastName,
    title,
    subTitle,
    photo,
    role,
    jobTitle,
    phoneNumber,
    mobileNumber,
    email,
    faxNumber,
    linkedInProfile,
    description,
    startPageData,
    contentType,
  } = props;

  const cardPhoto =
    photo.value?.urlSmall && photo.value?.urlMedium && photo.value?.urlLarge
      ? photo
      : startPageData?.contactPersonImageFallback;
  const isGenericContact = contentType[1] === "ContactGenericBlock";

  return (
    <article className={styles.contact} data-testid="ContactCardOverlay">
      <div className={styles.bioSection}>
        <h4 className={styles.name}>
          {firstName && <RenderProperty value={firstName} />}{" "}
          {lastName && <RenderProperty value={lastName} />}
          {title && <RenderProperty value={title} />}
        </h4>
        <RenderProperty value={cardPhoto} className={styles.photo} />
      </div>
      <div className={styles.infoSection}>
        {jobTitle && (
          <RenderProperty value={jobTitle} className={styles.defaultText} />
        )}
        {role && <RenderProperty value={role} className={styles.role} />}
        {subTitle && (
          <RenderProperty value={subTitle} className={styles.defaultText} />
        )}
        {description && isGenericContact && (
          <RenderProperty value={description} className={styles.defaultText} />
        )}
        <div className={styles.contacts}>
          <div className={styles.contactLabels}>
            {phoneNumber?.value && <span>T</span>}
            {mobileNumber?.value && <span>M</span>}
            {faxNumber.value && <span>F</span>}
            {email.value && <span>E</span>}
          </div>
          <div className={styles.contactNumbers}>
            <a href={`tel: ${phoneNumber.value}`}>
              <span className={styles.accessibilityText}>
                Call to a number {phoneNumber.value}
              </span>
              <RenderProperty value={phoneNumber} />
            </a>

            {showPropertyOnEdit(mobileNumber) && (
              <a href={`tel: ${mobileNumber.value}`}>
                <span className={styles.accessibilityText}>
                  Call to a mobile number {mobileNumber.value}
                </span>
                <RenderProperty value={mobileNumber} />
              </a>
            )}

            {showPropertyOnEdit(faxNumber) && (
              <a href={`fax: ${faxNumber.value}`}>
                <span className={styles.accessibilityText}>
                  Fax to a number {faxNumber.value}
                </span>
                <RenderProperty value={faxNumber} />
              </a>
            )}
            <a href={`mailto:${email.value}`}>
              <span className={styles.accessibilityText}>
                Write an email to {email.value}
              </span>
              <RenderProperty value={email} />
            </a>
          </div>
        </div>

        {linkedInProfile?.value && (
          <div className={styles.linkedInIcon}>
            <a href={linkedInProfile.value} target="_blank noreferer noopener">
              <span className={styles.accessibilityText}>
                Go to LinkedIn profile {linkedInProfile.value}
              </span>
              <FAIcon icon="linkedin" className={styles.icon} />
            </a>
          </div>
        )}

        <div className={cn(styles.ctaButtons, styles.ctaButtonsOverlay)}>
          <div className={styles.iconWrapperMobile}>
            {linkedInProfile?.value && (
              <a
                href={linkedInProfile.value}
                target="_blank noreferer noopener"
              >
                <span className={styles.accessibilityText}>
                  Go to LinkedIn profile {linkedInProfile.value}
                </span>
                <FAIcon icon="linkedin" className={styles.icon} />
              </a>
            )}
            <a href={`mailto:${email.value}`}>
              <span className={styles.accessibilityText}>
                Write an email to {email.value}
              </span>
              <FAIcon icon="envelope" className={styles.icon} />
            </a>
            <a href={`tel: ${phoneNumber.value}`}>
              <span className={styles.accessibilityText}>
                Call to a phone number {phoneNumber.value}
              </span>
              <FAIcon icon="phone" className={styles.icon} />
            </a>

            <button
              className={styles.primaryCta}
              onClick={props.openFormMobile}
            >
              <span className={styles.accessibilityText}>Contact via form</span>
              <FAIcon icon="comments" className={styles.icon} />
            </button>
          </div>
        </div>
      </div>
    </article>
  );
}

function LocationCardOverlay(
  props: ContactLocationBlockData & {
    openFormMobile: () => void;
    startPageData: StartPageData;
  }
) {
  const styles = locationCardStyles();
  const {
    locationName,
    photo,
    role,
    address,
    email,
    phoneNumber,
    faxNumber,
    website,
    startPageData,
  } = props;

  const websiteLink = website?.value?.[0];
  const cardPhoto =
    photo.value?.urlSmall && photo.value?.urlMedium && photo.value?.urlLarge
      ? photo
      : startPageData?.contactLocationImageFallback;

  return (
    <article className={styles.contact} data-testid="ContactCardOverlay">
      <div className={styles.bioSection}>
        <div className={styles.nameWrapper}>
          <h4 className={styles.name}>
            <RenderProperty value={locationName} />
          </h4>
          {showPropertyOnEdit(role) && (
            <RenderProperty value={role} className={styles.role} />
          )}
        </div>
        <RenderProperty value={cardPhoto} className={styles.photo} />
      </div>
      <div className={cn(styles.infoSection, styles.infoSectionOverlay)}>
        <div className={cn(styles.address, styles.addressOverlay)}>
          <RenderProperty value={address} />
        </div>
        <div className={styles.contacts}>
          <div className={styles.contactLabels}>
            {phoneNumber.value && <span>T</span>}
            {faxNumber.value && <span>F</span>}
            {email.value && <span>E</span>}
            {websiteLink && <span className={styles.websiteAddress}>W</span>}
          </div>
          <div
            className={cn(styles.contactNumbers, styles.contactNumbersOverlay)}
          >
            <a href={`tel: ${phoneNumber.value}`}>
              <span className={styles.accessibilityText}>
                Call to a phone number {phoneNumber.value}
              </span>
              <RenderProperty value={phoneNumber} />
            </a>
            {showPropertyOnEdit(faxNumber) && (
              <a href={`fax: ${faxNumber.value}`}>
                <span className={styles.accessibilityText}>
                  Fax to a number {faxNumber.value}
                </span>
                <RenderProperty value={faxNumber} />
              </a>
            )}
            <a href={`mailto:${email.value}`}>
              <span className={styles.accessibilityText}>
                Write an email to {email.value}
              </span>
              <RenderProperty value={email} />
            </a>
            {websiteLink && (
              <a href={websiteLink.href} className={styles.websiteAddress}>
                <span className={styles.accessibilityText}>
                  Open a website {websiteLink.href}
                </span>
                <RenderProperty value={website} />
              </a>
            )}
          </div>
        </div>
        <div className={styles.ctaButtons}>
          {websiteLink && (
            <Button
              href={websiteLink.href}
              type={isExternal(websiteLink.href) ? "secondary" : "primary"}
              target={isExternal(websiteLink.href) ? "_blank" : "_self"}
            >
              <span className={styles.accessibilityText}>
                Open a website {websiteLink.href}
              </span>
              {isExternal(websiteLink.href) ? (
                <LocalizedLabel section="Contact" label="VisitWebsite" />
              ) : (
                <LocalizedLabel section="Contact" label="ShowDetails" />
              )}
            </Button>
          )}
          <div className={styles.iconWrapperMobile}>
            <a href={`mailto:${email.value}`}>
              <span className={styles.accessibilityText}>
                Write an email to {email.value}
              </span>
              <FAIcon icon="envelope" className={styles.icon} />
            </a>
            <a href={`tel: ${phoneNumber.value}`}>
              <span className={styles.accessibilityText}>
                Call to a phone number {phoneNumber.value}
              </span>
              <FAIcon icon="phone" className={styles.icon} />
            </a>
            <button
              className={styles.primaryCta}
              onClick={props.openFormMobile}
            >
              <span className={styles.accessibilityText}>Contact via form</span>
              <FAIcon icon="comments" className={styles.icon} />
            </button>
          </div>
        </div>
      </div>
    </article>
  );
}
