import React from "react";
import { RenderProperty } from "../../views/RenderProperty";
import {
  Row,
  Col,
  rem,
  onBreakpoint,
  CONTAINER_OFFSET_WIDTHS,
  Container,
  onBefore,
  MARGIN_OF_CONTAINER,
  GUTTER_WIDTHS,
} from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { typography, customTypography } from "../../basics/typography";
import cn from "classnames";
import { spacings } from "../../basics/spacings";
import { colors } from "../../basics/colors";
import { ButtonWrapper } from "../Button/Button";
import { ContentOptionsBlockProps } from "../../models/content/ContentOptionsBlockData";
import ContentOptionsItemBlockData from "../../models/content/ContentOptionsItemBlockData";
import { AnimatedRevealBlock } from "../AnimatedReveal/AnimatedReveal";

const useStyles = createUseStyles({
  element: {
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      display: "block",
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  isSingle: {},
  isThree: {},
  left: {},
  right: {},
  backgroundRowColumn: {
    display: "none",
    "&$left": {
      gridColumnStart: 1,

      "&:not($isThree) > div": {
        left: `calc(-${CONTAINER_OFFSET_WIDTHS["small"]}px)`,
        ...onBreakpoint("sm", {
          left: `calc(-${CONTAINER_OFFSET_WIDTHS["big"]}px)`,
        }),
        ...onBreakpoint("md", {
          left: `calc(-1 * ${MARGIN_OF_CONTAINER} - ${CONTAINER_OFFSET_WIDTHS["big"]}px)`,
        }),
      },
    },
    "&$right": {
      gridColumnStart: 7,
      "&:not($isThree) > div": {
        right: `calc(-${CONTAINER_OFFSET_WIDTHS["small"]}px)`,
        ...onBreakpoint("sm", {
          right: `calc(-${CONTAINER_OFFSET_WIDTHS["big"]}px)`,
        }),
        ...onBreakpoint("md", {
          right: `calc(-1 * ${MARGIN_OF_CONTAINER} - ${CONTAINER_OFFSET_WIDTHS["big"]}px)`,
        }),
      },
    },

    "& > div": {
      position: "absolute",
      height: "100%",
      width: "50vw",
    },
    "&$isSingle > div": {
      width: "100%",
    },
    "&$isThree > div": {
      display: "none",
      ...onBreakpoint("md", {
        display: "block",
        width: `calc(100% + ${GUTTER_WIDTHS["big"] / 2}px)`,
      }),
    },
    "&$right&$isThree": {
      gridColumnStart: 9,
      "& > div": {
        left: -GUTTER_WIDTHS["big"] / 2,
      },
    },
    "&$threeBackgroundItem&$isThree": {
      gridColumnStart: 5,
      gridColumnEnd: "span 4",
      "& > div": {
        width: `calc(100% + ${GUTTER_WIDTHS["big"]}px)`,
        left: -GUTTER_WIDTHS["big"] / 2,
      },
    },

    ...onBreakpoint("sm", {
      gridRow: 1,
      display: "block",
    }),
  },
  threeBackgroundItem: {
    display: "none",
    ...onBreakpoint("md", {
      display: "block",
    }),
  },
  lightItem: {
    backgroundColor: colors.gray10,
  },
  mobileDarkTabletDesktopLightItem: {
    backgroundColor: colors.gray20,
    ...onBreakpoint("sm", {
      backgroundColor: colors.gray10,
    }),
  },
  darkVeryDarkItem: {
    backgroundColor: colors.gray30,
  },
  darkItem: {
    backgroundColor: colors.gray20,
  },
  mobileLightTabletDesktopLDarkItem: {
    backgroundColor: colors.gray10,
    ...onBreakpoint("sm", {
      backgroundColor: colors.gray20,
    }),
  },
  rightBackgroundThreeItem: {
    backgroundColor: colors.gray10,
  },
});

export function ContentOptionsBlock(props: ContentOptionsBlockProps) {
  const styles = useStyles();
  const numberOfElements = props.data.contentOptions.value?.length || 0;

  const isThree = numberOfElements === 3;
  const isSingle = numberOfElements === 1;
  const isEven = !isSingle && !isThree;

  const evenStyleMap = [
    styles.darkItem,
    styles.lightItem,
    styles.mobileDarkTabletDesktopLightItem,
    styles.mobileLightTabletDesktopLDarkItem,
  ];
  const threeItemStyleMap = [
    styles.darkVeryDarkItem,
    styles.darkItem,
    styles.lightItem,
  ];
  const singleStyleMap = [styles.darkItem];
  const styleMap = isEven
    ? evenStyleMap
    : isThree
    ? threeItemStyleMap
    : singleStyleMap;

  const rows = numberOfElements > 3 ? 2 : 1;
  const divider = isEven ? 2 : 3;

  return (
    <div className={styles.element}>
      {new Array(rows).fill("").map((_, idx) => (
        <Container key={idx}>
          <Row>
            {/* Backgrounds */}
            {numberOfElements >= (idx + 1) * 2 && (
              <>
                <Col
                  sm={isThree ? 4 : 6}
                  className={cn(
                    styles.backgroundRowColumn,
                    styles.left,
                    isThree && styles.isThree
                  )}
                >
                  <div className={styleMap[idx * 2]} />
                </Col>
                <Col
                  sm={isThree ? 4 : 6}
                  className={cn(
                    styles.backgroundRowColumn,
                    styles.right,
                    isThree && styles.isThree
                  )}
                >
                  <div className={styleMap[idx * 2 + divider - 1]} />
                </Col>
              </>
            )}
            {(isSingle || isThree) && (
              <Col
                sm={10}
                smStart={1}
                md={8}
                mdStart={2}
                className={cn(
                  styles.backgroundRowColumn,
                  isSingle && styles.isSingle,
                  isThree && styles.isThree,
                  isThree && styles.threeBackgroundItem
                )}
              >
                <div className={cn(isThree ? styleMap[1] : styleMap[0])} />
              </Col>
            )}

            {/* Elements */}
            {(
              props.data.contentOptions
                .expandedValue as ContentOptionsItemBlockData[]
            )
              ?.filter(
                (_: ContentOptionsItemBlockData, iidx: number) =>
                  Math.floor(iidx / divider) === idx
              )
              .map((item: ContentOptionsItemBlockData, iidx: number) => {
                const index = idx * 2 + iidx;
                return (
                  <Card
                    isSingle={isSingle}
                    isThree={isThree}
                    first={iidx === 0}
                    last={iidx === divider - 1}
                    backgroundStyle={styleMap[index] || ""}
                    key={iidx}
                    revealDelay={iidx * 200}
                    {...item}
                  />
                );
              })}
          </Row>
        </Container>
      ))}
    </div>
  );
}

const useCardStyles = createUseStyles({
  first: {
    gridColumnStart: "auto",
    ...onBreakpoint("sm", {
      gridColumnStart: 1,
      paddingRight: rem(spacings.sam),
      ...onBreakpoint("md", {
        paddingRight: 0,
      }),
    }),
  },
  last: {
    "&$isEven": {
      gridColumnStart: "auto",
      ...onBreakpoint("sm", {
        gridColumnStart: 7,
        paddingLeft: rem(spacings.sam),
      }),

      ...onBreakpoint("md", {
        gridColumnStart: 8,
        paddingLeft: 0,
      }),
    },
  },
  isEven: {
    "&$last": onBefore("sm", {
      gridRow: 2,
    }),
  },
  isSingle: {
    gridColumnStart: "auto",
    ...onBreakpoint("sm", {
      gridColumnStart: 2,
      paddingLeft: rem(spacings.xl),
      paddingRight: rem(spacings.xl),
    }),
    ...onBreakpoint("md", {
      gridColumnStart: 4,
      padding: 0,
    }),
  },
  isThree: {
    gridRow: 2,
    "&$first": {
      gridRow: 1,
    },
    "&$last": {
      gridRow: 3,
    },
    ...onBreakpoint("sm", {
      gridColumnStart: 2,
      paddingLeft: rem(spacings.xl),
      paddingRight: rem(spacings.xl),
    }),
    ...onBreakpoint("md", {
      gridRow: 1,
      gridColumnStart: 5,
      "&$first": {
        gridRow: 1,
        gridColumnStart: 1,
      },
      "&$last": {
        gridRow: 1,
        gridColumnStart: 9,
      },

      paddingLeft: rem(spacings.l.add(spacings.xs)),
      paddingRight: rem(spacings.l.add(spacings.xs)),
    }),
  },

  card: {
    display: "flex",
    flexDirection: "column",
    paddingTop: rem(spacings.l),
    paddingBottom: rem(spacings.l),
    ...onBreakpoint("sm", {
      paddingTop: rem(spacings.xl),
      paddingBottom: rem(spacings.xl),
      "&:not($isThree)": {
        gridRow: 1,
      },
    }),
    ...onBreakpoint("md", {
      paddingTop: rem(spacings.m.times(2)),
      paddingBottom: rem(spacings.m.times(2)),
    }),
    ...onBefore("sm", {
      paddingLeft: rem(spacings.s),
      paddingRight: rem(spacings.s),
    }),
  },
  caption: {
    ...customTypography(
      {
        ...typography.caption,
        marginBottom: rem(spacings.xs),
      },
      {},
      {
        marginBottom: rem(spacings.s),
      }
    ),
  },
  headline: typography.h2,
  mobileBackground: {
    position: "absolute",
    height: "100%",
    width: "100vw",
    top: 0,
    left: `-${CONTAINER_OFFSET_WIDTHS["small"]}px`,
    zIndex: -1,
    ...onBreakpoint("sm", {
      display: "none",
    }),
  },
  description: customTypography(
    typography.textDefault,
    { marginBottom: rem(spacings.m) },
    { marginBottom: rem(spacings.xl) },
    { marginBottom: rem(spacings.m.times(2)) }
  ),
  buttons: {
    marginTop: "auto",
  },
  cardButton: {
    "&:first-of-type": {
      marginRight: rem(spacings.sam),
    },
    "&:not(:first-of-type)": {
      marginTop: rem(spacings.s),
    },
  },
  fullHeight: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
});

function Card(
  props: {
    isSingle: boolean;
    isThree: boolean;
    first: boolean;
    last: boolean;
    backgroundStyle: string;
    revealDelay: number;
  } & ContentOptionsItemBlockData
) {
  const styles = useCardStyles();
  const mdSize = props.isThree ? 4 : props.isSingle ? 6 : 5;
  const isEven = !props.isSingle && !props.isThree;
  return (
    <Col
      sm={isEven ? 6 : 10}
      md={mdSize}
      className={cn(
        props.first && styles.first,
        props.last && styles.last,
        props.isSingle && styles.isSingle,
        props.isThree && styles.isThree,
        isEven && styles.isEven,
        props.backgroundStyle,
        styles.card
      )}
    >
      <AnimatedRevealBlock
        revealDelay={props.revealDelay}
        className={styles.fullHeight}
      >
        <div className={cn(styles.mobileBackground, props.backgroundStyle)} />
        <RenderProperty value={props.caption} className={styles.caption} />
        <RenderProperty value={props.headline} className={styles.headline} />
        <RenderProperty
          value={props.description}
          className={styles.description}
        />
        <div className={styles.buttons}>
          {props.buttons.value?.map((button, idx) => (
            <ButtonWrapper
              {...button}
              key={idx}
              className={styles.cardButton}
            />
          ))}
        </div>
      </AnimatedRevealBlock>
    </Col>
  );
}
