import { useState, useEffect } from "react";

export function useLocationHash() {
  const [hash, setHash] = useState(window.location.hash);
  useEffect(() => {
    function updateHash() {
      hash !== window.location.hash && setHash(window.location.hash);
    }
    window.addEventListener("hashchange", updateHash);
    updateHash();
    return () => window.removeEventListener("hashchange", updateHash);
  }, []);
  return hash;
}
