import React, { useState, useRef, RefObject, useEffect } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import cn from "classnames";
import { Container, onBefore, onBreakpoint, rem } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { LanguagePicker } from "../LanguagePicker/LanguagePicker";
import { spacings } from "../../basics/spacings";
import StartPageData from "../../models/content/StartPageData";
import { LocalizedLabel } from "../../hooks/LocalizationContext";
import { animations } from "../../basics/animations";
import { pseudoSelectorShadow } from "../../basics/mixins";
import { LocaleSuggestion } from "../LocaleSuggestion/LocaleSuggestion";
import { MonsterNavigation } from "./MonsterNavigation/MonsterNavigation";
import Search from "./Search/Search";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import SearchBlockData from "../../models/content/SearchBlockData";
import { customTypography, typography } from "../../basics/typography";

const HEADER_TOP_CONTENT_HEIGHT = 40;

const useStyles = createUseStyles({
  header: ({ liftHeaderUpByPx }) => ({
    position: "sticky",
    top: 0,
    zIndex: 99,
    transition: "transform 0.3s ease",
    transform: `translateY(-${liftHeaderUpByPx}px)`,
  }),
  headerMixins: {
    ...onBefore("sm", {
      transform: "none !important",
    }),
  },
  headerTop: {
    backgroundColor: colors.gray20,
    display: "none",
    ...onBreakpoint("sm", {
      display: "block",
    }),
  },
  headerTopContent: {
    height: HEADER_TOP_CONTENT_HEIGHT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& ul": {
      display: "flex",
      padding: 0,
      "& li": {
        listStyle: "none",
        margin: `0 ${spacings.sam.divide(2)}`,
        ...onBreakpoint("md", {
          margin: `0 ${spacings.sam}`,
        }),
        "& a": {
          ...customTypography(
            {
              ...typography.textXSmall,
              marginBottom: 0,
              color: colors.gray100,
              transition: "color 0.3s ease",
              "&:hover": {
                color: colors.black,
              },
              ...animations.linkChevronEffect,
            },
            {},
            {
              marginBottom: 0,
            }
          ),
        },
      },
    },
  },
  navLeft: {
    display: "flex",
    alignItems: "center",
    "& li:first-child": {
      marginLeft: 0,
    },
  },
  navRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headerBottom: {
    display: "flex",
    alignItems: "center",
    height: 64,
    backgroundColor: colors.white,
    position: "relative",
    borderBottom: ({ openedMenu, openedSearch }) =>
      openedMenu || openedSearch ? `1px solid ${colors.gray20}` : 0,
    "&:after": {
      ...pseudoSelectorShadow("0 4px 6px 0 rgba(0, 0, 0, 0.09)"),
      height: 12,
      opacity: ({ openedMenu, openedSearch }) =>
        openedMenu || openedSearch ? 0 : 1,
      transition: "all 0.5s",
    },
    ...onBreakpoint("sm", {
      borderBottom: () => 0,
    }),
    ...onBreakpoint("md", {
      height: 88,
    }),
  },
  headerBottomContent: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    display: "flex",
    marginRight: "auto",
    "& img": {
      width: 42,
      height: 17,
      objectFit: "contain",
      ...onBreakpoint("sm", {
        width: 60,
        height: 24,
      }),
      ...onBreakpoint("md", {
        width: 72,
        height: 29,
      }),
    },
  },
  search: {
    marginRight: 36,
    cursor: "pointer",
    transition: "all .3s",
    ...FAIconStyles({
      width: 25,
      height: 25,
    }),
    ...onBefore("md", {
      marginRight: 24,
      ...FAIconStyles({
        width: 20,
        height: 20,
      }),
    }),
  },
  mainMenu: {
    padding: 0,
    border: "none",
    font: "inherit",
    color: "inherit",
    textAlign: "inherit",
    background: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& span": {
      ...typography.textLarge,
      marginBottom: 0,
      color: colors.gray100,
      display: "none",
      ...onBreakpoint("sm", {
        fontSize: 20,
        display: "block",
        marginRight: rem(spacings.sam.divide(2)),
      }),
      ...onBreakpoint("md", {
        fontSize: 24,
      }),
    },
    "& svg": {
      color: colors.black,
    },
  },
  hamburgerIcon: ({ openedMenu }) => ({
    width: 21,
    height: 17,
    position: "relative",

    cursor: "pointer",
    "& div": {
      position: "absolute",
      height: 2,
      width: "100%",
      background: colors.black,
      opacity: 1,
      left: 0,
      transition: "transform 0.25s ease, opacity 0.25s ease",
      "&:nth-child(1)": {
        top: openedMenu ? 7 : 0,
        left: openedMenu ? "50%" : 0,
        width: openedMenu ? "0%" : "100%",
      },
      "&:nth-child(2)": {
        top: 7,
        transform: openedMenu ? "rotate(45deg)" : "rotate(0deg)",
      },
      "&:nth-child(3)": {
        top: 7,
        transform: openedMenu ? "rotate(-45deg)" : "rotate(0deg)",
        opacity: openedMenu ? 1 : 0,
      },
      "&:nth-child(4)": {
        top: openedMenu ? 7 : 14,
        left: openedMenu ? "50%" : 0,
        width: openedMenu ? "0%" : "100%",
      },
    },
  }),
  contactUsButtonWrapper: {
    marginRight: rem(spacings.m),
    opacity: 0,
    transition: "opacity 0.65s ease",
    pointerEvents: "none",
    ...onBefore("sm", {
      display: "none",
    }),
  },
  contactUsButtonWrapperShow: {
    opacity: 1,
    pointerEvents: "auto",
  },
});

export function Header(props: {
  metaNavigationLeft: StartPageData["metaNavigationLeft"];
  metaNavigationRight: StartPageData["metaNavigationRight"];
  logo: StartPageData["logo"];
  logoLink: StartPageData["logoLink"];
  userLogin: StartPageData["userLogin"];
  languagePicker: StartPageData["languagePicker"];
  searchField: StartPageData["searchField"];
  searchPage: StartPageData["searchPage"];
  contactListRef?: RefObject<HTMLDivElement>;
  keyVisualRef?: RefObject<HTMLDivElement>;
  headerButtonPortalRef?: RefObject<HTMLDivElement>;
  homepageSearch: StartPageData["homepageSearch"];
}) {
  const [liftHeaderUpByPx, setLiftHeaderUpByPx] = useState(0);
  const [showCtaButton, setShowCtaButton] = useState(false);

  const menuToggler = useRef<HTMLButtonElement>(null);
  const localeSuggestionWrapperRef = useRef<HTMLDivElement>(null);

  const keyVisualButtonsPosition =
    props.keyVisualRef?.current &&
    props.keyVisualRef?.current?.getBoundingClientRect().top + window.scrollY;

  const contactListPosition =
    props.contactListRef?.current &&
    props.contactListRef?.current?.getBoundingClientRect().top + window.scrollY;

  const searchRef = useRef<HTMLDivElement>(null);

  useScrollPosition(
    ({ currPos, prevPos }) => {
      if (currPos.y === prevPos.y) {
        return;
      }

      const newVisibilityStatus: boolean =
        currPos.y < prevPos.y || currPos.y < 0 || prevPos.y < 0;

      const localeSuggestionWrapperHeight =
        localeSuggestionWrapperRef?.current?.getBoundingClientRect()?.height ||
        0;

      setLiftHeaderUpByPx(
        newVisibilityStatus
          ? 0
          : localeSuggestionWrapperHeight + HEADER_TOP_CONTENT_HEIGHT
      );

      if (!keyVisualButtonsPosition) return;

      const shouldShowButton =
        currPos.y - keyVisualButtonsPosition > -64 &&
        (contactListPosition ? contactListPosition - currPos.y > 64 : true);

      setShowCtaButton(shouldShowButton);
    },
    [liftHeaderUpByPx, showCtaButton],
    undefined,
    true,
    300
  );

  const [headerElementToggle, setHeaderElementToggle] = useState({
    openedMenu: false,
    openedSearch: false,
  });

  function menuToggle() {
    setHeaderElementToggle((s) => ({
      openedSearch: false,
      openedMenu: !s.openedMenu,
    }));
  }

  function searchToggle() {
    setHeaderElementToggle((s) => ({
      openedSearch: !s.openedSearch,
      openedMenu: false,
    }));
  }

  function handleClickOutside(event: any) {
    if (menuToggler.current && menuToggler.current.contains(event.target)) {
      setHeaderElementToggle((s) => ({
        openedMenu: s.openedMenu,
        openedSearch: false,
      }));
    } else if (searchRef.current && !searchRef.current.contains(event.target)) {
      headerElementToggle.openedSearch &&
        setHeaderElementToggle((s) => ({
          openedMenu: s.openedMenu,
          openedSearch: false,
        }));
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const openedMenu = headerElementToggle.openedMenu;
  const openedSearch = headerElementToggle.openedSearch;
  const styles = useStyles({ liftHeaderUpByPx, openedMenu, openedSearch });

  return (
    <header
      className={cn(styles.header, styles.headerMixins)}
      data-testid="header"
    >
      <div ref={localeSuggestionWrapperRef}>
        <LocaleSuggestion />
      </div>
      <div className={styles.headerTop}>
        <Container>
          <div className={styles.headerTopContent}>
            <nav className={styles.navLeft}>
              <ul>
                {props.metaNavigationLeft.value?.map((l, idx) => (
                  <li key={idx}>
                    <a href={l.href} title={l.title} target={l.target}>
                      {l.text}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>

            <nav className={styles.navRight}>
              <ul>
                {props.metaNavigationRight.value?.map((l, idx) => (
                  <li key={idx}>
                    <a href={l.href} title={l.title} target={l.target}>
                      {l.text}
                    </a>
                  </li>
                ))}
                {props.userLogin.value && (
                  <li>
                    <a href="#">
                      <LocalizedLabel section="Global" label="Login" />
                    </a>
                  </li>
                )}
              </ul>
              {props.languagePicker.value && <LanguagePicker />}
            </nav>
          </div>
        </Container>
      </div>

      <div className={styles.headerBottom}>
        <Container>
          <div className={styles.headerBottomContent}>
            <a
              href={props.logoLink.value ? props.logoLink.value : undefined}
              className={styles.logo}
            >
              <img src={props.logo.value?.url || undefined} alt="logo" />
            </a>

            <div
              ref={props.headerButtonPortalRef}
              className={cn(
                styles.contactUsButtonWrapper,
                showCtaButton && styles.contactUsButtonWrapperShow
              )}
            />

            {props.searchField.value && headerElementToggle.openedSearch ? (
              <FAIcon
                icon="times"
                className={styles.search}
                onClick={searchToggle}
                aria-controls="search"
              />
            ) : (
              <FAIcon
                icon="search"
                className={styles.search}
                onClick={searchToggle}
                aria-controls="search"
              />
            )}

            <button
              aria-controls="main-menu"
              className={styles.mainMenu}
              ref={menuToggler}
              onClick={menuToggle}
              onMouseUp={() =>
                menuToggler.current && menuToggler.current.blur()
              }
            >
              <span>Menu</span>
              <div className={styles.hamburgerIcon} aria-hidden={true}>
                <div />
                <div />
                <div />
                <div />
              </div>
            </button>
          </div>
        </Container>
      </div>

      <MonsterNavigation
        isOpened={openedMenu}
        languagePicker={props.languagePicker}
        userLogin={props.userLogin}
      />
      <div ref={searchRef}>
        <Search
          isOpened={headerElementToggle.openedSearch}
          searchPage={props.searchPage}
          homepageSearch={
            props.homepageSearch?.expandedValue as SearchBlockData
          }
        />
      </div>
    </header>
  );
}
