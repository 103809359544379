import React, { CSSProperties } from "react";
import { createUseStyles } from "react-jss";
import { RenderProperty } from "../../views/RenderProperty";
import { typography } from "../../basics/typography";
import {
  Col,
  Container,
  onBreakpoint,
  rem,
  Row,
  GUTTER_WIDTHS,
} from "../../basics/layout";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { Button } from "../Button/Button";
import IContent from "../../models/IContent";
import StartPageData from "../../models/content/StartPageData";
import cn from "classnames";
import { Property } from "../../models/Property";
import ContentLink from "../../models/ContentLink";
import { LocalizedLabel } from "../../hooks/LocalizationContext";
import { AnimatedRevealBlock } from "../AnimatedReveal/AnimatedReveal";

export type RelatedPageType = IContent & {
  captionHeadline: StartPageData["captionHeadline"];
  metaTitle: StartPageData["metaTitle"];
  metaImageAdaptive: StartPageData["metaImageAdaptive"];
  metaTaxonomy: StartPageData["metaTaxonomy"];
};

const useStyles = createUseStyles({
  relatedContent: {
    marginBottom: rem(spacings.s),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.sam),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.m),
    }),
  },
  headline: {
    ...typography.h2,
    textAlign: "center",
  },
  relatedElement: {
    marginBottom: rem(spacings.s),
    ...onBreakpoint("sm", {
      height: `calc(100% - ${spacings.m})`,
      marginBottom: rem(spacings.m),
    }),
  },
  elementContent: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.gray10,
    padding: `${spacings.sam} ${spacings.sam} ${spacings.m}`,
    flexGrow: 1,
    alignSelf: "stretch",
    position: "relative",
  },
  singleElementContent: {
    "&:before": {
      position: "absolute",
      height: "100%",
      backgroundColor: colors.gray10,
      top: 0,
      left: 0,
      ...onBreakpoint("sm", {
        content: '""',
        left: -GUTTER_WIDTHS["small"],
        width: GUTTER_WIDTHS["small"],
      }),
      ...onBreakpoint("md", {
        left: -GUTTER_WIDTHS["big"],
        width: GUTTER_WIDTHS["big"],
      }),
    },
  },
  elementContentLink: {
    marginTop: "auto",
    justifyContent: "flex-start",
  },
  category: {
    ...typography.caption,
    fontSize: "1.25rem",
    lineHeight: 1.6,
    marginBottom: rem(spacings.xs),
    ...onBreakpoint("sm", {
      ...typography.caption,
      fontSize: "1rem",
      lineHeight: 1.5,
      marginBottom: rem(spacings.xs),
    }),
  },
  title: typography.h4,
  relatedElementImageWrapper: {
    width: "100%",
    flexShrink: 0,
  },
  relatedElementImageLink: {
    width: "100%",
    display: "block",
    overflow: "hidden",
  },
  relatedElementImage: {
    transform: "none",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.03)",
      transition: "transform 0.3s ease",
    },
  },
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  fullWidth: {
    width: "100%",
  },
});

export function RelatedContentBlock(props: {
  relatedContent: Property<Array<ContentLink>>;
}) {
  const relatedContentElements = props.relatedContent?.expandedValue as
    | RelatedPageType[]
    | null;

  const styles = useStyles(relatedContentElements?.length);

  const isOneElement = relatedContentElements?.length === 1;
  const isTwoElements = relatedContentElements?.length === 2;
  const isFourElements = relatedContentElements?.length === 4;

  const columnsToSpread = isFourElements ? 3 : 4;

  return (
    <div className={styles.relatedContent}>
      <h2 className={styles.headline}>
        <LocalizedLabel section="Global" label="RelatedContent" />
      </h2>
      <AnimatedRevealBlock revealDelay={isOneElement ? 0 : -1}>
        <Container>
          <Row>
            {isOneElement ? (
              <>
                {relatedContentElements?.map((item, idx) => (
                  <React.Fragment key={idx}>
                    <Col
                      sm={6}
                      md={4}
                      mdStart={2}
                      className={styles.relatedElementImageWrapper}
                    >
                      <a
                        href={item.url || undefined}
                        className={styles.relatedElementImageLink}
                      >
                        <RenderProperty
                          value={item.metaImageAdaptive}
                          className={styles.relatedElementImage}
                        />
                      </a>
                    </Col>
                    <Col sm={6} md={4}>
                      <div
                        className={cn(
                          styles.singleElementContent,
                          styles.elementContent
                        )}
                      >
                        <RenderProperty
                          value={item.captionHeadline}
                          className={styles.category}
                        />

                        <h4 className={styles.title}>
                          <RenderProperty
                            value={item.metaTitle}
                            fallbackValue={item.name}
                          />
                        </h4>
                        <Button
                          href={item.url || undefined}
                          type="link"
                          className={styles.elementContentLink}
                        >
                          <LocalizedLabel section="Global" label="ReadMore" />
                        </Button>
                      </div>
                    </Col>
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>
                {relatedContentElements?.map((item, idx) => (
                  <Col
                    sm={4}
                    smStart={
                      idx % 2 === 0 && (isTwoElements || isFourElements)
                        ? 2
                        : null
                    }
                    md={columnsToSpread}
                    mdStart={idx === 0 && isTwoElements ? 2 : null}
                    style={{ msGridRow: idx } as CSSProperties}
                    key={idx}
                  >
                    <AnimatedRevealBlock
                      revealDelay={idx * 200}
                      className={styles.relatedElement}
                    >
                      <div className={styles.flexWrapper}>
                        <a
                          href={item.url || undefined}
                          className={cn(
                            styles.relatedElementImageWrapper,
                            styles.relatedElementImageLink
                          )}
                        >
                          <RenderProperty
                            value={item.metaImageAdaptive}
                            className={styles.relatedElementImage}
                          />
                        </a>
                        <div className={styles.elementContent}>
                          <RenderProperty
                            value={item.captionHeadline}
                            className={styles.category}
                          />

                          <h4 className={styles.title}>
                            <RenderProperty
                              value={item.metaTitle}
                              fallbackValue={item.name}
                            />
                          </h4>
                          <Button
                            href={item.url || undefined}
                            type="link"
                            className={styles.elementContentLink}
                          >
                            <LocalizedLabel section="Global" label="ReadMore" />
                          </Button>
                        </div>
                      </div>
                    </AnimatedRevealBlock>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </Container>
      </AnimatedRevealBlock>
    </div>
  );
}
