import React, { ReactNode, useEffect, useRef, useState } from "react";
import { rem } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { typography } from "../../basics/typography";
import { spacings } from "../../basics/spacings";
import { colors } from "../../basics/colors";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import cn from "classnames";

const useAccordionStyles = createUseStyles({
  accordion: {
    margin: 0,
    borderTop: ({ noOuterBorder }) =>
      noOuterBorder ? 0 : `2px solid ${colors.gray20}`,
    borderBottom: ({ noOuterBorder }) =>
      noOuterBorder ? 0 : `2px solid ${colors.gray20}`,
    listStyle: "none",
    padding: 0,
  },
});

const useAccordionItemStyles = createUseStyles({
  accordion: {
    width: "100%",
  },
  title: {
    ...typography.textLarge,
    margin: "0 !important",
  },
  icon: FAIconStyles({
    width: 24,
    height: 24,
  }),
  item: {
    "&:not(:first-child)": {
      borderTop: `2px solid ${colors.gray20}`,
    },
  },
  itemContent: ({ active }) => ({
    maxHeight: active ? "2000px" : 0,
    opacity: active ? 1 : 0,
    pointerEvents: active ? "all" : "none",
    overflow: active ? "visible" : "hidden",
    transition: "opacity 0.3s ease, max-height 0.3s ease, padding 0.3s ease",
    padding: active
      ? `${spacings.xs} ${spacings.sam} ${spacings.sam}`
      : `0 ${spacings.sam} 0 ${spacings.sam}`,
    "& *:last-child": {
      marginBottom: 0,
    },
  }),
  toggler: {
    padding: rem(spacings.s),
    border: "none",
    font: "inherit",
    color: "inherit",
    textAlign: "inherit",
    background: "none",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  visuallyHidden: {
    ...typography.visuallyHidden,
  },
});

export interface AccordionProps {
  children: ReactNode;
  className?: string;
  noOuterBorder?: boolean;
  "data-testid"?: string;
}

export interface AccordionItemProps {
  headline: string | ReactNode;
  children: ReactNode;
  className?: string;
  initialIsActive?: boolean;
  onActivate?: () => void;
}

export function Accordion(props: AccordionProps) {
  const styles = useAccordionStyles({ noOuterBorder: props.noOuterBorder });

  return (
    <ul
      className={cn(props.className, styles.accordion)}
      data-testid={props["data-testid"]}
    >
      {props.children}
    </ul>
  );
}

export function AccordionItem(props: AccordionItemProps) {
  const [active, setActive] = useState<boolean>(Boolean(props.initialIsActive));
  const toggler = useRef<HTMLButtonElement>(null);

  const styles = useAccordionItemStyles({ active });

  useEffect(() => {
    if (active && props.onActivate) {
      props.onActivate();
    }
  }, [active]);

  return (
    <li
      className={cn(styles.item, props.className)}
      data-testid="AccordionItem"
    >
      <button
        className={styles.toggler}
        onClick={() => setActive((s) => !s)}
        ref={toggler}
        onMouseUp={() => toggler.current && toggler.current.blur()}
      >
        <span className={styles.title}>{props.headline}</span>
        {active ? (
          <>
            <span className={styles.visuallyHidden}>(click to expand)</span>
            <FAIcon icon="chevron-up" className={styles.icon} />
          </>
        ) : (
          <>
            <span className={styles.visuallyHidden}>(click to shrink)</span>
            <FAIcon icon="chevron-down" className={styles.icon} />
          </>
        )}
      </button>

      <div
        className={styles.itemContent}
        aria-expanded={active}
        data-testid="AccordionItemContent"
      >
        {props.children}
      </div>
    </li>
  );
}
