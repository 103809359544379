import React from "react";
import { RenderProperty } from "../../views/RenderProperty";
import { FooterLinkBlockProps } from "../../models/content/FooterLinkBlockData";
import { createUseStyles } from "react-jss";
import { spacings } from "../../basics/spacings";
import cn from "classnames";
import { rem } from "../../basics/layout";
import { FAIconStyles, FAIcon } from "../FAIcon/FAIcon";
import { colors } from "../../basics/colors";

const useStyles = createUseStyles({
  flatList: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    "& a": {
      marginBottom: 10,
      color: colors.gray80,
      transition: "color 0.2s ease",
      "&:hover": {
        color: colors.gray100,
        transition: "color 0.2s ease",
      },
    },
  },
  mobilePadding: {
    paddingTop: rem(spacings.m),
  },
  mobileArea: {
    width: "100%",
    padding: 0,
    border: "none",
    font: "inherit",
    color: "inherit",
    textAlign: "inherit",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headline: {
    color: colors.gray100,
  },
  blockPadding: {
    marginBottom: rem(spacings.m),
  },
  icon: FAIconStyles({
    width: 24,
    height: 24,
  }),
});

export default function FooterLinkBlock(
  props: FooterLinkBlockProps & {
    context?: {
      setOpenSection?: (f: (section: string) => string) => void;
      openSection?: string;
      uncollapsed?: boolean;
    };
  }
) {
  const styles = useStyles();
  const guid = props.data.contentLink.guidValue;
  return props.context ? (
    <div className={styles.mobilePadding}>
      <button
        onClick={() =>
          props.context?.setOpenSection &&
          props.context?.setOpenSection((section: string) =>
            section === guid ? "" : guid || ""
          )
        }
        className={styles.mobileArea}
      >
        <b>
          <RenderProperty
            className={cn(styles.headline, props.className)}
            value={props.data.headline}
          />
        </b>
        {!props.context.uncollapsed &&
          (props.context.openSection === guid ? (
            <FAIcon icon="chevron-up" className={styles.icon} />
          ) : (
            <FAIcon icon="chevron-down" className={styles.icon} />
          ))}
      </button>
      {(props.context.openSection === guid || props.context.uncollapsed) && (
        <RenderProperty
          className={cn(props.className, styles.flatList)}
          value={props.data.links}
        />
      )}
    </div>
  ) : (
    <div className={styles.blockPadding}>
      <b>
        <RenderProperty
          className={cn(styles.headline, props.className)}
          value={props.data.headline}
        />
      </b>
      <RenderProperty
        value={props.data.links}
        className={cn(props.className, styles.flatList)}
      />
    </div>
  );
}
