import React from "react";
import { ExtendedUrlElementBlockProps } from "../../../../models/content/ExtendedUrlElementBlockData";
import { RenderProperty } from "../../../../views/RenderProperty";
import {
  FormProps,
  useCommonFormStyles,
  getColumnStyle,
  getFieldProps,
  BreakFormLayout,
} from "./common";
import cn from "classnames";

export function FormUrl(
  props: ExtendedUrlElementBlockProps & FormProps<string>
) {
  const commonFormStyles = useCommonFormStyles();
  const columnStyle =
    commonFormStyles[getColumnStyle(props.data.horizontalLayout.value)];
  const newRow = props.data.newRow.value;
  const validationError =
    props.isValid === true ? null : props.isValid.join(" ");
  const showValid = props.isBlurred && !validationError;
  const showInvalid = props.isBlurred && validationError;

  return (
    <>
      {newRow && <BreakFormLayout />}
      <div className={columnStyle}>
        <label>
          <RenderProperty
            value={props.data.label}
            className={commonFormStyles.label}
          />
          <div
            className={cn(
              commonFormStyles.inputWrapper,
              showValid && commonFormStyles.validIcon,
              showInvalid && commonFormStyles.invalidIcon
            )}
          >
            <input
              {...getFieldProps(props.data)}
              className={commonFormStyles.inputBox}
              type="url"
              placeholder={props.data.placeHolder.value || undefined}
              value={props.value || ""}
              onChange={(e) => props.onChange(e.target.value)}
              onBlur={props.setBlurred}
            />
            <div
              className={cn(
                commonFormStyles.underlinePrimary,
                showValid && commonFormStyles.validUnderlinePrimary,
                showInvalid && commonFormStyles.invalidUnderlinePrimary
              )}
            />
          </div>
        </label>
        <div className={commonFormStyles.validationError}>
          {validationError}
        </div>
      </div>
    </>
  );
}
