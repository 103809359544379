import React, { useContext, useState, useEffect, useRef } from "react";
import { ProductPageProps } from "../../models/content/ProductPageData";
import { Header } from "../Header/Header";
import { Footer } from "../Shared/Footer";
import { RenderProperty } from "../../views/RenderProperty";
import { KeyVisual } from "../KeyVisual/KeyVisual";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import { DownloadBlock, DownloadProps } from "../Block/DownloadBlock";
import { RelatedContentBlock } from "../Block/RelatedContentBlock";
import { ContactExpert } from "../ContactExpert/ContactExpert";

export function ProductPage(props: ProductPageProps) {
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const contactListRef = useRef<HTMLDivElement>(null);
  const keyVisualRef = useRef<HTMLDivElement>(null);
  const headerButtonPortalRef = useRef<HTMLDivElement>(null);
  const isUserRetention = props.data.contactCta?.value === "enabled";

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  if (!startPageData) return null;

  const contactExpertData = props.data.contactsUseDefaultContent?.value
    ? startPageData
    : props.data;

  return (
    <>
      <Header
        {...startPageData}
        contactListRef={contactListRef}
        keyVisualRef={keyVisualRef}
        headerButtonPortalRef={headerButtonPortalRef}
      />
      <main data-testid="ProductPage">
        <KeyVisual
          {...props.data.keyVisual}
          contactListRef={contactListRef}
          keyVisualRef={keyVisualRef}
          headerButtonPortalRef={headerButtonPortalRef}
          isUserRetention={isUserRetention}
        />
        <RenderProperty value={props.data.keyFacts} />
        <RenderProperty value={props.data.createInterest} />
        <RenderProperty value={props.data.segmentUsers} />
        <RenderProperty value={props.data.additionalContent} />
        <RenderProperty value={props.data.createTrust} />
        {(props.data.downloadsGlobal?.value ||
          props.data.downloadsLocal?.value) && (
          <DownloadBlock
            downloadItems={[
              ...((props.data.downloadsLocal
                ?.expandedValue as DownloadProps[]) || []),
              ...((props.data.downloadsGlobal
                ?.expandedValue as DownloadProps[]) || []),
            ]}
          />
        )}

        {isUserRetention && (
          <div ref={contactListRef}>
            <ContactExpert {...contactExpertData} />
          </div>
        )}

        {props.data.relatedContent?.value && (
          <RelatedContentBlock relatedContent={props.data.relatedContent} />
        )}
      </main>
      <Footer {...startPageData} />
    </>
  );
}
