import React from "react";
import { createUseStyles } from "react-jss";
import { ExtendedChoiceElementBlockProps } from "../../../../models/content/ExtendedChoiceElementBlockData";
import { Checkbox } from "../../../Checkbox/Checkbox";
import { RadioButton } from "../../../RadioButton/RadioButton";
import {
  BreakFormLayout,
  ElementPropsWithOptions,
  FormProps,
  getColumnStyle,
  useCommonFormStyles,
} from "./common";
import cn from "classnames";
import { onBreakpoint } from "../../../../basics/layout";
import { RenderProperty } from "../../../../views/RenderProperty";

const useStyles = createUseStyles({
  choice: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  horizontal: onBreakpoint("sm", {
    flexDirection: "row",
  }),
});

export function FormChoice(
  props: ElementPropsWithOptions<ExtendedChoiceElementBlockProps> &
    FormProps<string>
) {
  const Element = props.data.allowMultiSelect.value ? Checkbox : RadioButton;
  const styles = useStyles();
  const commonFormStyles = useCommonFormStyles();
  const columnStyle =
    commonFormStyles[getColumnStyle(props.data.horizontalLayout.value)];
  const valueSet = new Set<string>(props.value?.split(","));
  const onChangeMulti = (value: string) => {
    valueSet.has(value) ? valueSet.delete(value) : valueSet.add(value);
    props.onChange(Array.from(valueSet.values()).join(","));
  };
  const onChange = props.data.allowMultiSelect.value
    ? onChangeMulti
    : props.onChange;
  const isHorizontal = props.data.orientation.value === "horizontal";
  const newRow = props.data.newRow.value;
  const validationError =
    props.isValid === true ? null : props.isValid.join(" ");

  const options = props.data.options;

  return (
    <>
      {newRow && <BreakFormLayout />}
      <div className={columnStyle}>
        <RenderProperty
          value={props.data.label}
          className={commonFormStyles.label}
        />
        <div
          className={cn(
            commonFormStyles.inputWrapper,
            styles.choice,
            isHorizontal && styles.horizontal
          )}
        >
          {options.map((option) => (
            <Element
              key={option.value + option.caption}
              label={option.caption}
              checked={valueSet.has(option.value)}
              onCheck={() => onChange(option.value)}
              onBlur={props.setBlurred}
            />
          ))}
        </div>
        <div className={commonFormStyles.validationError}>
          {validationError}
        </div>
      </div>
    </>
  );
}
