import React, { useReducer } from "react";
import IContent from "../models/IContent";
import ContentLink from "../models/ContentLink";
import Website from "../models/Website";
import StartPageData from "../models/content/StartPageData";
import GlobalSettings from "../models/GlobalSettings";
import MonsterNavigationColumns from "../models/MonsterNavigation";
import Breadcrumbs from "../models/Breadcrumbs";
import Contacts from "../models/Contacts";

export type GlobalContentStoreStateType = {
  mapping: Map<string, IContent>;
  startPageContentLink: ContentLink | null;
  navigation: MonsterNavigationColumns | null;
  breadcrumb: Breadcrumbs | null;
  contacts: Contacts | null;
  website: Website | null;
  mainPageContentLink: ContentLink | null;
  isInitialized: boolean;
};

type GlobalContentStoreActionType =
  | {
      type: "initializeStore";
      newState: GlobalContentStoreStateType;
    }
  | {
      type: "update";
      newMapping: [string, IContent][];
    };

export type InitialDataType = {
  content: IContent;
  contentLink: ContentLink;
  website: Website;
  startPageData: IContent & Partial<StartPageData>;
  globalSettings: GlobalSettings;
};

export function getUniqueId(item: IContent | ContentLink): string {
  const contentLink = item.hasOwnProperty("contentLink")
    ? (item as IContent).contentLink
    : (item as ContentLink);

  return `${contentLink.id}`;
}

// INFO(mkarol): Annotate props with key
function fieldNameAnnotator<T extends { [key: string]: any }>(content: T): T {
  return Object.entries(content).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]:
        typeof value === "object" && value !== null
          ? Array.isArray(value)
            ? value.map((item, idx) =>
                typeof item === "object" && item !== null
                  ? fieldNameAnnotator({ ...item, __key__: `${key}[${idx}]` })
                  : item
              )
            : fieldNameAnnotator({ ...value, __key__: key })
          : value,
    }),
    {} as any
  ) as T;
}

function extractContentMapping(
  content: IContent,
  visitedIds: Set<string>
): Array<[string, IContent]> {
  const id = getUniqueId(content);
  if (visitedIds.has(id)) return [];
  const updatedVisitedIds = new Set(visitedIds).add(id);

  const children: Array<[string, IContent]> = Object.values(content)
    .flatMap((value) =>
      value && value.expandedValue
        ? Array.isArray(value.expandedValue)
          ? value.expandedValue.flatMap((ev: any) =>
              extractContentMapping(ev, updatedVisitedIds)
            )
          : extractContentMapping(value.expandedValue, updatedVisitedIds)
        : undefined
    )
    .filter(Boolean);

  const mapping: [string, IContent] = [id, content];
  return [mapping, ...children];
}

function reducer(
  state: GlobalContentStoreStateType,
  action: GlobalContentStoreActionType
) {
  switch (action.type) {
    case "initializeStore": {
      return { ...action.newState };
    }
    case "update": {
      return {
        ...state,
        mapping: new Map([
          ...Array.from(state.mapping.entries()),
          ...action.newMapping,
        ]),
      };
    }
    default:
      throw new Error();
  }
}

export function initGlobalContentStore(
  initialData: InitialDataType,
  isEditing?: boolean
): GlobalContentStoreStateType {
  return {
    mapping: isEditing
      ? new Map([
          ...(
            initialData.globalSettings.navigation.menuContentArea ?? []
          ).flatMap((item) =>
            extractContentMapping(fieldNameAnnotator(item), new Set())
          ),
          ...extractContentMapping(
            fieldNameAnnotator(initialData.startPageData),
            new Set()
          ),
          ...extractContentMapping(
            fieldNameAnnotator(initialData.content),
            new Set()
          ),
        ])
      : new Map([
          ...(
            initialData.globalSettings.navigation.menuContentArea ?? []
          ).flatMap((item) => extractContentMapping(item, new Set())),
          ...extractContentMapping(initialData.startPageData, new Set()),
          ...extractContentMapping(initialData.content, new Set()),
        ]),
    startPageContentLink: initialData.startPageData.contentLink,
    navigation: initialData.globalSettings.navigation,
    breadcrumb: initialData.globalSettings.breadcrumb,
    contacts: initialData.globalSettings.contacts,
    website: initialData.website,
    mainPageContentLink: initialData.contentLink,
    isInitialized: true,
  };
}

export function useGlobalContentStore(
  initialData: InitialDataType | null,
  isEditing: boolean
) {
  const [state, dispatch] = useReducer(
    reducer,
    (initialData && initGlobalContentStore(initialData, isEditing)) || {
      mapping: new Map(),
      startPageContentLink: null,
      breadcrumb: null,
      navigation: null,
      contacts: null,
      website: null,
      mainPageContentLink: null,
      isInitialized: false,
    }
  );

  function getByContentLinkId(contentLinkId: string) {
    return state.mapping.get(contentLinkId);
  }

  async function getFromAPIByLinkId(contentLinkId: string): Promise<IContent> {
    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append("expand", "*");
    searchQueryParams.append("epieditmode", "True");
    searchQueryParams.append(
      "cacheBuster",
      Math.round(Math.random() * 100000000).toString()
    );
    searchQueryParams.append("location", encodeURI(window.location.pathname));
    return fetch(
      `/api/episerver/v2.0/content/${contentLinkId}?${searchQueryParams.toString()}`,
      {
        headers: [
          ["Accepted-Language", state.website?.currentLanguage.name || "en"],
        ],
      }
    ).then((r) => r.json());
  }

  async function getByContentLink(contentLink: ContentLink) {
    const id = getUniqueId(contentLink);

    return getByContentLinkId(id) || getFromAPIByLinkId(id);
  }

  function isInitialized() {
    return state.isInitialized;
  }

  async function updateWithContentLinkId(contentLinkId: string) {
    const newContent = await getFromAPIByLinkId(contentLinkId);
    dispatch({
      type: "update",
      newMapping: extractContentMapping(
        fieldNameAnnotator(newContent),
        new Set()
      ),
    });
  }

  async function getStartPageData() {
    return (
      state.startPageContentLink &&
      (getByContentLink(state.startPageContentLink) as Promise<StartPageData>)
    );
  }

  function getImmediateByContentLink(contentLink: ContentLink) {
    const id = getUniqueId(contentLink);
    return getByContentLinkId(id) || null;
  }

  function getImmediateStartPageData() {
    return (
      (state.startPageContentLink &&
        (getImmediateByContentLink(
          state.startPageContentLink
        ) as StartPageData)) ||
      null
    );
  }

  function getNavigation() {
    return state.navigation;
  }

  function getBreadcrumbs() {
    return state.breadcrumb;
  }

  function getContacts() {
    return state.contacts;
  }

  function getWebsiteData() {
    return state.website;
  }

  function getMainPageContentLink() {
    return state.mainPageContentLink;
  }

  return {
    getByContentLink,
    updateWithContentLinkId,
    getStartPageData,
    getNavigation,
    getBreadcrumbs,
    getContacts,
    getWebsiteData,
    isInitialized,
    getImmediateByContentLink,
    getImmediateStartPageData,
    getMainPageContentLink,
  };
}

export type GlobalContentStoreContextType = {
  getByContentLink: (contentLink: ContentLink) => Promise<IContent | null>;
  getStartPageData: () => Promise<StartPageData | null>;
  getNavigation: () => MonsterNavigationColumns | null;
  getBreadcrumbs: () => Breadcrumbs | null;
  getContacts: () => Contacts | null;
  getWebsiteData: () => Website | null;
  getMainPageContentLink: () => ContentLink | null;
  isInitialized: () => void;
  getImmediateByContentLink: (contentLink: ContentLink) => IContent | null;
  getImmediateStartPageData: () => StartPageData | null;
};

export const GlobalContentStoreContext =
  React.createContext<GlobalContentStoreContextType>({
    getByContentLink: async () => null,
    getStartPageData: async () => null,
    getNavigation: () => null,
    getBreadcrumbs: () => null,
    getContacts: () => null,
    getWebsiteData: () => null,
    getMainPageContentLink: () => null,
    isInitialized: () => false,
    getImmediateByContentLink: () => null,
    getImmediateStartPageData: () => null,
  });
