import React, { useContext, useState } from "react";
import FormContainerBlockData from "../../../models/content/FormContainerBlockData";
import ExtendedChoiceElementBlockData from "../../../models/content/ExtendedChoiceElementBlockData";
import ExtendedNumberElementBlockData from "../../../models/content/ExtendedNumberElementBlockData";
import ExtendedSelectionElementBlockData from "../../../models/content/ExtendedSelectionElementBlockData";
import ExtendedTextAreaElementBlockData from "../../../models/content/ExtendedTextAreaElementBlockData";
import ExtendedTextFormBlockData from "../../../models/content/ExtendedTextFormBlockData";
import ExtendedUrlElementBlockData from "../../../models/content/ExtendedUrlElementBlockData";
import MappedValueElementBlockData from "../../../models/content/MappedValueElementBlockData";
import { RenderProperty } from "../../../views/RenderProperty";
import { Button } from "../../Button/Button";
import { FormChoice } from "./Elements/FormChoice";
import { FormNumber } from "./Elements/FormNumber";
import { FormSelect } from "./Elements/FormSelect";
import { FormTextArea } from "./Elements/FormTextArea";
import { FormText } from "./Elements/FormText";
import { FormUrl } from "./Elements/FormUrl";
import { FormRichText } from "./Elements/FormRichText";
import { createUseStyles } from "react-jss";
import { rem, onBreakpoint, GUTTER_WIDTHS } from "../../../basics/layout";
import { spacings } from "../../../basics/spacings";
import { typography } from "../../../basics/typography";
import ParagraphTextElementBlockData from "../../../models/content/ParagraphTextElementBlockData";
import { useBlurred } from "./useBlurred";
import { getValidators } from "./validation";
import { hasOptions, hasValidators, WithOptions } from "./Elements/common";
import { EpiFormProps } from "./FormContainerBlock";
import { GlobalContentStoreContext } from "../../../hooks/GlobalContentStore";
import { CONTACT_MAIL_PLACEHOLDER } from "./placeholders";
import { LocalizedLabel } from "../../../hooks/LocalizationContext";

type FormElement =
  | ExtendedChoiceElementBlockData
  | ExtendedNumberElementBlockData
  | ExtendedSelectionElementBlockData
  | ExtendedTextAreaElementBlockData
  | ExtendedTextFormBlockData
  | ExtendedUrlElementBlockData
  | MappedValueElementBlockData
  | ParagraphTextElementBlockData;

function renderElement(
  data: FormElement,
  getValue: (fieldId: string) => any,
  setValue: (fieldId: string, value: any) => void,
  isFieldBlurred: (fieldId: string) => boolean,
  setFieldBlurred: (fieldId: string) => void,
  validateField: (fieldId: string) => void,
  getIsFieldValid: (fieldId: string) => true | string[]
) {
  const elementType = data.contentType.join("/");
  const fieldId = String(data.contentLink.id);
  const value = getValue(fieldId);
  const isBlurred = isFieldBlurred(fieldId);
  const onChange = (value: any) => setValue(fieldId, value);
  const setBlurred = () => {
    // FIXME(mkarol): Delay blur event so click on submit could be executed
    window.setTimeout(() => {
      setFieldBlurred(fieldId);
      validateField(fieldId);
    }, 500);
  };
  const isValid = getIsFieldValid(fieldId);

  switch (elementType) {
    case "Block/ExtendedChoiceElementBlock":
      return hasOptions(data) ? (
        <FormChoice
          data={data as WithOptions<ExtendedChoiceElementBlockData>}
          value={value}
          onChange={onChange}
          isBlurred={isBlurred}
          setBlurred={setBlurred}
          isValid={isValid}
        />
      ) : null;
    case "Block/ExtendedNumberElementBlock":
      return (
        <FormNumber
          data={data as ExtendedNumberElementBlockData}
          value={value}
          onChange={onChange}
          isBlurred={isBlurred}
          setBlurred={setBlurred}
          isValid={isValid}
        />
      );
    case "Block/ExtendedSelectionElementBlock":
      return hasOptions(data) ? (
        <FormSelect
          data={data as WithOptions<ExtendedSelectionElementBlockData>}
          value={value}
          onChange={onChange}
          isBlurred={isBlurred}
          setBlurred={setBlurred}
          isValid={isValid}
        />
      ) : null;
    case "Block/ExtendedTextAreaElementBlock":
      return (
        <FormTextArea
          data={data as ExtendedTextAreaElementBlockData}
          value={value}
          onChange={onChange}
          isBlurred={isBlurred}
          setBlurred={setBlurred}
          isValid={isValid}
        />
      );
    case "Block/ExtendedTextFormBlock":
      return (
        <FormText
          data={data as ExtendedTextFormBlockData}
          value={value}
          onChange={onChange}
          isBlurred={isBlurred}
          setBlurred={setBlurred}
          isValid={isValid}
        />
      );
    case "Block/ExtendedUrlElementBlock":
      return (
        <FormUrl
          data={data as ExtendedUrlElementBlockData}
          value={value}
          onChange={onChange}
          isBlurred={isBlurred}
          setBlurred={setBlurred}
          isValid={isValid}
        />
      );
    case "Block/ParagraphTextElementBlock":
      return <FormRichText data={data as ParagraphTextElementBlockData} />;
    default:
      return null;
  }
}

function useFormModel(initialFormDescription: FormContainerBlockData) {
  const [formModel, setFormModel] = useState<Map<string, any>>(
    new Map(
      initialFormDescription.elementsArea.expandedValue?.map((element) => {
        const elementType = element.contentType.join("/");
        const fieldId = String(element.contentLink.id);

        switch (elementType) {
          case "Block/ExtendedChoiceElementBlock": {
            const choiceElement =
              element as WithOptions<ExtendedChoiceElementBlockData>;
            const isMultiSelect = choiceElement.allowMultiSelect.value;
            const getValue = () =>
              choiceElement.options.find((option) => option.checked)?.value ||
              "";
            const getValues = () =>
              choiceElement.options
                .filter((option) => option.checked)
                .map((option) => option.value)
                .join(" ") || "";
            return [fieldId, isMultiSelect ? getValues() : getValue()];
          }
          case "Block/ExtendedSelectionElementBlock": {
            const selectElement =
              element as WithOptions<ExtendedSelectionElementBlockData>;
            const value =
              selectElement.options.find((option) => option.checked)?.value ||
              "";
            return [fieldId, value];
          }
          case "Block/MappedValueElementBlock": {
            const predefinedValue =
              (element as MappedValueElementBlockData).predefinedValue.value ||
              "";
            return [fieldId, predefinedValue];
          }
          default:
            return [fieldId, null];
        }
      }) || []
    )
  );
  const [validityMap, setValidityMap] = useState<
    Map<string, boolean | string[]>
  >(new Map());
  const validators = new Map<string, (value: any | null) => boolean | string[]>(
    initialFormDescription.elementsArea.expandedValue
      ?.filter(hasValidators)
      ?.map((element) => [
        String(element.contentLink.id),
        getValidators(element.validatorsExpanded),
      ])
  );

  function getValue(fieldId: string) {
    return formModel.get(fieldId) || null;
  }

  function setValue(fieldId: string, value: any) {
    setFormModel((s) => {
      const newMap = new Map(s);
      newMap.set(fieldId, value);
      return newMap;
    });
  }

  function validateField(fieldId: string) {
    const isFieldValid =
      validators.get(fieldId)?.(formModel.get(fieldId) || null) || true;
    setValidityMap((s) => {
      const newMap = new Map(s);
      newMap.set(fieldId, isFieldValid);
      return newMap;
    });
    return isFieldValid;
  }

  function getIsFieldValid(fieldId: string): true | string[] {
    return validityMap.get(fieldId) || true;
  }

  function validateForm(): boolean {
    return Array.from(validators.keys())
      .map((fieldId) => validateField(fieldId))
      .reduce<boolean>((acc, validity) => acc && validity === true, true);
  }

  function setFieldsErrors(
    validationErrors: { fieldId: string; error: string }[]
  ) {
    setValidityMap((s) => {
      const newMap = new Map(s);
      validationErrors.forEach(({ fieldId, error }) => {
        const prevFieldValidity = newMap.get(fieldId) || true;
        newMap.set(
          fieldId,
          prevFieldValidity === true ? [error] : [...prevFieldValidity, error]
        );
      });
      return newMap;
    });
  }

  return {
    getValue,
    setValue,
    validateField,
    getIsFieldValid,
    validateForm,
    setFieldsErrors,
    formModel,
  };
}

const useStyles = createUseStyles({
  headline: typography.h2,
  description: typography.textDefault,

  formModal: {
    "& .Form__Element": {
      marginTop: rem(spacings.sam),
    },
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    ...onBreakpoint("sm", {
      marginLeft: -GUTTER_WIDTHS["small"],
    }),
    ...onBreakpoint("md", {
      marginLeft: -GUTTER_WIDTHS["big"],
    }),
  },
  flexBreak: {
    flexBasis: "100%",
    height: 0,
  },
  submitWrapper: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    flexDirection: "column",
  },
});

export function FormContainer(props: EpiFormProps) {
  const { getMainPageContentLink } = useContext(GlobalContentStoreContext);
  const allSteps = 1;
  const [step, setStep] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const styles = useStyles();
  const { isFieldBlurred, setFieldBlurred, setAllBlurred } = useBlurred();
  const {
    getValue,
    setValue,
    validateField,
    getIsFieldValid,
    validateForm,
    setFieldsErrors,
    formModel,
  } = useFormModel(props.data);

  function onSubmit() {
    const isFormValid = validateForm();
    !isFormValid && setAllBlurred();
    if (isFormValid) {
      setSubmitting(true);
      const formData = new FormData();
      formData.append("__FormGuid", props.data.contentLink.guidValue);
      formData.append(
        "__FormHostedPage",
        String(getMainPageContentLink()?.id) || ""
      );
      formData.append("__FormLanguage", props.data.language?.name || "en");
      formData.append("__FormCurrentStepIndex", String(step));
      formData.append("__FormWithJavaScriptSupport", "true");
      Array.from(formModel.entries()).forEach(([id, value]) => {
        const valueMap = new Map<string, string | undefined>([
          [CONTACT_MAIL_PLACEHOLDER, props.emailValue],
        ]);
        formData.append(`__field_${id}`, valueMap.get(value) || value);
      });

      fetch("/EPiServer.Forms/DataSubmit/Submit", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response: any) => {
          if (response.IsSuccess) {
            props.trackingFunction?.();
            if (step + 1 === allSteps && props.data.redirectToPage.value) {
              window.location.href = props.data.redirectToPage.value;
            } else {
              setStep((s) => (s + 1 === allSteps ? -1 : s + 1));
            }
          } else {
            const errors = response.Data.ValidationInfo;
            const fieldIdRegex = /__field_(?<fieldId>\d+)/;
            setFieldsErrors(
              errors.map((error: any) => ({
                fieldId:
                  error.InvalidElementName.match(fieldIdRegex)?.groups
                    ?.fieldId || "",
                error: error.ValidationMessage,
              }))
            );
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setSubmitting(false));
    }
  }

  return (
    <div>
      {step >= 0 ? (
        <>
          <h2 className={styles.headline}>
            <RenderProperty value={props.data.title} />
          </h2>
          <aside>
            <RenderProperty
              value={props.data.description}
              className={styles.description}
            />
          </aside>
          <form
            className={styles.form}
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              onSubmit();
            }}
            id={`${props.data.contentLink.id}`}
          >
            {(props.data.elementsArea.expandedValue as FormElement[]).map(
              (elem) =>
                renderElement(
                  elem,
                  getValue,
                  setValue,
                  isFieldBlurred,
                  setFieldBlurred,
                  validateField,
                  getIsFieldValid
                )
            )}
            <div className={styles.submitWrapper}>
              <Button onClick={onSubmit} disabled={isSubmitting}>
                <LocalizedLabel section="Forms" label="Submit" />
              </Button>
            </div>
          </form>
        </>
      ) : (
        <RenderProperty value={props.data.submitSuccessMessage} />
      )}
    </div>
  );
}
