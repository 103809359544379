import React, { useRef } from "react";
import { RenderProperty } from "../../views/RenderProperty";
import { Container, Row, Col, rem, onBreakpoint } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import {
  RichTextWithTypographyRenderer,
  useRichTextStyles,
} from "./RichTextBlock";
import { typography, customTypography } from "../../basics/typography";
import { AccordionBlockProps } from "../../models/content/AccordionBlockData";
import { spacings } from "../../basics/spacings";
import { colors } from "../../basics/colors";
import { FAIconStyles } from "../FAIcon/FAIcon";
import { AccordionElement } from "../../models/Property";
import { AdaptiveImageComponent } from "../AdaptiveImageComponent/AdaptiveImageComponent";
import cn from "classnames";
import { Accordion, AccordionItem } from "../Accordion/Accordion";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";

const useStyles = createUseStyles({
  element: {
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  accordionHeadline: {
    ...typography.h2,
    textAlign: "center",
  },
  accordionTitle: customTypography(
    typography.h4,
    { marginTop: rem(spacings.s), marginBottom: rem(spacings.s) },
    { marginTop: rem(spacings.s), marginBottom: rem(spacings.s) },
    { marginTop: rem(spacings.s), marginBottom: rem(spacings.s) }
  ),
  icon: FAIconStyles({
    width: 24,
    height: 24,
  }),
  items: {
    display: "flex",
    flexDirection: "column",
  },
  item: {
    borderTop: `2px solid ${colors.gray20}`,
    "&:last-child": {
      borderBottom: `2px solid ${colors.gray20}`,
    },
  },
  itemContent: ({ active }) => ({
    maxHeight: active ? "1000px" : 0,
    opacity: active ? 1 : 0,
    pointerEvents: active ? "all" : "none",
    transition: "opacity 0.3s ease, max-height 0.3s ease",
    overflow: "hidden",
  }),
  accordionButton: {
    padding: 0,
    border: "none",
    font: "inherit",
    color: "inherit",
    textAlign: "inherit",
    background: "none",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  visuallyHidden: {
    ...typography.visuallyHidden,
  },
});

export function AccordionBlock(props: AccordionBlockProps) {
  const styles = useStyles({});

  return (
    <Container dataTestid="AccordionBlock" className={styles.element}>
      <Row>
        <Col sm={10} smStart={1} md={8} mdStart={2}>
          {props.data.headline?.value && (
            <SemanticHeader
              className={styles.accordionHeadline}
              headerSize={props.data.headerSize?.value}
            >
              <RenderProperty value={props.data.headline} />
            </SemanticHeader>
          )}
          <Accordion className={styles.items}>
            {props.data.items.value?.map((item, idx) => (
              <AccordionBlockItem key={idx} {...item} />
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}
const useAccordionBlockItemStyles = createUseStyles({
  accordionBlockImage: {
    height: `calc((100vw - 2 * ${spacings.s}) * 3 / 4)`,
    "& img": {
      left: `-${spacings.sam}`,
      width: `calc(100vw - 2 * ${spacings.s})`,
      height: "auto",
    },
    ...onBreakpoint("sm", {
      height: "auto",
      "& img": {
        width: "100%",
      },
    }),
  },
});

function AccordionBlockItem(props: AccordionElement) {
  const imageWrapperRef = useRef<HTMLDivElement>(null);

  const richTextStyles = useRichTextStyles({});
  const styles = useAccordionBlockItemStyles();

  const hasImage =
    props.imageAdaptive?.urlLarge ||
    props.imageAdaptive?.urlMedium ||
    props.imageAdaptive?.urlSmall;

  return (
    <AccordionItem headline={props.title}>
      {hasImage && (
        <div
          className={cn(
            richTextStyles.richTextWrapper,
            props.imageAlignment === "left"
              ? richTextStyles.imageLeft
              : richTextStyles.imageRight
          )}
          ref={imageWrapperRef}
        >
          <AdaptiveImageComponent
            large={props.imageAdaptive?.urlLarge}
            medium={props.imageAdaptive?.urlMedium}
            small={props.imageAdaptive?.urlSmall}
            alt={props.imageAdaptive?.alternateText}
            className={cn(
              richTextStyles.richTextImage,
              styles.accordionBlockImage
            )}
          />
          <RenderProperty
            value={props.imageCaption}
            className={richTextStyles.imageCaption}
          />
        </div>
      )}

      <RichTextWithTypographyRenderer value={props.richText} />
    </AccordionItem>
  );
}
