import Website from "../models/Website";
import { OptionsType } from "./commonTypes";
import { nonLocaleCompare } from "./nonLocaleCompare";

const INTERNATIONAL_LOCALE = "en";

export function getCountryOptions(
  websiteData: Website | null
): OptionsType<string> {
  return (websiteData?.languages || [])
    .map((locale) => ({
      label: locale.displayName,
      value: locale.link?.toLowerCase() || "",
    }))
    .sort((l, r) =>
      l.value === INTERNATIONAL_LOCALE
        ? -1
        : r.value === INTERNATIONAL_LOCALE
        ? 1
        : nonLocaleCompare(l.label, r.label)
    );
}

export function getCurrentLocale(
  websiteData: Website | null,
  initialQueryParametrs: URLSearchParams
): string {
  return (
    initialQueryParametrs.get("locale") ||
    websiteData?.currentLanguage.link?.toLowerCase() ||
    getCountryOptions(websiteData)[0]?.value ||
    "en"
  );
}
