import React from "react";
import { Container, Row, Col, rem, onBreakpoint } from "../../../basics/layout";
import { createUseStyles } from "react-jss";
import { spacings } from "../../../basics/spacings";
import { FormContainer } from "./FormContainer";
import FormContainerBlockData, {
  FormContainerBlockProps,
} from "../../../models/content/FormContainerBlockData";

export type EpiFormProps = FormContainerBlockProps & {
  data: FormContainerBlockData;
  emailValue?: string;
  trackingFunction?: () => void;
  isComponent?: boolean;
};

const useStyles = createUseStyles({
  element: {
    marginBottom: rem(spacings.l),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.xl),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
});

export function FormContainerBlock(props: EpiFormProps) {
  const styles = useStyles();
  return (
    <Container dataTestid="FormContainerBlock" className={styles.element}>
      <Row>
        <Col sm={10} smStart={1} md={8} mdStart={2}>
          <FormContainer {...props} />
        </Col>
      </Row>
    </Container>
  );
}
