import React, { useContext } from "react";
import { UnsubscribeConfirmationPageProps } from "../../models/content/UnsubscribeConfirmationPageData";
import { ContentPage } from "./ContentPage";
import { ContentPageProps } from "../../models/content/ContentPageData";
import { BackendButton } from "../../models/Property";
import {
  LocalizationContext,
  localizedLabelString,
} from "../../hooks/LocalizationContext";

export function UnsubscribeConfirmationPage(
  props: UnsubscribeConfirmationPageProps
) {
  const initialQueryParametrs = new URLSearchParams(window.location.search);
  const mailingID = initialQueryParametrs.get("link");
  const localizationCtx = useContext(LocalizationContext);
  const unsubscribeLabel = localizedLabelString({
    section: "Global",
    label: "Unsubscribe",
    localizationCtx,
  });

  const unsubscribeButton: Array<BackendButton> = mailingID
    ? [
        {
          link: [
            {
              href:
                "https://api.broadmail.de/http/mail/" +
                mailingID +
                "/unsubscribe?bmUrl=https://www.hdi.global/newsletter/unsubscribe-successful?localise=true",
              text: unsubscribeLabel,
              target: null,
              title: unsubscribeLabel,
            },
          ],
          buttonType: "primary",
          buttonIconType: "none",
          buttonIcon: null,
        },
      ]
    : [];

  return (
    <ContentPage
      {...({
        ...props,
        data: {
          ...props.data,
          keyVisual: {
            ...props.data.keyVisual,
            buttons: {
              ...props.data.keyVisual.buttons,
              value: [
                ...(props.data.keyVisual.buttons.value || []),
                ...unsubscribeButton,
              ],
            },
          },
        },
      } as ContentPageProps)}
    />
  );
}
