import { createContext, Dispatch, SetStateAction } from "react";
import DownloadCenterSubcategoryBlockData from "../models/content/DownloadCenterSubcategoryBlockData";

export type Downloads = Partial<
  Pick<DownloadCenterSubcategoryBlockData, "downloadsLocal" | "downloadsGlobal">
>;

export const DownloadCenterContext = createContext<{
  activeSubcategory: number | null;
  setActiveSubcategory: Dispatch<SetStateAction<number | null>>;
  downloads: Downloads;
  setDownloads: Dispatch<SetStateAction<Downloads>>;
}>({
  activeSubcategory: null,
  setActiveSubcategory: () => null,
  downloads: {},
  setDownloads: () => null,
});
