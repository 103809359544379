import React, { useContext, useEffect, useState } from "react";
import { GenericModalBodyContainer, Modal } from "../Modal/Modal";
import { createUseStyles } from "react-jss";
import { typography } from "../../basics/typography";
import { Button } from "../Button/Button";
import {
  LocalizationContext,
  localizedLabelString,
} from "../../hooks/LocalizationContext";

const useStyles = createUseStyles({
  content: {
    ...typography.textDefault,
    margin: "0 !important",
    "& p": {
      marginTop: 0,
    },
  },
  buttonWrapper: {
    textAlign: "right",
  },
});

function wasUnsupportedBrowserNoticeDisplayed() {
  return Boolean(localStorage?.getItem("unsupportedBrowserNoticeDisplayed"));
}

function setUnsupportedBrowserNoticeDisplayed() {
  return localStorage?.setItem("unsupportedBrowserNoticeDisplayed", "true");
}

function isIETest() {
  const ua = navigator.userAgent;
  return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
}

export function UnsupportedBrowserNotice() {
  const [modalOpened, setModalOpened] = useState(false);
  const localizationCtx = useContext(LocalizationContext);

  useEffect(() => {
    if (!wasUnsupportedBrowserNoticeDisplayed()) {
      setModalOpened(isIETest());
    }
  }, []);

  function close() {
    setUnsupportedBrowserNoticeDisplayed();
    setModalOpened(false);
  }

  const styles = useStyles();

  const headingLabel = localizedLabelString({
    section: "UnsupportedBrowserNotice",
    label: "Heading",
    localizationCtx,
  });

  const contentLabel = localizedLabelString({
    section: "UnsupportedBrowserNotice",
    label: "Content",
    localizationCtx,
  });

  const acceptButtonLabel = localizedLabelString({
    section: "UnsupportedBrowserNotice",
    label: "AcceptButton",
    localizationCtx,
  });

  return (
    <Modal modalOpened={modalOpened} closeModal={() => close()}>
      <GenericModalBodyContainer heading={headingLabel}>
        <div className={styles.content}>
          <p>{contentLabel}</p>
          <div className={styles.buttonWrapper}>
            <Button onClick={() => close()}>{acceptButtonLabel}</Button>
          </div>
        </div>
      </GenericModalBodyContainer>
    </Modal>
  );
}
