import React, { useContext, useState, useEffect } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Shared/Footer";
import { RenderProperty, showPropertyOnEdit } from "../../views/RenderProperty";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import {
  Container,
  Row,
  Col,
  rem,
  CONTAINER_OFFSET_WIDTHS,
  onBreakpoint,
  getGridColumnWidth,
  onBefore,
  MARGIN_OF_CONTAINER_LIMITED,
  MAX_LAYOUT_WIDTH,
} from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { typography, customTypography } from "../../basics/typography";
import cn from "classnames";
import { SectionLandingPageProps } from "../../models/content/SectionLandingPageData";
import IContent from "../../models/IContent";
import { BreadcrumbComponent } from "../BreadcrumbComponent/BreadcrumbComponent";
import { Button } from "../Button/Button";
import { LocalizedLabel } from "../../hooks/LocalizationContext";
import { ContactExpert } from "../ContactExpert/ContactExpert";
import { fonts } from "../../basics/fonts";

export type SectionLandingSubpageType = IContent & {
  metaTitle: StartPageData["metaTitle"];
  metaImageAdaptive: StartPageData["metaImageAdaptive"];
  metaDescription: StartPageData["metaDescription"];
};

const useSectionLandingPageStyles = createUseStyles({
  firstContainer: {
    paddingTop: rem(spacings.m),
  },
  headline: typography.h1,
  description: {
    ...customTypography(
      {
        ...typography.textDefault,
      },
      {},
      {
        marginBottom: rem(spacings.xl),
      }
    ),
  },
});

export function SectionLandingPage(props: SectionLandingPageProps) {
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );
  const styles = useSectionLandingPageStyles(props.data.listingStyle.value);
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  if (!startPageData) return null;

  const contactExpertData = props.data.contactsUseDefaultContent?.value
    ? startPageData
    : props.data;

  return (
    <>
      <Header {...startPageData} />
      <main data-testid="SectionLandingPage">
        <Container className={styles.firstContainer}>
          <Row>
            <Col>
              <BreadcrumbComponent />
            </Col>
            <Col sm={10} md={7}>
              {props.data.title?.value && (
                <h1 className={styles.headline}>
                  <RenderProperty
                    value={props.data.title}
                    fallbackValue={props.data.metaTitle}
                  />
                </h1>
              )}
            </Col>
            <Col sm={10} smStart={1} md={8} mdStart={2}>
              {props.data.description?.value && (
                <RenderProperty
                  value={props.data.description}
                  fallbackValue={props.data.metaDescription}
                  className={styles.description}
                />
              )}
            </Col>
          </Row>
        </Container>
        <Container isFluid={props.data.listingStyle.value === "single-column"}>
          <Row>
            {(
              (props.data.pages.expandedValue ||
                []) as SectionLandingSubpageType[]
            ).map((page, idx) =>
              props.data.listingStyle.value === "single-column" ? (
                <SinglePageCard index={idx} {...page} key={idx} />
              ) : (
                <PageCard
                  big={props.data.listingStyle.value === "two-columns"}
                  {...page}
                  key={idx}
                />
              )
            )}
          </Row>
        </Container>
        <RenderProperty value={props.data.additionalContent} />
        {props.data.contactCta?.value === "enabled" && (
          <ContactExpert {...contactExpertData} />
        )}
      </main>
      <Footer {...startPageData} />
    </>
  );
}

const usePageCardStyles = createUseStyles({
  cardItemColumn: {
    marginBottom: rem(spacings.sam),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  cardItem: {
    backgroundColor: colors.gray10,
    height: "100%",
  },
  itemSection: {
    padding: `${spacings.m} ${spacings.sam} ${spacings.l.add(spacings.xs)}`,
    ...onBreakpoint("sm", {
      padding: () => `${spacings.m} ${spacings.sam}`,
    }),
    ...onBreakpoint("md", {
      padding: (isBig: boolean) => (isBig ? rem(spacings.xl) : rem(spacings.m)),
    }),
  },
  h4: typography.h4,
  textDefault: {
    ...typography.textDefault,
    ...fonts.sansLight,
    ...onBefore("sm", {
      display: "none",
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xl.add(spacings.xs)),
    }),
  },
  button: {
    position: "absolute",
    bottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      bottom: () => rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      bottom: (isBig: boolean) => (isBig ? rem(spacings.xl) : rem(spacings.l)),
    }),
  },
  icon: {
    marginLeft: rem(spacings.s),
  },
});

function PageCard(props: SectionLandingSubpageType & { big: boolean }) {
  const styles = usePageCardStyles(props.big);

  return (
    <Col xs={4} sm={6} md={props.big ? 6 : 4} className={styles.cardItemColumn}>
      <div
        data-testid={`SectionLandingPageCard-${props.big ? "big" : "small"}`}
        className={styles.cardItem}
      >
        {showPropertyOnEdit(props.metaImageAdaptive) && (
          <a href={props.url || undefined}>
            <RenderProperty value={props.metaImageAdaptive} />
          </a>
        )}

        <div className={styles.itemSection}>
          <h4 className={styles.h4}>{props.metaTitle.value}</h4>
          <p className={styles.textDefault}>{props.metaDescription.value}</p>
          <Button
            type="link"
            className={styles.button}
            href={props.url || undefined}
          >
            <LocalizedLabel section="Global" label="ReadMore" />
          </Button>
        </div>
      </div>
    </Col>
  );
}

const useSinglePageCardStyles = createUseStyles({
  cardColumn: {
    display: "flex",
    justifyContent: "center",
    marginBottom: rem(spacings.l),
    ...onBreakpoint("sm", {
      marginBottom: `calc(${getGridColumnWidth("sm")} + ${spacings.xxl})`,
    }),
    ...onBreakpoint("md", {
      marginBottom: `calc(${getGridColumnWidth("md")} + ${spacings.xxl})`,
    }),
  },
  centerItem: {
    position: "relative",
    width: `calc(min(100vw, ${MAX_LAYOUT_WIDTH}px))`,
  },
  textContainer: {
    marginTop: `calc(100vmin / 16 * 10 - ${spacings.l})`,
    ...onBreakpoint("sm", {
      marginTop: 0,
      position: "absolute",
      height: `100%`,
      width: "100%",
      top: `calc(${getGridColumnWidth("sm")})`,
    }),
    ...onBreakpoint("md", {
      top: `calc(${getGridColumnWidth("md")})`,
    }),
  },
  itemSectionColumn: {
    gridRow: 1,
    gridColumnStart: (isEven: boolean) => (isEven ? 1 : undefined),
  },
  leftItem: {
    ...onBreakpoint("sm", {
      marginLeft: -CONTAINER_OFFSET_WIDTHS["big"],
    }),
    ...onBreakpoint("md", {
      // INFO(mkarol): CSS Calc magic here :) We are moving to the left by value of margin:auto of container minus single padding
      marginLeft: `calc(-1 * ${MARGIN_OF_CONTAINER_LIMITED} - ${CONTAINER_OFFSET_WIDTHS["big"]}px)`,
    }),
  },
  itemSection: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.gray10,
    padding: `${spacings.l} ${spacings.sam}`,
    ...onBreakpoint("sm", {
      backgroundColor: "transparent",
      padding: (isEven: boolean) =>
        isEven
          ? `${spacings.l} calc(${getGridColumnWidth("sm")}) ${spacings.l} ${
              spacings.m
            }`
          : `${spacings.l} ${spacings.m} ${
              spacings.l
            } calc(${getGridColumnWidth("sm")})`,
    }),
    ...onBreakpoint("md", {
      padding: (isEven: boolean) =>
        isEven
          ? `${spacings.xxl} calc(${getGridColumnWidth("md")}) ${
              spacings.xxl
            } 0`
          : `${spacings.xxl} 0 ${spacings.xxl} calc(${getGridColumnWidth(
              "md"
            )})`,
    }),
  },
  emptyBackgroundColumn: {
    gridRow: 1,
    display: "none",
    ...onBreakpoint("sm", {
      display: "block",
    }),
    gridColumnStart: (isEven: boolean) => (isEven ? 1 : undefined),
  },
  emptyBackground: {
    backgroundColor: colors.gray10,
    height: "100%",
    // INFO(mkarol): CSS Calc magic here :) We are adding the size of the margin:auto of container to the width plus single padding
    width: `calc(${MARGIN_OF_CONTAINER_LIMITED} + ${CONTAINER_OFFSET_WIDTHS["big"]}px + 100%)`,
    position: "absolute",
  },
  h4: {
    ...typography.h4,
    wordBreak: "break-word",
  },
  textDefault: customTypography(
    {
      ...typography.textDefault,
      ...fonts.sansLight,
      position: "absolute",
      height: `100%`,
      overflow: "hidden",
    },
    { marginBottom: 0 },
    { marginBottom: 0 },
    { marginBottom: 0 }
  ),
  teaserImage: {
    ...onBefore("sm", {
      position: "absolute",
      width: `100vw`,
      marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
    }),
    ...onBreakpoint("sm", {
      width: `calc(${MARGIN_OF_CONTAINER_LIMITED} + ${CONTAINER_OFFSET_WIDTHS["big"]}px + 100%)`,
    }),
  },
  button: {
    alignSelf: "flex-start",
  },
  icon: {
    marginLeft: rem(spacings.s),
  },
  fullHeight: {
    height: "100%",
  },
  descriptionBox: {
    display: "none",
    position: "relative",
    flex: 1,
    ...onBreakpoint("sm", {
      display: "block",
      marginBottom: rem(spacings.m),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xl),
    }),

    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: rem(spacings.m.times(2)),
      background: `linear-gradient(to bottom, ${colors.gray10}00, ${colors.gray10}FF 100%)`,
    },
  },
});

function SinglePageCard(props: SectionLandingSubpageType & { index: number }) {
  const isEven = props.index % 2 === 0;
  const styles = useSinglePageCardStyles(isEven);

  return (
    <Col md={12} className={styles.cardColumn} dataTestid="SinglePageCard">
      <div className={styles.centerItem}>
        <Container>
          <Row>
            <Col sm={9} smStart={isEven ? 3 : undefined}>
              <RenderProperty
                value={props.metaImageAdaptive}
                className={cn(styles.teaserImage, !isEven && styles.leftItem)}
              />
            </Col>
          </Row>
        </Container>
        <div className={styles.textContainer}>
          <Container className={styles.fullHeight}>
            <Row className={styles.fullHeight}>
              <Col
                xs={4}
                sm={4}
                smStart={isEven ? undefined : 8}
                className={cn(styles.emptyBackgroundColumn, styles.fullHeight)}
              >
                <div
                  className={cn(
                    styles.emptyBackground,
                    isEven && styles.leftItem
                  )}
                />
              </Col>
              <Col
                xs={4}
                sm={4}
                smStart={isEven ? undefined : 8}
                className={cn(styles.itemSectionColumn, styles.fullHeight)}
              >
                <div className={cn(styles.itemSection, styles.fullHeight)}>
                  <h4 className={styles.h4}>{props.metaTitle.value}</h4>
                  <div className={styles.descriptionBox}>
                    <p className={styles.textDefault}>
                      {props.metaDescription.value}
                    </p>
                  </div>
                  <Button
                    className={styles.button}
                    type="secondary"
                    href={props.url || undefined}
                  >
                    <LocalizedLabel section="Global" label="ReadMore" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Col>
  );
}
