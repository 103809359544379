import React, { useContext, useState, useEffect } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Shared/Footer";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import { ContactExpert } from "../ContactExpert/ContactExpert";
import { LocationsOverviewPageProps } from "../../models/content/LocationsOverviewPageData";
import {
  Col,
  Container,
  onBefore,
  onBreakpoint,
  rem,
  Row,
} from "../../basics/layout";
import { LocationCard } from "../Block/ContactListBlock/LocationCard";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { typography } from "../../basics/typography";
import ContactLocationBlockData from "../../models/content/ContactLocationBlockData";
import { spacings } from "../../basics/spacings";
import { ContactListOverlay } from "../Block/ContactListBlock/ContactListOverlay";
import { ContactCardsBlock } from "../Block/ContactListBlock/ContactListBlock";
import { RenderProperty } from "../../views/RenderProperty";
import { PageDataElement } from "../../models/Property";
import { DownloadBlock, DownloadProps } from "../Block/DownloadBlock";
import { Accordion, AccordionItem } from "../Accordion/Accordion";
import { colors } from "../../basics/colors";

const useStyles = createUseStyles({
  locationOverview: {
    paddingTop: rem(spacings.m),
    paddingBottom: rem(spacings.l),
    ...onBreakpoint("sm", {
      paddingTop: rem(spacings.l),
      paddingBottom: rem(spacings.xl),
    }),
    ...onBreakpoint("md", {
      paddingTop: rem(spacings.xl),
      paddingBottom: rem(spacings.xxl),
    }),
  },
  headline: {
    ...typography.h1,
  },
  accordion: onBefore("sm", {
    marginBottom: rem(spacings.l),
  }),
  countriesList: {
    padding: 0,
    listStyle: "none",
  },
  countryName: {
    "&$active $countryNameButton": {
      fontWeight: "bold",
    },
    "&:last-child $countryNameButton": {
      marginBottom: 0,
    },
  },
  countryNameButton: {
    ...typography.textDefault,
    width: "100%",
    textAlign: "left",
    background: "none",
    border: 0,
    padding: 0,
    color: colors.gray100,
    transition: "color 0.3s ease",

    "&:hover": {
      color: colors.black,
    },

    "&:last-child": {
      marginBottom: rem(spacings.s),
    },
  },
  countryHeadline: typography.h3,
  active: {},
  visuallyHidden: {
    ...typography.visuallyHidden,
  },
});

export function LocationsOverviewPage(props: LocationsOverviewPageProps) {
  const initialQueryParametrs = new URLSearchParams(window.location.search);
  const { getStartPageData, getImmediateStartPageData, getWebsiteData } =
    useContext(GlobalContentStoreContext);

  const websiteData = getWebsiteData();
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  const { pages, name } = props.data;

  const matchedUserRegion = pages.value
    ?.map((regions) => regions.pages?.value)
    ?.findIndex((region) =>
      region?.some(
        (country) =>
          country.relatedPageLocale?.value?.toLowerCase() ===
          websiteData?.currentLanguage.link?.toLowerCase()
      )
    );

  const matchedUserCountry = pages.value
    ?.flatMap((regions) => regions.pages?.value)
    .find(
      (country) =>
        country?.relatedPageLocale?.value?.toLowerCase() ===
        websiteData?.currentLanguage.link?.toLowerCase()
    ) as PageDataElement;

  const queryCountryName = initialQueryParametrs.get("country");

  const possibleSelections: [number, PageDataElement][] =
    pages.value
      ?.map((regions) => regions.pages?.value)
      ?.flatMap<[number, PageDataElement]>(
        (region, regionIdx) =>
          region
            ?.filter(
              (country) =>
                country.relatedPageLocale?.value?.toLowerCase() ===
                queryCountryName?.toLowerCase()
            )
            ?.map<[number, PageDataElement]>((country) => [
              regionIdx,
              country,
            ]) || []
      ) || [];
  const [queryUserRegion, queryUserCountry]: [
    number | undefined,
    PageDataElement | undefined
  ] = (possibleSelections.length === 1 && possibleSelections[0]) || [
    undefined,
    undefined,
  ];

  const [initialExpandedRegion, setInitialExpandedRegion] = useState<
    number | undefined
  >(queryUserRegion);
  const [activeCountry, setActiveCountry] = useState<
    PageDataElement | undefined
  >(queryUserCountry);

  const [modalOpened, setModalOpened] = useState(false);
  const [slide, setSlide] = useState<number>(0);

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  useEffect(() => {
    if (startPageData && !activeCountry) {
      setInitialExpandedRegion(matchedUserRegion);
      setActiveCountry(matchedUserCountry);
    }
    setShouldRender(true);
  }, [startPageData]);

  useEffect(() => {
    const relatedPageLocale = activeCountry?.relatedPageLocale?.value;
    const queryParameters = new URLSearchParams();
    relatedPageLocale && queryParameters.set("country", relatedPageLocale);

    history.replaceState(
      {},
      "",
      `${window.location.pathname}?${queryParameters}`
    );
  }, [activeCountry]);

  function closeModal() {
    setModalOpened(false);
  }

  function openModal(slide: number) {
    setModalOpened(true);
    setSlide(slide);
  }

  const styles = useStyles();
  if (!startPageData || !shouldRender) return null;

  const contactExpertData = props.data.contactsUseDefaultContent?.value
    ? startPageData
    : props.data;

  return (
    <>
      <Header {...startPageData} />
      <main data-testid="LocationsOverviewPage">
        <Container className={styles.locationOverview}>
          <h2 className={styles.headline}>
            <RenderProperty value={name} />
          </h2>
          <Row>
            <Col sm={3}>
              <Accordion className={styles.accordion}>
                {pages.value?.map((region, idx) => (
                  <AccordionItem
                    headline={region.name}
                    initialIsActive={initialExpandedRegion === idx}
                    key={idx}
                  >
                    <ul className={styles.countriesList}>
                      {region.pages?.value?.map((country, idx) => (
                        <li
                          className={cn(
                            styles.countryName,
                            activeCountry === country
                              ? styles.active
                              : undefined
                          )}
                          key={idx}
                        >
                          <button
                            onClick={() => setActiveCountry(country)}
                            className={styles.countryNameButton}
                          >
                            <RenderProperty value={country.name} />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </AccordionItem>
                ))}
              </Accordion>
            </Col>
            <Col sm={9}>
              <h3 className={styles.countryHeadline}>
                <RenderProperty value={activeCountry?.name} />
              </h3>
              {(
                activeCountry?.locations
                  ?.expandedValue as Array<ContactLocationBlockData>
              )?.map((l, idx) => (
                <LocationCard
                  {...l}
                  itemNumber={idx}
                  openModal={openModal}
                  key={idx}
                />
              ))}
            </Col>
          </Row>
        </Container>
        <ContactListOverlay
          contacts={
            activeCountry?.locations?.expandedValue as ContactCardsBlock[]
          }
          modalOpened={modalOpened}
          closeModal={closeModal}
          initialSlide={slide}
        />
        <RenderProperty value={props.data.additionalContent} />
        {(props.data.downloadsGlobal?.value ||
          props.data.downloadsLocal?.value) && (
          <DownloadBlock
            downloadItems={[
              ...((props.data.downloadsLocal
                ?.expandedValue as DownloadProps[]) || []),
              ...((props.data.downloadsGlobal
                ?.expandedValue as DownloadProps[]) || []),
            ]}
          />
        )}
        {props.data.contactCta?.value === "enabled" && (
          <ContactExpert {...contactExpertData} />
        )}
      </main>
      <Footer {...startPageData} />
    </>
  );
}
