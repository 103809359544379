import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import {
  Col,
  Container,
  onBefore,
  onBreakpoint,
  rem,
  Row,
} from "../../../basics/layout";
import { colors } from "../../../basics/colors";
import { createUseStyles } from "react-jss";
import { customTypography, typography } from "../../../basics/typography";
import { spacings } from "../../../basics/spacings";
import { ButtonWrapper } from "../../Button/Button";
import {
  isDeviceResOrLower,
  useDeviceType,
} from "../../../hooks/useDeviceType";

import HeaderCarouselElementBlockData from "../../../models/content/HeaderCarouselElementBlockData";
import { AdaptiveImageComponent } from "../../AdaptiveImageComponent/AdaptiveImageComponent";
import { RenderProperty } from "../../../views/RenderProperty";
import cn from "classnames";

export default function VisualHeaderCarousel(props: {
  items: Array<HeaderCarouselElementBlockData>;
  menuCollapsed: boolean;
}) {
  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");

  const settings = {
    arrows: !isMobile,
    dots: isMobile,
    infinite: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const useStyles = createUseStyles({
    visualHeaderCarousel: {
      position: "relative",
      maxWidth: 1920,
      margin: `0 auto ${spacings.xl.times(2)}`,
      "&:after": {
        content: "''",
        display: ({ menuCollapsed }) => (menuCollapsed ? "none" : "block"),
        position: "absolute",
        top: 0,
        right: 0,
        width: 500,
        height: 200,
        background:
          "linear-gradient(216deg, rgba(0,0,0,0.65) 0%, transparent 35%)",

        ...onBreakpoint("sm", {
          width: 700,
          height: 270,
          background:
            "linear-gradient(200deg, rgba(0,0,0,0.65) 0%, transparent 40%)",
        }),
        ...onBreakpoint("md", {
          width: 900,
          height: 400,
          background:
            "linear-gradient(205eg, rgba(0,0,0,0.65) 0%, transparent 50%)",
        }),
      },
      "& .slick-arrow": {
        width: 21,
        height: 42,
        top: ({ imageHeight }) => `${imageHeight + 50}px`,
        zIndex: 10,
        "&:before": {
          fontFamily: "'Font Awesome 5 Pro'",
          color: colors.gray60,
          fontSize: "2.6rem",
        },
        "&.slick-prev": {
          left: "unset",
          right: 85,
          "@media (min-width: 1800px)": {
            right: 115,
          },
          "&:before": {
            content: "'\\f053'",
          },
        },
        "&.slick-next": {
          left: "unset",
          right: 30,
          "@media (min-width: 1800px)": {
            right: 60,
          },
          "&:before": {
            content: "'\\f054'",
          },
        },
      },
      "& .slick-dots": {
        bottom: -55,
        "& li": {
          margin: "0 15px",
          "&.slick-active button": {
            border: `1px solid ${colors.lightGreen40}`,
            borderRadius: "50%",
            "&:before": {
              opacity: 1,
            },
          },
          "& button": {
            padding: 0,
            "&:before": {
              width: 14,
              fontSize: 14,
              color: colors.lightGreen40,
              top: 1,
              left: 3,
              opacity: 0.4,
            },
          },
        },
      },
    },
    carouselImage: {
      "& img": {
        ...onBefore("sm", {
          height: 300,
          objectFit: "cover",
        }),
      },
    },
    carouselCardContainer: {
      ...onBefore("sm", {
        padding: 0,
      }),
    },
    carouselCard: {
      backgroundColor: colors.gray10,
      marginTop: 0,
      padding: `${spacings.m} ${spacings.m} ${spacings.xl}`,
      ...onBreakpoint("sm", {
        marginTop: -120,
      }),
      ...onBreakpoint("md", {
        marginTop: -200,
        "&.center": {
          textAlign: "center",
        },
      }),
    },
    carouselCardHeadline: {
      ...typography.h3,
    },
    carouselCardDescription: {
      ...customTypography(
        {
          ...typography.textDefault,
        },
        {},
        {
          marginBottom: rem(spacings.m),
        }
      ),
    },
    rightContentColumn: {
      "@media (min-width: 1440px)": {
        gridColumnStart: 6,
      },
      "@media (min-width: 1700px)": {
        gridColumnStart: 7,
      },
    },
  });

  const imageRef = useRef<HTMLDivElement>(null);

  const [imageHeight, setImageHeight] = useState<number>(0);

  useEffect(() => {
    setImageHeight(imageRef.current?.getBoundingClientRect().height || 0);
    document.addEventListener("resize", () => {
      setImageHeight(imageRef.current?.getBoundingClientRect().height || 0);
    });
    return () =>
      document.removeEventListener("resize", () => {
        setImageHeight(imageRef.current?.getBoundingClientRect().height || 0);
      });
  });

  const styles = useStyles({ menuCollapsed: props.menuCollapsed, imageHeight });

  return (
    <div className={styles.visualHeaderCarousel}>
      <Slider {...settings}>
        {props.items?.map((item, idx) => (
          <div key={idx}>
            <div ref={imageRef}>
              <AdaptiveImageComponent
                fullHD={item.imageAdaptive.value?.urlOriginal || ""}
                large={item.imageAdaptive.value?.urlLarge || ""}
                medium={item.imageAdaptive.value?.urlLarge || ""}
                small={item.imageAdaptive.value?.urlLarge || ""}
                alt={item.imageAdaptive.value?.alternateText || ""}
                className={styles.carouselImage}
              />
            </div>
            <Container className={styles.carouselCardContainer}>
              <Row>
                <Col
                  sm={10}
                  md={item.alignment.value === "center" ? 10 : 6}
                  mdStart={
                    item.alignment.value === "right"
                      ? 5
                      : item.alignment.value === "center"
                      ? 1
                      : undefined
                  }
                  className={
                    item.alignment.value === "right"
                      ? styles.rightContentColumn
                      : undefined
                  }
                >
                  <div
                    className={cn(
                      styles.carouselCard,
                      item.alignment.value === "center" && "center"
                    )}
                  >
                    <h3 className={styles.carouselCardHeadline}>
                      <RenderProperty value={item.headline} />
                    </h3>
                    <p className={styles.carouselCardDescription}>
                      <RenderProperty value={item.description} />
                    </p>
                    {item.buttons.value?.map((b, idx) => (
                      <ButtonWrapper {...b} key={idx} />
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ))}
      </Slider>
    </div>
  );
}
