import React from "react";
import { EmbeddedHtmlBlockProps } from "../../models/content/EmbeddedHtmlBlockData";

export function EmbeddedHtmlBlock(props: EmbeddedHtmlBlockProps) {
  return (
    <div
      data-testid="EmbeddedHtmlBlock"
      dangerouslySetInnerHTML={{ __html: props.data.htmlContent.value || "" }}
    />
  );
}
