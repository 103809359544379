import React from "react";
import { RenderProperty } from "../../views/RenderProperty";
import { FooterTextBlockProps } from "../../models/content/FooterTextBlockData";
import { createUseStyles } from "react-jss";
import { spacings } from "../../basics/spacings";
import cn from "classnames";
import { rem } from "../../basics/layout";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";

const useStyles = createUseStyles({
  flatList: {
    marginTop: "1rem",
    "& .p1": {
      margin: 0,
      marginBottom: 12,
    },
  },
  mobilePadding: {
    paddingTop: rem(spacings.m),
  },
  mobileArea: {
    width: "100%",
    padding: 0,
    border: "none",
    font: "inherit",
    color: "inherit",
    textAlign: "inherit",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headline: {
    display: "block",
    marginBottom: rem(spacings.s),
  },
  icon: FAIconStyles({
    width: 24,
    height: 24,
  }),
});

export default function FooterTextBlock(
  props: FooterTextBlockProps & {
    context?: {
      setOpenSection?: (f: (section: string) => string) => void;
      openSection?: string;
      uncollapsed?: boolean;
    };
  }
) {
  const styles = useStyles();
  const guid = props.data.contentLink.guidValue;
  return props.context ? (
    <div className={styles.mobilePadding}>
      <button
        onClick={() =>
          props.context?.setOpenSection &&
          props.context?.setOpenSection((section: string) => {
            return section === guid ? "" : guid || "";
          })
        }
        className={styles.mobileArea}
      >
        <b>
          <RenderProperty
            className={props.className}
            value={props.data.headline}
          />
        </b>
        {!props.context.uncollapsed &&
          (props.context.openSection === guid ? (
            <FAIcon icon="chevron-up" className={styles.icon} />
          ) : (
            <FAIcon icon="chevron-down" className={styles.icon} />
          ))}
      </button>
      {(props.context.openSection === guid || props.context.uncollapsed) && (
        <RenderProperty
          className={cn(props.className, styles.flatList)}
          value={props.data.content}
        />
      )}
    </div>
  ) : (
    <>
      <b>
        <RenderProperty
          className={props.className}
          value={props.data.headline}
        />
      </b>
      <div
        className={cn(props.className, styles.flatList)}
        dangerouslySetInnerHTML={{ __html: props.data.content.value || "" }}
      />
    </>
  );
}
