import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import {
  LocalizationContext,
  localizedLabelString,
} from "../../../../hooks/LocalizationContext";
import { ExtendedSelectionElementBlockProps } from "../../../../models/content/ExtendedSelectionElementBlockData";
import { RenderProperty } from "../../../../views/RenderProperty";
import { Select } from "../../../Select/Select";
import {
  BreakFormLayout,
  ElementPropsWithOptions,
  FormProps,
  getColumnStyle,
  getFieldProps,
  useCommonFormStyles,
} from "./common";

const useStyles = createUseStyles({
  select: ({ inputWrapper }) => ({
    [`& .${inputWrapper} > *`]: {
      height: "3.5rem",
    },
    ["& .select-option"]: {
      fontSize: `1.25rem !important`,
    },
  }),
});

export function FormSelect(
  props: ElementPropsWithOptions<ExtendedSelectionElementBlockProps> &
    FormProps<string>
) {
  const commonFormStyles = useCommonFormStyles();
  const styles = useStyles({ inputWrapper: commonFormStyles.inputWrapper });
  const localizationCtx = useContext(LocalizationContext);
  const columnStyle =
    commonFormStyles[getColumnStyle(props.data.horizontalLayout.value)];
  const newRow = props.data.newRow.value;
  const validationError =
    props.isValid === true ? null : props.isValid.join(" ");

  return (
    <>
      {newRow && <BreakFormLayout />}
      <div className={columnStyle}>
        <RenderProperty
          value={props.data.label}
          className={commonFormStyles.label}
        />
        <Select
          {...getFieldProps(props.data)}
          className={styles.select}
          label={
            props.data.placeHolder.value ||
            localizedLabelString({
              localizationCtx,
              section: "Forms",
              label: "SelectOption",
            })
          }
          options={props.data.options.map((option) => ({
            value: option.value,
            label: option.caption,
          }))}
          selected={props.value || ""}
          setSelected={props.onChange}
          onBlur={props.setBlurred}
        />
        <div className={commonFormStyles.validationError}>
          {validationError}
        </div>
      </div>
    </>
  );
}
