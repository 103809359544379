import React from "react";
import { onBreakpoint, rem } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { customTypography, typography } from "../../basics/typography";
import { spacings } from "../../basics/spacings";
import { ButtonWrapper } from "../Button/Button";
import { ImageTeaserBlockProps } from "../../models/content/ImageTeaserBlockData";
import { RenderProperty } from "../../views/RenderProperty";
import { ComponentTheme } from "../../views/RenderComponent";

const useStyles = createUseStyles({
  container: {
    margin: `${spacings.xs} 0`,
    ...onBreakpoint("sm", {
      margin: 0,
    }),
  },
  headline: {
    ...customTypography(
      {
        ...typography.h4,
        marginBottom: 0,
      },
      {
        marginTop: rem(spacings.sam),
        marginBottom: 0,
      },
      {
        marginTop: rem(spacings.m),
        marginBottom: 0,
      }
    ),
  },
  button: {},
});

const useFilterStyles = createUseStyles({
  container: {
    margin: `${spacings.xs} 0`,
    ...onBreakpoint("sm", {
      margin: 0,
    }),
  },
  headline: {
    ...customTypography(
      {
        ...typography.h4,
        marginBottom: 0,
      },
      {
        marginTop: rem(spacings.sam),
        marginBottom: 0,
      },
      {
        marginTop: rem(spacings.m),
        marginBottom: 0,
      }
    ),
  },
  button: {
    marginTop: rem(spacings.sam),
    ...onBreakpoint("sm", {
      marginTop: rem(spacings.m),
    }),
  },
});

export function ImageTeaserBlock(
  props: ImageTeaserBlockProps & {
    theme?: ComponentTheme;
  }
) {
  const navigationStyles = useStyles();
  const filterStyles = useFilterStyles();
  const styles = props.theme === "filters" ? filterStyles : navigationStyles;

  return (
    <div className={styles.container}>
      <RenderProperty value={props.data.imageAdaptive} />
      <RenderProperty className={styles.headline} value={props.data.headline} />
      {props.data.buttons.value?.map((b, idx) => (
        <ButtonWrapper {...b} key={idx} className={styles.button} />
      ))}
    </div>
  );
}
