import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  picture: {
    height: "100%",
    width: "100%",
    display: "block",
  },
  image: {
    height: "100%",
    width: "100%",
    display: "block",
  },
});

export function AdaptiveImageComponent(props: {
  fullHD?: string | null;
  large: string | null;
  medium: string | null;
  small: string | null;
  alt: string | null;
  className?: string;
}) {
  const styles = useStyles();
  const urlDefault = props.large || props.medium || props.small || undefined;
  const minImageSize = 1440;
  const imageSizes = [
    1920,
    Math.floor((minImageSize * 4) / 9),
    Math.floor((minImageSize * 2) / 9),
    Math.floor((minImageSize * 1) / 9),
  ];
  return urlDefault ? (
    <div className={props.className}>
      <picture className={styles.picture}>
        <img
          src={props.small || urlDefault}
          srcSet={`${props.fullHD || urlDefault} ${imageSizes[0]}w, ${
            props.large || urlDefault
          } ${imageSizes[1]}w,
            ${props.medium || urlDefault} ${imageSizes[2]}w,
            ${props.small || urlDefault} ${imageSizes[3]}w`}
          alt={props.alt || ""}
          className={styles.image}
          loading="lazy"
        />
      </picture>
    </div>
  ) : null;
}
