import React, { ReactNode, useContext } from "react";
import cn from "classnames";
import { GenericProperty } from "../models/IContent";
import { EpiContext } from "../hooks/EpiContext";
import {
  Property as IContentProperty,
  LinkProperty,
  AdaptiveImage,
  AdaptiveSingleImage,
} from "../models/Property";
import { RenderComponent, ComponentTheme } from "./RenderComponent";
import { Link } from "../components/Shared/Link";
import { RichTextWithTypographyRenderer } from "../components/Block/RichTextBlock";
import { AdaptiveImageComponent } from "../components/AdaptiveImageComponent/AdaptiveImageComponent";
import {
  AnimatedRevealBlock,
  AnimatedRevealInline,
  AnimatedRevealProps,
} from "../components/AnimatedReveal/AnimatedReveal";

export interface RenderPropertyProps<T> {
  value?: GenericProperty;
  fallbackValue?: GenericProperty;
  className?: string;
  children?: ReactNode;
  context?: T;
  theme?: ComponentTheme;
}

const isJest = process.env.NODE_ENV === "test";
const isDevelopment = process.env.NODE_ENV === "development";
function checkIfIContentProperty(
  prop: GenericProperty
): prop is IContentProperty<any> {
  if (
    prop &&
    (prop as IContentProperty<any>).propertyDataType &&
    typeof (prop as IContentProperty<any>).propertyDataType === "string"
  ) {
    return true;
  }
  return false;
}

export function RenderProperty<T>(props: RenderPropertyProps<T>) {
  const { isDebug, isEditable } = useContext(EpiContext);

  // INFO(mkarol): In edit mode, properties are annotated with Proxy in GlobalContentStore
  const field = isEditable ? (props.value as any)?.["__key__"] : "";

  if (!props.value && typeof props.value !== "string") {
    if (props.fallbackValue)
      return <RenderProperty value={props.fallbackValue} />;
    if (!isDebug) return null;

    return (
      <div style={{ display: "none" }} data-epi-edit={field}>
        Property not present
      </div>
    );
  }

  const PropertyTypeNotKnown = (propType: string) =>
    isDebug ? (
      <div className="alert alert-warning" data-epi-edit={field}>
        Property type {propType} with value {JSON.stringify(props)} not
        supported
      </div>
    ) : null;

  if (
    !checkIfIContentProperty(props.value) &&
    typeof props.value !== "string"
  ) {
    return PropertyTypeNotKnown(typeof props);
  }

  if (!checkIfIContentProperty(props.value)) {
    return (
      <span
        className={props.className}
        data-epi-edit={isEditable ? field : undefined}
      >
        {props.value}
      </span>
    );
  }

  const fallbackValue =
    props.fallbackValue && checkIfIContentProperty(props.fallbackValue)
      ? props.fallbackValue?.value
      : props.fallbackValue;

  const propValue =
    props.value.value || (props.fallbackValue && fallbackValue) || null;
  const propType = props.value.propertyDataType;

  try {
    switch (propType) {
      case "PropertyString":
      case "PropertyLongString":
        return (
          <span
            className={props.className}
            data-epi-edit={isEditable ? field : undefined}
          >
            {propValue}
          </span>
        );
      case "PropertyUrl":
        return (
          <a
            href={propValue}
            className={props.className}
            data-epi-edit={isEditable ? field : undefined}
          >
            {props.children || propValue}
          </a>
        );
      case "PropertyDecimal":
      case "PropertyNumber":
      case "PropertyFloatNumber":
        return (
          <span
            className={cn(["number", props.className])}
            data-epi-edit={isEditable ? field : undefined}
          >
            {propValue}
          </span>
        );
      case "PropertyXhtmlString":
        return (
          <RichTextWithTypographyRenderer
            className={props.className}
            dataEpiEdit={isEditable ? field : undefined}
            value={propValue}
          />
        );
      case "PropertyLinkCollection": {
        return (
          <div
            data-epi-edit={isEditable ? field : undefined}
            className={props.className}
          >
            {propValue.map((link: LinkProperty, idx: number) => (
              <Link {...link} key={idx} />
            ))}
          </div>
        );
      }
      case "PropertyContentReference":
      case "PropertyContentReferenceList":
      case "PropertyPageReference":
      case "PropertyContentArea": {
        const item =
          propValue &&
          (Array.isArray(propValue) ? (
            propValue.map((propValueSingle, idx) => (
              <RenderComponent
                contentLink={propValueSingle.contentLink}
                className={props.className}
                key={idx}
                context={props.context}
                theme={props.theme}
              />
            ))
          ) : (
            <RenderComponent
              contentLink={propValue}
              className={props.className}
              context={props.context}
              theme={props.theme}
            />
          ));
        return (
          <div
            data-epi-edit={isEditable ? field : undefined}
            className={props.className}
          >
            {item}
          </div>
        );
      }
      case "AdaptiveImageProperty": {
        const adaptiveData: AdaptiveImage = propValue;
        return (
          <div data-epi-edit={isEditable ? field : undefined}>
            <AdaptiveImageComponent
              large={adaptiveData.urlLarge}
              medium={adaptiveData.urlMedium}
              small={adaptiveData.urlSmall}
              alt={adaptiveData.altSmall}
            />
          </div>
        );
      }
      case "SingleImageProperty": {
        const adaptiveData: AdaptiveSingleImage = propValue;
        return (
          <div data-epi-edit={isEditable ? field : undefined}>
            <AdaptiveImageComponent
              large={adaptiveData.urlLarge}
              medium={adaptiveData.urlMedium}
              small={adaptiveData.urlSmall}
              alt={adaptiveData.alternateText}
              className={props.className}
            />
          </div>
        );
      }
      case "CaptionHeadlineProperty": {
        return (
          <span
            className={props.className}
            data-epi-edit={isEditable ? field : undefined}
          >
            {propValue}
          </span>
        );
      }
      default:
        return PropertyTypeNotKnown(propType);
    }
  } catch (error) {
    if (isJest || isDevelopment) {
      !isJest && console.error(error);
      throw error;
    }
    return null;
  }
}

export const AnimatedRenderProperty = (
  props: RenderPropertyProps<any> & AnimatedRevealProps
) => (
  <AnimatedRevealInline {...props} revealDelay={props.revealDelay || 0}>
    <RenderProperty {...props} />
  </AnimatedRevealInline>
);

export const AnimatedBlockRenderProperty = (
  props: RenderPropertyProps<any> & AnimatedRevealProps
) => (
  <AnimatedRevealBlock {...props} revealDelay={props.revealDelay || 0}>
    <RenderProperty {...props} />
  </AnimatedRevealBlock>
);

export function showPropertyOnEdit(property?: GenericProperty) {
  const { isEditable } = useContext(EpiContext);
  return Boolean(
    property &&
      (isEditable ||
        typeof property === "string" ||
        (checkIfIContentProperty(property) && property.value))
  );
}
