import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { colors } from "../../basics/colors";
import { ModalWithCarousel } from "../ModalWithCarousel/ModalWithCarousel";
import { onBreakpoint } from "../../basics/layout";
import { isDeviceResOrLower, useDeviceType } from "../../hooks/useDeviceType";
import { typography } from "../../basics/typography";
import { isClientSide } from "../../basics/isClientSide";

export type CloudinaryVideoProps = {
  cloudName?: string;
  publicId: string;
  logoImageUrl: string;
  loop?: boolean;
  autoplay?: boolean;
  resetVideo?: boolean;
  showControls?: boolean;
  thumbnailUrl?: string;
  withOverlay?: boolean;
  forcePlay?: boolean;
  setForcePlay?: (v: boolean) => void;
};

const useStyles = createUseStyles({
  disableControlBar: {
    "& .cld-video-player .vjs-control-bar": {
      display: "none",
    },
    "& .cld-video-player-floater.cld-video-player-floating .vjs-big-play-button":
      {
        top: "50%",
      },
  },
  videoElement: {
    position: "relative",
    "& .video-js": {
      backgroundColor: "transparent",
      overflow: "hidden",
    },
    "& .vjs-fullscreen .vjs-poster": {
      width: "100vw",
      height: "calc(100vw / 16 * 9)",
      top: "calc((100vh - (100vw / 16 * 9)) / 2)",
    },
    "& .vjs-poster": {
      transform: ({ isOverlayFocused, isOverlayHovered }) =>
        isOverlayFocused || isOverlayHovered ? "scale(1.04)" : "scale(1)",
      transition: "transform 0.3s ease",
    },
    "& .cld-video-player .vjs-big-play-button .vjs-icon-placeholder:before": {
      borderLeftWidth: "30px !important",
      borderTopWidth: "20px !important",
      borderBottomWidth: "20px !important",
      marginLeft: "-30px !important",
      marginTop: "-20px !important",
    },
  },
  videoClickArea: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    cursor: "pointer",
    zIndex: 3,
    "&:focus, &:hover": {
      "& $videoClickBackground": {
        transform: "translate(-50%, -50%) scale(1.04)",
        transition: "transform 0.3s ease, background-color 0.3s ease",
        backgroundColor: colors.white,
      },
      "& $videoClickTriangle": {
        transform:
          "translate(-50%, -50%) scale(1.04)  rotate(-45deg) skew(15deg, 15deg)",
        transition: "transform 0.3s ease",
      },
    },
  },

  videoClickBackground: {
    content: '""',
    width: 100,
    height: 100,
    maxHeight: "60%",
    maxWidth: "calc(60% * 9 / 16)", // Image has 16:9 ratio
    ...onBreakpoint("sm", {
      width: 140,
      height: 140,
    }),
    backgroundColor: colors.gray10,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    transition: "transform 0.3s ease, background-color 0.3s ease",
    borderRadius: "50%",
  },
  videoClickContainer: {},
  videoClickTriangle: {
    top: "50%",
    left: "39%",
    maxHeight: "37%",
    maxWidth: "37%",
    position: "absolute",
    transform:
      "translate(-50%, -50%) scale(1) rotate(-45deg) skew(15deg, 15deg)",
    transition: "transform 0.3s ease 0s",
    overflow: "hidden",
    background: `linear-gradient(to bottom right, transparent 0%, transparent 50%, ${colors.gray90} 50%, ${colors.gray90} 100%)`,
    content: '""',
    width: 32,
    height: 32,
    ...onBreakpoint("sm", {
      left: "37%",
      width: 52,
      height: 52,
    }),
  },

  visuallyHidden: {
    ...typography.visuallyHidden,
  },
});

export function CloudinaryVideoPlayer(props: CloudinaryVideoProps) {
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  );
  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");
  const [modalOpened, setModalOpened] = useState(false);
  const [isOverlayFocused, setOverlayFocused] = useState(false);
  const [isOverlayHovered, setOverlayHovered] = useState(false);
  const videoPlayeRef = useRef<any>(null);
  const showControls =
    typeof props.showControls !== "undefined" ? props.showControls : true;

  useEffect(() => {
    async function asyncVideoInit() {
      if (!videoElement) return;
      if (videoElement.dataset["initialized"] === "true") return;

      const [{ Cloudinary }] = await Promise.all([
        import(
          /*
          webpackChunkName: "cloudinary-core",
        */ "cloudinary-core"
        ),
        import(
          /*
          webpackChunkName: "cloudinary-core",
        */
          // @ts-ignore No TS support
          "cloudinary-video-player/dist/cld-video-player.light.min.css"
        ),
        import(
          /*
          webpackChunkName: "cloudinary-core",
        */
          // @ts-ignore No TS support
          "cloudinary-video-player/dist/cld-video-player.light.min.js"
        ),
      ]);

      const cld = Cloudinary.new({
        cloud_name: props.cloudName || "hdiglobal",
      });
      const options: any = {
        publicId: props.publicId,
        controls: true,
        fluid: true,
        posterOptions: props.thumbnailUrl
          ? { publicId: props.thumbnailUrl }
          : {
              transformation: {
                startOffset: "0",
              },
            },
        skin: "light",
        playbackRates: [0.75, 1, 1.5, 2],
        colors: {
          base: "#f5f5f5",
          accent: "#7aba00",
          text: "#262626",
        },
        fontFace: "inherit",
        logoOnclickUrl: "https://www.hdi.global",
        logoImageUrl: props.logoImageUrl,
        showJumpControls: true,
        hideContextMenu: true,

        loop: props.loop,
        ...(props.autoplay && !props.withOverlay
          ? { autoplay: true, muted: true, autoplayMode: "on-scroll" }
          : {
              autoplay: false,
              muted: false,
              floatingWhenNotVisible: props.withOverlay ? undefined : "right",
            }),

        // WARNING(mkarol): Changes in analitics and usage reports require changes in privacy policy
        analytics: false,
        allowUsageReport: false,
      };

      const player = (cld as any).videoPlayer(videoElement, options);

      if (props.resetVideo && !props.loop) {
        player.on("ended", () => {
          player.videojs.currentTime(0);
          player.videojs.hasStarted(false);
          player.videojs.triggerReady();
        });
      }
      videoPlayeRef.current = player;
      videoElement.dataset["initialized"] = "true";
    }

    isClientSide() && asyncVideoInit();
  }, [videoElement]);

  useEffect(() => {
    if (isMobile && videoPlayeRef.current && (modalOpened || props.forcePlay)) {
      videoPlayeRef.current.play();
      videoPlayeRef.current.maximize();
      videoPlayeRef.current.on("fullscreenchange", () => {
        if (!videoPlayeRef.current.isMaximized()) {
          videoPlayeRef.current.pause();
          videoPlayeRef.current.videojs.currentTime(0);
          videoPlayeRef.current.videojs.hasStarted(false);
          videoPlayeRef.current.videojs.triggerReady();
          setModalOpened(false);
          props.setForcePlay && props.setForcePlay(false);
        }
      });
    }
  }, [isMobile, videoPlayeRef, modalOpened, props.forcePlay]);

  const styles = useStyles({ isOverlayFocused, isOverlayHovered });
  const initVideoRef = (node: HTMLVideoElement | null) => setVideoElement(node);

  return (
    <>
      <div
        className={cn(
          styles.videoElement,
          !showControls &&
            !(isMobile && (modalOpened || props.forcePlay)) &&
            styles.disableControlBar
        )}
      >
        <video ref={initVideoRef}></video>
        {props.withOverlay && (
          <button
            className={styles.videoClickArea}
            onClick={() => setModalOpened(true)}
            onMouseOver={() => setOverlayHovered(true)}
            onMouseOut={() => setOverlayHovered(false)}
            onFocus={() => setOverlayFocused(true)}
            onBlur={() => setOverlayFocused(false)}
          >
            <div className={styles.videoClickBackground}>
              <div className={styles.videoClickContainer}>
                <div className={styles.videoClickTriangle}></div>
              </div>
            </div>
            <span className={styles.visuallyHidden}>
              Click to play the video
            </span>
          </button>
        )}
      </div>
      {props.withOverlay && !isMobile && (
        <ModalWithCarousel
          video={{
            ...props,
            withOverlay: false,
            showControls: true,
            autoplay: true,
            forcePlay: undefined,
            setForcePlay: undefined,
          }}
          modalOpened={props.forcePlay || modalOpened}
          closeModal={() => {
            setModalOpened(false);
            props.setForcePlay && props.setForcePlay(false);
          }}
        />
      )}
    </>
  );
}
