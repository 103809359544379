import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { spacings } from "../../basics/spacings";
import { rem } from "../../basics/layout";
import { LocaleContext } from "../../hooks/LocaleContext";
import StartPageData from "../../models/content/StartPageData";
import { customTypography, typography } from "../../basics/typography";
import { colors } from "../../basics/colors";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";

export function LanguagePicker() {
  const useStyles = createUseStyles({
    languagePicker: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      position: "relative",
      marginLeft: rem(spacings.s),
      zIndex: 11,
    },
    currentLanguage: {
      ...customTypography(
        {
          paddingLeft: rem(spacings.xs),
          ...typography.textSmall,
          marginBottom: 0,
          color: colors.gray100,
          transition: "color 0.3s ease",
        },
        {},
        {
          marginBottom: 0,
        }
      ),
      "&:hover": {
        color: colors.black,
      },
    },
    flagImage: {
      width: 22,
      height: 16,
    },
    languageSelect: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      padding: 0,
      border: "none",
      font: "inherit",
      color: "inherit",
      textAlign: "inherit",
      background: "none",
      transition: "filter 0.3s ease",
      filter: "grayscale(0) contrast(1)",
      "&:hover": {
        transition: "filter 0.3s ease",
        filter: "grayscale(0) contrast(1.3)",
      },
    },
    visuallyHidden: {
      ...typography.visuallyHidden,
    },
  });

  const { setIsLocaleOverlayOpened } = useContext(LocaleContext);
  const { getStartPageData, getImmediateStartPageData, getWebsiteData } =
    useContext(GlobalContentStoreContext);
  const websiteData = getWebsiteData();
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  const styles = useStyles();

  if (!startPageData) return null;

  const currentLanguage =
    websiteData?.currentLanguage?.displayName || "International";

  return (
    <div className={styles.languagePicker} data-testid="languagepicker">
      <button
        className={styles.languageSelect}
        onClick={() => setIsLocaleOverlayOpened(true)}
      >
        <img
          src={startPageData.localeIcon?.value?.url || ""}
          className={styles.flagImage}
          alt="Change language"
        />
        {currentLanguage && (
          <span aria-hidden={true} className={styles.currentLanguage}>
            <span className={styles.visuallyHidden}>Current language:</span>
            {currentLanguage}
          </span>
        )}
      </button>
    </div>
  );
}
