import React, { useState } from "react";
import { Container, onBreakpoint, rem } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { Button } from "../Button/Button";
import { spacings } from "../../basics/spacings";
import { typography, customTypography } from "../../basics/typography";
import { NewsletterSignupTeaserBlockProps } from "../../models/content/NewsletterSignupTeaserBlockData";
import { RenderProperty } from "../../views/RenderProperty";
import { Input } from "../Input/Input";
import { ComponentTheme } from "../../views/RenderComponent";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { isDeviceResOrLower, useDeviceType } from "../../hooks/useDeviceType";
import { GenericModalBodyContainer, Modal } from "../Modal/Modal";
import { ContentAreaProperty, StringProperty } from "../../models/Property";
import { FormContainer } from "./Forms/FormContainer";
import { LocalizedLabel } from "../../hooks/LocalizationContext";
import FormContainerBlockData from "../../models/content/FormContainerBlockData";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";
import {
  MenuColumnHeader,
  NAVI_GROUP_LIST_LEFT_PADDING,
} from "../Header/MonsterNavigation/MonsterNavigation";

const useStandardThemeStyles = createUseStyles({
  newsletter: {
    backgroundColor: colors.gray20,
    marginBottom: rem(spacings.m),
    paddingTop: rem(spacings.sam),
    paddingBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
      paddingTop: rem(spacings.l),
      paddingBottom: rem(spacings.xl),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
      paddingTop: rem(spacings.xxl),
      paddingBottom: rem(spacings.xxl),
    }),
  },
  newsletterContent: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    ...onBreakpoint("md", {
      flexWrap: "nowrap",
    }),
  },
  newsletterText: {
    flexBasis: "100%",
    marginBottom: rem(spacings.sam),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.m),
    }),
    ...onBreakpoint("md", {
      flexBasis: "50%",
      marginBottom: 0,
    }),
  },
  newsletterHeadline: {
    ...customTypography(
      {
        ...typography.h2,
        marginBottom: rem(spacings.xs),
      },
      {},
      {
        marginBottom: rem(spacings.xs),
      }
    ),
  },
  newsletterDescription: customTypography(
    typography.h4,
    { marginBottom: 0 },
    { marginBottom: 0 }
  ),
  newsletterForm: {
    display: "flex",
    flexBasis: "100%",
    alignItems: "center",
    ...onBreakpoint("md", {
      flexBasis: "50%",
    }),
    "& input": {
      pointerEvents: "none",
    },
  },
  newsletterInput: customTypography(
    {
      ...typography.textDefault,
      marginRight: rem(spacings.sam),
      padding: `14px ${spacings.sam}`,
    },
    { marginBottom: 0 },
    { marginBottom: 0 }
  ),
  icon: {},
  button: {},
});

const useNavigationThemeStyles = createUseStyles({
  newsletter: {},
  newsletterContent: {
    textAlign: "center",
    ...onBreakpoint("sm", {
      textAlign: "left",
    }),
  },
  newsletterText: {
    marginBottom: rem(spacings.sam),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.m),
    }),
  },
  newsletterHeadline: {
    ...typography.h4,
  },
  newsletterDescription: {
    ...onBreakpoint("sm", {
      paddingLeft: NAVI_GROUP_LIST_LEFT_PADDING,
    }),
  },
  newsletterForm: {
    ...onBreakpoint("sm", {
      paddingLeft: NAVI_GROUP_LIST_LEFT_PADDING,
    }),
  },
  newsletterInput: {},
  icon: FAIconStyles({
    width: 16,
    height: 16,
    marginRight: rem(spacings.xs),
  }),
  button: {
    width: "100%",
    justifyContent: "center",
  },
});

const useFiltersThemeStyles = createUseStyles({
  newsletter: {},
  newsletterContent: {
    textAlign: "center",
    ...onBreakpoint("sm", {
      textAlign: "left",
    }),
  },
  newsletterText: {},
  newsletterHeadline: {
    ...customTypography(
      { ...typography.h4, marginBottom: rem(spacings.sam) },
      {},
      { marginBottom: rem(spacings.m) }
    ),
  },
  newsletterDescription: {},
  newsletterForm: {},
  newsletterInput: {},
  icon: FAIconStyles({
    width: 16,
    height: 16,
    marginRight: rem(spacings.xs),
  }),
  button: {
    width: "100%",
    justifyContent: "center",
  },
});

const useOverlayStyles = createUseStyles({
  content: {
    "& .Form__Element:first-child": {
      marginTop: 0,
      "& h3": {
        marginBottom: 0,
      },
    },
  },
});

const NEWSLETTER_ICON = "mailbox";

export function NewsletterBlock(
  props: NewsletterSignupTeaserBlockProps & {
    theme?: ComponentTheme;
  }
) {
  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const isNavigationTheme = props.theme === "navigation";
  const isFilterTheme = props.theme === "filters";
  const { headline, description, formArea, overlayHeadline } = props.data;

  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");

  const styles = isNavigationTheme
    ? useNavigationThemeStyles()
    : isFilterTheme
    ? useFiltersThemeStyles()
    : useStandardThemeStyles();

  return (
    <>
      <Container
        isFluid
        className={styles.newsletter}
        dataTestid="NewsletterBlock"
      >
        <Container isFluid={isNavigationTheme || isFilterTheme}>
          <div className={styles.newsletterContent}>
            <div className={styles.newsletterText}>
              {isNavigationTheme && !isMobile ? (
                <MenuColumnHeader
                  icon={NEWSLETTER_ICON}
                  text={headline.value || ""}
                />
              ) : (
                <SemanticHeader
                  className={styles.newsletterHeadline}
                  headerSize={props.data.headerSize?.value}
                >
                  {isNavigationTheme && (
                    <FAIcon icon={NEWSLETTER_ICON} className={styles.icon} />
                  )}
                  <RenderProperty value={headline} />
                </SemanticHeader>
              )}
              {!isFilterTheme && (
                <div className={styles.newsletterDescription}>
                  <RenderProperty value={description} />
                </div>
              )}
            </div>
            <div className={styles.newsletterForm}>
              {!isNavigationTheme && !isFilterTheme && (
                <Input
                  type="email"
                  theme="white"
                  label="Enter your e-mail"
                  placeholder="your.name@email.com"
                  className={styles.newsletterInput}
                  onClick={() => setModalOpened(true)}
                  disabled
                />
              )}
              <Button
                className={styles.button}
                small={true}
                type={
                  isNavigationTheme || isFilterTheme ? "primary" : "secondary"
                }
                onClick={() => setModalOpened(true)}
              >
                <LocalizedLabel
                  section="NewsletterSignupTeaser"
                  label="Button"
                />
              </Button>
            </div>
          </div>
        </Container>
      </Container>
      <NewsletterOverlay
        modalOpened={isModalOpened}
        closeModal={() => setModalOpened(false)}
        formArea={formArea}
        overlayHeadline={overlayHeadline}
      />
    </>
  );
}

export function NewsletterOverlay(props: {
  modalOpened: boolean;
  closeModal: () => void;
  formArea: ContentAreaProperty;
  overlayHeadline: StringProperty;
}) {
  const styles = useOverlayStyles();

  return (
    <Modal
      modalOpened={props.modalOpened}
      closeModal={props.closeModal}
      allowMobile
    >
      <GenericModalBodyContainer heading={props.overlayHeadline}>
        <div className={styles.content}>
          {props.formArea.expandedValue?.[0] && (
            <FormContainer
              data={props.formArea.expandedValue[0] as FormContainerBlockData}
            />
          )}
        </div>
      </GenericModalBodyContainer>
    </Modal>
  );
}
