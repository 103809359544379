import React from "react";
import { Col, Container, onBreakpoint, rem, Row } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { spacings } from "../../basics/spacings";
import { typography } from "../../basics/typography";
import { ButtonWrapper } from "../Button/Button";
import { colors } from "../../basics/colors";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { KeyVisualWithSchemaBlockProps } from "../../models/content/KeyVisualWithSchemaBlockData";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  AnimatedBlockRenderProperty,
  AnimatedRenderProperty,
  RenderProperty,
} from "../../views/RenderProperty";
import TextTeaserBlockData from "../../models/content/TextTeaserBlockData";
import ImageTeaserBlockData from "../../models/content/ImageTeaserBlockData";
import cn from "classnames";
import { AnimatedRevealBlock } from "../AnimatedReveal/AnimatedReveal";

const useStyles = createUseStyles({
  keyVisual: {
    marginTop: rem(spacings.sam),
    marginBottom: rem(spacings.sam),
  },
  colBig: {
    "& h1": {
      ...typography.h1,
    },
    "& $textTeaser": {
      "& h1": {
        ...typography.h2,
      },
    },
  },
  colSmall: {
    "& h2": {
      ...typography.h3,
    },
    "& $textTeaser": {
      "& h2": {
        ...typography.h4,
      },
    },
  },
  colEven: {
    "& h1, & h2": {
      ...typography.h2,
    },
  },
  textTeaser: {
    backgroundColor: (color: string) => {
      switch (color) {
        case "yellow":
          return colors.alertsYellow40;
        case "light_blue":
          return colors.lightBlue40;
        case "dark_blue":
          return colors.darkBlue70;
        case "dark_red":
          return colors.darkRed70;
        case "green":
          return colors.darkGreen70;
        default:
          return colors.lightBlue40;
      }
    },
    marginBottom: rem(spacings.sam),
    padding: `${spacings.s} ${spacings.s} ${spacings.m}`,
    ...onBreakpoint("md", {
      padding: `${spacings.xxl} ${spacings.l} ${spacings.l}`,
    }),
  },
  buttons: {
    display: "inline-block",
    "&:not(:last-child)": {
      marginRight: rem(spacings.m),
    },
  },
  imageTeaser: {
    marginBottom: rem(spacings.sam),
  },
  image: {
    marginBottom: rem(spacings.s),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.l),
    }),
  },
  caption: {
    ...typography.caption,
    marginBottom: rem(spacings.s),
  },
  headlineWrapper: {
    display: "flex",
    flexDirection: "row",
    ...onBreakpoint("sm", {
      flexDirection: "column",
    }),
    ...onBreakpoint("md", {
      flexDirection: "row",
    }),
  },
  textHeadline: {
    color: `${colors.white} !important`,
    marginRight: rem(spacings.sam),
    ...onBreakpoint("sm", {
      marginRight: 0,
    }),
  },
  icon: {
    ...FAIconStyles({
      width: 56,
      height: 56,
      color: colors.white,
      marginLeft: "auto",
      marginBottom: rem(spacings.sam),
      alignSelf: "center",
      order: 1,
      ...onBreakpoint("sm", {
        width: 120,
        height: 120,
        marginLeft: "unset",
        order: 0,
      }),
      ...onBreakpoint("md", {
        order: 1,
        marginLeft: "auto",
      }),
    }),
  },
});

export function KeyVisualHomepage(props: KeyVisualWithSchemaBlockProps) {
  const { keyVisualSchema, teaserBlocks } = props.data;
  const styles = useStyles();
  const schema = keyVisualSchema?.value;
  const goldenRatio = schema === "golden-ratio";
  const goldenRatioReversed = schema === "golden-ratio-reversed";
  const evenlyRatio = schema === "evenly-sized";

  const columnOneItems = (
    teaserBlocks.expandedValue as Array<
      TextTeaserBlockData & ImageTeaserBlockData
    >
  )?.slice(0, !goldenRatioReversed ? 1 : 2);

  const columnTwoItems = (
    teaserBlocks.expandedValue as Array<
      TextTeaserBlockData & ImageTeaserBlockData
    >
  )?.slice(!goldenRatioReversed ? 1 : 2, evenlyRatio ? 2 : 3);

  return (
    <Container className={styles.keyVisual}>
      <Row>
        <Col
          sm={goldenRatio ? 7 : goldenRatioReversed ? 5 : 6}
          className={
            goldenRatio
              ? styles.colBig
              : goldenRatioReversed
              ? styles.colSmall
              : styles.colEven
          }
        >
          {columnOneItems.map((teaser, idx) => {
            return teaser.imageAdaptive ? (
              <ImageTeaser
                {...teaser}
                schema={schema || ""}
                leftColumn
                key={idx}
                revealDelay={100 * idx}
              />
            ) : (
              <AnimatedRevealBlock revealDelay={100 * idx} key={idx}>
                <TextTeaser {...teaser} schema={schema || ""} leftColumn />
              </AnimatedRevealBlock>
            );
          })}
        </Col>

        <Col
          sm={goldenRatio ? 5 : goldenRatioReversed ? 7 : 6}
          className={cn(
            goldenRatio
              ? styles.colSmall
              : goldenRatioReversed
              ? styles.colBig
              : styles.colEven
          )}
        >
          {columnTwoItems.map((teaser, idx) => {
            return teaser.imageAdaptive ? (
              <ImageTeaser
                {...teaser}
                schema={schema || ""}
                rightColumn
                key={idx}
                revealDelay={(idx + columnOneItems.length) * 100}
              />
            ) : (
              <AnimatedRevealBlock
                revealDelay={(idx + columnOneItems.length) * 100}
                key={idx}
              >
                <TextTeaser {...teaser} schema={schema || ""} rightColumn />
              </AnimatedRevealBlock>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
}

function TextTeaser(
  props: TextTeaserBlockData & {
    schema: string;
    leftColumn?: boolean;
    rightColumn?: boolean;
  }
) {
  const styles = useStyles(props.color.value);
  return (
    <div className={styles.textTeaser}>
      <div className={styles.headlineWrapper}>
        <FAIcon icon={props.icon.value as IconProp} className={styles.icon} />
        {(props.schema === "golden-ratio" && props.leftColumn) ||
        (props.schema === "golden-ratio-reversed" && props.rightColumn) ||
        (props.schema === "evenly-sized" && props.leftColumn) ? (
          <h1 className={styles.textHeadline}>
            <RenderProperty value={props.headline} />
          </h1>
        ) : (
          <h2 className={styles.textHeadline}>
            <RenderProperty value={props.headline} />
          </h2>
        )}
      </div>
      {props.buttons.value?.map((button, idx) => (
        <ButtonWrapper {...button} key={idx} />
      ))}
    </div>
  );
}

function ImageTeaser(
  props: ImageTeaserBlockData & {
    schema: string;
    leftColumn?: boolean;
    rightColumn?: boolean;
    revealDelay: number;
  }
) {
  const styles = useStyles();
  return (
    <div className={styles.imageTeaser}>
      <AnimatedBlockRenderProperty
        value={props.imageAdaptive}
        className={styles.image}
        revealDelay={props.revealDelay}
      />
      <span className={styles.caption}>
        <AnimatedRenderProperty
          value={props.captionHeadline}
          revealDelay={props.revealDelay + 100}
        />
      </span>
      {(props.schema === "golden-ratio" && props.leftColumn) ||
      (props.schema === "golden-ratio-reversed" && props.rightColumn) ||
      (props.schema === "evenly-sized" && props.leftColumn) ? (
        <h1>
          <AnimatedRenderProperty
            value={props.headline}
            revealDelay={props.revealDelay + 200}
          />
        </h1>
      ) : (
        <h2>
          <AnimatedRenderProperty
            value={props.headline}
            revealDelay={props.revealDelay + 300}
          />
        </h2>
      )}
      {props.buttons.value?.map((button, idx) => (
        <AnimatedRevealBlock
          key={idx}
          revealDelay={props.revealDelay + 400}
          className={styles.buttons}
        >
          <ButtonWrapper {...button} />
        </AnimatedRevealBlock>
      ))}
    </div>
  );
}
