import React from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, rem, Row } from "../../basics/layout";
import { spacings } from "../../basics/spacings";
import { typography, customTypography } from "../../basics/typography";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { colors } from "../../basics/colors";
import { QuickLinksBlockProps } from "../../models/content/QuickLinksBlockData";
import { QuickLink } from "../../models/Property";
import {
  AnimatedRenderProperty,
  showPropertyOnEdit,
} from "../../views/RenderProperty";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ComponentTheme } from "../../views/RenderComponent";
import cn from "classnames";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";

const useStyles = createUseStyles({
  quickLinks: {
    position: "relative",
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  textContainer: {
    marginBottom: rem(spacings.s),
  },
  headline: {
    ...typography.h2,
    textAlign: "center",
  },
  description: customTypography(
    { ...typography.textDefault, textAlign: "center" },
    { marginBottom: rem(spacings.s) },
    { marginBottom: rem(spacings.sam) },
    { marginBottom: rem(spacings.m) }
  ),
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: (itemsAmount: number) => (itemsAmount > 3 ? "wrap" : "nowrap"),
    ...onBreakpoint("sm", {
      flexWrap: (itemsAmount: number) => (itemsAmount > 5 ? "wrap" : "nowrap"),
    }),
    ...onBreakpoint("md", {
      flexWrap: () => "nowrap",
    }),
  },
  card: {
    flexBasis: (itemsAmount: number) => {
      switch (itemsAmount) {
        case 5:
        case 6:
          return "33%";
        case 4:
          return "50%";
        default:
          return 200;
      }
    },
    maxWidth: 246,
    marginBottom: rem(spacings.m),
    padding: `${rem(spacings.m)} ${spacings.s}`,
    textAlign: "center",
    borderLeft: `1px solid ${colors.gray30}`,
    cursor: "pointer",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: colors.gray20,
      transition: "background-color 0.3s ease",
      "& $cardIcon": {
        transform: "scale(1.1)",
        transition: "transform 0.3s ease",
      },
    },
    "&:nth-child(2)": {
      borderRight: (itemsAmount: number) =>
        itemsAmount === 4 ? `1px solid ${colors.gray30}` : "none",
    },
    "&:nth-child(3)": {
      borderRight: (itemsAmount: number) =>
        itemsAmount > 4 ? `1px solid ${colors.gray30}` : "none",
    },
    "&:last-child": {
      borderRight: () => `1px solid ${colors.gray30}`,
    },
    ...onBreakpoint("sm", {
      flexBasis: (itemsAmount: number) => (itemsAmount > 5 ? "25%" : 220),
      maxWidth: "unset",
      hyphens: "auto",
      marginBottom: rem(spacings.l),
      padding: `${rem(spacings.l)} ${spacings.s}`,
      borderRight: 0,
      "&:nth-child(2), &:nth-child(3)": {
        borderRight: () => "none",
      },
      "&:nth-child(4)": {
        borderRight: (itemAmount: number) =>
          itemAmount > 5 ? `1px solid ${colors.gray30}` : "none",
      },
      "&:last-child": {
        borderRight: () => `1px solid ${colors.gray30}`,
      },
    }),
    ...onBreakpoint("md", {
      marginBottom: 0,
      padding: `${rem(spacings.xl)} ${spacings.sam}`,
      flexBasis: () => 288,
      "&:nth-child(4)": {
        borderRight: () => "none",
      },
      "&:last-child": {
        borderRight: () => `1px solid ${colors.gray30}`,
      },
    }),
  },
  cardIcon: {
    color: colors.lightGreen40,
    marginBottom: rem(spacings.m),
    transform: "scale(1)",
    transition: "transform 0.3s ease",
    ...FAIconStyles({
      width: 64,
      height: 64,
    }),
  },
  cardTitle: customTypography(
    { ...typography.h4, hyphens: "auto", wordBreak: "break-all" },
    { marginBottom: 0 },
    { marginBottom: 0 },
    { marginBottom: 0 }
  ),
  homepage: {
    backgroundColor: colors.gray10,
    paddingTop: rem(spacings.m),
    ...onBreakpoint("sm", {
      paddingTop: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      paddingTop: rem(spacings.xxl),
      paddingBottom: rem(spacings.xxl),
    }),
  },
  noBorders: {
    border: "none !important",
  },
});

export function QuickLinksBlock(
  props: QuickLinksBlockProps & { theme: ComponentTheme }
) {
  const itemsAmount = props.data.links.value?.length;
  const styles = useStyles(itemsAmount);
  const isHomepage = props.theme === "homepage";

  const isGrayBg = props.data.backgroundColor?.value === "gray";

  return (
    <Container
      className={cn(
        styles.quickLinks,
        (isHomepage || isGrayBg) && styles.homepage
      )}
      isFluid={isHomepage || isGrayBg}
    >
      <Row>
        <Col
          sm={10}
          smStart={1}
          md={8}
          mdStart={2}
          className={styles.textContainer}
        >
          {showPropertyOnEdit(props.data.headline) && (
            <SemanticHeader
              className={styles.headline}
              headerSize={props.data.headerSize?.value}
            >
              <AnimatedRenderProperty value={props.data.headline} />
            </SemanticHeader>
          )}
          {showPropertyOnEdit(props.data.description) && (
            <div className={styles.description}>
              <AnimatedRenderProperty value={props.data.description} />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.cardContainer}>
            {props.data.links.value?.map((l, idx) => (
              <Card
                {...l}
                itemsAmount={itemsAmount || 0}
                theme={props.theme}
                key={idx}
                revealDelay={100 * idx}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function Card(
  props: QuickLink & {
    itemsAmount: number;
    theme: ComponentTheme;
    revealDelay: number;
  }
) {
  const { icon, link, itemsAmount } = props;
  const styles = useStyles(itemsAmount);
  const firstLink = link?.[0];
  return (
    <a
      href={firstLink?.href}
      className={cn(
        styles.card,
        props.theme === "homepage" && styles.noBorders
      )}
      target={firstLink?.target}
    >
      <FAIcon icon={icon as IconProp} className={styles.cardIcon} />
      <h4 className={styles.cardTitle}>
        <AnimatedRenderProperty
          value={firstLink?.text}
          revealDelay={props.revealDelay}
        />
      </h4>
    </a>
  );
}
