import React from "react";
import { rem } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { customTypography, typography } from "../../basics/typography";
import cn from "classnames";

const useStyles = createUseStyles({
  element: {
    marginBottom: rem(spacings.s),
    display: "flex",
    position: "relative",
    "& input:checked + $customCheckbox::after": {
      opacity: 1,
    },
    "& input:focus + $customCheckbox": {
      outline: `2px solid ${colors.focusOutline}`,
    },
  },
  customCheckbox: {
    content: '""',
    cursor: "pointer",
    height: 32,
    width: 32,
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: colors.white,
    border: `2px solid ${colors.gray90}`,
    "&:after": {
      position: "absolute",
      content: '""',
      top: 0,
      left: 8,
      height: 20,
      width: 12,
      cursor: "pointer",
      border: `2px solid ${colors.gray90}`,
      borderLeft: 0,
      borderTop: 0,
      opacity: 0,
      transform: "rotate(45deg)",
    },
  },
  label: {
    ...customTypography(
      {
        ...typography.textDefault,
        marginLeft: rem(spacings.sam),
        marginBottom: 0,
        cursor: "pointer",
      },
      {},
      {
        marginBottom: 0,
      }
    ),
  },
  strong: {
    fontWeight: "bold",
  },
  disabled: {
    "& div": {
      cursor: "not-allowed",
      borderColor: colors.gray40,
      "&:after": {
        cursor: "not-allowed",
        borderColor: colors.gray40,
      },
    },
    "& label": {
      color: colors.gray40,
    },
  },
  checkbox: {
    height: 32,
    width: 32,
    flexShrink: 0,
    margin: 0,
    padding: 0,
    opacity: 0,
  },
});

export function Checkbox(props: {
  label: string;
  checked: boolean;
  onCheck: () => void;
  disabled?: boolean;
  className?: string;
  onBlur?: () => void;
}) {
  const styles = useStyles();
  return (
    <label
      className={cn(
        styles.element,
        props.checked && styles.strong,
        props.disabled && styles.disabled,
        props.className
      )}
    >
      <input
        type="checkbox"
        className={styles.checkbox}
        checked={props.checked}
        onChange={() => props.onCheck()}
        disabled={props.disabled}
        onBlur={props.onBlur}
      />
      <div className={styles.customCheckbox} />
      <span className={styles.label}>{props.label}</span>
    </label>
  );
}
