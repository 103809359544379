import React from "react";
import { ContactExpertComponent } from "../Block/ContactExpertBlock/ContactExpertComponent";
import StartPageData from "../../models/content/StartPageData";

export function ContactExpert(props: {
  contactsHeadline: StartPageData["contactsHeadline"];
  contactsDescriptionRichText: StartPageData["contactsDescriptionRichText"];
  contactsTagLine: StartPageData["contactsTagLine"];
  contactsCtaButton: StartPageData["contactsCtaButton"];
}) {
  const {
    contactsHeadline,
    contactsDescriptionRichText,
    contactsTagLine,
    contactsCtaButton,
  } = props;
  return (
    <ContactExpertComponent
      title={contactsHeadline}
      description={contactsDescriptionRichText}
      tagLine={contactsTagLine}
      buttons={contactsCtaButton}
      isFeaturedPage
    />
  );
}
