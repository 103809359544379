import React, { useEffect, useState } from "react";
import { EpiContext } from "./hooks/EpiContext";
import { RenderComponent } from "./views/RenderComponent";
import {
  useGlobalContentStore,
  InitialDataType,
  GlobalContentStoreContext,
} from "./hooks/GlobalContentStore";
import { createUseStyles, JssProvider, SheetsRegistry } from "react-jss";
import ConsentBlockData from "./models/content/ConsentBlockData";
import { PrivacyContext, usePrivacyStore } from "./hooks/PrivacyStore";
import {
  LocalizationDataType,
  LocalizationContext,
  InitLocalizationContext,
} from "./hooks/LocalizationContext";
import LocaleSelectorBlockData from "./models/content/LocaleSelectorBlockData";
import { LocaleContext } from "./hooks/LocaleContext";
import { colors } from "./basics/colors";
import { UnsupportedBrowserNotice } from "./components/UnsupportedBrowserNotice/UnsupportedBrowserNotice";
import { fonts } from "./basics/fonts";
import { renderToString } from "react-dom/server";
import { isCypressTest } from "./basics/isCypressTest";
import cn from "classnames";

export const NO_SCROLLING_CLASS = "no-scrolling" as const;
export const MODALS_ROOT_ID = "modals-root" as const;

const useStyles = createUseStyles({
  "@global": {
    html: {
      margin: 0,
      padding: 0,
      overflowX: "hidden",
      background: colors.white,
      [`&.${NO_SCROLLING_CLASS}`]: {
        position: "fixed",
        overflow: "hidden",
      },
      boxSizing: "border-box",
    },
    "*, :after, :before": {
      boxSizing: "inherit",
    },
    "symbol path": {
      fill: `${colors.gray60} !important`,
    },
    body: {
      ...fonts.sansRegular,
      webkitFontSmoothing: "antialiased",
      mozOsxFontSmoothing: "grayscale",
      margin: 0,
      fontSize: 16,
    },
    "body figure": {
      margin: "unset",
    },
    a: {
      textDecoration: "none",
    },
    "a, p, span": {
      fontFamily: "inherit",
      color: "inherit",
      wordBreak: "break-word",
    },
    button: {
      border: "none",
      cursor: "pointer",
    },
    ".component-piwik": {
      display: "none",
    },
  },
  app: {
    width: "100vw",
    maxWidth: "100vw",
  },
  cypressTest: {
    "& *": {
      WebkitTransition: "none !important",
      MozTransition: "none !important",
      OTransition: "none !important",
      transition: "none !important",
      WebkitAnimation: "none !important",
      MozAnimation: "none !important",
      OAnimation: "none !important",
      animation: "none !important",
    },
    '& *[style*="opacity"]': {
      opacity: "1 !important",
    },
    '& *[style*="transform"]': {
      transform: "none !important",
    },
  },
});

export function App(props: {
  testInitialData?: InitialDataType;
  testTranslations?: LocalizationDataType;
}) {
  const initialData =
    typeof window.__INITIAL__DATA__ !== "undefined"
      ? (JSON.parse(window.__INITIAL__DATA__) as InitialDataType)
      : props.testInitialData || null;

  const initialLocalizationData =
    typeof window.jsl10n !== "undefined"
      ? (JSON.parse(window.jsl10n) as LocalizationDataType)
      : props.testTranslations || null;

  const isEditing =
    typeof window.__EDITING__ !== "undefined"
      ? window.__EDITING__
      : process.env.NODE_ENV === "development";

  useEffect(() => {
    window.addEventListener("load", () => {
      (window as any).epi?.subscribe?.("contentSaved", function (event: any) {
        globalContentStore.updateWithContentLinkId(event.contentLink);
      });
    });
    window.history &&
      window.location &&
      history.replaceState(
        {},
        "",
        `${window.location.pathname?.toLowerCase()}${window.location.search}${
          window.location.hash
        }`
      );
  }, []);

  const globalContentStore = useGlobalContentStore(initialData, isEditing);

  if (!globalContentStore.isInitialized()) return null;
  return (
    <EpiContext.Provider
      value={{
        isDebug: true,
        isEditable: isEditing,
      }}
    >
      <GlobalContentStoreContext.Provider value={globalContentStore}>
        <LocalizationContext.Provider
          value={InitLocalizationContext(initialLocalizationData)}
        >
          <InitedApp globalContentStore={globalContentStore} />
        </LocalizationContext.Provider>
      </GlobalContentStoreContext.Provider>
    </EpiContext.Provider>
  );
}

function InitedApp(props: {
  globalContentStore: ReturnType<typeof useGlobalContentStore>;
}) {
  const consentOptions =
    (props.globalContentStore.getImmediateStartPageData()?.consentOptions
      ?.expandedValue as ConsentBlockData) || null;
  const localeOverlay =
    (props.globalContentStore.getImmediateStartPageData()?.localeSelector
      ?.expandedValue as LocaleSelectorBlockData) || null;
  const rootContentLink = props.globalContentStore.getMainPageContentLink();

  const privacySettings = usePrivacyStore(consentOptions);
  const [isLocaleOverlayOpened, setIsLocaleOverlayOpened] = useState(false);
  const styles = useStyles();

  return (
    <div className={cn(styles.app, isCypressTest() && styles.cypressTest)}>
      <PrivacyContext.Provider value={privacySettings}>
        <LocaleContext.Provider
          value={{ isLocaleOverlayOpened, setIsLocaleOverlayOpened }}
        >
          <>
            <div id={MODALS_ROOT_ID} />
            <UnsupportedBrowserNotice />
            {consentOptions && (
              <RenderComponent contentLink={consentOptions?.contentLink} />
            )}
            {localeOverlay && (
              <RenderComponent contentLink={localeOverlay?.contentLink} />
            )}
            {rootContentLink && (
              <RenderComponent contentLink={rootContentLink} />
            )}
          </>
        </LocaleContext.Provider>
      </PrivacyContext.Provider>
    </div>
  );
}

export function SSRApp() {
  const registry = new SheetsRegistry();

  const body = renderToString(
    <JssProvider registry={registry}>
      <App />
    </JssProvider>
  );
  const minifiedStyles = registry.registry
    .filter((ss) => ss.attached)
    .map((ss) => ss.toString())
    .join("\n")
    .replaceAll("}\n", "} ")
    .replaceAll("\n", "")
    .replaceAll(/\s\s+/g, " ")
    .replaceAll("{ ", "{")
    .replaceAll(": ", ":")
    .replaceAll("; ", ";");

  return { body, styles: minifiedStyles };
}
