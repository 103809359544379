import React, { useContext, useState, useEffect } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Shared/Footer";
import {
  AnimatedBlockRenderProperty,
  RenderProperty,
} from "../../views/RenderProperty";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import { NewsArticlePageProps } from "../../models/content/NewsArticlePageData";
import { Container, Row, Col, rem, onBreakpoint } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { typography } from "../../basics/typography";
import { spacings } from "../../basics/spacings";
import TaxonomyBaseData from "../../models/content/TaxonomyBaseData";
import { TagBadge } from "../TagBadge/TagBadge";
import { namePropertyValue } from "../../models/IContent";
import { BreadcrumbComponent } from "../BreadcrumbComponent/BreadcrumbComponent";
import { RelatedContentBlock } from "../Block/RelatedContentBlock";
import { ContactExpert } from "../ContactExpert/ContactExpert";
import { DownloadBlock, DownloadProps } from "../Block/DownloadBlock";

const useStyles = createUseStyles({
  header: {
    paddingTop: rem(spacings.m),
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  headline: typography.h1,
  articleInfo: {
    ...typography.caption,
    marginBottom: rem(spacings.sam),
  },
  spacer: {
    margin: `0, ${spacings.s}`,
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
  },
  textColumn: {
    order: 1,
    marginTop: rem(spacings.l),
    ...onBreakpoint("sm", {
      order: 0,
      marginTop: 0,
    }),
  },
  mobileAndTabletOnly: {
    display: "block",
    ...onBreakpoint("md", {
      display: "none",
    }),
  },
  desktopOnly: {
    display: "none",
    ...onBreakpoint("md", {
      display: "flex",
    }),
  },
  description: typography.textDefault,
});

export function NewsArticlePage(props: NewsArticlePageProps) {
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );

  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const styles = useStyles();

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  if (!startPageData) return null;

  const contactExpertData = props.data.contactsUseDefaultContent?.value
    ? startPageData
    : props.data;

  const Spacer = <span className={styles.spacer}>&nbsp;|&nbsp;</span>;
  const image = props.data.headerImageAdaptive?.value;
  const hasImage = image?.urlLarge || image?.urlMedium || image?.urlSmall;
  const showDescription = props.data.showDescription.value || false;

  return (
    <>
      <Header {...startPageData} />
      <main data-testid="NewsArticlePage">
        <Container>
          <Row className={styles.header}>
            <Col className={styles.mobileAndTabletOnly}>
              <BreadcrumbComponent />
            </Col>
            <Col
              sm={hasImage ? 6 : 12}
              md={hasImage ? 7 : 12}
              className={styles.textColumn}
            >
              <BreadcrumbComponent className={styles.desktopOnly} />
              <h1 className={styles.headline}>
                <RenderProperty
                  value={props.data.title}
                  fallbackValue={props.data.name}
                />
              </h1>
              <div className={styles.articleInfo}>
                <RenderProperty value={props.data.dateFormatted} />
                {props.data.location?.value && Spacer}
                <RenderProperty value={props.data.location} />
                {props.data.author?.value && Spacer}
                <RenderProperty value={props.data.author} />
              </div>
              <div>
                {showDescription && props.data.description && (
                  <AnimatedBlockRenderProperty
                    value={props.data.description}
                    className={styles.description}
                    revealDelay={100}
                    movement={false}
                  />
                )}
              </div>
              <div className={styles.tags}>
                {(
                  props.data.visibleTags.expandedValue as TaxonomyBaseData[]
                )?.map((item) => (
                  <TagBadge
                    isReadOnly
                    label={namePropertyValue(item.name) || ""}
                    key={item.contentLink.id}
                    href={`${props.data.newsWallLinkExpandedProperty.value?.url}?tags=${item.contentLink.id}`}
                  />
                ))}
              </div>
            </Col>
            {hasImage && (
              <Col sm={6} md={5}>
                <RenderProperty value={props.data.headerImageAdaptive} />
              </Col>
            )}
          </Row>
        </Container>
        <RenderProperty value={props.data.content} />
        <RenderProperty value={props.data.additionalContent} />
        {(props.data.downloadsGlobal?.value ||
          props.data.downloadsLocal?.value) && (
          <DownloadBlock
            downloadItems={[
              ...((props.data.downloadsLocal
                ?.expandedValue as DownloadProps[]) || []),
              ...((props.data.downloadsGlobal
                ?.expandedValue as DownloadProps[]) || []),
            ]}
          />
        )}
        {props.data.contactCta?.value === "enabled" && (
          <ContactExpert {...contactExpertData} />
        )}
        {props.data.relatedContent?.value && (
          <RelatedContentBlock relatedContent={props.data.relatedContent} />
        )}
      </main>
      <Footer {...startPageData} />
    </>
  );
}
