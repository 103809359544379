import React, { useState, useEffect, useContext } from "react";
import { createUseStyles } from "react-jss";
import { typography, customTypography } from "../../basics/typography";
import {
  Col,
  Container,
  onBreakpoint,
  onBefore,
  rem,
  Row,
  MARGIN_OF_CONTAINER,
} from "../../basics/layout";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { Button, isFile, isExternal, ButtonWrapper } from "../Button/Button";
import StartPageData from "../../models/content/StartPageData";
import {
  NewsWallApiHit,
  NewsWallApiResponse,
  getNewsWallData,
  Event,
} from "../Page/NewsWallPage";
import { Spinner } from "../Spinner/Spinner";
import cn from "classnames";
import { NewsWallListingBlockProps } from "../../models/content/NewsWallListingBlockData";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import { ComponentTheme } from "../../views/RenderComponent";
import { AdaptiveImageComponent } from "../AdaptiveImageComponent/AdaptiveImageComponent";
import { LocalizedLabel } from "../../hooks/LocalizationContext";
import { AnimatedRevealBlock } from "../AnimatedReveal/AnimatedReveal";
import { CloudinaryVideoPlayer } from "../CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import NewsArticlePageData from "../../models/content/NewsArticlePageData";
import CrossReferencePageData from "../../models/content/CrossReferencePageData";
import EventNewsPageData from "../../models/content/EventNewsPageData";
import VideoNewsWallPageData from "../../models/content/VideoNewsWallPageData";
import TaxonomyBaseData from "../../models/content/TaxonomyBaseData";
import { namePropertyValue } from "../../models/IContent";
import VideoBlockCloudinaryData from "../../models/content/VideoBlockCloudinaryData";
import { useDebounce } from "../../hooks/useDebounce";
import { RichTextWithTypographyRenderer } from "./RichTextBlock";
import { fonts } from "../../basics/fonts";

type FeaturedArticlesType =
  | NewsArticlePageData
  | CrossReferencePageData
  | EventNewsPageData
  | VideoNewsWallPageData;

const useStyles = createUseStyles({
  element: {
    marginBottom: rem(spacings.m),
    paddingTop: (isThemeHomepage: boolean) =>
      isThemeHomepage ? 0 : rem(spacings.m),
    paddingBottom: (isThemeHomepage: boolean) =>
      isThemeHomepage ? 0 : rem(spacings.l),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
      paddingTop: (isThemeHomepage: boolean) =>
        isThemeHomepage ? 0 : rem(spacings.l),
      paddingBottom: (isThemeHomepage: boolean) =>
        isThemeHomepage ? 0 : rem(spacings.xl),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
      paddingTop: (isThemeHomepage: boolean) =>
        isThemeHomepage ? 0 : rem(spacings.xl.times(2)),
      paddingBottom: (isThemeHomepage: boolean) =>
        isThemeHomepage ? 0 : rem(spacings.xxl),
    }),

    position: "relative",
  },
  desktopOnly: {
    display: "none",
    ...onBreakpoint("md", {
      display: "block",
    }),
  },
  tabletOnly: {
    display: "none",
    ...onBreakpoint("sm", {
      display: "block",
    }),
    ...onBreakpoint("md", {
      display: "none",
    }),
  },
  mobileEvents: {
    display: "block",
    backgroundColor: (isThemeHomepage: boolean) =>
      isThemeHomepage ? colors.gray10 : colors.white,
    marginTop: rem(spacings.s),
    paddingTop: rem(spacings.sam),
    paddingLeft: rem(spacings.s),
    paddingRight: rem(spacings.s),
    ...onBreakpoint("sm", {
      display: "none",
    }),
  },
  hideOnMobile: {
    display: "none",
    ...onBreakpoint("sm", {
      display: "block",
    }),
  },
  mainHeadline: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  blockOnDesktop: {
    ...onBreakpoint("md", {
      display: "none",
    }),
  },
  customEventBorder: {
    borderBottom: `2px solid ${colors.gray20}`,
    ...onBreakpoint("md", {
      borderBottom: "none",
    }),
  },
  headline: {
    ...customTypography(
      {
        ...typography.h2,
        marginBottom: rem(spacings.xl),
      },
      {},
      {
        marginBottom: rem(spacings.xl),
      }
    ),
  },
  eventsHeader: typography.textLarge,
  background: {
    position: "absolute",
    backgroundColor: (isThemeHomepage: boolean) =>
      isThemeHomepage ? colors.white : colors.gray10,
    height: "100%",

    top: 0,
    width: `calc(2 * ${MARGIN_OF_CONTAINER} + 100%)`,
    left: `calc(-1 * ${MARGIN_OF_CONTAINER})`,
  },
  secondRow: {
    marginTop: rem(spacings.s),
    ...onBreakpoint("sm", {
      marginTop: rem(spacings.sam),
    }),
    ...onBreakpoint("md", {
      marginTop: rem(spacings.m),
    }),
    rowGap: rem(spacings.s),
  },
  buttonRow: {
    marginTop: rem(spacings.m),
    display: "none",
    ...onBreakpoint("md", {
      display: "flex",
      justifyContent: "center",
    }),
  },
  fullHeight: {
    height: "100%",
  },
});

export function NewsWallTeaserBlock(
  props: NewsWallListingBlockProps & { theme: ComponentTheme }
) {
  const styles = useStyles(props.theme === "homepage");
  const [newsWallData, setNewsWallData] = useState<NewsWallApiResponse | null>(
    null
  );
  const [isLoading, setLoading] = useState(false);
  const { getStartPageData, getImmediateStartPageData, getWebsiteData } =
    useContext(GlobalContentStoreContext);
  const websiteData = getWebsiteData();
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const filters = props.data.filters?.value?.map((filter) => filter.id) || [];
  const { debouncePromise } = useDebounce();

  function loadItems(newsWallData?: StartPageData["newsWallPage"]) {
    setLoading(true);
    debouncePromise(() =>
      getNewsWallData(
        1,
        newsWallData?.value || undefined,
        websiteData?.currentLanguage.link?.toLowerCase(),
        filters,
        []
      ).then((response) => {
        setNewsWallData(response);
        setLoading(false);
      })
    );
  }

  const newsWallPage =
    props.data.useCustomNewsWallPage.value === "enabled"
      ? props.data.customNewsWallPage
      : startPageData?.newsWallPage;

  useEffect(() => {
    loadItems(newsWallPage);
  }, [startPageData]);

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  if (!startPageData) return null;
  if (isLoading) return <Spinner />;

  function convertToNewsWallApiHit(
    item: FeaturedArticlesType
  ): NewsWallApiHit | null {
    function generateNewsWallTags(items: TaxonomyBaseData[]) {
      return items.map((i) => ({
        Name: namePropertyValue(i.name) || "",
        ContentLink: String(i.contentLink.id),
      }));
    }

    const commonPart = {
      Id: item.contentLink.id,
      Title: item.title.value,
      Description: item.description.value,
      Url: item.url || "",
      Tags: generateNewsWallTags(
        (item.visibleTags.expandedValue as TaxonomyBaseData[]) || []
      ),
      Categories: generateNewsWallTags(
        (item.categories.expandedValue as TaxonomyBaseData[]) || []
      ),
    };

    const isArticle = (i: FeaturedArticlesType): i is NewsArticlePageData =>
      i.contentType.join("/") === "Page/NewsArticlePage";
    const isCrossReference = (
      i: FeaturedArticlesType
    ): i is CrossReferencePageData =>
      i.contentType.join("/") === "Page/CrossReferencePage";
    const isEvent = (i: FeaturedArticlesType): i is EventNewsPageData =>
      i.contentType.join("/") === "Page/EventNewsPage";
    const isVideo = (i: FeaturedArticlesType): i is VideoNewsWallPageData =>
      i.contentType.join("/") === "Page/VideoNewsWallPage";

    if (isArticle(item)) {
      const image = item.metaImageAdaptive.value;
      return {
        ...commonPart,
        Type: "Article",
        ImageUrlLarge: image?.urlLarge || null,
        ImageUrlMedium: image?.urlMedium || null,
        ImageUrlSmall: image?.urlSmall || null,
        ImageAlt: image?.alternateText || null,
        Date: item.date.value,
        Location: item.location.value,
        Author: item.author.value,
      };
    }

    if (isCrossReference(item)) {
      const image = item.imageAdaptive.value;
      const link = item.link.value?.[0];
      return {
        ...commonPart,
        Type: "CrossReference",
        ImageUrlLarge: image?.urlLarge || null,
        ImageUrlMedium: image?.urlMedium || null,
        ImageUrlSmall: image?.urlSmall || null,
        ImageAlt: image?.alternateText || null,
        Link: link?.href || null,
        LinkText: link?.text || null,
      };
    }

    if (isEvent(item)) {
      const link = item.link.value?.[0];
      return {
        ...commonPart,
        Type: "Event",
        DateFrom: item.date.value || "",
        DateTo: item.dateTo.value,
        Location: item.location.value || "",
        Link: link?.href || null,
        LinkText: link?.text || null,
      };
    }

    if (isVideo(item)) {
      const videoBlock = (
        item.videoBlockArea.expandedValue as VideoBlockCloudinaryData[] | null
      )?.[0];
      const image = videoBlock?.thumbnail.value;

      return {
        ...commonPart,
        Type: "Video",
        ImageUrlLarge: image?.urlLarge || null,
        ImageUrlMedium: image?.urlMedium || null,
        ImageUrlSmall: image?.urlSmall || null,
        ImageAlt: image?.alternateText || null,
        CloudinaryVideoPublicID:
          videoBlock?.cloudinaryVideoPublicID.value || null,
        StartAction: videoBlock?.startAction.value || null,
        EndAction: videoBlock?.endAction.value || null,
        ShowControls: videoBlock?.showControls.value || null,
      };
    }

    return null;
  }

  const featured = (
    (props.data.featuredArticles.expandedValue as
      | FeaturedArticlesType[]
      | null) || []
  )
    .map(convertToNewsWallApiHit)
    .filter((x): x is NewsWallApiHit => Boolean(x));
  const featuredIds = featured.map((f) => f.Id);

  const cards = [
    ...featured,
    ...((newsWallData && newsWallData.Hits) || []).filter(
      (h) => !featuredIds.includes(h.Id)
    ),
  ];

  const showEvents =
    (newsWallData?.UpcomingEvents?.length || 0) !== 0 &&
    props.data.showEvents.value;
  const headerText =
    props.data.useCustomNewsWallPage.value === "enabled" ? (
      props.data.headline.value
    ) : showEvents ? (
      <LocalizedLabel section="NewsWallTeaser" label="InsightsEvents" />
    ) : (
      <LocalizedLabel section="NewsWallTeaser" label="RelatedInsights" />
    );

  return (
    <>
      <Container className={styles.element}>
        <div className={styles.background} />
        <Col
          className={cn(
            styles.mainHeadline,
            showEvents && styles.blockOnDesktop
          )}
        >
          <h2 className={styles.headline}>{headerText}</h2>
        </Col>

        <Row>
          {showEvents && (
            <Col sm={4}>
              <span className={styles.desktopOnly}>
                <h2 className={styles.headline}>{headerText}</h2>
              </span>
              <span className={styles.tabletOnly}>
                <span className={styles.eventsHeader}>
                  <LocalizedLabel
                    section="NewsWallPage"
                    label="UpcomingEvents"
                  />
                </span>
              </span>
              {newsWallData && newsWallData.UpcomingEvents.length > 0 && (
                <div className={styles.hideOnMobile}>
                  {newsWallData.UpcomingEvents.map((item, idx) => (
                    <Event
                      {...item}
                      key={idx}
                      className={styles.customEventBorder}
                    />
                  ))}
                </div>
              )}
            </Col>
          )}
          <Col sm={showEvents ? 8 : 12} smStart={showEvents ? 4 : undefined}>
            {cards.slice(0, 1).map((card, cidx) => (
              <AnimatedRevealBlock key={cidx} className={styles.fullHeight}>
                <FirstItemCard
                  {...card}
                  startPageData={startPageData}
                  sideCard={!showEvents}
                  theme={props.theme}
                />
              </AnimatedRevealBlock>
            ))}
          </Col>
        </Row>
        {cards.length > 1 && (
          <Row className={styles.secondRow}>
            {cards.slice(1, 4).map((card, cidx) => (
              <Col key={cidx} sm={4}>
                <AnimatedRevealBlock
                  revealDelay={cidx * 100 + 100}
                  className={styles.fullHeight}
                >
                  <NewsWallTeaserCard
                    {...card}
                    startPageData={startPageData}
                    theme={props.theme}
                  />
                </AnimatedRevealBlock>
              </Col>
            ))}
          </Row>
        )}
        <Row>
          <Col className={styles.buttonRow}>
            {props.data.useCustomNewsWallPage.value === "enabled" ? (
              <>
                {props.data.customNewsWallPageButton.value?.map((b, idx) => (
                  <ButtonWrapper {...b} key={idx} />
                ))}
              </>
            ) : (
              <Button
                href={startPageData.newsWallPage.value?.url || ""}
                type="secondary"
              >
                <LocalizedLabel
                  section="NewsWallTeaser"
                  label="NewsroomButton"
                />
              </Button>
            )}
          </Col>
        </Row>
        {showEvents && (
          <Row>
            <Col className={styles.mobileEvents}>
              <span className={styles.eventsHeader}>
                <LocalizedLabel section="NewsWallPage" label="UpcomingEvents" />
              </span>
              {newsWallData && newsWallData.UpcomingEvents.length > 0 && (
                <>
                  {newsWallData.UpcomingEvents.map((item, idx) => (
                    <Event
                      {...item}
                      key={idx}
                      className={styles.customEventBorder}
                    />
                  ))}
                </>
              )}
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

type SupportedNewsWallTeaserCardTypes =
  | "Article"
  | "CrossReference"
  | "Event"
  | "Video";

const useFirstChildCardStyles = createUseStyles({
  newsItem: {
    backgroundColor: (isThemeHomepage: boolean) =>
      isThemeHomepage ? colors.gray10 : colors.white,
    paddingBottom: rem(spacings.m),
  },
  sideCardNewsItem: {
    paddingBottom: 0,
  },
  newsItemSection: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: rem(spacings.m),
    paddingLeft: rem(spacings.m),
    paddingRight: rem(spacings.m),
  },
  sideCardNewsItemSection: {
    ...onBreakpoint("sm", {
      gridRow: 1,
    }),
  },
  h4: customTypography(
    typography.h4,
    {
      marginBottom: rem(spacings.s),
    },
    {
      marginBottom: rem(spacings.sam),
    },
    {
      marginBottom: rem(spacings.m),
    }
  ),
  category: {
    ...typography.caption,
    display: "flex",
    flexDirection: "column",
    marginBottom: rem(spacings.xs),
  },
  buttons: {
    marginTop: "auto",
  },
  sideCardButtons: {
    paddingBottom: rem(spacings.l),
  },
  nonBreakingSpan: {
    whiteSpace: "nowrap",
    //fix for Firefox
    "@supports (-moz-appearance:none)": {
      whiteSpace: "normal",
    },
    //fix for Safari
    "@media not all and (min-resolution:.001dpcm)": {
      whiteSpace: "normal",
    },
  },
  descriptionBox: {
    display: "none",
    ...onBreakpoint("md", {
      display: "block",
      position: "relative",
      flex: 1,
    }),
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: rem(spacings.m.times(2)),
      background: (isThemeHomepage: boolean) =>
        isThemeHomepage
          ? `linear-gradient(to bottom, ${colors.gray10}00, ${colors.gray10}FF 100%)`
          : `linear-gradient(to bottom, ${colors.white}00, ${colors.white}FF 100%)`,
    },
  },
  textDefault: {
    position: "absolute",
    height: `100%`,
    overflow: "hidden",
    ...typography.textDefault,
    ...fonts.sansLight,
  },
  imageContainer: {
    overflow: "hidden",
  },
  image: {
    transform: "none",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.03)",
      transition: "transform 0.3s ease",
    },
  },
});
function FirstItemCard(
  props: NewsWallApiHit & { Type: SupportedNewsWallTeaserCardTypes } & {
    sideCard: boolean;
    theme: ComponentTheme;
    startPageData: StartPageData;
  }
) {
  const styles = useFirstChildCardStyles(props.theme === "homepage");
  const [forceVideoPlay, setForceVideoPlay] = useState(false);

  const isAutoplay = props.Type === "Video" && props.StartAction === "autoplay";
  const isLoop = props.Type === "Video" && props.EndAction === "loop_video";
  const resetOnEnd =
    props.Type === "Video" && props.EndAction === "reset_to_thumbnail";
  const defaultPosterUrl =
    props.Type === "Video" &&
    (props.ImageUrlLarge || props.ImageUrlMedium || props.ImageUrlSmall);

  return (
    <Row
      className={cn(styles.newsItem, props.sideCard && styles.sideCardNewsItem)}
      dataTestid="NewsWallTeaserCard"
    >
      <Col
        sm={props.sideCard ? 8 : 12}
        smStart={props.sideCard ? 4 : undefined}
        className={styles.imageContainer}
      >
        {(props.Type === "Article" || props.Type === "CrossReference") &&
          (props.ImageUrlLarge ||
            props.ImageUrlMedium ||
            props.ImageUrlSmall) && (
            <a
              href={
                (props.Type === "Article" ? props.Url : props.Link) || undefined
              }
            >
              <AdaptiveImageComponent
                large={props.ImageUrlLarge}
                medium={props.ImageUrlMedium}
                small={props.ImageUrlSmall}
                alt={props.ImageAlt}
                className={styles.image}
              />
            </a>
          )}
        {props.Type === "Video" && (
          <CloudinaryVideoPlayer
            publicId={props.CloudinaryVideoPublicID || ""}
            logoImageUrl={props.startPageData.logo.value?.url || ""}
            showControls={false}
            autoplay={isAutoplay}
            loop={isLoop}
            resetVideo={resetOnEnd}
            thumbnailUrl={defaultPosterUrl || undefined}
            withOverlay={true}
            forcePlay={forceVideoPlay}
            setForcePlay={setForceVideoPlay}
          />
        )}
      </Col>

      <Col
        sm={props.sideCard ? 4 : 12}
        className={cn(
          styles.newsItemSection,
          props.sideCard && styles.sideCardNewsItemSection
        )}
      >
        {(props.Type === "Article" || props.Type === "CrossReference") && (
          <span className={styles.category}>{props.Categories?.[0]?.Name}</span>
        )}

        {props.Type === "Event" && (
          <span className={styles.category}>
            {props.DateTo ? (
              <>
                <span>
                  <span className={styles.nonBreakingSpan}>
                    {props.DateFrom}
                  </span>
                  <span className={styles.nonBreakingSpan}>
                    {" "}
                    - {props.DateTo}
                  </span>
                </span>
                <span>{props.Location}</span>
              </>
            ) : (
              <span>
                {props.DateFrom} | {props.Location}
              </span>
            )}
          </span>
        )}

        <h4 className={styles.h4}>{props.Title}</h4>
        {props.sideCard && (
          <div className={styles.descriptionBox}>
            {props.Type === "CrossReference" ? (
              <RichTextWithTypographyRenderer value={props.Description || ""} />
            ) : (
              <p className={styles.textDefault}>{props.Description}</p>
            )}
          </div>
        )}
        <div
          className={cn(
            styles.buttons,
            props.sideCard && styles.sideCardButtons
          )}
        >
          {props.Type === "Article" && (
            <Button type="link" href={props.Url}>
              <LocalizedLabel section="Global" label="ReadMore" />
            </Button>
          )}
          {(props.Type === "CrossReference" || props.Type === "Event") &&
            props.Link && (
              <Button
                small={true}
                type={
                  isFile(props.Link) && !isExternal(props.Link)
                    ? "primary"
                    : "secondary"
                }
                href={props.Link}
                title={props.LinkText || undefined}
                target="_blank"
              >
                {props.LinkText}
              </Button>
            )}
          {props.Type === "Video" && (
            <Button
              type="secondary"
              onClick={() => setForceVideoPlay(true)}
              icon="play"
            >
              <LocalizedLabel section="Global" label="PlayVideo" />
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
}

const useCardStyles = createUseStyles({
  newsItem: {
    height: "100%",
    display: "flex",
    backgroundColor: (isThemeHomepage: boolean) =>
      isThemeHomepage ? colors.gray10 : colors.white,
    ...onBreakpoint("sm", {
      flexDirection: "column",
      paddingBottom: rem(spacings.m),
    }),
  },
  newsItemSection: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: rem(spacings.s),
    ...onBreakpoint("sm", {
      padding: `${spacings.m} ${spacings.m} 0 ${spacings.m}`,
    }),
  },
  h4: customTypography(
    typography.h4,
    { marginBottom: 0 },
    { marginBottom: rem(spacings.sam) },
    { marginBottom: rem(spacings.m) }
  ),
  category: {
    ...typography.caption,
    display: "flex",
    flexDirection: "column",
    marginBottom: rem(spacings.xs),
  },
  imageContainer: {
    overflow: "hidden",
    ...onBefore("sm", {
      flex: 1,
    }),
  },
  image: {
    transform: "none",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.03)",
      transition: "transform 0.3s ease",
    },
  },
  teaserImageLink: {
    position: "relative",
    display: "block",
    paddingTop: "62%",
    height: "100%",
  },
  teaserImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    objectFit: "cover",
  },
  buttons: {
    display: "none",
    marginTop: "auto",
    ...onBreakpoint("sm", {
      display: "block",
    }),
  },
  nonBreakingSpan: {
    whiteSpace: "nowrap",
    //fix for Firefox
    "@supports (-moz-appearance:none)": {
      whiteSpace: "normal",
    },
    //fix for Safari
    "@media not all and (min-resolution:.001dpcm)": {
      whiteSpace: "normal",
    },
  },
});

export function NewsWallTeaserCard(
  props: NewsWallApiHit & { Type: SupportedNewsWallTeaserCardTypes } & {
    theme: ComponentTheme;
    startPageData: StartPageData;
  }
) {
  const styles = useCardStyles(props.theme === "homepage");
  const [forceVideoPlay, setForceVideoPlay] = useState(false);

  const isAutoplay = props.Type === "Video" && props.StartAction === "autoplay";
  const isLoop = props.Type === "Video" && props.EndAction === "loop_video";
  const resetOnEnd =
    props.Type === "Video" && props.EndAction === "reset_to_thumbnail";
  const defaultPosterUrl =
    props.Type === "Video" &&
    (props.ImageUrlLarge || props.ImageUrlMedium || props.ImageUrlSmall);

  return (
    <div className={styles.newsItem} data-testid="NewsWallTeaserCard">
      <div className={styles.imageContainer}>
        {(props.Type === "Article" || props.Type === "CrossReference") &&
          (props.ImageUrlLarge ||
            props.ImageUrlMedium ||
            props.ImageUrlSmall) && (
            <a
              href={
                (props.Type === "Article" ? props.Url : props.Link) || undefined
              }
            >
              <AdaptiveImageComponent
                large={props.ImageUrlLarge}
                medium={props.ImageUrlMedium}
                small={props.ImageUrlSmall}
                alt={props.ImageAlt}
                className={styles.image}
              />
            </a>
          )}
        {props.Type === "Video" && (
          <CloudinaryVideoPlayer
            publicId={props.CloudinaryVideoPublicID || ""}
            logoImageUrl={props.startPageData.logo.value?.url || ""}
            showControls={false}
            autoplay={isAutoplay}
            loop={isLoop}
            resetVideo={resetOnEnd}
            thumbnailUrl={defaultPosterUrl || undefined}
            withOverlay={true}
            forcePlay={forceVideoPlay}
            setForcePlay={setForceVideoPlay}
          />
        )}
      </div>

      <div className={styles.newsItemSection}>
        {(props.Type === "Article" || props.Type === "CrossReference") && (
          <span className={styles.category}>{props.Categories?.[0]?.Name}</span>
        )}
        {props.Type === "Event" && (
          <span className={styles.category}>
            {props.DateTo ? (
              <>
                <span>
                  <span className={styles.nonBreakingSpan}>
                    {props.DateFrom}
                  </span>
                  <span className={styles.nonBreakingSpan}>
                    {" "}
                    - {props.DateTo}
                  </span>
                </span>
                <span>{props.Location}</span>
              </>
            ) : (
              <span>
                {props.DateFrom} | {props.Location}
              </span>
            )}
          </span>
        )}

        <h4 className={styles.h4}>{props.Title}</h4>
        <div className={styles.buttons}>
          {props.Type === "Article" && (
            <Button type="link" href={props.Url}>
              <LocalizedLabel section="Global" label="ReadMore" />
            </Button>
          )}
          {(props.Type === "CrossReference" || props.Type === "Event") &&
            props.Link && (
              <Button
                small={true}
                type={
                  isFile(props.Link) && !isExternal(props.Link)
                    ? "primary"
                    : "secondary"
                }
                href={props.Link}
                title={props.LinkText || undefined}
                target="_blank"
              >
                {props.LinkText}
              </Button>
            )}
          {props.Type === "Video" && (
            <Button
              type="secondary"
              onClick={() => setForceVideoPlay(true)}
              icon="play"
            >
              <LocalizedLabel section="Global" label="PlayVideo" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
