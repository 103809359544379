import { useWindowWidth } from "./useWindowWidth";
import { BREAKPOINTS } from "../basics/layout";

export type DeviceType = "mobile" | "tablet" | "desktop" | "largeDesktop";

export function useDeviceType() {
  const width = useWindowWidth();

  if (width < BREAKPOINTS["sm"]) {
    return "mobile";
  } else if (width >= BREAKPOINTS["sm"] && width < BREAKPOINTS["md"]) {
    return "tablet";
  } else if (width >= BREAKPOINTS["md"] && width < BREAKPOINTS["lg"]) {
    return "desktop";
  } else {
    return "largeDesktop";
  }
}

export function isDeviceResOrHigher(
  realDevice: DeviceType,
  wantedDevice: DeviceType
): boolean {
  switch (wantedDevice) {
    case "mobile":
      return true;
    case "tablet":
      return ["tablet", "desktop", "largeDesktop"].includes(realDevice);
    case "desktop":
      return ["desktop", "largeDesktop"].includes(realDevice);
    case "largeDesktop":
      return ["largeDesktop"].includes(realDevice);
    default:
      return false;
  }
}

export function isDeviceResOrLower(
  realDevice: DeviceType,
  wantedDevice: DeviceType
): boolean {
  switch (wantedDevice) {
    case "mobile":
      return ["mobile"].includes(realDevice);
    case "tablet":
      return ["mobile", "tablet"].includes(realDevice);
    case "desktop":
      return ["mobile", "tablet", "desktop"].includes(realDevice);
    case "largeDesktop":
      return true;
    default:
      return false;
  }
}
