import React from "react";
import { createUseStyles } from "react-jss";
import {
  BREAKPOINTS,
  Col,
  Container,
  onBefore,
  onBreakpoint,
  rem,
  Row,
} from "../../basics/layout";
import { spacings } from "../../basics/spacings";
import { typography, customTypography } from "../../basics/typography";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { colors } from "../../basics/colors";
import { FeaturedPagesBlockProps } from "../../models/content/FeaturedPagesBlockData";
import {
  AnimatedRenderProperty,
  RenderProperty,
  showPropertyOnEdit,
} from "../../views/RenderProperty";
import StartPageData from "../../models/content/StartPageData";
import IContent from "../../models/IContent";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { isDeviceResOrHigher, useDeviceType } from "../../hooks/useDeviceType";
import { AnimatedRevealInline } from "../AnimatedReveal/AnimatedReveal";
import { pseudoSelectorShadow } from "../../basics/mixins";
import cn from "classnames";
import { createSlider } from "../Slider/Slider";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";

const useStyles = createUseStyles({
  featuredPages: {
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      display: "block",
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  textContainer: {
    marginBottom: rem(spacings.s),
  },
  headline: {
    ...typography.h2,
    textAlign: "center",
  },
  description: customTypography(
    { ...typography.textDefault, textAlign: "center" },
    { marginBottom: rem(spacings.s) },
    { marginBottom: rem(spacings.sam) },
    { marginBottom: rem(spacings.m) }
  ),
  cardCarousel: {
    width: ({ itemsAmount }) =>
      itemsAmount < 5 ? undefined : "calc(100% - 80px)",
    ...onBefore("md", {
      width: () => "auto",
    }),
    margin: "0 auto",
    "& .slick-list": {
      padding: `${spacings.xs} 0`,
    },
    "& .slick-track": {
      display: "flex",
      marginBottom: rem(spacings.l),
    },
    "& .slick-slide": {
      height: "auto",
      "& > div": {
        height: "100%",
        margin: `0 ${spacings.xs.add(spacings.xxs)}`,
      },
    },
    "& .slick-arrow": {
      width: 48,
      height: 48,
      "&:before": {
        color: colors.lightGreen40,
        fontSize: "3rem",
      },
    },
    "& .slick-prev": {
      left: -50,
      "&:before": {
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f053"',
      },
    },
    "& .slick-next": {
      right: -50,
      "&:before": {
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f054"',
      },
    },
    "& .slick-dots": {
      bottom: -10,
      "& button:before, & .slick-active button:before": {
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f111"',
        fontWeight: 900,
        fontSize: "1rem",
        color: colors.lightGreen40,
      },
    },
  },
  grayBg: {
    backgroundColor: ({ isGrayBg }) => isGrayBg && colors.gray10,
    padding: `${spacings.m} ${spacings.sam} ${spacings.m}`,
    ...onBreakpoint("sm", {
      padding: `${spacings.l} ${spacings.m} ${spacings.l}`,
    }),
    ...onBreakpoint("md", {
      padding: `${spacings.xxl} ${spacings.l} ${spacings.xxl}`,
    }),
  },
  bgRow: {
    maxWidth: BREAKPOINTS["xl"],
    margin: "0 auto",
  },
});

export type LinkCard = IContent & {
  metaIcon: StartPageData["metaIcon"];
};

export function FeaturedPagesBlock(props: FeaturedPagesBlockProps) {
  const { headline, description, pageList } = props.data;

  const deviceType = useDeviceType();

  function slidesToShow() {
    switch (deviceType) {
      case "mobile":
        return 2;
      case "tablet":
      case "desktop":
        return 3;
      default:
        return 4;
    }
  }
  const carouselSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow(),
    slidesToScroll: 1,
    arrows: isDeviceResOrHigher(deviceType, "desktop"),
  };

  const isGrayBg = props.data.backgroundColor?.value === "gray";

  const styles = useStyles({
    isGrayBg: isGrayBg,
    itemsAmount: pageList.value?.length,
  });
  const Slider = createSlider();

  return (
    <Container
      isFluid={isGrayBg}
      className={cn(styles.featuredPages, isGrayBg ? styles.grayBg : undefined)}
    >
      {(headline.value || description.value) && (
        <Row className={isGrayBg ? styles.bgRow : undefined}>
          <Col
            sm={10}
            smStart={1}
            md={8}
            mdStart={2}
            className={styles.textContainer}
          >
            {showPropertyOnEdit(headline) && (
              <SemanticHeader
                className={styles.headline}
                headerSize={props.data.headerSize?.value}
              >
                <RenderProperty value={headline} />
              </SemanticHeader>
            )}
            {showPropertyOnEdit(description) && (
              <div className={styles.description}>
                <RenderProperty value={description} />
              </div>
            )}
          </Col>
        </Row>
      )}
      <Row className={isGrayBg ? styles.bgRow : undefined}>
        <Col style={{ width: "100%" /* due to FF */ }}>
          {Slider && (
            <Slider {...carouselSettings} className={styles.cardCarousel}>
              {(pageList.expandedValue as Array<LinkCard>).map((page, idx) => (
                <Card
                  {...page}
                  key={idx}
                  revealDelay={
                    idx >= carouselSettings.slidesToShow ? -1 : 100 * idx
                  }
                />
              ))}
            </Slider>
          )}
        </Col>
      </Row>
    </Container>
  );
}

const useCardStyles = createUseStyles({
  cardWrapper: {
    height: "100%",
  },
  card: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    flexWrap: "wrap",
    textAlign: "center",
    backgroundColor: colors.white,
    padding: `${rem(spacings.m.add(spacings.xxs))} ${spacings.s} ${spacings.m}`,
    transition: "background-color 0.3s ease",
    position: "relative",
    "&:after": {
      ...pseudoSelectorShadow("0 2px 12px 0 rgba(0, 0, 0, 0.3)"),
      opacity: 0.5,
      transition: "opacity 0.3s ease",
    },
    "&:hover": {
      "&:after": {
        opacity: 1,
        transition: "opacity 0.3s ease",
      },
      "& $cardIcon": {
        transform: "scale(1.1)",
        transition: "transform 0.3s ease",
      },
    },
    ...onBreakpoint("md", {
      padding: `${rem(spacings.xl)} ${spacings.m} ${spacings.xxl}`,
    }),
  },
  cardIcon: {
    color: colors.lightGreen40,
    flexBasis: "100%",
    marginBottom: rem(spacings.xl),
    transform: "scale(1)",
    transition: "transform 0.3s ease",
    ...FAIconStyles({
      width: 56,
      height: 56,
      ...onBreakpoint("md", {
        width: 88,
        height: 88,
      }),
    }),
  },
  cardTitle: customTypography(
    { ...typography.h4, hyphens: "auto", wordBreak: "brake-all" },
    { marginBottom: 0 },
    { marginBottom: 0 },
    { marginBottom: 0 }
  ),
});

function Card(
  props: LinkCard & {
    revealDelay: number;
  }
) {
  const styles = useCardStyles();
  return (
    <AnimatedRevealInline
      revealDelay={props.revealDelay}
      className={styles.cardWrapper}
    >
      <a href={props.url || ""} className={styles.card}>
        <FAIcon
          icon={(props.metaIcon.value as IconProp) || ("link" as IconProp)}
          className={styles.cardIcon}
        />
        <h4 className={styles.cardTitle}>
          <AnimatedRenderProperty
            value={props.name}
            revealDelay={props.revealDelay}
          />
        </h4>
      </a>
    </AnimatedRevealInline>
  );
}
