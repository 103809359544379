import React from "react";
import { ParagraphTextElementBlockProps } from "../../../../models/content/ParagraphTextElementBlockData";
import { useCommonFormStyles } from "./common";
import { RenderProperty } from "../../../../views/RenderProperty";

export function FormRichText(props: ParagraphTextElementBlockProps) {
  const commonFormStyles = useCommonFormStyles();
  return (
    <div className={commonFormStyles.singleCol}>
      <RenderProperty value={props.data.paragraphText} />
    </div>
  );
}
