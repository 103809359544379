import React from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, rem, Row } from "../../basics/layout";
import { spacings } from "../../basics/spacings";
import { RenderProperty } from "../../views/RenderProperty";
import { typography } from "../../basics/typography";
import { colors } from "../../basics/colors";
import { TextAndImageBlockProps } from "../../models/content/TextAndImageBlockData";
import cn from "classnames";
import { AnimatedRevealBlock } from "../AnimatedReveal/AnimatedReveal";
import { ButtonWrapper } from "../Button/Button";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";
import { fonts } from "../../basics/fonts";

const useStyles = createUseStyles({
  quote: {
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  description: {
    ...typography.textDefault,
    ...typography.grayBackgroundFix,
    ...fonts.sansLight,
    "& p": {
      ...fonts.sansLight,
    },
  },
  textContainer: {
    minHeight: 230,
    padding: rem(spacings.m),
    paddingRight: 0,
    backgroundColor: colors.gray10,
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 500px)": {
      paddingRight: rem(spacings.m),
    },
    ...onBreakpoint("sm", {
      marginTop: rem(spacings.l),
      padding: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginTop: rem(spacings.xxl),
      padding: rem(spacings.xxl),
      paddingRight: rem(spacings.l),
    }),
    "&:after": {
      content: '""',
      position: "absolute",
      top: 0,
      right: "-25%",
      width: "100%",
      height: "100%",
      backgroundColor: colors.gray10,
      zIndex: -1,
      ...onBreakpoint("sm", {
        right: "-15%",
      }),
    },
  },
  narrow: {
    ...onBreakpoint("sm", {
      "&:after": {
        right: "-12%",
      },
    }),
    ...onBreakpoint("md", {
      "&:after": {
        right: "-35%",
      },
    }),
  },
  imageContainer: {
    position: "absolute",
    bottom: 32,
    width: "100%",
    ...onBreakpoint("sm", {
      width: "auto",
      position: "static",
      marginBottom: rem(spacings.xxl),
    }),
  },
  buttonsWrapper: {
    marginTop: "auto",
  },
  buttons: {
    display: "inline-block",
    "&:not(:last-child)": {
      marginRight: rem(spacings.m),
    },
  },
  h4: {
    ...typography.h4,
    wordBreak: "break-word",
  },
});

export function TextAndImageBlock(props: TextAndImageBlockProps) {
  const { image, layout, richText } = props.data;
  const styles = useStyles();
  const isNarrow = layout.value === "narrow";
  return (
    <AnimatedRevealBlock>
      <Container className={styles.quote} dataTestid="TextAndImageBlock">
        <Row style={{ position: "relative" }}>
          <Col
            xs={3}
            sm={isNarrow ? 9 : 7}
            md={isNarrow ? 6 : 7}
            mdStart={isNarrow ? 1 : null}
            className={cn(styles.textContainer, isNarrow && styles.narrow)}
          >
            <SemanticHeader
              className={styles.h4}
              headerSize={props.data.headerSize?.value}
            >
              <RenderProperty value={props.data.headline} />
            </SemanticHeader>

            <RenderProperty value={richText} className={styles.description} />
            <div className={styles.buttonsWrapper}>
              {props.data.buttons?.value?.map((button, idx) => (
                <ButtonWrapper
                  key={idx}
                  {...button}
                  className={styles.buttons}
                />
              ))}
            </div>
          </Col>

          <Col
            xs={1}
            xsStart={3}
            sm={isNarrow ? 3 : 5}
            smStart={isNarrow ? 9 : 7}
            md={isNarrow ? 4 : 5}
            mdStart={7}
            className={styles.imageContainer}
          >
            <RenderProperty value={image} />
          </Col>
        </Row>
      </Container>
    </AnimatedRevealBlock>
  );
}
