import React, { ReactNode } from "react";
import { LinkProperty } from "../../models/Property";
import cn from "classnames";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  link: {
    "a&": {
      textDecoration: "none",
      "&:hover": {
        color: "inherit",
      },
      "&:focus": {
        color: "inherit",
      },
    },
  } as any,
});

export const Link = (
  props: LinkProperty & { children?: ReactNode; className?: string }
) => {
  const styles = useStyles();

  return (
    <a
      href={props.href}
      title={props.title}
      target={props.target}
      className={cn(styles.link, props.className)}
      rel={props.target === "_blank" ? "noreferer noopener" : undefined}
    >
      {props.text}
      {props.children}
    </a>
  );
};
