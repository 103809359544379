import React from "react";
import { FactsAndFiguresProps } from "./FactsAndFigures";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { colors } from "../../../basics/colors";
import { customTypography, typography } from "../../../basics/typography";
import { spacings } from "../../../basics/spacings";
import { onBefore, onBreakpoint, rem } from "../../../basics/layout";
import { FAIcon, FAIconStyles } from "../../FAIcon/FAIcon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { AnimatedRevealBlock } from "../../AnimatedReveal/AnimatedReveal";
import { CountUpAnimation } from "../../CountUpAnimation/CountUpAnimation";

const useLayoutOneStyles = createUseStyles({
  factsAndFiguresBlock: {
    ...onBreakpoint("md", {
      gridArea: "1 / 6 / span 2 / span 2",
      height: "min(342px, calc(3 / 12 / 100vw - 34px))",
    }),
    ...onBefore("md", {
      flexBasis: "calc(50% - 12px)",
      marginBottom: rem(spacings.sam),
    }),
    ...onBefore("sm", {
      flexBasis: "100%",
    }),
    "&:nth-child(1)": {
      ...onBreakpoint("sm", {
        marginRight: rem(spacings.sam),
      }),
      ...onBreakpoint("md", {
        gridArea: "1 / 1 / span 2 / span 3",
        marginRight: 0,
      }),
    },
    "&:nth-child(2)": {
      ...onBreakpoint("md", {
        gridArea: "1 / 4 / span 2 / span 3",
      }),
    },
    "&:nth-child(3)": {
      ...onBreakpoint("sm", {
        marginRight: rem(spacings.sam),
      }),
      ...onBreakpoint("md", {
        gridArea: "1 / 7 / span 2 / span 3",
        marginRight: 0,
      }),
    },
    "&:nth-child(4)": {
      ...onBreakpoint("md", {
        gridArea: "1 / 10 / span 2 / span 3",
      }),
    },
  },
  withImage: {
    position: "relative",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& $content": {
      position: "absolute",
      top: 0,
    },
  },
  backgroundCover: {
    content: "''",
    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, .3)",
  },
  lightGreen: {
    backgroundColor: colors.webGreen20,
  },
  darkGreen: {
    backgroundColor: colors.darkGreen70,
  },
  lightGreenAlt: {
    backgroundColor: colors.webGreen10,
    "& $content > *": {
      color: colors.gray100,
    },
  },
  darkGreenAlt: {
    backgroundColor: colors.webGreen50,
    "& $content > *": {
      color: colors.gray100,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: rem(spacings.sam),
    height: "100%",
    "& > *:nth-last-child(2)": {
      marginBottom: rem(spacings.sam),
    },
    "& > *:last-child": {
      marginTop: "auto",
    },
  },
  headline: customTypography(
    { ...typography.h4, color: colors.white },
    {},
    { marginBottom: 0 },
    { fontSize: "min(28px, 2vw)" }
  ),
  number: customTypography(
    { ...typography.h1, color: colors.white },
    {},
    { marginBottom: 0 },
    { marginBottom: 0, fontSize: "min(76px, 5.2vw)", lineHeight: "1.25" }
  ),
  text: customTypography(
    { ...typography.textDefault, color: colors.white, marginBottom: 0 },
    {},
    {},
    { fontSize: "min(20px, 1.4vw)" }
  ),
  icon: FAIconStyles({
    color: colors.white,
    width: 40,
    height: 40,
    ...onBreakpoint("sm", {
      width: 56,
      height: 56,
    }),
    ...onBreakpoint("md", {
      width: "min(80px, 5.7vw)",
      height: "min(80px, 5.7vw)",
    }),
  }),
  vertical: {},
});

const useLayoutTwoStyles = createUseStyles({
  factsAndFiguresBlock: {
    ...onBefore("md", {
      flexBasis: "calc(50% - 12px)",
      marginBottom: rem(spacings.sam),
    }),
    ...onBefore("sm", {
      flexBasis: "100%",
    }),
    "&:nth-child(1)": {
      "& $number": {
        fontSize: "6rem",
        lineHeight: 1.25,
      },
      ...onBreakpoint("sm", {
        marginRight: rem(spacings.sam),
      }),
      ...onBreakpoint("md", {
        gridArea: "1 / 1 / span 2 / span 5",
        height: "min(342px, calc(3 / 12 * 100vw - 34px))",
        marginRight: 0,
        "& $number": {
          fontSize: "min(144px, 10vw)",
        },
      }),
    },
    "&:nth-child(2)": {
      ...onBreakpoint("md", {
        gridArea: "1 / 6 / span 2 / span 3",
        height: "min(342px, calc(3 / 12 * 100vw - 34px))",
      }),
    },
    "&:nth-child(3)": {
      "& $content": {
        alignItems: "center",
        flexDirection: "row",
        "&$vertical": {
          flexDirection: "column",
          alignItems: "flex-start",
          "& > *:first-child": {
            marginBottom: rem(spacings.xs),
          },
          "& > *:last-child": {
            marginTop: "auto",
          },
        },
        "& > *:first-child": {
          marginRight: rem(spacings.sam),
        },
        "& > *:last-child": {
          marginTop: "unset",
        },
        "& $headline, & $number, & $icon": {
          marginBottom: 0,
        },
        "& $number": {
          flexShrink: 0,
        },
      },
      ...onBreakpoint("sm", {
        marginRight: rem(spacings.sam),
      }),
      ...onBreakpoint("md", {
        gridArea: "1 / 9 / span 1 / span 4",
        height: "min(159px, calc(1.5 / 12 * 100vw - 29px))",
        marginRight: 0,
      }),
    },
    "&:nth-child(4)": {
      "& $content": {
        flexDirection: "row",
        alignItems: "center",
        "&$vertical": {
          flexDirection: "column",
          alignItems: "flex-start",
          "& > *:first-child": {
            marginBottom: rem(spacings.xs),
          },
          "& > *:last-child": {
            marginTop: "auto",
          },
        },
        "& > *:first-child": {
          marginRight: rem(spacings.sam),
        },
        "& > *:last-child": {
          marginTop: "unset",
        },
        "& $headline, & $number, & $icon": {
          marginBottom: 0,
        },
        "& $number": {
          flexShrink: 0,
        },
      },
      ...onBreakpoint("md", {
        gridArea: "2 / 9 / span 1 / span 4",
        height: "min(159px, calc(1.5 / 12 * 100vw - 29px))",
      }),
    },
  },
  withImage: {
    position: "relative",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& $content": {
      position: "absolute",
      top: 0,
    },
  },
  backgroundCover: {
    content: "''",
    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, .35)",
  },
  lightGreen: {
    backgroundColor: colors.webGreen20,
  },
  darkGreen: {
    backgroundColor: colors.darkGreen70,
  },
  lightGreenAlt: {
    backgroundColor: colors.webGreen10,
    "& $content > *": {
      color: colors.gray100,
    },
  },
  darkGreenAlt: {
    backgroundColor: colors.webGreen50,
    "& $content > *": {
      color: colors.gray100,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: rem(spacings.sam),
    height: "100%",
    "& > *:nth-last-child(2)": {
      marginBottom: rem(spacings.sam),
    },
    "& > *:last-child": {
      marginTop: "auto",
    },
  },
  headline: customTypography(
    { ...typography.h4, color: colors.white },
    {},
    { marginBottom: 0 },
    { fontSize: "min(28px, 2vw)" }
  ),
  number: customTypography(
    { ...typography.h1, color: colors.white },
    {},
    { marginBottom: 0 },
    { marginBottom: 0, fontSize: "min(76px, 5.2vw)", lineHeight: "1.30" }
  ),
  text: customTypography(
    { ...typography.textDefault, color: colors.white, marginBottom: 0 },
    {},
    {},
    { fontSize: "min(20px, 1.4vw)" }
  ),
  icon: FAIconStyles({
    color: colors.white,
    width: 40,
    height: 40,
    ...onBreakpoint("sm", {
      width: 56,
      height: 56,
    }),
    ...onBreakpoint("md", {
      width: "min(80px, 5.7vw)",
      height: "min(80px, 5.7vw)",
    }),
  }),
  vertical: {},
});

const useLayoutThreeStyles = createUseStyles({
  factsAndFiguresBlock: {
    ...onBefore("md", {
      flexBasis: "calc(50% - 12px)",
      marginBottom: rem(spacings.sam),
    }),
    ...onBefore("sm", {
      flexBasis: "100%",
    }),
    "&:nth-child(1)": {
      ...onBreakpoint("sm", {
        marginRight: rem(spacings.sam),
      }),
      ...onBreakpoint("md", {
        gridArea: "1 / 1 / span 2 / span 3",
        height: "min(342px, calc(3 / 12 * 100vw - 34px))",
        marginRight: 0,
      }),
    },
    "&:nth-child(2)": {
      "& $content": {
        alignItems: "center",
        flexDirection: "row",
        "&$vertical": {
          flexDirection: "column",
          alignItems: "flex-start",
          "& > *:first-child": {
            marginBottom: rem(spacings.xs),
          },
          "& > *:last-child": {
            marginTop: "auto",
          },
        },
        "& > *:first-child": {
          marginRight: rem(spacings.sam),
        },
        "& > *:last-child": {
          marginTop: "unset",
        },
        "& $headline, & $number, & $icon": {
          marginBottom: 0,
        },
        "& $number": {
          flexShrink: 0,
        },
      },
      ...onBreakpoint("md", {
        gridArea: "1 / 4 / span 1 / span 4",
        height: "min(159px, calc(1.5 / 12 * 100vw - 29px))",
      }),
    },
    "&:nth-child(3)": {
      "& $content": {
        flexDirection: "row",
        alignItems: "center",
        "&$vertical": {
          flexDirection: "column",
          alignItems: "flex-start",
          "& > *:first-child": {
            marginBottom: rem(spacings.xs),
          },
          "& > *:last-child": {
            marginTop: "auto",
          },
        },
        "& > *:first-child": {
          marginRight: rem(spacings.sam),
        },
        "& > *:last-child": {
          marginTop: "unset",
        },
        "& $headline, & $number, & $icon": {
          marginBottom: 0,
        },
        "& $number": {
          flexShrink: 0,
        },
      },
      ...onBreakpoint("sm", {
        marginRight: rem(spacings.sam),
      }),
      ...onBreakpoint("md", {
        gridArea: "2 / 4 / span 1 / span 4",
        height: "min(159px, calc(1.5 / 12 * 100vw - 29px))",
        marginRight: 0,
      }),
    },
    "&:nth-child(4)": {
      "& $number": {
        fontSize: "6rem",
        lineHeight: 1.25,
      },
      ...onBreakpoint("md", {
        gridArea: "1 / 8 / span 2 / span 5",
        height: "min(342px, calc(3 / 12 * 100vw - 34px))",
        "& $number": {
          fontSize: "min(144px, 10vw)",
        },
      }),
    },
  },
  withImage: {
    position: "relative",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& $content": {
      position: "absolute",
      top: 0,
    },
  },
  backgroundCover: {
    content: "''",
    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, .3)",
  },
  lightGreen: {
    backgroundColor: colors.webGreen20,
  },
  darkGreen: {
    backgroundColor: colors.darkGreen70,
  },
  lightGreenAlt: {
    backgroundColor: colors.webGreen10,
    "& $content > *": {
      color: colors.gray100,
    },
  },
  darkGreenAlt: {
    backgroundColor: colors.webGreen50,
    "& $content > *": {
      color: colors.gray100,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: rem(spacings.sam),
    height: "100%",
    "& > *:nth-last-child(2)": {
      marginBottom: rem(spacings.sam),
    },
    "& > *:last-child": {
      marginTop: "auto",
    },
  },
  headline: customTypography(
    { ...typography.h4, color: colors.white },
    {},
    { marginBottom: 0 },
    { fontSize: "min(28px, 2vw)" }
  ),
  number: customTypography(
    { ...typography.h1, color: colors.white },
    {},
    { marginBottom: 0 },
    { marginBottom: 0, fontSize: "min(76px, 5.2vw)" }
  ),
  text: customTypography(
    { ...typography.textDefault, color: colors.white, marginBottom: 0 },
    {},
    {},
    { fontSize: "min(20px, 1.4vw)", lineHeight: "1.30" }
  ),
  icon: FAIconStyles({
    color: colors.white,
    width: 40,
    height: 40,
    ...onBreakpoint("sm", {
      width: 56,
      height: 56,
    }),
    ...onBreakpoint("md", {
      width: "min(80px, 5.7vw)",
      height: "min(80px, 5.7vw)",
    }),
  }),
  vertical: {},
});

export function FactsAndFiguresLayout(props: FactsAndFiguresProps) {
  const { blocks, layout } = props;

  const layout1 = useLayoutOneStyles();
  const layout2 = useLayoutTwoStyles();
  const layout3 = useLayoutThreeStyles();

  const styles = layout === "1" ? layout1 : layout === "2" ? layout2 : layout3;

  return (
    <>
      {blocks.map((block, idx) => (
        <AnimatedRevealBlock
          className={cn(
            styles.factsAndFiguresBlock,
            block.schema && styles[block.schema],
            block.backgroundImage && styles.withImage
          )}
          key={idx}
          revealDelay={idx * 100}
        >
          {block.backgroundImage && <img src={block.backgroundImage} />}
          {block.backgroundImage && <div className={styles.backgroundCover} />}
          <div
            className={cn(
              styles.content,
              block.elements.some((e) => e.type === "text") &&
                block.elements.some((e) => e.type === "headline")
                ? styles.vertical
                : undefined
            )}
          >
            {block.elements.map((element, idx) => (
              <React.Fragment key={idx}>
                {element.type === "headline" && (
                  <h4 className={styles.headline}>{element.value}</h4>
                )}
                {element.type === "number" && (
                  <span className={styles.number}>
                    {element.prefix}
                    <CountUpAnimation
                      decimalSeparator={element.decimalSeparator}
                      thousandsSeparator={element.thousandsSeparator}
                    >
                      {element.value}
                    </CountUpAnimation>
                    {element.suffix}
                  </span>
                )}
                {element.type === "text" && (
                  <p className={styles.text}>{element.value}</p>
                )}
                {element.type === "icon" && (
                  <FAIcon
                    icon={(element.value as IconProp) || ""}
                    className={styles.icon}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </AnimatedRevealBlock>
      ))}
    </>
  );
}
