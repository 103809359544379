import React, { ReactElement, useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, rem, Row } from "../../basics/layout";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { customTypography, typography } from "../../basics/typography";
import { FAIcon } from "../FAIcon/FAIcon";
import cn from "classnames";
import { LocaleContext } from "../../hooks/LocaleContext";
import { LocaleSelectorBlockProps } from "../../models/content/LocaleSelectorBlockData";
import { RegionElement } from "../../models/Property";
import { animations } from "../../basics/animations";
import { Modal } from "../Modal/Modal";
import { addAcceptableUrlPrefix } from "../LocaleSuggestion/LocaleSuggestion";
import Cookies from "js-cookie";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";

const useStyles = createUseStyles({
  ...animations.simpleFadeIn,
  ...animations.simpleFadeOut,
  modalBox: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.gray10,
    padding: rem(spacings.sam),
    maxHeight: "80vh",
    overflow: "auto",
    ...onBreakpoint("sm", {
      padding: rem(spacings.m),
    }),
  },
  headline: {
    ...typography.h4,
    marginBottom: rem(spacings.m),
  },
  region: {
    position: "relative",
    borderBottom: `3px solid ${colors.gray20}`,
    marginBottom: rem(spacings.sam),
    "&:last-child": {
      borderBottom: 0,
      marginBottom: 0,
    },
  },
  regionName: {
    ...customTypography(
      {
        ...typography.h5,
        flexBasis: "100%",
      },
      {},
      {
        marginBottom: rem(spacings.s),
      }
    ),
  },
  countries: {
    display: "flex",
    flexWrap: "wrap",
    height: 0,
    overflow: "hidden",
    transition: "all .3s ease-in-out",
    opacity: 0,
    ...onBreakpoint("sm", {
      height: "auto",
      opacity: 1,
    }),
  },
  oneCountry: {
    display: "flex",
    alignItems: "center",
    marginBottom: rem(spacings.sam),
  },
  country: {
    display: "flex",
    alignItems: "center",
    flexBasis: "100%",
    marginBottom: rem(spacings.sam),
    ...onBreakpoint("sm", {
      flexBasis: "50%",
    }),
    ...onBreakpoint("md", {
      flexBasis: "33%",
    }),
    "&:hover $countryFlag": {
      transition: "filter 0.3s ease",
      filter: "grayscale(0) contrast(1.3)",
    },
  },
  countryFlag: {
    width: 24,
    transition: "filter 0.3s ease",
    filter: "grayscale(0) contrast(1)",
  },
  countryName: {
    ...customTypography(
      {
        ...typography.textSmall,
        marginLeft: rem(spacings.s),
        marginBottom: 0,
      },
      {},
      {
        marginBottom: 0,
      }
    ),
  },
  arrowIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    ...onBreakpoint("sm", {
      display: "none",
    }),
  },
  active: {
    opacity: 1,
    height: "auto",
  },
  sectionIconButton: {
    padding: 0,
    border: "none",
    font: "inherit",
    color: "inherit",
    textAlign: "inherit",
    background: "none",
    ...onBreakpoint("sm", {
      display: "none",
    }),
  },
  visuallyHidden: {
    ...typography.visuallyHidden,
  },
});

const LOCALE_CHOSEN_COOKIE_KEY = "localeChosen" as const;

export function isLocaleChosen(): boolean {
  return Boolean(Cookies.get(LOCALE_CHOSEN_COOKIE_KEY));
}

export function setLocaleChosen() {
  Cookies.set(LOCALE_CHOSEN_COOKIE_KEY, "true");
}

type LocalizedUrl = {
  LanguageCode: string;
  Url: string;
};

function getLocalizedPage(pageId: number): Promise<Array<LocalizedUrl>> {
  return fetch(`/api/hdiglobal/getlocalisedpage/${pageId}`).then((r) =>
    r.json()
  );
}

export function LocaleOverlay(props: LocaleSelectorBlockProps) {
  const [activeRegion, setActiveRegion] = useState<number | null>(null);
  const [fullLocalizedUrls, setFullLocalizedUrls] = useState<
    Array<LocalizedUrl>
  >([]);
  const { isLocaleOverlayOpened, setIsLocaleOverlayOpened } =
    useContext(LocaleContext);
  const { getMainPageContentLink } = useContext(GlobalContentStoreContext);
  const contentLink = getMainPageContentLink();

  const openAccordion = (region: number | null) => {
    setActiveRegion(region);
  };

  const onRegionSelection = (chosenLanguageCode: string) => {
    setLocaleChosen();
    addAcceptableUrlPrefix(chosenLanguageCode);
  };

  useEffect(() => {
    contentLink &&
      getLocalizedPage(contentLink.id).then(
        (response) => response && setFullLocalizedUrls(response)
      );
  }, []);

  const styles = useStyles();

  return (
    <Modal
      modalOpened={isLocaleOverlayOpened}
      closeModal={() => {
        setIsLocaleOverlayOpened(false);
      }}
      allowMobile
    >
      <Container>
        <Row>
          <Col sm={10} smStart={1} md={8} mdStart={2}>
            <div className={styles.modalBox}>
              <h4 className={styles.headline}>{props.data.headline.value}</h4>
              {props.data.section.value?.map((r, idx) => (
                <Region
                  {...r}
                  key={idx}
                  className={idx === activeRegion ? styles.active : undefined}
                  fullLocalizedUrls={fullLocalizedUrls}
                  openAccordion={
                    idx !== activeRegion
                      ? () => openAccordion(idx)
                      : () => openAccordion(null)
                  }
                  icon={
                    idx === activeRegion ? (
                      <FAIcon
                        icon="chevron-up"
                        size="lg"
                        className={styles.arrowIcon}
                      />
                    ) : (
                      <FAIcon
                        icon="chevron-down"
                        size="lg"
                        className={styles.arrowIcon}
                      />
                    )
                  }
                  onRegionSelection={onRegionSelection}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

function Region(
  props: RegionElement & {
    className?: string;
    openAccordion: () => void;
    icon: ReactElement;
    fullLocalizedUrls: Array<LocalizedUrl>;
    onRegionSelection: (chosenLanguageCode: string) => void;
  }
) {
  const styles = useStyles();

  const intLocale = props.locales.filter((l) => l.languageCode === "en")[0];

  return (
    <>
      {intLocale ? (
        <div className={styles.region}>
          <a
            href={
              intLocale.externalUrl
                ? intLocale.externalUrl
                : props.fullLocalizedUrls?.find(
                    (u) => u.LanguageCode === intLocale.languageCode
                  )?.Url
            }
            className={styles.oneCountry}
            onClick={() => props.onRegionSelection("international")}
          >
            <FAIcon icon="globe" />
            <span className={styles.countryName}>International website</span>
          </a>
        </div>
      ) : (
        <div className={styles.region}>
          <h5 className={styles.regionName}>{props.headline}</h5>

          <button
            onClick={props.openAccordion}
            className={styles.sectionIconButton}
          >
            {props.icon}
          </button>
          <div className={cn(styles.countries, props.className)}>
            {props.locales.map((l, idx) => (
              <a
                href={
                  l.externalUrl
                    ? l.externalUrl
                    : props.fullLocalizedUrls?.find(
                        (u) => u.LanguageCode === l.languageCode
                      )?.Url
                }
                className={styles.country}
                key={idx}
                onClick={() => props.onRegionSelection(l.languageCode)}
                target={l.externalUrl && "_blank"}
              >
                <img src={l.flag?.url} className={styles.countryFlag} />
                <span className={styles.countryName}>{l.label}</span>
              </a>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
