import React from "react";
import { onBreakpoint, rem } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { spacings } from "../../basics/spacings";
import { ButtonWrapper } from "../Button/Button";
import { TextTeaserBlockProps } from "../../models/content/TextTeaserBlockData";
import { RenderProperty } from "../../views/RenderProperty";
import { ComponentTheme } from "../../views/RenderComponent";
import { colors } from "../../basics/colors";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import cn from "classnames";
import { customTypography, typography } from "../../basics/typography";

const useStyles = createUseStyles({
  container: {
    backgroundColor: (color: string) => {
      switch (color) {
        case "yellow":
          return colors.alertsYellow40;
        case "light_blue":
          return colors.lightBlue40;
        case "dark_blue":
          return colors.darkBlue70;
        case "dark_red":
          return colors.darkRed70;
        case "green":
          return colors.darkGreen70;
        default:
          return colors.lightBlue40;
      }
    },
    margin: `${spacings.xs} 0`,
    padding: rem(spacings.sam),
    ...onBreakpoint("sm", {
      margin: 0,
    }),
  },
  headlineWrapper: {
    display: "flex",
    flexDirection: "row",
    ...onBreakpoint("sm", {
      flexDirection: "column",
    }),
  },
  headline: {
    ...customTypography(
      {
        ...typography.textLarge,
        marginRight: rem(spacings.sam),
        color: `${colors.white} !important`,
      },
      {},
      {
        marginRight: 0,
      }
    ),
  },
  icon: {
    ...FAIconStyles({
      width: 56,
      height: 56,
      color: colors.white,
      marginLeft: "auto",
      marginBottom: rem(spacings.sam),
      alignSelf: "center",
      order: 1,
      ...onBreakpoint("sm", {
        width: 80,
        height: 80,
        marginLeft: "unset",
        order: 0,
      }),
    }),
  },
  whiteLink: {
    color: colors.white,
    "& svg": {
      color: colors.white,
    },
  },
});

export function TextTeaserBlock(
  props: TextTeaserBlockProps & {
    theme?: ComponentTheme;
  }
) {
  const styles = useStyles(props.data.color.value);

  return (
    <div className={styles.container}>
      <div className={styles.headlineWrapper}>
        <FAIcon
          icon={props.data.icon.value as IconProp}
          className={styles.icon}
        />
        <RenderProperty
          className={styles.headline}
          value={props.data.headline}
        />
      </div>
      {props.data.buttons.value?.map((b, idx) => (
        <ButtonWrapper
          {...b}
          key={idx}
          className={cn(b.buttonType === "link" && styles.whiteLink)}
        />
      ))}
    </div>
  );
}
