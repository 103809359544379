import React, { useContext } from "react";

export type LocalizationSection = { readonly [key: string]: string };

export type LocalizationDataType = {
  HdiGlobal: {
    Cms: {
      Localization: {
        Translations: { readonly [key: string]: LocalizationSection };
      };
    };
  };
};

export function InitLocalizationContext(
  localizationData: LocalizationDataType | null
) {
  return {
    get: (section: string, label: string) => {
      return (
        (localizationData &&
          localizationData.HdiGlobal.Cms.Localization.Translations[section]?.[
            label
          ]) ||
        undefined
      );
    },
  };
}

type LocalizationContextType = {
  get: (section: string, label: string) => string | undefined;
} | null;

export const LocalizationContext =
  React.createContext<LocalizationContextType>(null);

export function localizedLabelString(props: {
  localizationCtx: LocalizationContextType;
  section: string;
  label: string;
}) {
  if (props.localizationCtx === null) return "Localization data missing";

  return (
    props.localizationCtx.get(props.section, props.label) ||
    `$${props.section}.$${props.label}`
  );
}

export function LocalizedLabel(props: { section: string; label: string }) {
  const localizationCtx = useContext(LocalizationContext);
  const localizedLabel = localizedLabelString({ ...props, localizationCtx });
  return <>{localizedLabel}</>;
}
