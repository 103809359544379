import React, { useEffect, useContext, useState } from "react";
import { AnimatedRenderProperty } from "../../views/RenderProperty";
import { Container, Row, Col, rem, onBreakpoint } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { spacings } from "../../basics/spacings";
import { typography } from "../../basics/typography";
import { VideoBlockCloudinaryProps } from "../../models/content/VideoBlockCloudinaryData";
import StartPageData from "../../models/content/StartPageData";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import { CloudinaryVideoPlayer } from "../CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";

const useStyles = createUseStyles({
  element: {
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      display: "block",
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  textContainer: {
    marginBottom: rem(spacings.xl),
  },
  headline: {
    ...typography.h2,
    textAlign: "center",
  },
  description: {
    ...typography.textDefault,
    textAlign: "center",
  },
});

export function VideoBlock(props: VideoBlockCloudinaryProps) {
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const styles = useStyles();

  const isAutoplay = props.data.startAction?.value === "autoplay";
  const isLoop = props.data.endAction?.value === "loop_video";
  const resetOnEnd = props.data.endAction?.value === "reset_to_thumbnail";
  const defaultPosterUrl =
    props.data.thumbnail.value?.urlLarge ||
    props.data.thumbnail.value?.urlMedium ||
    props.data.thumbnail.value?.urlSmall;

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  if (!startPageData) return null;

  return (
    <Container dataTestid="VideoBlock">
      <Row>
        <Col sm={10} smStart={1} md={8} mdStart={2}>
          <div className={styles.element}>
            {(props.data.headline?.value ||
              props.data.descriptionRichText.value) && (
              <div className={styles.textContainer}>
                <SemanticHeader
                  className={styles.headline}
                  headerSize={props.data.headerSize?.value}
                >
                  <AnimatedRenderProperty value={props.data.headline} />
                </SemanticHeader>
                <AnimatedRenderProperty
                  value={props.data.descriptionRichText}
                  className={styles.description}
                />
              </div>
            )}
            <CloudinaryVideoPlayer
              publicId={props.data.cloudinaryVideoPublicID?.value || ""}
              logoImageUrl={startPageData.logo.value?.url || ""}
              autoplay={isAutoplay}
              loop={isLoop}
              resetVideo={resetOnEnd}
              showControls={Boolean(props.data.showControls.value)}
              thumbnailUrl={defaultPosterUrl || undefined}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
