import { useState } from "react";

export type BlurredType = {
  setFieldBlurred: (guid: string) => void;
  isFieldBlurred: (guid: string) => boolean;
  clearAllBlurred: () => void;
  setAllBlurred: () => void;
};

export function useBlurred(): BlurredType {
  const [blurred, setBlurred] = useState<Set<string>>(new Set());
  const [forced, setForced] = useState<boolean>(false);

  function setFieldBlurred(guid: string) {
    setBlurred((s) => new Set(s).add(guid));
  }

  function isFieldBlurred(guid: string): boolean {
    return forced || blurred.has(guid);
  }

  function clearAllBlurred() {
    setBlurred((_) => new Set());
    setForced((_) => false);
  }

  function setAllBlurred() {
    setBlurred((_) => new Set());
    setForced((_) => true);
  }

  return {
    setFieldBlurred,
    isFieldBlurred,
    clearAllBlurred,
    setAllBlurred,
  };
}
