import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Shared/Footer";
import { ProxyPageProps } from "../../models/content/ProxyPageData";
import { RenderProperty } from "../../views/RenderProperty";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import { StringProperty } from "../../models/Property";
import { KeyVisual } from "../KeyVisual/KeyVisual";
import { ContactExpert } from "../ContactExpert/ContactExpert";
import { createUseStyles } from "react-jss";
import { DownloadBlock, DownloadProps } from "../Block/DownloadBlock";
import { BreadcrumbComponent } from "../BreadcrumbComponent/BreadcrumbComponent";
import { Col, Container, rem, Row } from "../../basics/layout";
import { spacings } from "../../basics/spacings";
import { isExternal } from "../Button/Button";
import { typography } from "../../basics/typography";
import { isClientSide } from "../../basics/isClientSide";

export const useProductPageProxyStyles = createUseStyles({
  page: {
    "&#overwrittenStyles .proxy-content": {
      "& .main.container > .ls-fxr": {
        marginRight: "16.666667%",
      },
      "& .ls-row.col-lg-9, .ls-row.col-md-9, .ls-row.col-sm-9": {
        width: "100%",
        float: "none",
      },
      "& .content-column": {
        paddingRight: 0,
        borderRight: "none",
      },
      "& #aside-content": {
        display: "none",
      },
    },
  },
  keyVisualWrapper: {
    marginTop: rem(spacings.m),
  },
  title: typography.h1,
});

export function ProxyPage(
  props: ProxyPageProps & { additionalProxyContent?: React.ReactNode }
) {
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const [pageDescription, setPageDescription] = useState<StringProperty>({
    propertyDataType: "PropertyString",
    value: null,
  });
  const [stylesNeeded, setStylesNeeded] = useState<boolean>(isClientSide());

  const styles = useProductPageProxyStyles();

  const transformToXHtmlProperty = (prop: StringProperty): StringProperty => {
    return { ...prop, propertyDataType: "PropertyXhtmlString" };
  };

  const fixExternalLinks = (node: HTMLDivElement) => {
    const links = node.querySelectorAll("a:not([target])");

    for (const link of Array.from(links)) {
      if (isExternal(link.getAttribute("href") || "")) {
        link.setAttribute("target", "_blank");
      }
    }
  };

  const contactListRef = useRef<HTMLDivElement>(null);
  const keyVisualRef = useRef<HTMLDivElement>(null);
  const headerButtonPortalRef = useRef<HTMLDivElement>(null);
  const isUserRetention = props.data.contactCta?.value === "enabled";
  const proxyHasKeyVisual = !!props.data.keyVisual;

  const proxyContentWrapperRef = useCallback((node) => {
    if (node) {
      const proxyPageTitleElement = node.querySelector(
        ".component-pagetitle h1"
      );
      const proxyPageTitle = proxyPageTitleElement?.innerText;
      const epiPageDescription = props.data.keyVisual?.description;

      if (epiPageDescription?.value) {
        setPageDescription(epiPageDescription);
      } else {
        setPageDescription({
          propertyDataType: "PropertyString",
          value: proxyPageTitle,
        });
      }

      fixExternalLinks(node);
    }
  }, []);

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
    stylesNeeded &&
      import(
        /*
          webpackChunkName: "proxy-page",
        */
        // @ts-ignore No TS support
        "./proxy-page.scss"
      ).then(() => setStylesNeeded(false));
  }, []);

  if (!startPageData || stylesNeeded) return null;

  const contactExpertData = props.data.contactsUseDefaultContent?.value
    ? startPageData
    : props.data;

  return (
    <>
      <Header
        {...startPageData}
        contactListRef={contactListRef}
        keyVisualRef={keyVisualRef}
        headerButtonPortalRef={headerButtonPortalRef}
      />
      <main
        id="overwrittenStyles"
        className={props.className}
        data-testid="ProxyPage"
      >
        {proxyHasKeyVisual ? (
          <KeyVisual
            {...props.data.keyVisual}
            description={pageDescription}
            isUserRetention={isUserRetention}
            contactListRef={contactListRef}
            keyVisualRef={keyVisualRef}
            headerButtonPortalRef={headerButtonPortalRef}
          />
        ) : (
          <Container className={styles.keyVisualWrapper}>
            <Row>
              <Col>
                <BreadcrumbComponent />
                <h1 className={styles.title}>
                  <RenderProperty value={pageDescription} />
                </h1>
              </Col>
            </Row>
          </Container>
        )}

        <div ref={proxyContentWrapperRef}>
          <RenderProperty
            value={transformToXHtmlProperty(props.data.proxyContent)}
            className="proxy-content"
          />
        </div>
        <RenderProperty value={props.data.additionalContent} />
        {(props.data.downloadsGlobal?.value ||
          props.data.downloadsLocal?.value) && (
          <DownloadBlock
            downloadItems={[
              ...((props.data.downloadsLocal
                ?.expandedValue as DownloadProps[]) || []),
              ...((props.data.downloadsGlobal
                ?.expandedValue as DownloadProps[]) || []),
            ]}
          />
        )}
        {props.additionalProxyContent && (
          <div ref={contactListRef}>{props.additionalProxyContent}</div>
        )}
        {!props.additionalProxyContent && isUserRetention && (
          <div ref={contactListRef}>
            <ContactExpert {...contactExpertData} />
          </div>
        )}
      </main>
      <Footer {...startPageData} />
    </>
  );
}
