import React, { useState } from "react";
import {
  BREAKPOINTS,
  Col,
  Container,
  onBreakpoint,
  rem,
  Row,
} from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { RenderProperty } from "../../views/RenderProperty";
import { customTypography, typography } from "../../basics/typography";
import { spacings } from "../../basics/spacings";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { colors } from "../../basics/colors";
import { ModalWithCarousel } from "../ModalWithCarousel/ModalWithCarousel";
import { ImageGalleryBlockProps } from "../../models/content/ImageGalleryBlockData";
import { AdaptiveImageComponent } from "../AdaptiveImageComponent/AdaptiveImageComponent";
import { RichTextWithTypographyRenderer } from "./RichTextBlock";
import { isDeviceResOrLower, useDeviceType } from "../../hooks/useDeviceType";
import cn from "classnames";
import { createSlider } from "../Slider/Slider";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";

const imageStyles = createUseStyles({
  imageGallery: {
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      display: "block",
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  textContainer: {
    marginBottom: rem(spacings.s),
  },
  headline: {
    ...typography.h2,
    textAlign: "center",
  },
  description: customTypography(
    { ...typography.textDefault, textAlign: "center" },
    { marginBottom: rem(spacings.s) },
    { marginBottom: rem(spacings.sam) },
    { marginBottom: rem(spacings.m) }
  ),
  image: {
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
    background: "none",
    border: 0,
    padding: 0,
    width: "100%",
    ...onBreakpoint("sm", {
      "&:after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        transition: "opacity 0.3s ease",
        backgroundColor: colors.darkGreen100,
      },
      "&:hover, &:focus": {
        "&:after": {
          opacity: 0.2,
          transition: "opacity 0.3s ease",
        },
        "& $zoomWatermark": {
          opacity: 1,
          transform: "translate(-50%, -50%)",
        },
        "& $imageInner": {
          transform: "scale(1.04)",
        },
      },
    }),
    "& img": {
      width: "100%",
      display: "block",
    },
  },
  imageMobile: {
    height: "250px",
    "& img": {
      objectFit: "contain",
    },
  },
  imageInner: {
    width: "100%",
    height: "100%",
    transform: "scale(1)",
    transition: "transform 0.3s ease",
  },
  imageCaption: {
    marginTop: rem(spacings.xxs),
    "& p": {
      ...typography.textXSmall,
      color: colors.gray60,
    },
  },
  zoomWatermark: {
    ...FAIconStyles({
      position: "absolute",
      color: colors.gray10,
      opacity: 0,
      width: 96,
      height: 96,
      transition: "transform 0.3s ease, opacity 0.3s ease",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -25%)",
      zIndex: 1,
    }),
  },
  galleryPreviewMobile: {
    "& .slick-prev, & .slick-next": {
      zIndex: 1,
      background: "rgba(0, 0, 0, 0.3)",
      padding: 8,
      borderRadius: 3,
      width: "auto",
      height: "auto",
      "&:before": {
        fontFamily: "Font Awesome\\ 5 Pro",
      },
    },
    "& .slick-prev": {
      left: 10,
      paddingRight: 10,
      "&:before": {
        content: '"\\f053"',
      },
    },
    "& .slick-next": {
      right: 10,
      paddingLeft: 10,
      "&:before": {
        content: '"\\f054"',
      },
    },
    "& .slick-slide": {
      opacity: 0,
      transition: "opacity 0.3s ease",
    },
    "& .slick-current": {
      opacity: 1,
    },
  },
  grayBg: {
    backgroundColor: (isGrayBg) => isGrayBg && colors.gray10,
    padding: `${spacings.m} ${spacings.sam} ${spacings.m}`,
    ...onBreakpoint("sm", {
      padding: `${spacings.l} ${spacings.m} ${spacings.l}`,
    }),
    ...onBreakpoint("md", {
      padding: `${spacings.xxl} ${spacings.l} ${spacings.xxl}`,
    }),
  },
  bgRow: {
    maxWidth: BREAKPOINTS["xl"],
    margin: "0 auto",
  },
});

export function ImageGalleryBlock(props: ImageGalleryBlockProps) {
  const { images, title, description } = props.data;
  const [modalOpened, setModalOpened] = useState(false);

  const closeModal = () => {
    setModalOpened(false);
  };
  const imageItems = images?.value?.map((img) => ({
    image: img.image,
    description: img.imageCaption,
  }));
  const [currentPreviewImage, setCurrentPreviewImage] = useState(
    images?.value?.[0]
  );

  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");

  const carouselSettings = {
    dots: !isMobile,
    arrows: isMobile,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange(currentSlide: number) {
      if (images.value && images.value[currentSlide]) {
        setCurrentPreviewImage(images.value[currentSlide]);
      }
    },
  };

  const isGrayBg = props.data.backgroundColor?.value === "gray";

  const styles = imageStyles(isGrayBg);
  const Slider = createSlider();

  return (
    <>
      <Container
        isFluid={isGrayBg}
        className={cn(
          styles.imageGallery,
          isGrayBg ? styles.grayBg : undefined
        )}
      >
        {(title?.value || description?.value) && (
          <Row className={isGrayBg ? styles.bgRow : undefined}>
            <Col
              sm={10}
              smStart={1}
              md={8}
              mdStart={2}
              className={styles.textContainer}
            >
              <SemanticHeader
                className={styles.headline}
                headerSize={props.data.headerSize?.value}
              >
                <RenderProperty value={title} />
              </SemanticHeader>
              <div className={styles.description}>
                <RenderProperty value={description} />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={8} smStart={2} md={6} mdStart={3}>
            {isMobile ? (
              <>
                {Slider && (
                  <Slider
                    {...carouselSettings}
                    className={styles.galleryPreviewMobile}
                  >
                    {images.value?.map((item, idx) => (
                      <div
                        className={cn(styles.image, styles.imageMobile)}
                        key={idx}
                      >
                        <AdaptiveImageComponent
                          className={styles.imageInner}
                          large={item.image.urlLarge}
                          medium={item.image.urlMedium}
                          small={item.image.urlSmall}
                          alt={item.image.alternateText}
                        />
                      </div>
                    ))}
                  </Slider>
                )}
              </>
            ) : (
              <button
                className={styles.image}
                onClick={() => setModalOpened(true)}
              >
                {currentPreviewImage && (
                  <AdaptiveImageComponent
                    className={styles.imageInner}
                    large={currentPreviewImage.image.urlLarge}
                    medium={currentPreviewImage.image.urlMedium}
                    small={currentPreviewImage.image.urlSmall}
                    alt={currentPreviewImage.image.alternateText}
                  />
                )}
                <FAIcon icon="images" className={styles.zoomWatermark} />
              </button>
            )}

            <RichTextWithTypographyRenderer
              value={currentPreviewImage?.imageCaption || ""}
              className={styles.imageCaption}
            />
          </Col>
        </Row>
      </Container>
      {!isMobile && (
        <ModalWithCarousel
          images={imageItems}
          modalOpened={modalOpened}
          closeModal={closeModal}
        />
      )}
    </>
  );
}
