import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  rem,
  onBreakpoint,
  getGridColumnWidth,
  MAX_LAYOUT_WIDTH,
  onBefore,
} from "../../../basics/layout";
import { createUseStyles } from "react-jss";
import { spacings } from "../../../basics/spacings";
import { FeaturedContentBlockProps } from "../../../models/content/FeaturedContentBlockData";
import { FeaturedVideoBlockProps } from "../../../models/content/FeaturedVideoBlockData";
import { FeaturedContent } from "./FeaturedContent";
import { FeaturedContentReversed } from "./FeaturedContentReversed";
import { GlobalContentStoreContext } from "../../../hooks/GlobalContentStore";
import StartPageData from "../../../models/content/StartPageData";
import {
  isDeviceResOrLower,
  useDeviceType,
} from "../../../hooks/useDeviceType";

const useStyles = createUseStyles({
  cardColumn: {
    display: "flex",
    justifyContent: "center",
    marginBottom: rem(spacings.l),
    ...onBreakpoint("sm", {
      marginBottom: `calc(${getGridColumnWidth("sm")} + ${spacings.xl})`,
    }),
    ...onBreakpoint("md", {
      marginBottom: `calc(${getGridColumnWidth("md")} + ${spacings.xxl})`,
    }),
  },
  centerItem: {
    position: "relative",
    ...onBefore("sm", {
      display: "flex",
      flexDirection: "column",
    }),
    width: `calc(min(100vw, ${MAX_LAYOUT_WIDTH}px))`,
  },
});

export function FeaturedContentBlock(
  props: (FeaturedContentBlockProps | FeaturedVideoBlockProps) & {
    isVideo?: boolean;
  }
) {
  const styles = useStyles();

  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  if (!startPageData) return null;

  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");

  return (
    <Container isFluid dataTestid="FeaturedContentBlock">
      <Row>
        <Col
          md={12}
          className={styles.cardColumn}
          dataTestid="FeaturedElementCard"
        >
          <div className={styles.centerItem}>
            {props.data.reverseLayout.value === true && !isMobile ? (
              <FeaturedContentReversed
                {...props}
                startPageData={startPageData}
              />
            ) : (
              <FeaturedContent {...props} startPageData={startPageData} />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
