import React, { useRef, useState } from "react";
import StartPageData from "../../../models/content/StartPageData";
import { createUseStyles } from "react-jss";
import { Input } from "../../Input/Input";
import {
  Container,
  rem,
  Row,
  Col,
  onBreakpoint,
  onBefore,
} from "../../../basics/layout";
import { fonts } from "../../../basics/fonts";
import { spacings } from "../../../basics/spacings";
import { colors } from "../../../basics/colors";
import { Button } from "../../Button/Button";
import { LocalizedLabel } from "../../../hooks/LocalizationContext";
import { FAIcon, FAIconStyles } from "../../FAIcon/FAIcon";
import cn from "classnames";
import { typography } from "../../../basics/typography";
import SearchBlockData from "../../../models/content/SearchBlockData";

const useStyles = createUseStyles({
  search: ({ isOpened }) => ({
    backgroundColor: colors.white,
    ...fonts.sansRegular,
    color: colors.lightGreen100,
    position: "absolute",
    boxShadow: "0 1px 12px 0 rgba(0, 0, 0, 0.09)",
    width: "100%",
    overflowY: "auto",
    paddingTop: rem(spacings.m),
    paddingBottom: rem(spacings.l),
    zIndex: -1,
    transform: isOpened ? "scale(1,1)" : "scale(1,0)",
    transformOrigin: "center top",
    transition:
      (isOpened ? "transform 0.3s ease" : "transform 0.4s ease") +
      ", border-color 0.5s ease",
    transitionDelay: isOpened ? "0s" : "0.2s",
  }),
  searchRwd: {
    ...onBreakpoint("md", {
      paddingTop: `${rem(spacings.l)} !important`,
    }),
  },
  searchRwdStartPage: {
    paddingTop: `${rem(spacings.m.times(3))} !important`,
    ...onBreakpoint("md", {
      paddingTop: `${rem(spacings.xl.times(2))} !important`,
    }),
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: rem(spacings.m),
    "& label": {
      margin: "0 30px",
      ...onBefore("sm", {
        marginLeft: 0,
      }),
    },
  },
  searchIcon: {
    ...FAIconStyles({
      width: 25,
      height: 25,
    }),
    ...onBefore("sm", {
      display: "none",
    }),
  },
  searchSuggestions: {
    "& ul": {
      padding: 0,
      "& li": {
        listStyle: "none",
        "& a": {
          ...typography.textSmall,
          color: colors.gray60,
          fontSize: 17,
          marginBottom: 24,
          "&:hover": {
            color: colors.black,
          },
        },
      },
    },
  },
});

export default function Search(props: {
  isOpened: boolean;
  isStartPage?: boolean;
  searchPage: StartPageData["searchPage"];
  homepageSearch: SearchBlockData;
}) {
  const [query, setQuery] = useState<string>();

  function searchQuery() {
    if (props.searchPage.value)
      window.location.href = `${props.searchPage.value.url}?query=${query}`;
  }

  const queryInputRef = useRef<HTMLInputElement>(null);

  function getValue(value: string) {
    setQuery(value);
  }

  const styles = useStyles({ isOpened: props.isOpened });

  return (
    <nav id="search" aria-expanded={props.isOpened}>
      <Container
        isFluid
        className={cn(
          styles.search,
          styles.searchRwd,
          props.isStartPage && styles.searchRwdStartPage
        )}
      >
        <Container>
          <Row>
            <Col sm={8} smStart={2} md={7} mdStart={3}>
              <div className={styles.searchBar}>
                <FAIcon icon="search" className={styles.searchIcon} />
                <Input
                  theme="transparent"
                  label="Search"
                  placeholder={
                    props.homepageSearch?.placeholderText.value || ""
                  }
                  inputRef={queryInputRef}
                  onSubmit={searchQuery}
                  getValue={getValue}
                />
                <Button type="primary" onClick={() => searchQuery()}>
                  <LocalizedLabel section="Search" label="Search" />
                </Button>
              </div>

              <div className={styles.searchSuggestions}>
                <ul>
                  {props.homepageSearch?.searchSuggestions.value?.map(
                    (s, idx) => (
                      <li key={idx}>
                        <a href={s.href} target={s.target}>
                          {s.text}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </nav>
  );
}
