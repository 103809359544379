import React, { useRef } from "react";
import { RenderProperty, showPropertyOnEdit } from "../../views/RenderProperty";
import { RichTextBlockProps } from "../../models/content/RichTextBlockData";
import { createUseStyles } from "react-jss";
import {
  Col,
  Container,
  onBreakpoint,
  rem,
  Row,
  GUTTER_WIDTHS,
  onBefore,
  BREAKPOINTS,
} from "../../basics/layout";
import { customTypography, typography } from "../../basics/typography";
import { spacings } from "../../basics/spacings";
import cn from "classnames";
import { overridedTypography } from "../../basics/overridedTypography";
import { colors } from "../../basics/colors";
import { fonts } from "../../basics/fonts";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";

export const useRichTextStyles = createUseStyles({
  richTextContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      display: "block",
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },

  richTextBlock: {
    "& h1": {
      ...typography.h1,
    },
    "& h2": {
      ...typography.h2,
    },
    "& h3": {
      ...typography.h3,
    },
    "& h4": {
      ...typography.h4,
    },
    "& h5": {
      ...typography.h5,
    },
    "& h6": {
      ...typography.h6,
    },
    "& p": {
      ...typography.textDefault,
      "&.large": {
        ...typography.textLarge,
      },
      "&.caption": {
        ...typography.caption,
      },
    },
    "& ul": {
      ...typography.uList,
    },
    "& ol": {
      ...typography.oList,
    },

    "& > *:last-child": {
      marginBottom: 0,
    },

    "#overwrittenStyles .proxy-content&": {
      "& h1": {
        ...overridedTypography.h1,
      },
      "& h2": {
        ...overridedTypography.h2,
      },
      "& h3": {
        ...overridedTypography.h3,
      },
      "& h4": {
        ...overridedTypography.h4,
      },
      "& h5": {
        ...overridedTypography.h5,
      },
      "& p": {
        ...overridedTypography.textDefault,
        "&.lead": {
          ...overridedTypography.textDefault,
        },
        "&.caption": {
          ...overridedTypography.caption,
        },
      },
      "& ul": {
        ...overridedTypography.uList,
      },
      "& ol": {
        ...overridedTypography.oList,
      },
      "& .img-responsive": {
        maxWidth: "100%",
      },
      "& .container": {
        width: "unset",
        maxWidth: 1504,
        padding: `0 ${spacings.s}`,

        ...onBreakpoint("sm", {
          padding: `0 ${spacings.m}`,
        }),
        "& > .ls-fxr": {
          ...onBreakpoint("md", {
            marginLeft: "16.666667%",
          }),
        },
      },

      "& .content-column": {
        paddingLeft: 0,
        paddingRight: rem(spacings.l),
        borderBottom: 0,
      },
      "& .component-richtext": {
        "& ul li:before, & ol li:before": {
          top: 0,
          backgroundColor: "transparent",
          width: "unset",
          height: "unset",
        },
      },

      "& .component-visualheader": {
        display: "none",
      },

      "& .component-breadcrumb": {
        display: "none",
      },

      "& .component-printbutton": {
        display: "none",
      },

      "& .component-pagetitle h1": {
        display: "none",
      },

      "& .typography-block": {
        "& h1": {
          ...overridedTypography.h2,
        },
      },

      "& .download-inline a:hover>svg": {
        color: colors.webGreen40,
      },

      "& .component-sidebardownloads ": {
        "& .menu-aside": {
          paddingLeft: `${spacings.l} !important`,
        },
      },
      "& .sidebar-component": {
        paddingLeft: 0,
        ...onBreakpoint("sm", {
          paddingLeft: rem(spacings.s),
        }),
        ...onBreakpoint("lg", {
          paddingLeft: rem(spacings.l),
        }),
        "& .module-aside": {
          marginTop: 0,
          "& .body-module": {
            paddingRight: 0,
            "& .menu-aside": {
              paddingLeft: 0,
              marginBottom: 0,
              paddingTop: 0,
              "& li": {
                marginTop: 0,
                "&:before": {
                  display: "none",
                },
                "& a": {
                  ...overridedTypography.textDefault,
                  marginBottom: 0,
                  "& svg": {
                    display: "none",
                  },
                  "& .label-download": {
                    textDecoration: "none",
                    "&:before": {
                      fontFamily: "Font Awesome\\ 5 Pro",
                      content: '"\\f33d"',
                      fontSize: "1.6rem",
                      fontWeight: 300,
                      marginRight: rem(spacings.s),
                      position: "absolute",
                      top: -8,
                      left: `-${spacings.l}`,
                    },
                  },
                  "& .details": {
                    ...overridedTypography.caption,
                    display: "inline",
                    marginBottom: 0,
                    opacity: 1,
                  },
                },
              },
            },
          },
          "& h3": {
            ...overridedTypography.h4,
            "& i, & .badge": {
              display: "none",
            },
          },

          "& .no-image-contact-content": {
            padding: 0,
            marginBottom: rem(spacings.l),
            borderBottom: 0,
            "& p": {
              marginBottom: 0,
              "&.contact-role": {
                color: colors.gray50,
              },
            },
          },
        },

        "& hr": {
          margin: `${spacings.l} 0`,
        },
      },
      "& .panel-default": {
        borderTop: `2px solid ${colors.gray20}`,
        "&:last-child": {
          borderBottom: `2px solid ${colors.gray20}`,
        },
        "& .panel-title a": customTypography(
          {
            width: "100%",
            ...typography.h4,

            backgroundColor: colors.white,
            padding: `${spacings.s} 0`,
            "&:hover": {
              backgroundColor: colors.white,
            },
            "&.collapsed": {
              backgroundColor: colors.white,
              "&:hover": {
                backgroundColor: colors.white,
              },
            },
            "& .fa.fa-chevron-down, & .fa.fa-chevron-right": {
              color: colors.gray60,
              right: 0,
            },
          },
          { marginBottom: 0 },
          { marginBottom: 0 }
        ),
        "& .panel-body": {
          border: 0,
          padding: 0,
        },
      },
      "& .panel-collapse": {
        width: "100%",
        position: "static",
        "& .panel-group": {
          "& .panel-default": {
            borderBottom: 0,
          },
          "& .panel-heading": {
            background: "none",
            "& .panel-title": {
              "& .subcategory-button": {
                background: "none !important",
                borderBottom: `2px solid ${colors.gray20}`,
                "&.collapsed": {
                  borderBottom: 0,
                },
              },
            },
          },
          "& .panel-body": {
            padding: `${spacings.s} ${spacings.m}`,
            "& > h3": {
              ...typography.h5,
              marginBottom: "1rem !important",
            },
          },
        },
      },
      "& .media-point": {
        backgroundColor: "transparent",
        color: colors.gray60,
        "& > span": {
          fontWeight: 500,
        },
      },
      "& .img-bg": {
        backgroundColor: "transparent",
      },
      "& .nav-tabs": {
        background: colors.gray30,
        margin: 0,
        padding: 0,
        overflow: "visible",
        "& li": {
          padding: `0 !important`,
          margin: "0 !important",
          lineHeight: "0 !important",
          overflow: "visible",
          display: "inline-block",
          ...onBefore("sm", {
            display: "block",
          }),
          "&.active": {
            "& a": {
              color: colors.darkGreen80,
              background: colors.webGreen20,
              overflow: "visible",
              margin: 0,
              "&:after": {
                position: "absolute",
                left: "50%",
                bottom: -5,
                transform: "translateX(-50%)",
                display: "block",
                content: "''",
                width: 0,
                height: 0,
                borderLeft: "5px solid transparent",
                borderRight: "5px solid transparent",
                borderTop: `5px solid ${colors.webGreen20}`,
              },
              "&:hover, &:focus": {
                color: colors.darkGreen80,
                background: colors.webGreen20,
              },
            },
          },
          "& a": {
            padding: `${spacings.s} ${spacings.sam} !important`,
            border: 0,
            color: colors.gray100,
            background: "none",
            display: "inline-block",
            transition: "background-color 0.3s ease, color 0.3s ease",
            ...onBefore("sm", {
              width: "100%",
            }),
            "&:hover, &:focus": {
              padding: `${spacings.s} ${spacings.sam} !important`,
              border: 0,
              color: colors.darkGreen80,
              background: colors.webGreen40,
              textDecoration: "none",
              transition: "background-color 0.3s ease, color 0.3s ease",
            },
          },
        },
      },
      "& .hdi-btn-primary": {
        ...fonts.sansBold,
        color: colors.darkGreen80,
        fontSize: "1rem",
        lineHeight: 1.2,
        padding: "12px 22px",
        border: "2px solid transparent",
        backgroundColor: colors.webGreen20,
        transition: "background-color .3s",
        "&:hover": {
          backgroundColor: colors.webGreen40,
        },
        "&:disabled": {
          color: colors.gray60,
          backgroundColor: colors.gray20,
          pointerEvents: "none",
          cursor: "not-allowed",
        },
        "&:before": {
          display: "none",
        },
        "& svg": {
          display: "none",
        },
      },

      "& .hdi-btn-secondary": {
        ...fonts.sansBold,
        color: colors.gray100,
        fontSize: "1rem",
        lineHeight: 1.2,
        padding: "12px 22px",
        border: `2px solid ${colors.gray90}`,
        backgroundColor: colors.white,
        marginTop: rem(spacings.s),
        transition: "background-color .3s",
        "&:hover": {
          backgroundColor: colors.gray10,
        },
        "&:disabled": {
          color: colors.gray60,
          backgroundColor: colors.white,
          borderColor: colors.gray60,
        },
        "&:before": {
          display: "none",
        },
        "& svg": {
          display: "none",
        },
      },
      "& .tab-content": {
        "& .tab-pane": {
          "&> .panel-group:first-child > .panel-default": {
            padding: 0,
            "&:first-child": {
              borderTop: "0 !important",
            },
          },
          "& .panel-group": {
            marginTop: "5px !important",
            "& .panel-default": {
              padding: `0 ${spacings.m}`,
              "&>div": {
                paddingLeft: 0,
                paddingRight: 0,
              },
            },
            "& .row": {
              marginLeft: 0,
              marginRight: 0,
            },
          },
          "& .panel-group-vertical": {
            "& .panel-default": {
              "& > .col-sm-6": {
                width: "100%",
              },
              "& .panel-title a": {
                "& .fa.fa-chevron-right": {
                  right: `${rem(spacings.m)} !important`,
                  transform: "none !important",
                  "&:before": {
                    content: "'\\f07b'",
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  headline: {
    ...typography.h2,
    textAlign: "center",
  },
  richTextWrapper: {
    marginBottom: rem(spacings.s),
    position: "relative",
    ...onBreakpoint("sm", {
      // marginTop: 7, //INFO(mkarol): Fixing image-text line aligmnet
      marginBottom: rem(spacings.sam),
      width: "50%",
    }),
  },
  richTextImage: {
    height: "calc(100vw / 4 * 3)",
    position: "relative",
    display: "block",

    "& img": {
      position: "absolute",
      top: 0,
      left: -GUTTER_WIDTHS["small"],
      width: "100vw",
    },
    ...onBreakpoint("sm", {
      height: "auto",
      "& img": {
        position: "initial",
        width: "100%",
      },
    }),
  },
  imageLeft: {
    ...onBreakpoint("sm", {
      float: "left",
      paddingRight: GUTTER_WIDTHS["small"],
    }),
    ...onBreakpoint("md", {
      paddingRight: GUTTER_WIDTHS["big"],
    }),
  },
  imageRight: {
    ...onBreakpoint("sm", {
      float: "right",
      paddingLeft: GUTTER_WIDTHS["small"],
    }),
    ...onBreakpoint("md", {
      paddingLeft: GUTTER_WIDTHS["big"],
    }),
  },
  imageCaption: {
    ...customTypography(
      {
        ...typography.textXSmall,
        color: colors.gray60,
        marginTop: rem(spacings.xxs),
        textAlign: "right",
      },
      {},
      {
        marginBottom: 0,
      }
    ),
  },
  grayBg: {
    backgroundColor: ({ isGrayBg }) => isGrayBg && colors.gray10,
    padding: `${spacings.m} ${spacings.sam} ${spacings.m}`,
    ...onBreakpoint("sm", {
      padding: `${spacings.l} ${spacings.m} ${spacings.l}`,
    }),
    ...onBreakpoint("md", {
      padding: `${spacings.xxl} ${spacings.l} ${spacings.xxl}`,
    }),
  },
  bgRow: {
    maxWidth: BREAKPOINTS["xl"],
    margin: "0 auto",
  },
});

export default function RichTextBlock(props: RichTextBlockProps) {
  const hasImage =
    props.data.imageAdaptive?.value?.urlLarge ||
    props.data.imageAdaptive?.value?.urlMedium ||
    props.data.imageAdaptive?.value?.urlSmall;

  const imageWrapperRef = useRef<HTMLDivElement>(null);

  const isGrayBg = props.data.backgroundColor?.value === "gray";

  const styles = useRichTextStyles({
    isGrayBg: isGrayBg,
  });

  return (
    <Container
      dataTestid="RichTextBlock"
      isFluid={isGrayBg}
      className={cn(
        styles.richTextContainer,
        isGrayBg ? styles.grayBg : undefined
      )}
    >
      <Row className={isGrayBg ? styles.bgRow : undefined}>
        <Col sm={10} smStart={1} md={8} mdStart={2}>
          {showPropertyOnEdit(props.data.headline) && (
            <SemanticHeader
              className={styles.headline}
              headerSize={props.data.headerSize?.value}
            >
              <RenderProperty value={props.data.headline} />
            </SemanticHeader>
          )}

          {hasImage && (
            <div
              className={cn(
                styles.richTextWrapper,
                props.data.imageAlignment?.value === "left"
                  ? styles.imageLeft
                  : styles.imageRight
              )}
              ref={imageWrapperRef}
            >
              <RenderProperty
                value={props.data.imageAdaptive}
                className={styles.richTextImage}
              />
              <RenderProperty
                value={props.data.imageCaption}
                className={styles.imageCaption}
              />
            </div>
          )}

          <RenderProperty value={props.data.richText} />
        </Col>
      </Row>
    </Container>
  );
}

export function RichTextWithTypographyRenderer(props: {
  value: string;
  className?: string;
  dataEpiEdit?: string;
}) {
  const styles = useRichTextStyles({});

  return (
    <div
      data-epi-edit={props.dataEpiEdit}
      className={cn(props.className, styles.richTextBlock)}
      dangerouslySetInnerHTML={{ __html: props.value }}
    />
  );
}
