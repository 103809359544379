import React, { useContext, useState, useEffect, useRef } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Shared/Footer";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import {
  LocalizedLabel,
  localizedLabelString,
  LocalizationContext,
} from "../../hooks/LocalizationContext";
import { createUseStyles } from "react-jss";
import {
  rem,
  onBreakpoint,
  Container,
  Row,
  Col,
  onBefore,
  CONTAINER_OFFSET_WIDTHS,
} from "../../basics/layout";
import { spacings } from "../../basics/spacings";
import { typography, customTypography } from "../../basics/typography";
import { colors } from "../../basics/colors";
import { Spinner } from "../Spinner/Spinner";
import { Input } from "../Input/Input";
import { Button } from "../Button/Button";
import { RadioButton } from "../RadioButton/RadioButton";
import { Checkbox } from "../Checkbox/Checkbox";
import { AdaptiveImageComponent } from "../AdaptiveImageComponent/AdaptiveImageComponent";
import { isDeviceResOrLower, useDeviceType } from "../../hooks/useDeviceType";
import { Select } from "../Select/Select";
import {
  ContactCardsBlock,
  ContactListBlockComponent,
} from "../Block/ContactListBlock/ContactListBlock";
import { DownloadBlock, DownloadProps } from "../Block/DownloadBlock";
import { SearchPageProps } from "../../models/content/SearchPageData";
import { RenderProperty } from "../../views/RenderProperty";
import { convertPascalCaseToCamelCase } from "../../basics/convertCases";
import { Accordion, AccordionItem } from "../Accordion/Accordion";
import { useDebounce } from "../../hooks/useDebounce";
import { OptionsType } from "../../basics/commonTypes";
import { getCurrentLocale } from "../../basics/locales";
import { ContactExpert } from "../ContactExpert/ContactExpert";
import { ContentAreaProperty } from "../../models/Property";
import LanguageList from "../../models/LanguageList";
import { fonts } from "../../basics/fonts";

const PAGE_SIZE = 30;

export type SearchPageApiHit = {
  Type: string;
  ParentTitle: string;
  Title: string | null;
  Description: string | null;
  ImageUrlLarge: string | null;
  ImageUrlMedium: string | null;
  ImageUrlSmall: string | null;
  ImageAlt: string | null;
  Link: string;
};

export type SearchApiResponse<T> = {
  Hits: Array<T> | null;
  TotalCount: number;
  Filter: {
    Q: string;
  };
};

type PageTypeType = "content" | "product" | "article";
type SortByType = "relevant" | "recent";
type SearchTypeType = "pages" | "contacts" | "documents";

export function getSearchData<T>(
  page: number,
  language: string | undefined,
  query: string,
  pageType: Set<PageTypeType>,
  sortBy: SortByType,
  searchType: SearchTypeType
): Promise<SearchApiResponse<T>> {
  const queryParameters = new URLSearchParams();
  queryParameters.set("q", query);
  language && queryParameters.set("language", language);
  queryParameters.set("page", page.toString());
  queryParameters.set("size", PAGE_SIZE.toString());
  queryParameters.set("page_type", Array.from(pageType).join(","));
  queryParameters.set("sort_by", sortBy);

  return fetch(`/api/hdiglobal/search/${searchType}?${queryParameters}`).then(
    (r) => r.json()
  );
}

const useStyles = createUseStyles({
  element: {
    paddingBottom: rem(spacings.l),
    ...onBreakpoint("sm", {
      paddingTop: rem(spacings.l),
      paddingBottom: rem(spacings.xl),
    }),
    ...onBreakpoint("md", {
      paddingTop: rem(spacings.xl),
      paddingBottom: rem(spacings.xxl),
    }),
  },
  header: typography.h1,

  results: {
    marginTop: rem(spacings.sam),
  },
  resultsText: {
    ...customTypography(
      typography.textLarge,
      { marginBottom: 0 },
      { marginBottom: rem(spacings.m) }
    ),
    flex: 3,
  },
  autocorrectionText: typography.textDefault,
  autocorrectionLink: {
    textDecoration: "underline",
  },
  contentRow: onBreakpoint("sm", {
    gridTemplateRows: "auto 1fr",
  }),
  searchBarColumn: {
    gridRow: 1,
    zIndex: 1,
  },
  articleCardsColumn: {
    gridRow: 3,
    ...onBreakpoint("sm", {
      gridRow: 2,
    }),
  },
  filtersColumn: {
    zIndex: 1,
    gridRow: 2,
    ...onBreakpoint("sm", {
      gridRow: 1,
      gridRowEnd: "span 2",
    }),
  },
  flexBar: {
    display: "flex",
    alignItems: "flex-end",
  },
  queryBar: {
    ...onBefore("sm", { marginTop: rem(spacings.m) }),
    display: "flex",
  },
  filtersBackground: onBefore("sm", {
    zIndex: -1,
    position: "absolute",
    height: "100%",
    width: "100vw",
    backgroundColor: colors.gray10,
    marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
  }),
  moreButton: {
    display: "flex",
    justifyContent: "center",
    paddingTop: rem(spacings.m),
  },
  sortDropdown: {
    marginBottom: rem(spacings.sam),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.m),
    }),
    ...onBreakpoint("md", {
      flex: 1,
      marginBottom: rem(spacings.m),
    }),
  },
  blockWrapper: {
    width: `calc(100% + ${CONTAINER_OFFSET_WIDTHS["small"] * 2}px)`,
    marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
    ...onBreakpoint("sm", {
      width: `calc(100% + ${CONTAINER_OFFSET_WIDTHS["big"] * 2}px)`,
      marginLeft: -CONTAINER_OFFSET_WIDTHS["big"],
    }),
  },
});

export function SearchPage(props: SearchPageProps) {
  const initialQueryParametrs = new URLSearchParams(window.location.search);

  const { getStartPageData, getImmediateStartPageData, getWebsiteData } =
    useContext(GlobalContentStoreContext);
  const websiteData = getWebsiteData();
  const localizationCtx = useContext(LocalizationContext);
  const styles = useStyles();
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const [searchPageData, setSearchPageData] =
    useState<SearchApiResponse<SearchPageApiHit> | null>(null);

  const [searchContactData, setSearchContactData] =
    useState<SearchApiResponse<ContactCardsBlock> | null>(null);

  const [searchDownloadsData, setSearchDownloadsData] =
    useState<SearchApiResponse<DownloadProps> | null>(null);

  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(0);

  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");
  const isTabletOrMobile = isDeviceResOrLower(deviceType, "tablet");

  const CONTENT_TYPE_OPTIONS = [
    {
      label: localizedLabelString({
        section: "Search",
        label: "ContentTypePages",
        localizationCtx,
      }),
      value: "pages" as SearchTypeType,
    },
    {
      label: localizedLabelString({
        section: "Search",
        label: "ContentTypeDownloads",
        localizationCtx,
      }),
      value: "documents" as SearchTypeType,
    },
    {
      label: localizedLabelString({
        section: "Search",
        label: "ContentTypeContacts",
        localizationCtx,
      }),
      value: "contacts" as SearchTypeType,
    },
  ];
  const PAGE_TYPE_OPTIONS = [
    {
      label: localizedLabelString({
        section: "Search",
        label: "PageTypesContentPages",
        localizationCtx,
      }),
      value: "content" as PageTypeType,
    },
    {
      label: localizedLabelString({
        section: "Search",
        label: "PageTypesProductPages",
        localizationCtx,
      }),
      value: "product" as PageTypeType,
    },
    {
      label: localizedLabelString({
        section: "Search",
        label: "PageTypesArticlePages",
        localizationCtx,
      }),
      value: "article" as PageTypeType,
    },
  ];

  const SORTBY_OPTIONS = [
    {
      label: localizedLabelString({
        section: "Search",
        label: "MostRelevant",
        localizationCtx,
      }),
      value: "relevant" as SortByType,
    },
    {
      label: localizedLabelString({
        section: "Search",
        label: "MostRecent",
        localizationCtx,
      }),
      value: "recent" as SortByType,
    },
  ];

  const [contentTypeSelection, setContentTypeSelection] =
    useState<SearchTypeType>(
      (initialQueryParametrs.get("contenttype") as SearchTypeType) ||
        CONTENT_TYPE_OPTIONS?.[0]?.value
    );
  const [pageTypeSelection, setPageTypeSelection] = useState<Set<PageTypeType>>(
    new Set(
      initialQueryParametrs.has("pagetypes")
        ? decodeURI(initialQueryParametrs.get("pagetypes") || "").split(",")
        : PAGE_TYPE_OPTIONS.map((pt) => pt.value)
    ) as Set<PageTypeType>
  );
  const [localeSelection, setLocaleSelection] = useState<string | undefined>(
    getCurrentLocale(websiteData, initialQueryParametrs)
  );

  const [sortType, setSortType] = useState<SortByType>(
    (initialQueryParametrs.get("sort") as SortByType) ||
      SORTBY_OPTIONS?.[0]?.value
  );
  const [query, setQuery] = useState(
    decodeURI(initialQueryParametrs.get("query") || "")
  );
  const queryInputRef = useRef<HTMLInputElement>(null);
  const results = searchPageData || searchContactData || searchDownloadsData;

  const { debouncePromise } = useDebounce();

  function loadItemsPage<T>(
    requestedPage?: number
  ): Promise<SearchApiResponse<T> | null> {
    const apiPage = requestedPage || page + 1;
    setLoading(true);

    return debouncePromise(() =>
      getSearchData<T>(
        apiPage,
        localeSelection !== "all" ? localeSelection : undefined,
        query,
        pageTypeSelection,
        sortType,
        contentTypeSelection
      ).then((response) => {
        setPage(apiPage);
        setLoading(false);
        return response;
      })
    );
  }

  function loadItems(page?: number) {
    const append = !page;
    switch (contentTypeSelection) {
      case "pages": {
        return loadItemsPage<SearchPageApiHit>(page).then((response) => {
          setSearchPageData((s) =>
            s && append
              ? { ...s, Hits: [...(s.Hits || []), ...(response?.Hits || [])] }
              : response
          );
        });
      }
      case "contacts": {
        return loadItemsPage<ContactCardsBlock>(page).then((response) => {
          const Hits =
            (response?.Hits?.map(
              convertPascalCaseToCamelCase
            ) as ContactCardsBlock[]) || [];

          setSearchContactData(
            (s) =>
              response && {
                ...response,
                Hits: append ? [...(s?.Hits || []), ...Hits] : Hits,
              }
          );
        });
      }
      default: {
        return loadItemsPage<DownloadProps>(page).then((response) => {
          const Hits =
            (response?.Hits?.map(
              convertPascalCaseToCamelCase
            ) as DownloadProps[]) || [];
          setSearchDownloadsData(
            (s) =>
              response && {
                ...response,
                Hits: append ? [...(s?.Hits || []), ...Hits] : Hits,
              }
          );
        });
      }
    }
  }

  function searchFunction() {
    setQuery(queryInputRef.current?.value || "");
  }

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams();
    queryParameters.set("contenttype", contentTypeSelection);
    queryParameters.set(
      "pagetypes",
      encodeURI(Array.from(pageTypeSelection.values()).join(","))
    );
    localeSelection && queryParameters.set("locale", localeSelection);
    queryParameters.set("query", encodeURI(query));
    queryParameters.set("sort", sortType);

    setSearchPageData(null);
    setSearchContactData(null);
    setSearchDownloadsData(null);
    history.replaceState(
      {},
      "",
      `${window.location.pathname}?${queryParameters}`
    );
    loadItems(1);
  }, [
    startPageData,
    contentTypeSelection,
    pageTypeSelection,
    localeSelection,
    sortType,
    query,
  ]);

  if (!startPageData) return null;

  const isUserRetention = props.data.contactCta?.value === "enabled";

  const contactExpertData = props.data.contactsUseDefaultContent?.value
    ? startPageData
    : props.data;

  return (
    <>
      <Header {...startPageData} />
      <main data-testid="SearchPage">
        <Container className={styles.element}>
          {!isMobile && (
            <Row>
              <Col sm={7} md={8}>
                <h1 className={styles.header}>
                  <RenderProperty
                    value={localizedLabelString({
                      section: "Search",
                      label: "Header",
                      localizationCtx,
                    })}
                  />
                </h1>
              </Col>
            </Row>
          )}

          <Row className={styles.contentRow}>
            <Col xs={4} sm={4} md={3} className={styles.filtersColumn}>
              <div className={styles.filtersBackground} />
              <FiltersElement
                contentTypeSelection={contentTypeSelection}
                setContentTypeSelection={(value: string) =>
                  setContentTypeSelection(value as SearchTypeType)
                }
                pageTypeSelection={pageTypeSelection}
                setPageTypeSelection={(
                  changeFunc: (state: Set<string>) => Set<string>
                ) =>
                  setPageTypeSelection(
                    (state) => changeFunc(state) as Set<PageTypeType>
                  )
                }
                localeSelection={localeSelection}
                setLocaleSelection={setLocaleSelection}
                contentTypeOptions={CONTENT_TYPE_OPTIONS}
                pageTypeOptions={PAGE_TYPE_OPTIONS}
                localeOptions={props.data.existingLanguages || []}
                teaserContent={props.data.teaserContent}
                enableLocalFilter={props.data.enableLocalFilter?.value}
              />
            </Col>

            <Col
              xs={4}
              sm={8}
              smStart={4}
              md={6}
              mdStart={3}
              className={styles.searchBarColumn}
            >
              <div className={styles.filtersBackground}></div>
              <div className={styles.queryBar}>
                <Input
                  label={localizedLabelString({
                    section: "Search",
                    label: "Search",
                    localizationCtx,
                  })}
                  initialValue={query}
                  inputRef={queryInputRef}
                  onSubmit={searchFunction}
                  theme={isMobile ? "white" : "gray"}
                />
                <Button type="primary" onClick={searchFunction}>
                  <LocalizedLabel section="Search" label="Search" />
                </Button>
              </div>
            </Col>
            <Col
              xs={4}
              sm={8}
              smStart={4}
              md={9}
              mdStart={3}
              className={styles.articleCardsColumn}
            >
              <div className={styles.results}>
                {isLoading && <Spinner />}
                {results && (
                  <>
                    <div className={styles.flexBar}>
                      <p className={styles.resultsText}>
                        <strong>{results?.TotalCount}</strong>&nbsp;
                        <LocalizedLabel section="Search" label="Results" />
                        {results?.Filter.Q && (
                          <>
                            &nbsp;
                            <LocalizedLabel section="Search" label="ForQuery" />
                            &nbsp;
                            <strong>&quot;{results?.Filter.Q}&quot;</strong>
                          </>
                        )}
                      </p>
                      <Select
                        className={styles.sortDropdown}
                        label="Sort by"
                        options={SORTBY_OPTIONS}
                        selected={sortType}
                        setSelected={(props: string) =>
                          setSortType(props as SortByType)
                        }
                        customTriggerIcon="sort-amount-up"
                        iconOnlyTrigger={isTabletOrMobile}
                      />
                    </div>

                    {searchPageData?.Hits &&
                      contentTypeSelection === "pages" && (
                        <PageSearchResults pages={searchPageData.Hits} />
                      )}

                    {searchContactData?.Hits &&
                      contentTypeSelection === "contacts" && (
                        <div className={styles.blockWrapper}>
                          <ContactListBlockComponent
                            contacts={searchContactData.Hits}
                            isWide
                          />
                        </div>
                      )}

                    {searchDownloadsData?.Hits &&
                      contentTypeSelection === "documents" && (
                        <div className={styles.blockWrapper}>
                          <DownloadBlock
                            downloadItems={searchDownloadsData.Hits || []}
                            disableFeaturedOption
                            isFullWidth
                          />
                        </div>
                      )}
                  </>
                )}
              </div>
            </Col>
            {results && results.TotalCount > page * PAGE_SIZE && (
              <Col className={styles.moreButton}>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Button type="secondary" onClick={() => loadItems()}>
                    <LocalizedLabel section="Search" label="LoadMore" />
                  </Button>
                )}
              </Col>
            )}
          </Row>
        </Container>
        <RenderProperty value={props.data.additionalContent} />
        {(props.data.downloadsGlobal?.value ||
          props.data.downloadsLocal?.value) && (
          <DownloadBlock
            downloadItems={[
              ...((props.data.downloadsLocal
                ?.expandedValue as DownloadProps[]) || []),
              ...((props.data.downloadsGlobal
                ?.expandedValue as DownloadProps[]) || []),
            ]}
          />
        )}
        {isUserRetention && <ContactExpert {...contactExpertData} />}
      </main>
      <Footer {...startPageData} />
    </>
  );
}

const useFiltersStyles = createUseStyles({
  mainElement: {
    marginBottom: rem(spacings.sam),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.m),
    }),
  },
  teaserContentItem: {
    "& > div": {
      marginBottom: rem(spacings.sam),
      ...onBreakpoint("sm", {
        marginBottom: rem(spacings.m),
      }),
    },
  },
});

function FiltersElement(props: {
  contentTypeSelection: string;
  setContentTypeSelection: (props: string) => void;
  contentTypeOptions: OptionsType<string>;
  pageTypeSelection: Set<string>;
  setPageTypeSelection: (
    stateChangeFunc: (state: Set<string>) => Set<string>
  ) => void;
  pageTypeOptions: OptionsType<string>;
  localeSelection: string | undefined;
  setLocaleSelection: (props: string) => void;
  localeOptions: LanguageList;
  teaserContent: ContentAreaProperty;
  enableLocalFilter: boolean | null;
}) {
  const localizationCtx = useContext(LocalizationContext);
  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");

  const styles = useFiltersStyles();

  return (
    <>
      <Accordion noOuterBorder={isMobile} className={styles.mainElement}>
        <AccordionItem
          headline={localizedLabelString({
            section: "Search",
            label: "ContentType",
            localizationCtx,
          })}
          initialIsActive={!isMobile}
        >
          {props.contentTypeOptions.map((option) => (
            <RadioButton
              key={option.value}
              label={option.label}
              onCheck={() => props.setContentTypeSelection(option.value)}
              checked={props.contentTypeSelection === option.value}
            />
          ))}
        </AccordionItem>
        <AccordionItem
          headline={localizedLabelString({
            section: "Search",
            label: "PageTypes",
            localizationCtx,
          })}
          initialIsActive={!isMobile}
        >
          {props.pageTypeOptions.map((option) => (
            <Checkbox
              disabled={props.contentTypeSelection !== "pages"}
              key={option.value}
              onCheck={() =>
                props.setPageTypeSelection((s) => {
                  if (!s.has(option.value)) return new Set(s).add(option.value);
                  const newSet = new Set(s);
                  newSet.delete(option.value);
                  return newSet;
                })
              }
              checked={props.pageTypeSelection.has(option.value)}
              label={option.label}
            />
          ))}
        </AccordionItem>
        {props.enableLocalFilter && (
          <AccordionItem
            headline={localizedLabelString({
              section: "Search",
              label: "Locales",
              localizationCtx,
            })}
          >
            {props.localeOptions.map((option, idx) => (
              <RadioButton
                key={idx}
                label={option.displayName}
                onCheck={() => props.setLocaleSelection(option.name)}
                checked={props.localeSelection === option.name}
              />
            ))}
          </AccordionItem>
        )}
      </Accordion>
      <RenderProperty
        value={props.teaserContent}
        theme="filters"
        className={styles.teaserContentItem}
      />
    </>
  );
}

const usePagesStyles = createUseStyles({
  pagesList: {
    ...onBreakpoint("md", {
      "& $pageItem:first-child": {
        borderTop: "none",
        paddingTop: 0,
      },
    }),
  },
  pageItem: {
    display: "flex",
    padding: `${spacings.sam} 0`,
    borderTop: `2px solid ${colors.gray20}`,
  },
  pageImage: {
    "& img": {
      ...onBefore("sm", {
        paddingBottom: rem(spacings.sam),
      }),
      height: "auto",
    },
  },
  h4: typography.h4,
  caption: typography.caption,
  textDefault: {
    ...typography.textDefault,
    ...fonts.sansLight,
  },
  fullWidth: {
    width: "100%",
  },
});

function PageSearchResults(props: { pages: Array<SearchPageApiHit> }) {
  const styles = usePagesStyles();
  return (
    <div className={styles.pagesList}>
      {props.pages.map((page, idx) => {
        const hasImage =
          page.ImageUrlLarge || page.ImageUrlMedium || page.ImageUrlSmall;
        return (
          <Container
            key={idx}
            className={styles.pageItem}
            dataTestid="SearchPageResultCard"
          >
            <Row className={styles.fullWidth}>
              {hasImage && (
                <Col sm={4} md={3}>
                  <a href={page.Link}>
                    <AdaptiveImageComponent
                      large={page.ImageUrlLarge}
                      medium={page.ImageUrlMedium}
                      small={page.ImageUrlSmall}
                      alt={page.ImageAlt}
                      className={styles.pageImage}
                    />
                  </a>
                </Col>
              )}
              <Col sm={hasImage ? 8 : 12} md={hasImage ? 9 : 12}>
                <span className={styles.caption}>{page.ParentTitle}</span>

                <h4 className={styles.h4}>{page.Title}</h4>
                <p className={styles.textDefault}>{page.Description}</p>
                <Button type="link" href={page.Link}>
                  <LocalizedLabel section="Global" label="ReadMore" />
                </Button>
              </Col>
            </Row>
          </Container>
        );
      })}
    </div>
  );
}
