import { createUseStyles } from "react-jss";
import { spacings } from "../../basics/spacings";
import { colors } from "../../basics/colors";
import { onBefore, onBreakpoint, rem } from "../../basics/layout";
import { customTypography, typography } from "../../basics/typography";
import React, { useState } from "react";
import { RenderProperty } from "../../views/RenderProperty";
import { Button } from "../Button/Button";
import { LocalizedLabel } from "../../hooks/LocalizationContext";
import { FAIcon } from "../FAIcon/FAIcon";
import { BooleanProperty, StringProperty } from "../../models/Property";
import cn from "classnames";

const downloadItemStyles = createUseStyles({
  downloadItem: {
    display: "flex",
    flexWrap: "wrap",
    padding: `${spacings.sam} 0 ${spacings.sam}`,
    borderBottom: `1px solid ${colors.gray20}`,
    ...onBreakpoint("sm", {
      padding: `${spacings.sam} 0 ${spacings.m}`,
    }),
    "&:first-child": {
      ...onBefore("md", {
        borderTop: `1px solid ${colors.gray20}`,
      }),
      ...onBreakpoint("md", {
        paddingTop: 0,
      }),
    },
  },
  infoWrapper: {
    ...onBefore("sm", {
      flexBasis: "100%",
    }),
  },
  itemInfo: {
    ...typography.caption,
    flexBasis: "100%",
    marginBottom: rem(spacings.xs),
  },

  title: customTypography(
    {
      ...typography.h4,
      marginRight: 0,
    },
    {},
    { marginBottom: 0 }
  ),
  downloadButton: {
    alignSelf: "center",
    marginLeft: "auto",
    marginTop: rem(spacings.xs),
    ...onBefore("sm", {
      marginLeft: "unset",
    }),
  },
  languagesButton: {
    padding: 0,
    background: "none",
    display: "flex",
    flexBasis: "100%",
    alignItems: "center",
    marginTop: rem(spacings.sam),
    outline: "none",
  },
  buttonText: {
    ...customTypography(
      {
        ...typography.textSmall,
        marginBottom: 0,
        marginRight: rem(spacings.s),
      },
      {},
      { marginBottom: 0 }
    ),
  },
  languagesList: ({ opened }) => ({
    width: "100%",
    margin: 0,
    maxHeight: opened ? "unset" : 0,
    opacity: opened ? 1 : 0,
    pointerEvents: opened ? "all" : "none",
    transition: "opacity 0.3s ease, max-height 0.3s ease",
    overflow: "hidden",
  }),
  divider: {
    margin: `0 ${spacings.xs}`,
  },
});

function findExtension(url: string | StringProperty) {
  url = typeof url === "string" ? url : url.value || "";

  return url.split(".").pop();
}

export interface DownloadItemProps {
  url?: string | null;
  fileSize?: string | StringProperty;
  title?: string | StringProperty;
  isFeatured?: BooleanProperty;
  localizedFiles?: Array<Exclude<DownloadItemProps, "localizedFiles">>;
  className?: string;
}

export function DownloadItem(
  props: DownloadItemProps & { context?: { disableFeaturedOption?: boolean } }
) {
  const [opened, setOpened] = useState(false);
  const styles = downloadItemStyles({ opened });
  return (
    <div className={cn(styles.downloadItem, props.className)}>
      <div className={styles.infoWrapper}>
        <span className={styles.itemInfo}>
          {findExtension(props.url || "")}
          <span className={styles.divider}>|</span>
          <RenderProperty value={props.fileSize} />
          {props.isFeatured?.value && !props.context?.disableFeaturedOption && (
            <>
              <span className={styles.divider}>|</span>
              <LocalizedLabel section="Download" label="Featured" />
            </>
          )}
        </span>
        <h4 className={styles.title}>
          <RenderProperty value={props.title} />
        </h4>
      </div>
      <Button
        type="primary"
        icon="arrow-to-bottom"
        className={styles.downloadButton}
        href={props.url || ""}
        target="_blank"
      >
        <LocalizedLabel section="Download" label="Button" />
      </Button>
      {props.localizedFiles?.length && (
        <button
          className={styles.languagesButton}
          onClick={() => setOpened((s) => !s)}
          data-testid="DownloadItemShowMore"
        >
          <span className={styles.buttonText}>
            {opened ? (
              <LocalizedLabel section="Download" label="ShowLess" />
            ) : (
              <LocalizedLabel section="Download" label="ShowMore" />
            )}
          </span>
          {opened ? (
            <FAIcon icon="chevron-up" size="lg" />
          ) : (
            <FAIcon icon="chevron-down" size="lg" />
          )}
        </button>
      )}

      <ul className={styles.languagesList} aria-expanded={opened}>
        {props.localizedFiles?.map((lf, idx) => (
          <LocalizedDownloadItem {...lf} key={idx} />
        ))}
      </ul>
    </div>
  );
}

const localizedDownloadItemStyles = createUseStyles({
  localizedDownloadItem: {
    marginTop: rem(spacings.sam),
    display: "flex",
    alignItems: "center",
  },
  downloadIcon: {
    padding: 0,
    background: "none",
    outline: "none",
    marginRight: rem(spacings.s),
  },
  itemInfo: {
    ...typography.caption,
    flexBasis: "100%",
  },

  title: customTypography(
    { ...typography.h5 },
    { marginBottom: 0 },
    { marginBottom: 0 }
  ),
  divider: {
    margin: `0 ${spacings.xs}`,
  },
});

function LocalizedDownloadItem(
  props: Exclude<DownloadItemProps, "localizedFiles">
) {
  const styles = localizedDownloadItemStyles();

  return (
    <li className={styles.localizedDownloadItem}>
      <a
        className={styles.downloadIcon}
        href={props.url || ""}
        target="_blank noreferer noopener"
      >
        <FAIcon icon="arrow-to-bottom" size="2x" />
      </a>
      <div>
        <span className={styles.itemInfo}>
          {findExtension(props.url || "")}
          <span className={styles.divider}>|</span>
          <RenderProperty value={props.fileSize} />
        </span>
        <h5 className={styles.title}>
          <RenderProperty value={props.title} />
        </h5>
      </div>
    </li>
  );
}
