import React, { useContext, useState } from "react";
import {
  Col,
  Container,
  onBreakpoint,
  rem,
  Row,
  BREAKPOINTS,
} from "../../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../../basics/colors";
import { fonts } from "../../../basics/fonts";
import { GlobalContentStoreContext } from "../../../hooks/GlobalContentStore";
import { MonsterNavigationColumn } from "../../../models/MonsterNavigation";
import cn from "classnames";
import { LanguagePicker } from "../../LanguagePicker/LanguagePicker";
import StartPageData from "../../../models/content/StartPageData";
import { spacings } from "../../../basics/spacings";
import { FAIcon, FAIconStyles } from "../../FAIcon/FAIcon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { RenderComponent } from "../../../views/RenderComponent";

const ICON_SMALL_SIZE_PX = 16;
export const NAVI_GROUP_LIST_LEFT_PADDING = `calc(${ICON_SMALL_SIZE_PX}px + ${spacings.xs})`;

const useStyles = createUseStyles({
  monsterNavigation: ({ isOpened }) => ({
    backgroundColor: colors.white,
    ...fonts.sansRegular,
    color: colors.lightGreen100,
    position: "absolute",
    boxShadow: "0 1px 12px 0 rgba(0, 0, 0, 0.09)",
    width: "100%",
    height: "100vh",
    paddingBottom: 0,
    overflowY: "auto",
    zIndex: -1,
    transform: isOpened ? "scale(1,1)" : "scale(1,0)",
    transformOrigin: "center top",
    transition:
      (isOpened ? "transform 0.3s ease" : "transform 0.4s ease") +
      ", border-color 0.5s ease",
    transitionDelay: isOpened ? "0s" : "0.2s",
  }),
  monsterNavigationRwd: {
    paddingTop: rem(spacings.s),
    ...onBreakpoint("sm", {
      paddingTop: `${rem(spacings.sam)} !important`,
      paddingBottom: `${rem(spacings.xxl)} !important`,
    }),
    ...onBreakpoint("md", {
      paddingTop: `${rem(spacings.m)} !important`,
    }),
  },
  monsterNavigationRwdStartPage: {
    paddingTop: `${rem(spacings.l.times(2))} !important`,
    ...onBreakpoint("sm", {
      paddingBottom: `${rem(spacings.xl)} !important`,
    }),
    ...onBreakpoint("md", {
      paddingTop: `${rem(spacings.xl.add(spacings.xxl))} !important`,
    }),
  },
  monsterNavigationInside: ({ isOpened }) => ({
    opacity: isOpened ? 1 : 0,
    transition: "opacity 0.3s ease",
    transitionDelay: isOpened ? "0.1s" : "0s",
  }),
  naviGroup: {
    marginBottom: 0,
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.sam),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.m),
    }),

    "&.active": {
      backgroundColor: colors.gray10,
      width: `calc(100% + ${spacings.m})`,
      transform: `translateX(-${spacings.s})`,
      padding: `0 ${spacings.s}`,
      ...onBreakpoint("sm", {
        backgroundColor: colors.white,
      }),
    },
  },
  naviGroupButton: {
    width: "100%",
    padding: 0,
    border: "none",
    font: "inherit",
    color: "inherit",
    textAlign: "inherit",
    background: "none",
  },
  naviGroupHeader: {
    display: "flex",
    alignItems: "center",
    padding: `${spacings.s} 0`,
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.s),
      padding: 0,
    }),
    "& img": {
      maxWidth: 24,
      maxHeight: 24,
    },
    "& a, & span": {
      color: colors.gray100,
      fontWeight: "normal",
      marginLeft: rem(spacings.xs),
      textDecoration: "none",
      ...onBreakpoint("sm", {
        fontWeight: "bold",
      }),
    },

    "& svg": {
      "&:last-child": {
        display: "block",
        marginLeft: "auto",
        ...onBreakpoint("sm", {
          display: "none",
        }),
      },
    },
  },

  naviGroupList: {
    paddingLeft: NAVI_GROUP_LIST_LEFT_PADDING,
    height: 0,
    opacity: 0,
    overflow: "hidden",
    margin: 0,
    "&.active": {
      height: "auto",
      opacity: 1,
    },
    ...onBreakpoint("sm", {
      height: "auto",
      opacity: 1,
      overflow: "visible",
    }),

    "& li": {
      listStyle: "none",
      marginBottom: rem(spacings.sam),
      ...onBreakpoint("sm", {
        marginBottom: rem(spacings.xs),
      }),
      "& a": {
        textDecoration: "none",
        lineHeight: "1.5rem",
        transition: "color 0.2s ease",
        "&:hover": {
          color: colors.gray100,
          transition: "0.2s ease",
        },
        ...onBreakpoint("md", {
          color: colors.gray70,
        }),
      },
    },
  },
  leftGroupColumn: {
    position: "relative",
    ...onBreakpoint("md", {
      "&:after": {
        content: "''",
        display: "block",
        width: 2,
        height: "100%",
        position: "absolute",
        // INFO(mkarol): Smooth trasition from 0px at breakpoint start margin to 32px at breakpoint end.
        right: `calc(min((100vw - ${BREAKPOINTS["md"]}px) / 8, 32px))`,
        top: 0,
        backgroundColor: colors.gray20,
      },
    }),
  },
  lastLeftColumn: {
    ...onBreakpoint("md", {
      // INFO(mkarol): Smooth trasition from 16px margin at breakpoint start to 32px at breakpoint end.
      marginRight: `calc(${spacings.s} + min((100vw - ${BREAKPOINTS["md"]}px) / 16, 16px))`,
    }),
  },
  newsletterColumn: {
    marginTop: rem(spacings.xs),
    paddingTop: rem(spacings.xs),
    borderTop: `1px solid ${colors.gray20}`,
    ...onBreakpoint("sm", {
      marginTop: rem(spacings.s),
      paddingTop: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      margin: 0,
      padding: 0,
      border: "none",
    }),
  },
  loginSection: {
    display: "flex",
    color: colors.gray100,
    backgroundColor: colors.gray10,
    marginTop: rem(spacings.sam),
    justifyContent: "space-between",
    padding: rem(spacings.s),
    paddingBottom: 80,
    ...onBreakpoint("sm", {
      display: "none",
      paddingBottom: rem(spacings.s),
    }),
  },
  icon: FAIconStyles({
    width: 24,
    height: 24,
  }),
  iconSmall: FAIconStyles({
    width: ICON_SMALL_SIZE_PX,
    height: ICON_SMALL_SIZE_PX,
  }),
  iconSmallPlaceholder: {
    width: ICON_SMALL_SIZE_PX,
    height: ICON_SMALL_SIZE_PX,
  },
});

export function MonsterNavigation(props: {
  isOpened: boolean;
  isStartPage?: boolean;
  userLogin: StartPageData["userLogin"];
  languagePicker: StartPageData["languagePicker"];
}) {
  const { getNavigation } = useContext(GlobalContentStoreContext);

  const [activeItem, setActiveItem] = useState<MonsterNavigationColumn | null>(
    null
  );

  const monsterNavigation = getNavigation();

  function chunkArray(
    arr: Array<MonsterNavigationColumn> | undefined,
    chunkCount: number
  ) {
    const chunks = [];
    while (arr?.length) {
      const chunkSize = Math.ceil(arr.length / chunkCount--);
      const chunk = arr.slice(0, chunkSize);
      chunks.push(chunk);
      arr = arr.slice(chunkSize);
    }
    return chunks;
  }

  const openAccordionFunction = (column: MonsterNavigationColumn) => () =>
    column === activeItem ? setActiveItem(null) : setActiveItem(column);

  const styles = useStyles({
    isOpened: props.isOpened,
    isStartPage: props.isStartPage,
  });
  const chunkedMenuLeftColumn = chunkArray(
    monsterNavigation?.menuLeftColumn,
    3
  );

  return (
    <nav
      id="main-menu"
      data-testid="monsternavigation"
      aria-expanded={props.isOpened}
    >
      <Container
        isFluid
        className={cn(
          styles.monsterNavigation,
          styles.monsterNavigationRwd,
          props.isStartPage && styles.monsterNavigationRwdStartPage
        )}
      >
        <Container className={styles.monsterNavigationInside}>
          <Row>
            <Col md={9} className={styles.leftGroupColumn}>
              <Row>
                {chunkedMenuLeftColumn.map((menuColumnGroup, idx) => (
                  <Col
                    xs={4}
                    sm={4}
                    key={idx}
                    className={cn(idx === 2 && styles.lastLeftColumn)}
                  >
                    {menuColumnGroup.map((menuColumn, sidx) => (
                      <MenuColumn
                        column={menuColumn}
                        isActive={activeItem === menuColumn}
                        openAccordion={openAccordionFunction(menuColumn)}
                        key={sidx}
                      />
                    ))}
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md={3} className={styles.newsletterColumn}>
              <Row>
                {monsterNavigation?.menuRightColumn.map((rightColumn, idx) => (
                  <Col xs={4} sm={4} md={11} key={idx}>
                    <MenuColumn
                      column={rightColumn}
                      isActive={activeItem === rightColumn}
                      openAccordion={openAccordionFunction(rightColumn)}
                    />
                  </Col>
                ))}
                {monsterNavigation?.menuContentArea?.map((block, idx) => (
                  <Col xs={4} sm={4} md={11} key={idx}>
                    <div className={styles.naviGroup}>
                      <RenderComponent
                        contentLink={block.contentLink}
                        key={
                          idx + monsterNavigation?.menuRightColumn?.length || 0
                        }
                        theme="navigation"
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
        <div className={styles.loginSection}>
          {props.userLogin.value && <a href="#">Login</a>}
          {props.languagePicker.value && <LanguagePicker />}
        </div>
      </Container>
    </nav>
  );
}

export function MenuColumnHeader(props: {
  icon: string | undefined;
  text: string;
  href?: string;
  target?: string;
  isActive?: boolean;
}) {
  const styles = useStyles({});

  return (
    <div className={styles.naviGroupHeader}>
      {props.icon ? (
        <FAIcon icon={props.icon as IconProp} className={styles.iconSmall} />
      ) : (
        <div className={styles.iconSmallPlaceholder} />
      )}
      {props.href ? (
        <a href={props.href} target={props.target || "_self"}>
          {props.text}
        </a>
      ) : (
        <span>{props.text}</span>
      )}

      {props.isActive ? (
        <FAIcon icon="chevron-up" className={styles.icon} />
      ) : (
        <FAIcon icon="chevron-down" className={styles.icon} />
      )}
    </div>
  );
}

function MenuColumn(props: {
  column: MonsterNavigationColumn;
  isActive: boolean;
  openAccordion: () => void;
}) {
  const styles = useStyles({});

  return (
    <div className={styles.naviGroup}>
      <button
        role="button"
        tabIndex={-1}
        className={cn(
          styles.naviGroupButton,
          props.isActive ? "active" : undefined
        )}
        onClick={props.openAccordion}
      >
        <MenuColumnHeader {...props.column} isActive={props.isActive} />
      </button>
      <ul
        className={cn(
          styles.naviGroupList,
          props.isActive ? "active" : undefined
        )}
      >
        {props.column.children.map((i, cidx) => (
          <li key={cidx}>
            <a href={i.href} target={i.target}>
              {i.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
