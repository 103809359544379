import React, { useContext, useState, useEffect, useRef } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Shared/Footer";
import { ContentPageProps } from "../../models/content/ContentPageData";
import { RenderProperty } from "../../views/RenderProperty";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import { KeyVisual } from "../KeyVisual/KeyVisual";
import { RelatedContentBlock } from "../Block/RelatedContentBlock";
import { ContactExpert } from "../ContactExpert/ContactExpert";
import { DownloadBlock, DownloadProps } from "../Block/DownloadBlock";
import { isCypressTest } from "../../basics/isCypressTest";

export function ContentPage(props: ContentPageProps) {
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const contactListRef = useRef<HTMLDivElement>(null);
  const keyVisualRef = useRef<HTMLDivElement>(null);
  const headerButtonPortalRef = useRef<HTMLDivElement>(null);
  const isUserRetention = props.data.contactCta?.value === "enabled";

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  if (!startPageData) return null;

  const contactExpertData = props.data.contactsUseDefaultContent?.value
    ? startPageData
    : props.data;

  return (
    <>
      {!isCypressTest() && (
        <Header
          {...startPageData}
          contactListRef={contactListRef}
          keyVisualRef={keyVisualRef}
          headerButtonPortalRef={headerButtonPortalRef}
        />
      )}
      <main data-testid="ContentPage">
        {!isCypressTest() && (
          <KeyVisual
            {...props.data.keyVisual}
            contactListRef={contactListRef}
            keyVisualRef={keyVisualRef}
            headerButtonPortalRef={headerButtonPortalRef}
            isUserRetention={isUserRetention}
          />
        )}
        <RenderProperty value={props.data.mainContent} />
        <RenderProperty value={props.data.additionalContent} />
        {(props.data.downloadsGlobal?.value ||
          props.data.downloadsLocal?.value) && (
          <DownloadBlock
            downloadItems={[
              ...((props.data.downloadsLocal
                ?.expandedValue as DownloadProps[]) || []),
              ...((props.data.downloadsGlobal
                ?.expandedValue as DownloadProps[]) || []),
            ]}
          />
        )}
        {isUserRetention && (
          <div ref={contactListRef}>
            <ContactExpert {...contactExpertData} />
          </div>
        )}
        {props.data.relatedContent?.value && (
          <RelatedContentBlock relatedContent={props.data.relatedContent} />
        )}
      </main>
      <Footer {...startPageData} />
    </>
  );
}
