import React, { ReactNode } from "react";

export function SemanticHeader(props: {
  headerSize: string | null | undefined;
  className: string;
  children: ReactNode;
}) {
  switch (props.headerSize) {
    case "h2":
      return <h2 className={props.className}>{props.children}</h2>;
    case "h3":
      return <h3 className={props.className}>{props.children}</h3>;
    case "h4":
      return <h4 className={props.className}>{props.children}</h4>;
    case "h5":
      return <h5 className={props.className}>{props.children}</h5>;
    default:
      return <h2 className={props.className}>{props.children}</h2>;
  }
}
