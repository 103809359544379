import React from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, rem, Row } from "../../basics/layout";
import { spacings } from "../../basics/spacings";
import {
  AnimatedBlockRenderProperty,
  RenderProperty,
} from "../../views/RenderProperty";
import { typography } from "../../basics/typography";
import { colors } from "../../basics/colors";
import { QuoteBlockProps } from "../../models/content/QuoteBlockData";
import cn from "classnames";
import { AnimatedRevealBlock } from "../AnimatedReveal/AnimatedReveal";

const useStyles = createUseStyles({
  quote: {
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      display: "block",
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  description: typography.h3,
  author: {
    ...typography.textDefault,
    display: "flex",
    flexDirection: "column",
    marginBottom: 0,
    "& span": {
      "&:last-child": {
        color: colors.gray60,
      },
    },
  },
  textContainer: {
    minHeight: 230,
    padding: rem(spacings.m),
    paddingRight: 0,
    backgroundColor: colors.gray10,
    "@media (min-width: 500px)": {
      paddingRight: rem(spacings.m),
    },
    ...onBreakpoint("sm", {
      marginTop: rem(spacings.l),
      padding: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginTop: rem(spacings.xxl),
      padding: rem(spacings.xxl),
      paddingRight: rem(spacings.l),
    }),
    "&:after": {
      content: '""',
      position: "absolute",
      top: 0,
      right: "-25%",
      width: "100%",
      height: "100%",
      backgroundColor: colors.gray10,
      zIndex: -1,
      ...onBreakpoint("sm", {
        right: "-15%",
      }),
    },
  },
  narrow: {
    ...onBreakpoint("sm", {
      "&:after": {
        right: "-12%",
      },
    }),
    ...onBreakpoint("md", {
      "&:after": {
        right: "-35%",
      },
    }),
  },
  imageContainer: {
    position: "absolute",
    bottom: 32,
    width: "100%",
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      width: "auto",
      position: "static",
      marginBottom: rem(spacings.l),
    }),

    ...onBreakpoint("md", {
      marginBottom: rem(spacings.m.times(2)),
    }),
  },
});

export function QuoteBlock(props: QuoteBlockProps) {
  const { additionalInformation, image, layout, quote, source } = props.data;
  const styles = useStyles();
  const isNarrow = layout.value === "narrow";
  return (
    <Container className={styles.quote} dataTestid="QuoteBlock">
      <Row style={{ position: "relative" }}>
        <Col
          xs={3}
          sm={isNarrow ? 9 : 7}
          md={isNarrow ? 6 : 7}
          mdStart={isNarrow ? 1 : null}
          className={cn(styles.textContainer, isNarrow && styles.narrow)}
        >
          <AnimatedRevealBlock>
            <RenderProperty value={quote} className={styles.description} />
            <div className={styles.author}>
              <RenderProperty value={source} />
              <RenderProperty value={additionalInformation} />
            </div>
          </AnimatedRevealBlock>
        </Col>

        <Col
          xs={1}
          xsStart={3}
          sm={isNarrow ? 3 : 5}
          smStart={isNarrow ? 9 : 7}
          md={isNarrow ? 4 : 5}
          mdStart={7}
          className={styles.imageContainer}
        >
          <AnimatedBlockRenderProperty value={image} revealDelay={200} />
        </Col>
      </Row>
    </Container>
  );
}
