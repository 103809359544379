import React, { useState, useContext, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, rem, Row } from "../../basics/layout";
import { colors } from "../../basics/colors";
import { RenderProperty } from "../../views/RenderProperty";
import { typography, customTypography } from "../../basics/typography";
import { spacings } from "../../basics/spacings";
import { Checkbox } from "../Checkbox/Checkbox";
import { Button } from "../Button/Button";
import ConsentBlockData from "../../models/content/ConsentBlockData";
import { PrivacyContext } from "../../hooks/PrivacyStore";
import { ConsentButton } from "../../models/Property";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { LocalizedLabel } from "../../hooks/LocalizationContext";

const useStyles = createUseStyles({
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(15,18,20,.84)",
    display: "flex",
    alignItems: "center",
    zIndex: 100,
  },
  modalBox: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "80vh",
    overflow: "auto",
  },
  modalMain: {
    backgroundColor: colors.white,
    padding: rem(spacings.sam),
    ...onBreakpoint("sm", {
      padding: rem(spacings.m),
    }),
  },
  headline: typography.h4,
  text: customTypography(
    typography.textSmall,
    { marginBottom: 0 },
    { marginBottom: 0 },
    { marginBottom: 0 }
  ),
  textSection: {
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
    }),
  },
  checkboxSection: {
    display: "flex",
    flexWrap: "wrap",
  },
  checkboxSectionDiv: {
    flexBasis: "100%",
    marginRight: 0,
    "&:last-child": {
      marginRight: 0,
    },
    ...onBreakpoint("sm", {
      flexBasis: "50%",
    }),
    ...onBreakpoint("md", {
      flexBasis: "auto",
      marginRight: rem(spacings.l),
    }),
  },
  ctaSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: rem(spacings.sam),
  },
  ctaSectionDiv: {
    padding: 0,
    border: "none",
    font: "inherit",
    color: "inherit",
    textAlign: "inherit",
    background: "none",
    display: "none",
    ...onBreakpoint("sm", {
      display: "flex",
      alignItems: "center",
    }),
  },
  textSectionDetails: {
    padding: 0,
    border: "none",
    font: "inherit",
    color: "inherit",
    textAlign: "inherit",
    background: "none",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    ...onBreakpoint("sm", {
      display: "none",
    }),
  },
  ctaSectionSpan: {
    ...customTypography(
      {
        ...typography.textSmall,
        marginBottom: 0,
        marginRight: rem(spacings.s),
        cursor: "pointer",
      },
      {},
      {
        marginBottom: 0,
      }
    ),
  },
  detailsSection: {
    backgroundColor: colors.gray10,
    padding: rem(spacings.m),
    paddingBottom: 0,
    "& > *:last-child": {
      marginBottom: "0 !important",
    },
  },
  detailsSectionH4: typography.h4,
  detailsSectionP: {
    ...customTypography(
      {
        ...typography.textSmall,
      },
      { marginBottom: rem(spacings.s) },
      { marginBottom: rem(spacings.sam) },
      { marginBottom: rem(spacings.m) }
    ),
  },
  modalButtons: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    padding: rem(spacings.sam),
    backgroundColor: colors.gray10,
    ...onBreakpoint("sm", {
      padding: rem(spacings.m),
    }),
  },
  modalButtonsButton: {
    flexBasis: "100%",
    justifyContent: "center",
    marginLeft: 0,
    marginBottom: rem(spacings.s),
    "&:last-child": {
      marginBottom: 0,
    },
    ...onBreakpoint("sm", {
      flexBasis: "auto",
      marginLeft: rem(spacings.sam),
      marginBottom: 0,
      "&:first-child": {
        marginLeft: 0,
      },
    }),
  },
  icon: FAIconStyles({
    width: 24,
    height: 24,
  }),
});

export function PrivacyOverlay(
  props: {
    isVisible: boolean;
    close: () => void;
  } & ConsentBlockData
) {
  const styles = useStyles();
  const privacyContext = useContext(PrivacyContext);

  const [consentStateMap, setConsentStateMap] = useState<Map<string, boolean>>(
    new Map()
  );
  const [showDetails, setShowDetails] = useState(false);

  if (!privacyContext || !privacyContext.isInitialized) return null;

  const { allowRequired, allowSelected, allowAll } = privacyContext;

  const actionMap = new Map<ConsentButton["function"], () => void>([
    ["accept_necessary", allowRequired],
    ["save_selection", () => allowSelected(consentStateMap)],
    ["accept_all", allowAll],
  ]);
  const privacyPageLink = props.privacyPage.value?.[0];

  useEffect(() => {
    setConsentStateMap(
      new Map(
        privacyContext
          ?.getAllConsents()
          .map((consent) => [consent.consentId || "", consent.status]) || []
      )
    );
  }, [privacyContext]);

  return (
    (props.isVisible && (
      <div className={styles.modal} data-testid="PrivacyOverlay">
        <Container>
          <Row>
            <Col sm={10} smStart={1} md={8} mdStart={2}>
              <div className={styles.modalBox}>
                <div className={styles.modalMain}>
                  <h2 className={styles.headline}>
                    <RenderProperty value={props.consentTitle} />
                  </h2>
                  <div className={styles.textSection}>
                    <RenderProperty
                      className={styles.text}
                      value={props.consentDescription}
                    />
                    <button
                      onClick={() => setShowDetails(!showDetails)}
                      className={styles.textSectionDetails}
                      data-testid="PrivacyOverlayShowDetails"
                    >
                      <span className={styles.ctaSectionSpan}>
                        <LocalizedLabel section="Contact" label="ShowDetails" />
                      </span>
                      {showDetails ? (
                        <FAIcon icon="chevron-up" className={styles.icon} />
                      ) : (
                        <FAIcon icon="chevron-down" className={styles.icon} />
                      )}
                    </button>
                  </div>

                  <div className={styles.checkboxSection}>
                    {props.consentOptions.value?.map((consent) => (
                      <Checkbox
                        key={consent.consentId || ""}
                        label={consent.optionTitle || ""}
                        disabled={!!consent.isRequired}
                        checked={
                          !!consent.isRequired ||
                          !!consentStateMap.get(consent.consentId || "")
                        }
                        onCheck={() =>
                          setConsentStateMap((s) =>
                            new Map(s).set(
                              consent.consentId || "",
                              !s.get(consent.consentId || "")
                            )
                          )
                        }
                        className={styles.checkboxSectionDiv}
                      />
                    ))}
                  </div>

                  <div className={styles.ctaSection}>
                    <Button
                      type="link"
                      href={privacyPageLink?.href}
                      target={privacyPageLink?.target}
                      title={privacyPageLink?.title}
                    >
                      {privacyPageLink?.text}
                    </Button>
                    <button
                      onClick={() => setShowDetails(!showDetails)}
                      className={styles.ctaSectionDiv}
                      data-testid="PrivacyOverlayShowDetails"
                    >
                      <span className={styles.ctaSectionSpan}>
                        <LocalizedLabel section="Contact" label="ShowDetails" />
                      </span>
                      {showDetails ? (
                        <FAIcon icon="chevron-up" className={styles.icon} />
                      ) : (
                        <FAIcon icon="chevron-down" className={styles.icon} />
                      )}
                    </button>
                  </div>
                </div>
                {showDetails && (
                  <div className={styles.detailsSection}>
                    {props.consentOptions.value?.map((consent) => (
                      <React.Fragment key={consent.consentId || ""}>
                        <h4 className={styles.detailsSectionH4}>
                          {consent.optionTitle}
                        </h4>
                        <p className={styles.detailsSectionP}>
                          {consent.optionDescription}
                        </p>
                      </React.Fragment>
                    ))}
                  </div>
                )}
                <div className={styles.modalButtons}>
                  {props.overlayButtons.value?.map((button, idx) => (
                    <Button
                      key={idx}
                      type={button.buttonType || "secondary"}
                      small
                      onClick={() => {
                        props.close();
                        actionMap.get(button.function)?.();
                      }}
                      className={styles.modalButtonsButton}
                    >
                      {button.label}
                    </Button>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )) ||
    null
  );
}
