import React from "react";
import { ContactExpertBlockProps } from "../../../models/content/ContactExpertBlockData";
import { ContactExpertComponent } from "./ContactExpertComponent";

export function ContactExpertBlock(props: ContactExpertBlockProps) {
  return (
    <ContactExpertComponent
      {...props.data}
      description={props.data.descriptionRichText}
    />
  );
}
