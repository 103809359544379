import React, { useContext, useState } from "react";
import {
  Col,
  Container,
  onBefore,
  onBreakpoint,
  rem,
  Row,
} from "../../../basics/layout";
import { GlobalContentStoreContext } from "../../../hooks/GlobalContentStore";
import { ContactCardsBlock } from "../ContactListBlock/ContactListBlock";
import {
  isDeviceResOrHigher,
  isDeviceResOrLower,
  useDeviceType,
} from "../../../hooks/useDeviceType";
import { colors } from "../../../basics/colors";
import { typography } from "../../../basics/typography";
import { spacings } from "../../../basics/spacings";
import { createUseStyles } from "react-jss";
import {
  ContactsButtonProperty,
  Property,
  StringProperty,
} from "../../../models/Property";
import ContentLink from "../../../models/ContentLink";
import { AnimatedRenderProperty } from "../../../views/RenderProperty";
import { ButtonWrapper } from "../../Button/Button";
import { ContactExpertCard } from "./ContactExpertCard";
import { ContactListOverlay } from "../ContactListBlock/ContactListOverlay";
import cn from "classnames";
import { AnimatedRevealBlock } from "../../AnimatedReveal/AnimatedReveal";
import { createSlider } from "../../Slider/Slider";

const contactExpertStyles = createUseStyles({
  contactExperts: {
    textAlign: ({ isSingleElement }) => (isSingleElement ? "left" : "center"),
    backgroundColor: ({ theme, isFeaturedPage }) =>
      isFeaturedPage ? theme : undefined,
    "& $title, & $tag, & $description, & $description p, & $description li": {
      color: ({ isLight }) => (isLight ? colors.gray100 : colors.white),
    },
    "& $description a": {
      color: ({ isLight }) => (isLight ? colors.gray100 : colors.white),
      textDecoration: ({ isLight }) => (isLight ? undefined : "underline"),
    },
    "&$isSingleElement": {
      ...onBefore("sm", {
        textAlign: "center",
      }),
    },
  },
  contactExpertsContainer: {
    position: "relative",
    marginBottom: rem(spacings.m),
    paddingTop: rem(spacings.m),
    paddingBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
      paddingTop: rem(spacings.l),
      paddingBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
      paddingTop: rem(spacings.xxl),
      paddingBottom: rem(spacings.xxl),
    }),
    backgroundColor: ({ theme }) => theme,
  },
  contactList: {
    marginBottom: rem(spacings.s),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.sam),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xl),
    }),
  },
  infoSection: {
    marginRight: ({ isSingleElement }) =>
      isSingleElement ? rem(spacings.s) : undefined,
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.s),
    }),
  },
  title: typography.h2,
  tag: {
    ...typography.caption,
    marginBottom: rem(spacings.s),
  },
  description: typography.textDefault,
  findMoreTop: {
    display: "none",
    color: ({ isLight }) => (isLight ? colors.gray100 : colors.white),
    "&$isSingleElement": {
      display: "inline-flex",
      ...onBefore("sm", {
        display: "none",
      }),
    },
    "& svg": {
      color: ({ isLight }) => (isLight ? colors.gray100 : colors.white),
    },
  },
  findMoreBottom: {
    color: ({ isLight }) => (isLight ? colors.gray100 : colors.white),
    "&$isSingleElement": {
      display: "none",
      ...onBefore("sm", {
        display: "inline-flex",
        marginTop: rem(spacings.l),
      }),
    },
    "& svg": {
      color: ({ isLight }) => (isLight ? colors.gray100 : colors.white),
    },
  },
  cardCarousel: {
    margin: "0 auto",
    "& .slick-list": {
      padding: `${spacings.xs} 0`,
    },
    "& .slick-track": {
      display: "flex",
      marginBottom: rem(spacings.sam),
    },
    "& .slick-slide": {
      height: "auto",
      "& > div": {
        height: "100%",
        margin: `0 ${spacings.s}`,
      },
    },
    "& .slick-arrow": {
      width: 48,
      height: 48,
      "&:before": {
        color: ({ isLight }) => (isLight ? colors.gray100 : colors.white),
        fontSize: "3rem",
      },
    },
    "& .slick-prev": {
      left: -70,
      "&:before": {
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f053"',
      },
    },
    "& .slick-next": {
      right: -70,
      "&:before": {
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f054"',
      },
    },
    "& .slick-dots": {
      "& button:before, & .slick-active button:before": {
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f111"',
        fontWeight: 900,
        fontSize: "1rem",
        color: ({ isLight }) => (isLight ? colors.gray100 : colors.white),
      },
    },
    "& + $findMoreBottom": {
      marginTop: rem(spacings.xl),
    },
  },
  isSingleElement: {},
  fullHeight: {
    height: "100%",
  },
  flexCenter: {
    ...onBreakpoint("sm", {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  },
});

export function ContactExpertComponent(props: {
  title: StringProperty;
  description: StringProperty;
  tagLine: StringProperty;
  contact?: Property<Array<ContentLink>>;
  colorSchema?: StringProperty;
  buttons: ContactsButtonProperty;
  isFeaturedPage?: boolean;
}) {
  const {
    title,
    description,
    tagLine,
    contact,
    colorSchema,
    buttons,
    isFeaturedPage,
  } = props;
  const { getContacts } = useContext(GlobalContentStoreContext);

  const contacts = getContacts();

  function pickTheme() {
    switch (colorSchema?.value) {
      case "blue":
        return colors.darkBlue90;
      case "green":
        return colors.darkGreen70;
      case "red":
        return colors.darkRed80;
      case "orange":
        return colors.alertsYellow40;
      default:
        return colors.gray20;
    }
  }

  const isLightTheme =
    colorSchema?.value === "default" || colorSchema?.value === "orange";

  const contactsWithFallback = (contact?.expandedValue ||
    contacts?.items) as Array<ContactCardsBlock>;

  const contactsAmount = contactsWithFallback?.length;

  const isSingleElement = contactsWithFallback?.length === 1;

  const styles = contactExpertStyles({
    theme: isFeaturedPage ? colors.darkBlue90 : pickTheme(),
    isLight: isLightTheme,
    isSingleElement: isSingleElement,
    isFeaturedPage: isFeaturedPage,
  });

  const [modalOpened, setModalOpened] = useState(false);
  const [slide, setSlide] = useState<number>(0);

  const closeModal = () => {
    setModalOpened(false);
  };

  function openModal(slide: number) {
    setModalOpened(true);
    setSlide(slide);
  }

  const deviceType = useDeviceType();

  const maxMobile = isDeviceResOrLower(deviceType, "mobile");

  const maxTablet = isDeviceResOrLower(deviceType, "tablet");

  const minLargeDesktop = isDeviceResOrHigher(deviceType, "largeDesktop");

  const onlyTablet =
    isDeviceResOrHigher(deviceType, "tablet") &&
    isDeviceResOrLower(deviceType, "tablet");

  const onlySmallDesktop =
    isDeviceResOrHigher(deviceType, "desktop") &&
    isDeviceResOrLower(deviceType, "desktop");

  const mobileStyles =
    (maxMobile && contactsAmount > 1) || (maxTablet && contactsAmount > 2);

  const carouselSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: minLargeDesktop ? 3 : 2,
    slidesToScroll: 1,
    arrows: true,
  };
  const Slider = createSlider();
  return (
    <>
      <Container
        className={cn(
          styles.contactExperts,
          isSingleElement && styles.isSingleElement
        )}
        isFluid={isFeaturedPage}
      >
        <Container className={styles.contactExpertsContainer}>
          <Row>
            <Col
              sm={isSingleElement ? 6 : 8}
              smStart={isSingleElement ? 1 : 2}
              md={isSingleElement ? 5 : 6}
              mdStart={isSingleElement ? 2 : 3}
              className={styles.flexCenter}
            >
              <div className={styles.infoSection}>
                <AnimatedRenderProperty
                  value={tagLine}
                  className={styles.tag}
                />
                <h2 className={styles.title}>
                  <AnimatedRenderProperty value={title} revealDelay={200} />
                </h2>

                {isSingleElement && (
                  <AnimatedRenderProperty
                    value={description}
                    className={styles.description}
                    revealDelay={400}
                  />
                )}
                <AnimatedRevealBlock revealDelay={600}>
                  {buttons.value?.map((b, idx) => (
                    <ButtonWrapper
                      {...b}
                      key={idx}
                      className={cn(
                        styles.findMoreTop,
                        isSingleElement && styles.isSingleElement
                      )}
                      buttonType="link"
                    />
                  ))}
                </AnimatedRevealBlock>
              </div>
            </Col>

            <Col
              sm={isSingleElement ? 4 : 8}
              smStart={isSingleElement ? null : 2}
              md={isSingleElement ? 4 : 10}
              mdStart={isSingleElement ? null : 1}
              lg={isSingleElement ? 3 : 10}
              style={{ width: "100%" /* due to FF */ }}
            >
              {(onlySmallDesktop && contactsAmount > 2) ||
              (minLargeDesktop && contactsAmount > 3) ? (
                <>
                  {Slider && (
                    <Slider
                      {...carouselSettings}
                      className={styles.cardCarousel}
                    >
                      {contactsWithFallback?.map((c, idx) => (
                        <AnimatedRevealBlock
                          key={idx}
                          className={styles.fullHeight}
                          revealDelay={
                            idx >= carouselSettings.slidesToShow
                              ? -1
                              : 200 * idx
                          }
                        >
                          <ContactExpertCard
                            {...c}
                            openModal={openModal}
                            itemNumber={idx}
                          />
                        </AnimatedRevealBlock>
                      ))}
                    </Slider>
                  )}
                </>
              ) : (
                <Row
                  className={cn(
                    isSingleElement ? undefined : styles.contactList
                  )}
                >
                  {contactsWithFallback?.map((c, idx) => (
                    <Col
                      sm={contactsAmount === 2 ? 6 : 12}
                      md={isSingleElement ? 12 : 5}
                      mdStart={idx === 0 && contactsAmount === 2 ? 1 : null}
                      lg={isSingleElement ? 12 : 4}
                      lgStart={idx === 0 && contactsAmount === 2 ? 2 : null}
                      key={idx}
                    >
                      <AnimatedRevealBlock
                        movement={false}
                        revealDelay={200 + 200 * idx}
                        className={styles.fullHeight}
                      >
                        <ContactExpertCard
                          {...c}
                          isSingleElement={isSingleElement}
                          moreThanTwoItems={contactsAmount > 2}
                          openModal={openModal}
                          mobileStyles={mobileStyles}
                          onlyTablet={onlyTablet}
                          itemNumber={idx}
                        />
                      </AnimatedRevealBlock>
                    </Col>
                  ))}
                </Row>
              )}
              <AnimatedRevealBlock
                revealDelay={600}
                className={cn(
                  styles.findMoreBottom,
                  isSingleElement && styles.isSingleElement
                )}
              >
                {buttons.value?.map((b, idx) => (
                  <ButtonWrapper
                    {...b}
                    key={idx}
                    className={styles.findMoreBottom}
                    buttonType="link"
                  />
                ))}
              </AnimatedRevealBlock>
            </Col>
          </Row>
        </Container>
      </Container>
      <ContactListOverlay
        contacts={contactsWithFallback}
        modalOpened={modalOpened}
        closeModal={closeModal}
        initialSlide={slide}
      />
    </>
  );
}
