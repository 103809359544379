import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import { rem } from "../../basics/layout";
import { customTypography, typography } from "../../basics/typography";
import { spacings } from "../../basics/spacings";
import cn from "classnames";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";

const useStyles = createUseStyles({
  breadcrumbs: {
    padding: 0,
    marginTop: 0,
    marginBottom: rem(spacings.s),
    display: "flex",
    flexWrap: "wrap",
    "& li": {
      display: "flex",
      alignItems: "center",
      listStyle: "none",
      marginBottom: rem(spacings.s),
      "&:last-child": {
        "& svg": {
          display: "none",
        },
      },
      "& a": {
        ...customTypography(
          {
            ...typography.textDefault,
            marginBottom: 0,
          },
          {},
          {
            marginBottom: 0,
          }
        ),

        "&:hover": {
          textDecoration: "underline",
        },
      },

      "& svg": {
        margin: `0 ${spacings.s}`,
      },
    },
  },
  icon: FAIconStyles({
    width: 24,
    height: 24,
  }),
});

export function BreadcrumbComponent(props: { className?: string }) {
  const { getBreadcrumbs } = useContext(GlobalContentStoreContext);
  const styles = useStyles();
  const breadcrumbs = getBreadcrumbs();

  return breadcrumbs ? (
    <ul className={cn(styles.breadcrumbs, props.className)}>
      {breadcrumbs.items.map((b, idx) => (
        <li key={idx}>
          <a href={b.url}>{b.label}</a>
          <FAIcon icon="long-arrow-right" className={styles.icon} />
        </li>
      ))}
    </ul>
  ) : null;
}
