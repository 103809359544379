import React, { useContext, useEffect, useState } from "react";
import { Col, onBefore, onBreakpoint, rem } from "../../../basics/layout";
import { createUseStyles } from "react-jss";
import {
  RenderProperty,
  showPropertyOnEdit,
} from "../../../views/RenderProperty";
import { Button, isExternal } from "../../Button/Button";
import { LocalizedLabel } from "../../../hooks/LocalizationContext";
import { colors } from "../../../basics/colors";
import { spacings } from "../../../basics/spacings";
import { customTypography, typography } from "../../../basics/typography";
import ContactLocationBlockData from "../../../models/content/ContactLocationBlockData";
import cn from "classnames";
import { FAIcon, FAIconStyles } from "../../FAIcon/FAIcon";
import { AnimatedRevealBlock } from "../../AnimatedReveal/AnimatedReveal";
import { GlobalContentStoreContext } from "../../../hooks/GlobalContentStore";
import StartPageData from "../../../models/content/StartPageData";
import { useTracking } from "../../../hooks/useTracking";

export const locationCardStyles = createUseStyles({
  contactCol: {
    marginBottom: rem(spacings.m),
  },
  contact: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    borderRadius: 10,
    cursor: "pointer",
    filter: "drop-shadow(0px 1px 7px rgba(0,0,0,.1))",
    transition: "filter 0.3s ease",
    "&:hover": {
      filter: "drop-shadow(0px 1px 7px rgba(0,0,0,.2))",
      transition: "filter 0.3s ease",
    },
  },

  bioSection: {
    display: "flex",
    backgroundColor: colors.gray10,
    minHeight: 80,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    ...onBreakpoint("sm", {
      minHeight: 96,
    }),
    ...onBreakpoint("md", {
      minHeight: 128,
    }),
  },
  nameWrapper: {
    marginRight: "auto",
  },
  name: customTypography(
    {
      ...typography.h4,
      marginLeft: rem(spacings.s),
    },
    { marginTop: rem(spacings.s) },
    {},
    { margin: `${rem(spacings.m)} 0 ${rem(spacings.s)} ${spacings.m}` }
  ),

  nameAdditionalMargin: {
    marginBottom: rem(spacings.sam),
  },

  photo: {
    "& img": {
      width: 88,
      height: 88,
      borderTopRightRadius: 10,
      marginLeft: rem(spacings.s),
      objectFit: "cover",
      ...onBreakpoint("sm", {
        width: 114,
        height: 114,
      }),
      ...onBreakpoint("md", {
        width: 146,
        height: 146,
      }),
    },
  },
  infoSection: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
    padding: `${spacings.s} ${spacings.s} ${spacings.m}`,
    ...onBreakpoint("md", {
      padding: `${spacings.sam} ${spacings.m} ${spacings.l}`,
    }),
  },
  role: {
    ...customTypography(
      {
        ...typography.textDefault,
        color: colors.gray60,
        marginLeft: rem(spacings.s),
        marginBottom: rem(spacings.s),
      },
      {},
      {},
      { marginLeft: rem(spacings.m), marginBottom: rem(spacings.l) }
    ),
  },
  address: {
    ...typography.textDefault,
    marginBottom: 0,
    paddingRight: rem(spacings.s),
    flexBasis: (inContactList: boolean) => (inContactList ? "50%" : "35%"),
    ...onBefore("sm", {
      flexBasis: () => "auto",
      paddingRight: 0,
    }),
    "& p": customTypography({
      marginBottom: 0,
    }),
    "& span:last-child": {
      display: "block",
    },
  },

  cityWrapper: {
    display: "flex",
  },

  contactNumbers: {
    ...typography.textDefault,
    marginBottom: 0,
    marginRight: "auto",
    paddingRight: rem(spacings.s),
    flexBasis: (inContactList: boolean) => (inContactList ? "50%" : "auto"),
    display: "flex",
    flexDirection: "column",
    ...onBefore("sm", {
      display: "none",
    }),
    "& a": {
      color: colors.gray100,
      paddingLeft: rem(spacings.xxs),
    },
    "& a:hover": {
      color: colors.lightGreen40,
      textDecoration: "underline",
    },
  },

  ctaButtons: {
    display: "flex",
    flexWrap: "wrap",
    flexBasis: (inContactList: boolean) => (inContactList ? "100%" : "auto"),
    justifyContent: "space-between",
    alignItems: "flex-end",
    ...onBefore("sm", {
      flexBasis: () => "100%",
    }),
    "& > button, & > a": {
      marginTop: rem(spacings.s),
      ...onBefore("sm", {
        marginRight: rem(spacings.s),
      }),
    },
  },

  iconWrapperMobile: {
    marginTop: rem(spacings.s),
    marginLeft: "auto",
    display: "flex",
    ...onBreakpoint("sm", {
      display: "none",
    }),
    "& button, & a": {
      backgroundColor: colors.gray20,
      "&$primaryCta": {
        backgroundColor: colors.webGreen20,
        color: colors.webGreen40,
      },
      padding: "11px 12px",
      borderRadius: "50%",
      marginRight: rem(spacings.xs),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  primaryCta: {},
  icon: {
    ...FAIconStyles({
      color: colors.gray90,
      width: 24,
      height: 24,
      cursor: "pointer",
    }),
  },

  accessibilityText: typography.visuallyHidden,

  //for overlay only
  infoSectionOverlay: {
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  contacts: {
    ...typography.textDefault,
    marginTop: rem(spacings.m),
    marginRight: "auto",
    marginBottom: 0,
    display: "flex",
    ...onBefore("sm", {
      marginTop: rem(spacings.s),
    }),
  },
  contactLabels: {
    display: "flex",
    color: colors.gray60,
    flexDirection: "column",
  },
  addressOverlay: {
    flexBasis: () => "100%",
    flexShrink: 2,
  },
  contactNumbersOverlay: {
    display: "flex",
    flexBasis: () => "100%",
    "& a": {
      color: colors.gray100,
    },
  },
  websiteAddress: {
    display: "none",
  },
});

export function LocationCard(
  props: ContactLocationBlockData & {
    itemNumber: number;
    inContactList?: boolean;
    openModal: (slide: number) => void;
  }
) {
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );

  const tracking = useTracking();

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  const {
    locationName,
    photo,
    role,
    address,
    email,
    phoneNumber,
    faxNumber,
    website,
    itemNumber,
    inContactList,
  } = props;

  const cardPhoto =
    photo.value?.urlSmall && photo.value?.urlMedium && photo.value?.urlLarge
      ? photo
      : startPageData?.contactLocationImageFallback;

  const styles = locationCardStyles(inContactList);

  function openModalFromCard(e: any, itemNumber: number) {
    if (
      e.target.tagName !== "A" &&
      e.target.tagName !== "BUTTON" &&
      e.target.tagName !== "SPAN"
    )
      return props.openModal(itemNumber);
  }

  const websiteLink = website?.value?.[0];

  function openModal(e: any) {
    tracking.event(
      "Contacts",
      `Open location contact${websiteLink ? " link" : ""}`,
      locationName?.value || "Missing location contact name",
      websiteLink?.href ? websiteLink?.href : ""
    );

    openModalFromCard(e, itemNumber);
  }

  return (
    <Col
      sm={inContactList ? 8 : 12}
      md={inContactList ? 6 : 9}
      smStart={inContactList ? 2 : null}
      mdStart={inContactList ? 3 : 2}
      className={styles.contactCol}
    >
      <AnimatedRevealBlock revealDelay={itemNumber * 100}>
        <article className={styles.contact} onClick={(e) => openModal(e)}>
          <div className={styles.bioSection}>
            <div className={styles.nameWrapper}>
              <h4
                className={cn(
                  styles.name,
                  role?.value === "" && styles.nameAdditionalMargin
                )}
              >
                <RenderProperty value={locationName} />
              </h4>
              {showPropertyOnEdit(role) && (
                <RenderProperty value={role} className={styles.role} />
              )}
            </div>
            <RenderProperty value={cardPhoto} className={styles.photo} />
          </div>
          <div className={styles.infoSection}>
            <div className={styles.address}>
              <RenderProperty value={address} />
            </div>
            <div className={styles.contactNumbers}>
              <a href={`mailto:${email.value}`}>
                <span className={styles.accessibilityText}>
                  Write an email to {email.value}
                </span>
                <RenderProperty value={email} />
              </a>
              <a href={`tel: ${phoneNumber.value}`}>
                <span className={styles.accessibilityText}>
                  Call to a phone number {phoneNumber.value}
                </span>
                <RenderProperty value={phoneNumber} />
              </a>
              {showPropertyOnEdit(faxNumber) && (
                <a href={`fax: ${faxNumber.value}`}>
                  <span className={styles.accessibilityText}>
                    Fax to a number {faxNumber.value}
                  </span>
                  <RenderProperty value={faxNumber} />
                </a>
              )}
            </div>

            <div className={styles.ctaButtons}>
              {websiteLink ? (
                <Button
                  href={websiteLink.href}
                  type={isExternal(websiteLink.href) ? "secondary" : "primary"}
                  target={isExternal(websiteLink.href) ? "_blank" : "_self"}
                >
                  <span className={styles.accessibilityText}>
                    Open a website {websiteLink.href}
                  </span>
                  {isExternal(websiteLink.href) ? (
                    <LocalizedLabel section="Contact" label="VisitWebsite" />
                  ) : (
                    <LocalizedLabel section="Contact" label="ShowDetails" />
                  )}
                </Button>
              ) : (
                <Button type="link" onClick={() => props.openModal(itemNumber)}>
                  <span className={styles.accessibilityText}>
                    Open contact details
                  </span>
                  <LocalizedLabel section="Global" label="ShowMore" />
                </Button>
              )}

              <div className={styles.iconWrapperMobile}>
                <a href={`mailto:${email.value}`}>
                  <span className={styles.accessibilityText}>
                    Write an email to {email.value}
                  </span>
                  <FAIcon icon="envelope" className={styles.icon} />
                </a>
                <a href={`tel: ${phoneNumber.value}`}>
                  <span className={styles.accessibilityText}>
                    Call to a phone number {phoneNumber.value}
                  </span>
                  <FAIcon icon="phone" className={styles.icon} />
                </a>
              </div>
            </div>
          </div>
        </article>
      </AnimatedRevealBlock>
    </Col>
  );
}
