import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { colors } from "../../../basics/colors";
import { onBefore, onBreakpoint, rem } from "../../../basics/layout";
import { spacings } from "../../../basics/spacings";
import { customTypography, typography } from "../../../basics/typography";
import { FAIcon, FAIconStyles } from "../../FAIcon/FAIcon";
import { ContactCardsBlock } from "../ContactListBlock/ContactListBlock";
import {
  RenderProperty,
  showPropertyOnEdit,
} from "../../../views/RenderProperty";
import { Button } from "../../Button/Button";
import { LocalizedLabel } from "../../../hooks/LocalizationContext";
import { GlobalContentStoreContext } from "../../../hooks/GlobalContentStore";
import StartPageData from "../../../models/content/StartPageData";
import { useTracking } from "../../../hooks/useTracking";

const contactCardExpertStyles = createUseStyles({
  card: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    position: "relative",
    marginLeft: ({ isSingleElement }) =>
      isSingleElement ? rem(spacings.s) : undefined,
    textAlign: "center",
    height: "100%",
    borderRadius: 10,
    ...onBefore("sm", {
      maxWidth: 346,
      marginLeft: "auto !important",
      marginRight: "auto",
    }),
    "&:before": {
      display: ({ isPhoto }) => (isPhoto ? "block" : "none"),
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: 240,
      backgroundColor: colors.gray30,
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
    },
    ...onBefore("md", {
      "&:before": {
        display: () => "none",
      },
    }),
  },

  landscaped: {
    "&:before": {
      display: "none",
    },
    "& $photo": {
      margin: 0,
      marginBottom: rem(spacings.xl.add(spacings.xs)),
      maxWidth: "unset",
      ...onBefore("md", {
        marginBottom: rem(spacings.m),
      }),
      "& img": {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },

  onlyTablet: {},

  cardMobile: {
    height: "auto",
    marginBottom: rem(spacings.sam),
    flexDirection: "row",
    textAlign: "left",
    flexWrap: "wrap",
    padding: `${spacings.s} ${spacings.s} ${spacings.m}`,
    maxWidth: "unset",
    "& > div:first-child": {
      flexShrink: 0,
    },
    "& $photo": {
      maxWidth: 120,
      marginRight: rem(spacings.s),
      ...onBreakpoint("sm", {
        maxWidth: 156,
        margin: `${spacings.m}`,
      }),
    },

    "& $bioSection": {
      width: "100%",
      height: "auto",
      alignItems: "unset",
      paddingTop: 0,
      ...onBreakpoint("sm", {
        padding: `${spacings.m} ${spacings.m} ${spacings.m} 0`,
      }),
    },
    "& $ctaButtons": {
      marginTop: "unset",
      "& button": {
        marginTop: rem(spacings.sam),
      },
    },
    "&$onlyTablet": {
      padding: 0,
      flexWrap: "unset",
      "& $bioSection": {
        paddingLeft: ({ isPhoto }) => (isPhoto ? undefined : rem(spacings.m)),
      },
    },
  },
  singleCard: {
    ...onBefore("sm", {
      paddingBottom: rem(spacings.m),
    }),
    "& $photo": {
      ...onBefore("sm", {
        maxWidth: 180,
        margin: `${spacings.m} auto 0`,
      }),
    },
    "& $bioSection": {
      paddingTop: ({ isPhoto }) => (isPhoto ? undefined : rem(spacings.m)),
      padding: `0 ${spacings.m} 0 ${spacings.m}`,
      ...onBreakpoint("sm", {
        padding: `0 ${spacings.s} ${spacings.m}`,
      }),
    },
  },
  photoWrapper: {
    ...onBreakpoint("md", {
      padding: `0 ${spacings.m}`,
    }),
  },
  photo: {
    position: "relative",
    ...onBreakpoint("sm", {
      margin: `${spacings.m}`,
    }),
    ...onBreakpoint("md", {
      margin: `${spacings.m} auto ${spacings.sam}`,
      maxWidth: 240,
    }),
    "& img": {
      borderRadius: 10,
      objectFit: "cover",
      ...onBreakpoint("md", {
        height: 240,
      }),
    },
  },
  bioSection: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    flexBasis: ({ isPhoto }) => (isPhoto ? "calc(100% - 136px)" : undefined),
    paddingTop: ({ isPhoto }) => (isPhoto ? undefined : rem(spacings.m)),
    ...onBreakpoint("sm", {
      padding: `0 ${spacings.s} ${spacings.m} ${spacings.s} `,
      flexBasis: "unset",
    }),
    ...onBreakpoint("md", {
      padding: `0 ${spacings.m} ${spacings.m}`,
    }),
  },
  name: customTypography(
    {
      ...typography.h4,
    },
    {
      marginBottom: rem(spacings.s),
    },
    {
      marginBottom: rem(spacings.s),
    }
  ),
  role: {
    ...customTypography(
      {
        ...typography.textDefault,
        color: colors.gray60,
      },
      {},
      {
        marginBottom: 0,
      }
    ),
  },
  jobTitle: {
    ...customTypography(
      {
        ...typography.textDefault,
      },
      {},
      {
        marginBottom: rem(spacings.xxs),
      }
    ),
  },
  subtitle: {
    ...customTypography(
      {
        ...typography.textDefault,
      },
      {},
      {
        marginBottom: 0,
      }
    ),
  },
  ctaButtons: {
    marginTop: "auto",
    ...onBefore("sm", {
      display: "none",
    }),
    "& button": {
      marginTop: rem(spacings.m),
    },
  },
  iconsMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: ({ isSingleElement }) =>
      isSingleElement ? "center" : "flex-end",
    ...onBreakpoint("sm", {
      display: "none",
    }),
    "& div": {
      display: "flex",
    },
    "& a": {
      backgroundColor: colors.gray20,
      marginTop: rem(spacings.s),
      padding: "12px 12px 9px 12px",
      borderRadius: "50%",
      marginRight: rem(spacings.xs),
      "&:last-child": {
        marginRight: 0,
      },
    },
    "& button": {
      marginTop: rem(spacings.sam),
    },
  },
  buttonMobile: {
    flexBasis: "100%",
    textAlign: ({ isSingleElement }) => (isSingleElement ? "center" : "right"),
    marginTop: rem(spacings.sam),
    ...onBreakpoint("sm", {
      display: "none",
    }),
  },
  icon: {
    ...FAIconStyles({
      color: colors.gray90,
      width: 24,
      height: 24,
      cursor: "pointer",
    }),
  },
  accessibilityText: typography.visuallyHidden,
});

export function ContactExpertCard(
  props: ContactCardsBlock & {
    isSingleElement?: boolean;
    moreThanTwoItems?: boolean;
    openModal: (slide: number) => void;
    itemNumber: number;
    mobileStyles?: boolean;
    onlyTablet?: boolean;
  }
) {
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );

  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );

  const tracking = useTracking();

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  const {
    firstName,
    lastName,
    title,
    photo,
    role,
    jobTitle,
    subTitle,
    email,
    phoneNumber,
  } = props;

  const cardPhoto =
    photo.value?.urlSmall && photo.value?.urlMedium && photo.value?.urlLarge
      ? photo
      : startPageData?.contactPersonImageFallback;

  const isPhoto =
    photo.value?.urlMedium !== "" ||
    startPageData?.contactPersonImageFallback.value?.urlMedium !== "";

  const styles = contactCardExpertStyles({
    isSingleElement: props.isSingleElement,
    isPhoto: isPhoto,
    onlyTablet: props.onlyTablet,
  });

  const isLandscape = props.contentType[1] === "ContactGenericBlock";

  const trackingFunction = () =>
    tracking.event(
      "Contacts",
      "Open expert contact",
      `${firstName?.value || ""} ${lastName?.value || ""} ${title?.value || ""}`
    );

  return (
    <article
      className={cn(
        styles.card,
        !props.mobileStyles && isLandscape && styles.landscaped,
        props.mobileStyles && styles.cardMobile,
        props.isSingleElement && styles.singleCard,
        props.onlyTablet && styles.onlyTablet
      )}
    >
      {cardPhoto && (
        <div className={cn(!isLandscape && styles.photoWrapper)}>
          <RenderProperty value={cardPhoto} className={styles.photo} />
        </div>
      )}
      <div className={styles.bioSection}>
        <h4 className={styles.name}>
          {firstName && <RenderProperty value={firstName} />}{" "}
          {lastName && <RenderProperty value={lastName} />}
          {title && <RenderProperty value={title} />}
        </h4>
        {showPropertyOnEdit(jobTitle) && (
          <RenderProperty value={jobTitle} className={styles.jobTitle} />
        )}
        {showPropertyOnEdit(role) && (
          <RenderProperty value={role} className={styles.role} />
        )}
        {showPropertyOnEdit(subTitle) && (
          <RenderProperty value={subTitle} className={styles.subtitle} />
        )}
        <div className={styles.ctaButtons}>
          <Button
            onClick={() => props.openModal(props.itemNumber)}
            trackingFunction={trackingFunction}
            dataTestid="ContactExpertCardButton"
          >
            <LocalizedLabel section="Contact" label="Contact" />
          </Button>
        </div>

        <div className={styles.iconsMobile}>
          <div>
            <a href={`mailto:${email.value}`}>
              <span className={styles.accessibilityText}>
                Write an email to {email.value}
              </span>
              <FAIcon icon="envelope" className={styles.icon} />
            </a>
            <a href={`tel: ${phoneNumber.value}`}>
              <span className={styles.accessibilityText}>
                Call to a phone number {phoneNumber.value}
              </span>
              <FAIcon icon="phone" className={styles.icon} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.buttonMobile}>
        <Button
          type="link"
          onClick={() => props.openModal(props.itemNumber)}
          trackingFunction={trackingFunction}
          dataTestid="ContactExpertCardButton"
        >
          <LocalizedLabel section="Contact" label="ShowDetails" />
        </Button>
      </div>
    </article>
  );
}
