import React from "react";
import { rem } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { customTypography, typography } from "../../basics/typography";
import cn from "classnames";

const useStyles = createUseStyles({
  element: {
    marginBottom: rem(spacings.s),
    display: "flex",
    position: "relative",
    "& input:checked + $customRadiobox::after": {
      opacity: 1,
    },
    "& input:focus + $customRadiobox": {
      outline: `2px solid ${colors.focusOutline}`,
    },
  },
  customRadiobox: {
    content: '""',
    cursor: "pointer",
    height: 32,
    width: 32,
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: colors.white,
    border: `2px solid ${colors.gray90}`,
    borderRadius: "100%",
    "&:after": {
      position: "absolute",
      content: '""',
      cursor: "pointer",
      border: `2px solid ${colors.gray90}`,
      borderRadius: "100%",
      height: 32,
      width: 32,
      left: -2,
      top: -2,
      transition: "all .1s ease-out",
      background: colors.webGreen40,
      boxShadow: `inset 0 0 0 6px ${colors.white}`,
      opacity: 0,
    },
  },
  label: {
    ...customTypography(
      {
        ...typography.textDefault,
        marginLeft: rem(spacings.sam),
        marginBottom: 0,
        cursor: "pointer",
      },
      {},
      {
        marginBottom: 0,
      }
    ),
  },
  strong: {
    fontWeight: "bold",
  },
  disabled: {
    "& div": {
      cursor: "not-allowed",
      borderColor: colors.gray40,
      "&:after": {
        cursor: "not-allowed",
        borderColor: colors.gray40,
      },
    },
    "& label": {
      color: colors.gray40,
    },
  },
  checkbox: {
    height: 32,
    width: 32,
    flexShrink: 0,
    margin: 0,
    padding: 0,
    opacity: 0,
  },
});

export function RadioButton(props: {
  checked: boolean;
  onCheck: () => void;
  label: string;
  disabled?: boolean;
  className?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}) {
  const styles = useStyles();
  return (
    <label
      className={cn(
        styles.element,
        props.checked && styles.strong,
        props.disabled && styles.disabled,
        props.className
      )}
    >
      <input
        type="radio"
        className={styles.checkbox}
        checked={props.checked}
        onChange={props.onCheck}
        disabled={props.disabled}
        onBlur={props.onBlur}
      />
      <div className={styles.customRadiobox} onClick={props.onCheck} />
      <span className={styles.label} onClick={props.onCheck}>
        {props.label}
      </span>
    </label>
  );
}
