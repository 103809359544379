import React, { useContext, useEffect, useState } from "react";
import ContactPersonBlockData from "../../../models/content/ContactPersonBlockData";
import { Col, onBefore, onBreakpoint, rem } from "../../../basics/layout";
import { createUseStyles } from "react-jss";
import {
  RenderProperty,
  showPropertyOnEdit,
} from "../../../views/RenderProperty";
import { Button } from "../../Button/Button";
import { LocalizedLabel } from "../../../hooks/LocalizationContext";
import { FAIcon, FAIconStyles } from "../../FAIcon/FAIcon";
import { colors } from "../../../basics/colors";
import { spacings } from "../../../basics/spacings";
import { customTypography, typography } from "../../../basics/typography";
import ContactGenericBlockData from "../../../models/content/ContactGenericBlockData";
import { AnimatedRevealBlock } from "../../AnimatedReveal/AnimatedReveal";
import { GlobalContentStoreContext } from "../../../hooks/GlobalContentStore";
import StartPageData from "../../../models/content/StartPageData";
import { useTracking } from "../../../hooks/useTracking";

export const personCardStyles = createUseStyles({
  contactCol: {
    marginBottom: rem(spacings.m),
  },
  animationWrapper: {
    height: "100%",
  },
  contact: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    borderRadius: 10,
    cursor: "pointer",
    filter: "drop-shadow(0px 1px 7px rgba(0,0,0,.1))",
    transition: "filter 0.3s ease",
    "&:hover": {
      filter: "drop-shadow(0px 1px 7px rgba(0,0,0,.2))",
      transition: "filter 0.3s ease",
    },
  },
  bioSection: {
    display: "flex",
    backgroundColor: colors.gray10,
    minHeight: 80,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    ...onBreakpoint("sm", {
      minHeight: 96,
    }),
    ...onBreakpoint("md", {
      minHeight: 128,
    }),
  },
  name: {
    ...typography.h4,
    marginLeft: rem(spacings.s),
    marginRight: "auto",
    marginTop: rem(spacings.s),
    ...onBreakpoint("md", {
      marginTop: rem(spacings.m),
      marginLeft: rem(spacings.m),
    }),
  },
  photo: {
    "& img": {
      width: 80,
      height: 80,
      borderTopRightRadius: 10,
      marginLeft: rem(spacings.s),
      objectFit: "cover",
      ...onBreakpoint("sm", {
        width: 96,
        height: 96,
      }),
      ...onBreakpoint("md", {
        width: 128,
        height: 128,
      }),
    },
  },
  infoSection: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: `${spacings.s} ${spacings.s} ${spacings.m}`,
    ...onBreakpoint("md", {
      padding: `${spacings.sam} ${spacings.m} ${spacings.m}`,
    }),
  },
  role: {
    ...customTypography(
      {
        ...typography.textDefault,
        color: colors.gray60,
        marginBottom: 0,
      },
      {},
      {
        marginBottom: 0,
      }
    ),
  },
  defaultText: {
    ...customTypography(
      {
        ...typography.textDefault,
        marginBottom: rem(spacings.xxs),
      },
      {},
      {
        marginBottom: rem(spacings.xxs),
      }
    ),
  },
  ctaButtons: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: "auto",
    "& > button": {
      marginTop: rem(spacings.s),
      marginRight: rem(spacings.s),
    },
  },
  iconWrapper: {
    marginTop: rem(spacings.s),
    marginLeft: "auto",
    ...onBefore("sm", {
      display: "none",
    }),
    "& button, & a": {
      backgroundColor: colors.gray20,
      transition: "color 0.3s ease, background-color 0.3s ease",
      padding: "11px 12px",
      borderRadius: "50%",
      marginRight: rem(spacings.s),
      "&:last-child": {
        marginRight: 0,
      },
      "&:hover": {
        color: colors.darkGreen80,
        backgroundColor: colors.webGreen20,
        transition: "color 0.3s ease, background-color 0.3s ease",
      },
    },
  },
  iconWrapperMobile: {
    marginTop: rem(spacings.s),
    marginLeft: "auto",
    display: "flex",
    ...onBreakpoint("sm", {
      display: "none",
    }),
    "& button, & a": {
      backgroundColor: colors.gray20,
      "&$primaryCta": {
        backgroundColor: colors.webGreen20,
        color: colors.webGreen40,
      },
      padding: "11px 12px",
      borderRadius: "50%",
      marginRight: rem(spacings.xs),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  primaryCta: {},
  icon: {
    ...FAIconStyles({
      color: colors.gray90,
      width: 24,
      height: 24,
      cursor: "pointer",
      ...onBreakpoint("md", {
        width: 20,
        height: 20,
      }),
    }),
  },
  accessibilityText: typography.visuallyHidden,

  //for overlay only
  contacts: {
    ...typography.textDefault,
    marginTop: rem(spacings.m),
    marginBottom: 0,
    display: "flex",
    ...onBefore("sm", {
      marginTop: rem(spacings.s),
    }),
  },
  contactLabels: {
    display: "flex",
    color: colors.gray60,
    flexDirection: "column",
  },
  contactNumbers: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: rem(spacings.s),
    "& a": {
      color: colors.gray100,
    },
  },
  ctaButtonsOverlay: {
    ...onBreakpoint("sm", {
      marginTop: "unset",
    }),
  },
  linkedInIcon: {
    display: "flex",
    marginTop: "auto",
    alignSelf: "flex-end",
    ...onBefore("sm", {
      display: "none",
    }),
    "& a": {
      marginTop: rem(spacings.sam),
      backgroundColor: colors.gray20,
      padding: "11px 12px",
      borderRadius: "50%",
      transition: "color 0.3s ease, background-color 0.3s ease",
      "&:hover": {
        color: colors.darkGreen80,
        backgroundColor: colors.webGreen20,
        transition: "color 0.3s ease, background-color 0.3s ease",
      },
    },
  },
});

export function PersonCard(
  props: ContactPersonBlockData &
    ContactGenericBlockData & {
      contactsAmount: number;
      itemNumber: number;
      openModal: (slide: number) => void;
      isWide?: boolean;
    }
) {
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );

  const tracking = useTracking();

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  const {
    firstName,
    lastName,
    title,
    subTitle,
    photo,
    role,
    jobTitle,
    phoneNumber,
    email,
    contactsAmount,
    itemNumber,
    isWide,
  } = props;

  const isOneElement = contactsAmount === 1;

  const styles = personCardStyles(isOneElement);

  const lastOddContact =
    contactsAmount % 2 !== 0 && itemNumber + 1 === contactsAmount;

  const isEvenContact = itemNumber % 2 === 0;

  const cardPhoto =
    photo.value?.urlSmall && photo.value?.urlMedium && photo.value?.urlLarge
      ? photo
      : startPageData?.contactPersonImageFallback;

  const isGenericContact = props.contentType[1] === "ContactGenericBlock";

  function openModal() {
    tracking.event(
      "Contacts",
      isGenericContact ? "Open generic contact" : "Open person contact",
      `${firstName?.value || ""} ${lastName?.value || ""} ${title?.value || ""}`
    );

    props.openModal(itemNumber);
  }

  return (
    <Col
      sm={isWide ? 12 : contactsAmount === 1 || lastOddContact ? 8 : 5}
      md={isWide || contactsAmount === 1 ? 6 : 4}
      smStart={
        isWide
          ? null
          : contactsAmount === 1 || lastOddContact
          ? 2
          : isEvenContact
          ? 1
          : 6
      }
      mdStart={
        (!isWide && itemNumber === 0 && contactsAmount < 3
          ? Math.max((12 - 3 * contactsAmount) / 3, 0)
          : null) as Parameters<typeof Col>[0]["mdStart"]
      }
      className={styles.contactCol}
    >
      <AnimatedRevealBlock
        revealDelay={itemNumber * 100}
        className={styles.animationWrapper}
      >
        <article className={styles.contact} onClick={() => openModal()}>
          <div className={styles.bioSection}>
            <h4 className={styles.name}>
              {firstName && <RenderProperty value={firstName} />}{" "}
              {lastName && <RenderProperty value={lastName} />}
              {title && <RenderProperty value={title} />}
            </h4>
            <RenderProperty value={cardPhoto} className={styles.photo} />
          </div>
          <div className={styles.infoSection}>
            {showPropertyOnEdit(jobTitle) && (
              <RenderProperty value={jobTitle} className={styles.defaultText} />
            )}
            {showPropertyOnEdit(role) && (
              <RenderProperty value={role} className={styles.role} />
            )}
            {showPropertyOnEdit(subTitle) && (
              <RenderProperty value={subTitle} className={styles.defaultText} />
            )}
            <div className={styles.ctaButtons}>
              <Button type="link" onClick={() => props.openModal(itemNumber)}>
                <span className={styles.accessibilityText}>
                  Open contact details
                </span>
                <LocalizedLabel section="Global" label="ShowMore" />
              </Button>
              <div className={styles.iconWrapper}>
                <button>
                  <span className={styles.accessibilityText}>
                    Open contact details
                  </span>
                  <FAIcon icon="envelope" className={styles.icon} />
                </button>
                <button>
                  <span className={styles.accessibilityText}>
                    Open contact details
                  </span>
                  <FAIcon icon="phone" className={styles.icon} />
                </button>
              </div>

              <div className={styles.iconWrapperMobile}>
                <a href={`mailto:${email.value}`}>
                  <span className={styles.accessibilityText}>
                    Write an email to {email.value}
                  </span>
                  <FAIcon icon="envelope" className={styles.icon} />
                </a>
                <a href={`tel: ${phoneNumber.value}`}>
                  <span className={styles.accessibilityText}>
                    Call to a phone number {phoneNumber.value}
                  </span>
                  <FAIcon icon="phone" className={styles.icon} />
                </a>
              </div>
            </div>
          </div>
        </article>
      </AnimatedRevealBlock>
    </Col>
  );
}
