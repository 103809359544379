import React, { useContext, useState, useEffect } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Shared/Footer";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import {
  Container,
  Row,
  Col,
  onBreakpoint,
  rem,
  onBefore,
} from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { typography, customTypography } from "../../basics/typography";
import { Spinner } from "../Spinner/Spinner";
import { Button } from "../Button/Button";
import { RenderProperty } from "../../views/RenderProperty";
import { AdaptiveImageComponent } from "../AdaptiveImageComponent/AdaptiveImageComponent";
import {
  LocalizedLabel,
  localizedLabelString,
  LocalizationContext,
} from "../../hooks/LocalizationContext";
import {
  NewsWallApiResponse,
  getNewsWallData,
  NewsWallApiHit,
  FiltersElement,
} from "./NewsWallPage";
import { PressSectionPageProps } from "../../models/content/PressSectionPageData";
import TaxonomyBaseData from "../../models/content/TaxonomyBaseData";
import { ContactExpert } from "../ContactExpert/ContactExpert";
import { DownloadBlock, DownloadProps } from "../Block/DownloadBlock";
import { useDebounce } from "../../hooks/useDebounce";
import { getCurrentLocale } from "../../basics/locales";
import { fonts } from "../../basics/fonts";

const PAGE_SIZE = 30;

const useStyles = createUseStyles({
  newsWall: {
    paddingTop: rem(spacings.m),
    paddingBottom: rem(spacings.l),
    ...onBreakpoint("sm", {
      paddingTop: rem(spacings.l),
      paddingBottom: rem(spacings.xl),
    }),
    ...onBreakpoint("md", {
      paddingTop: rem(spacings.xl),
      paddingBottom: rem(spacings.xxl),
    }),
  },
  headline: typography.h1,
  moreButton: {
    display: "flex",
    justifyContent: "center",
    paddingTop: rem(spacings.m),
  },
  noResults: {
    ...typography.textDefault,
    textAlign: "center",
  },
});

export function PressSectionPage(props: PressSectionPageProps) {
  const initialQueryParametrs = new URLSearchParams(window.location.search);
  const dataFacets = (props.data.filters.expandedValue ||
    []) as Array<TaxonomyBaseData>;

  const { getStartPageData, getImmediateStartPageData, getWebsiteData } =
    useContext(GlobalContentStoreContext);
  const websiteData = getWebsiteData();
  const styles = useStyles();
  const localizationCtx = useContext(LocalizationContext);
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const [newsWallData, setNewsWallData] = useState<NewsWallApiResponse | null>(
    null
  );

  const [isLoading, setLoading] = useState(false);

  const [activeFacet, setActiveFacet] = useState<
    TaxonomyBaseData["contentLink"]["id"] | null
  >(
    initialQueryParametrs.has("facet")
      ? Number(initialQueryParametrs.get("facet") || "")
      : null
  );

  const [localeSelection, setLocaleSelection] = useState<string | undefined>(
    getCurrentLocale(websiteData, initialQueryParametrs)
  );

  const [page, setPage] = useState<number>(0);
  const { debouncePromise } = useDebounce();

  function loadItems(
    requestedPage?: number
  ): Promise<NewsWallApiResponse | null> {
    const apiPage = requestedPage || page + 1;
    setLoading(true);

    return debouncePromise(() =>
      getNewsWallData(
        apiPage,
        props.data.contentLink,
        localeSelection,
        activeFacet ? [activeFacet] : dataFacets.map((f) => f.contentLink.id),
        []
      ).then((response) => {
        setPage(apiPage);
        setLoading(false);
        return response;
      })
    );
  }

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams();
    activeFacet &&
      queryParameters.set("category", encodeURI(String(activeFacet)));
    localeSelection && queryParameters.set("locale", localeSelection);

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${queryParameters}`
    );
    setNewsWallData(null);
    loadItems(1).then((response) => response && setNewsWallData(response));
  }, [startPageData, activeFacet, localeSelection]);

  if (!startPageData) return null;

  const cards = (newsWallData && newsWallData.Hits) || [];

  const contactExpertData = props.data.contactsUseDefaultContent?.value
    ? startPageData
    : props.data;

  return (
    <>
      <Header {...startPageData} />
      <main data-testid="PressSectionPage">
        <Container className={styles.newsWall}>
          <Row style={{ width: "100%" }}>
            <Col sm={7} md={8}>
              <h1 className={styles.headline}>
                <RenderProperty
                  value={props.data.metaTitle}
                  fallbackValue={props.data.name}
                />
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={4} md={3}>
              <aside>
                <FiltersElement
                  headline={localizedLabelString({
                    section: "NewsWallPage",
                    label: "Filters",
                    localizationCtx,
                  })}
                  defaultFilter={localizedLabelString({
                    section: "NewsWallPage",
                    label: "AllItems",
                    localizationCtx,
                  })}
                  filters={dataFacets}
                  activeFilter={activeFacet}
                  setActiveFilter={setActiveFacet}
                  localeSelection={localeSelection}
                  setLocaleSelection={setLocaleSelection}
                  localeOptions={props.data.existingLanguages || []}
                  teaserContent={props.data.teaserContent}
                  enableLocalFilter={props.data.enableLocalFilter?.value}
                />
              </aside>
            </Col>
            {newsWallData ? (
              cards.length > 0 ? (
                <Col xs={4} sm={8} md={9}>
                  {cards.map(
                    (card, idx) =>
                      card.Type === "Article" && <Card {...card} key={idx} />
                  )}
                </Col>
              ) : (
                <Col xs={4} sm={12} md={9} className={styles.noResults}>
                  <LocalizedLabel
                    section="NewsWallPage"
                    label="NoResultsFound"
                  />
                </Col>
              )
            ) : (
              <Col xs={4} sm={12} md={9}>
                <Spinner />
              </Col>
            )}
          </Row>
          {newsWallData && newsWallData.TotalCount > page * PAGE_SIZE && (
            <Row>
              <Col className={styles.moreButton}>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Button
                    type="secondary"
                    onClick={() => {
                      loadItems().then(
                        (response) =>
                          response &&
                          setNewsWallData((s) =>
                            s
                              ? { ...s, Hits: [...s.Hits, ...response.Hits] }
                              : response
                          )
                      );
                    }}
                  >
                    <LocalizedLabel
                      section="NewsWallPage"
                      label="MoreInsights"
                    />
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </Container>
        <RenderProperty value={props.data.additionalContent} />
        {(props.data.downloadsGlobal?.value ||
          props.data.downloadsLocal?.value) && (
          <DownloadBlock
            downloadItems={[
              ...((props.data.downloadsLocal
                ?.expandedValue as DownloadProps[]) || []),
              ...((props.data.downloadsGlobal
                ?.expandedValue as DownloadProps[]) || []),
            ]}
          />
        )}
        {props.data.contactCta?.value === "enabled" && (
          <ContactExpert {...contactExpertData} />
        )}
      </main>
      <Footer {...startPageData} />
    </>
  );
}

const useCardStyles = createUseStyles({
  newsItem: {
    display: "flex",
    padding: `${spacings.sam} 0`,
    borderTop: `2px solid ${colors.gray20}`,
    "&:last-child": {
      borderBottom: `2px solid ${colors.gray20}`,
    },
  },
  articleImageWrapper: {
    overflow: "hidden",
    display: "block",
  },
  articleImage: {
    transform: "none",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.03)",
      transition: "transform 0.3s ease",
    },
    "& img": {
      ...onBefore("sm", {
        paddingBottom: rem(spacings.sam),
      }),
      height: "auto",
    },
  },
  h4: customTypography(typography.h4, { marginBottom: 0 }, { marginBottom: 0 }),
  caption: typography.caption,

  textDefault: {
    paddingTop: rem(spacings.s),
    ...typography.textDefault,
    ...fonts.sansLight,
  },
  fullWidth: {
    width: "100%",
  },
});

function Card(props: NewsWallApiHit & { Type: "Article" }) {
  const styles = useCardStyles();
  const hasImage =
    props.ImageUrlLarge || props.ImageUrlMedium || props.ImageUrlSmall;
  const captionText = [props.Date, props.Location, props.Author]
    .filter((text) => Boolean(text))
    .join(" | ");

  return (
    <Container className={styles.newsItem} dataTestid="PressSectionCard">
      <Row>
        {hasImage && (
          <Col sm={4} md={3}>
            <a href={props.Url} className={styles.articleImageWrapper}>
              <AdaptiveImageComponent
                large={props.ImageUrlLarge}
                medium={props.ImageUrlMedium}
                small={props.ImageUrlSmall}
                alt={props.ImageAlt}
                className={styles.articleImage}
              />
            </a>
          </Col>
        )}
        <Col sm={hasImage ? 8 : 12} md={hasImage ? 9 : 12}>
          <span className={styles.caption}>{captionText}</span>

          <h4 className={styles.h4}>{props.Title}</h4>
          <p className={styles.textDefault}>{props.Description}</p>
          <Button type="link" href={props.Url}>
            <LocalizedLabel section="Global" label="ReadMore" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
