import IContent from "../models/IContent";
import ContentLink from "../models/ContentLink";
import React, { useContext, useState, useEffect } from "react";
import StartPageData from "../models/content/StartPageData";
import { StartPage } from "../components/Page/StartPage";
import { GlobalContentStoreContext } from "../hooks/GlobalContentStore";
import FooterTextBlock from "../components/Block/FooterTextBlock";
import FooterLinkBlock from "../components/Block/FooterLinkBlock";
import FooterTextBlockData from "../models/content/FooterTextBlockData";
import FooterLinkBlockData from "../models/content/FooterLinkBlockData";
import {
  ProxyPage,
  useProductPageProxyStyles,
} from "../components/ProxyPage/ProxyPage";
import ProxyPageData from "../models/content/ProxyPageData";
import { ContentPage } from "../components/Page/ContentPage";
import ContentPageData from "../models/content/ContentPageData";
import RichTextBlock from "../components/Block/RichTextBlock";
import RichTextBlockData from "../models/content/RichTextBlockData";
import { IconWithTextListBlock } from "../components/Block/IconWithTextListBlock";
import IconWithTextListBlockData from "../models/content/IconWithTextListBlockData";
import { AccordionBlock } from "../components/Block/AccordionBlock";
import AccordionBlockData from "../models/content/AccordionBlockData";
import { NewsWallPage } from "../components/Page/NewsWallPage";
import NewsWallPageData from "../models/content/NewsWallPageData";
import { NewsArticlePage } from "../components/Page/NewsArticlePage";
import NewsArticlePageData from "../models/content/NewsArticlePageData";
import { SectionLandingPage } from "../components/Page/SectionLandingPage";
import ProductPageData from "../models/content/ProductPageData";
import { ProductPage } from "../components/Page/ProductPage";
import SectionLandingPageData from "../models/content/SectionLandingPageData";
import { FeaturedFactsBlock } from "../components/Block/FeaturedFactsBlock";
import FeaturedFactsBlockData from "../models/content/FeaturedFactsBlockData";
import { EmbeddedHtmlBlock } from "../components/Block/EmbeddedHtmlBlock";
import EmbeddedHtmlBlockData from "../models/content/EmbeddedHtmlBlockData";
import ConsentBlockData from "../models/content/ConsentBlockData";
import { PrivacyNote } from "../components/PrivacyNote/PrivacyNote";
import { ContentOptionsBlock } from "../components/Block/ContentOptionsBlock";
import ContentOptionsBlockData from "../models/content/ContentOptionsBlockData";
import QuoteBlockData from "../models/content/QuoteBlockData";
import { QuoteBlock } from "../components/Block/QuoteBlock";
import { FeaturedContentBlock } from "../components/Block/FeaturedContentBlock/FeaturedContentBlock";
import FeaturedContentBlockData from "../models/content/FeaturedContentBlockData";
import { NewsWallTeaserBlock } from "../components/Block/NewsWallTeaserBlock";
import NewsWallListingBlockData from "../models/content/NewsWallListingBlockData";
import { LocaleOverlay } from "../components/LocaleOverlay/LocaleOverlay";
import LocaleSelectorBlockData from "../models/content/LocaleSelectorBlockData";
import { QuickLinksBlock } from "../components/Block/QuickLinksBlock";
import QuickLinksBlockData from "../models/content/QuickLinksBlockData";
import { FeaturedPagesBlock } from "../components/Block/FeaturedPagesBlock";
import FeaturedPagesBlockData from "../models/content/FeaturedPagesBlockData";
import { NewsletterBlock } from "../components/Block/NewsletterBlock";
import NewsletterSignupTeaserBlockData from "../models/content/NewsletterSignupTeaserBlockData";
import { KeyVisualHomepage } from "../components/KeyVisualHomepage/KeyVisualHomepage";
import KeyVisualWithSchemaBlockData from "../models/content/KeyVisualWithSchemaBlockData";
import { createUseStyles } from "react-jss";
import { VideoBlock } from "../components/Block/VideoBlock";
import VideoBlockCloudinaryData from "../models/content/VideoBlockCloudinaryData";
import ImageBlockData from "../models/content/ImageBlockData";
import { ImageBlock } from "../components/Block/ImageBlock";
import { FormContainerBlock } from "../components/Block/Forms/FormContainerBlock";
import { ContactListBlock } from "../components/Block/ContactListBlock/ContactListBlock";
import ContactListBlockData from "../models/content/ContactListBlockData";
import PressSectionPageData from "../models/content/PressSectionPageData";
import { PressSectionPage } from "../components/Page/PressSectionPage";
import FeaturedVideoBlockData from "../models/content/FeaturedVideoBlockData";
import ImageGalleryBlockData from "../models/content/ImageGalleryBlockData";
import { ImageGalleryBlock } from "../components/Block/ImageGalleryBlock";
import { DownloadBlockWrapper } from "../components/Block/DownloadBlock";
import DownloadBlockData from "../models/content/DownloadBlockData";
import ContactExpertBlockData from "../models/content/ContactExpertBlockData";
import { ContactExpertBlock } from "../components/Block/ContactExpertBlock/ContactExpertBlock";
import { FooterIconBlock } from "../components/Block/FooterIconBlock";
import FooterImageLinksBlockData from "../models/content/FooterImageLinksBlockData";
import { SearchPage } from "../components/Page/SearchPage";
import SearchPageData from "../models/content/SearchPageData";
import { LocationsOverviewPage } from "../components/Page/LocationsOverviewPage";
import LocationsOverviewPageData from "../models/content/LocationsOverviewPageData";
import { FactsAndFigures } from "../components/Block/FactsAndFigures/FactsAndFigures";
import FactsAndFiguresBlockData from "../models/content/FactsAndFiguresBlockData";
import { ImageTeaserBlock } from "../components/Block/ImageTeaserBlock";
import ImageTeaserBlockData from "../models/content/ImageTeaserBlockData";
import { ContactExpert } from "../components/ContactExpert/ContactExpert";
import { RelatedContentBlock } from "../components/Block/RelatedContentBlock";
import { DownloadCenterPage } from "../components/Page/DownloadCenterPage";
import { DownloadCenterCategoryBlock } from "../components/Block/DownloadCenterCategoryBlock";
import DownloadCenterCategoryBlockData from "../models/content/DownloadCenterCategoryBlockData";
import { DownloadCenterSubcategoryBlock } from "../components/Block/DownloadCenterSubcategoryBlock";
import DownloadCenterSubcategoryBlockData from "../models/content/DownloadCenterSubcategoryBlockData";
import DocumentFileData from "../models/content/DocumentFileData";
import ProductDownloadBlockData from "../models/content/ProductDownloadBlockData";
import { DownloadItem } from "../components/DownloadItem/DownloadItem";
import { JobListingPage } from "../components/Page/JobListingPage";
import { StringProperty } from "../models/Property";
import TextAndImageBlockData from "../models/content/TextAndImageBlockData";
import { TextAndImageBlock } from "../components/Block/TextAndImageBlock";
import TextTeaserBlockData from "../models/content/TextTeaserBlockData";
import { TextTeaserBlock } from "../components/Block/TextTeaserBlock";
import UnsubscribeConfirmationPageData from "../models/content/UnsubscribeConfirmationPageData";
import { UnsubscribeConfirmationPage } from "../components/Page/UnsubscribeConfirmationPage";

interface RenderComponentProps {
  /**
   * The link to the content to be rendered in this component
   */
  contentLink: ContentLink;

  /**
   * Context information
   */
  contentType?: string;

  /**
   * CSS Class to be added
   */
  className?: string;

  /**
   * The width of the component
   */
  width?: number;

  /**
   * The height of the component
   */
  height?: number;

  /**
   * The name of the property being rendered
   */
  propertyName?: string;

  actionName?: string;

  actionData?: any;

  path?: string;
}

export type ComponentTheme = "standard" | "homepage" | "navigation" | "filters";

export function RenderComponent<T>(
  props: RenderComponentProps & {
    context?: T;
    theme?: ComponentTheme;
  }
) {
  const {
    getByContentLink,
    getStartPageData,
    getImmediateByContentLink,
    getImmediateStartPageData,
  } = useContext(GlobalContentStoreContext);
  const [componentData, setComponentData] = useState<IContent | null>(
    getImmediateByContentLink(props.contentLink)
  );
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const productPageProxyStyles = useProductPageProxyStyles();

  useEffect(() => {
    setComponentData(getImmediateByContentLink(props.contentLink));
  }, [getImmediateByContentLink]);

  useEffect(() => {
    !componentData &&
      getByContentLink(props.contentLink).then((response) => {
        setComponentData(response);
      });
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  if (!componentData || !startPageData) return null;

  const componentName = componentData.contentType.join("/");
  const theme = props.theme || "standard";

  // TODO(mkarol): Remove when proxy will no longer be needed
  const maybeProxyPage = componentData as any;
  if (
    maybeProxyPage.showProxyPage?.value &&
    maybeProxyPage.proxyContent?.value
  ) {
    const contactExpertData = maybeProxyPage.contactsUseDefaultContent?.value
      ? startPageData
      : maybeProxyPage;

    const additionalProxyContent =
      componentName === "Page/ProductPage" ? (
        <>
          {maybeProxyPage.contactCta?.value === "enabled" && (
            <ContactExpert {...contactExpertData} />
          )}
          {maybeProxyPage.relatedContent?.value && (
            <RelatedContentBlock
              relatedContent={maybeProxyPage.relatedContent}
            />
          )}
        </>
      ) : null;

    return (
      <ProxyPage
        data={
          {
            ...componentData,
            proxyContent: maybeProxyPage.proxyContent,
          } as ProxyPageData
        }
        additionalProxyContent={additionalProxyContent}
        className={(props.className, productPageProxyStyles.page)}
      />
    );
  }

  switch (componentName) {
    case "Block/FooterTextBlock":
      return (
        <FooterTextBlock
          data={componentData as FooterTextBlockData}
          className={props.className}
          context={props.context}
        />
      );
    case "Block/FooterLinkBlock":
      return (
        <FooterLinkBlock
          data={componentData as FooterLinkBlockData}
          className={props.className}
          context={props.context}
        />
      );
    case "Block/FooterImageLinksBlock":
      return (
        <FooterIconBlock
          data={componentData as FooterImageLinksBlockData}
          className={props.className}
        />
      );
    case "Block/IconWithTextListBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <IconWithTextListBlock
              data={componentData as IconWithTextListBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/AccordionBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <AccordionBlock
              data={componentData as AccordionBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/RichTextBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <RichTextBlock
              data={componentData as RichTextBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/FeaturedFactsBlock":
      return (
        <TopMarginWrapper hasDarkBackground={true}>
          <Anchor anchor={componentData.anchor}>
            <FeaturedFactsBlock
              data={componentData as FeaturedFactsBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/EmbeddedHtmlBlock":
      const ThemedEmbeddedHtmlBlock = () => (
        <Anchor anchor={componentData.anchor}>
          <EmbeddedHtmlBlock
            data={componentData as EmbeddedHtmlBlockData}
            className={props.className}
          />
        </Anchor>
      );

      return theme === "navigation" || theme === "filters" ? (
        <ThemedEmbeddedHtmlBlock />
      ) : (
        <TopMarginWrapper hasDarkBackground={false}>
          <ThemedEmbeddedHtmlBlock />
        </TopMarginWrapper>
      );
    case "Block/QuoteBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <QuoteBlock
              data={componentData as QuoteBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/TextAndImageBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <TextAndImageBlock
              data={componentData as TextAndImageBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/ConsentBlock":
      return (
        <Anchor anchor={componentData.anchor}>
          <PrivacyNote
            data={componentData as ConsentBlockData}
            className={props.className}
          />
        </Anchor>
      );
    case "Block/ContentOptionsBlock":
      return (
        <TopMarginWrapper hasDarkBackground={true} hasCustomTopMargin={true}>
          <Anchor anchor={componentData.anchor}>
            <ContentOptionsBlock
              data={componentData as ContentOptionsBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/FeaturedContentBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <FeaturedContentBlock
              data={componentData as FeaturedContentBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/FeaturedVideoBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <FeaturedContentBlock
              data={componentData as FeaturedVideoBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/NewsWallListingBlock":
      return (
        <TopMarginWrapper hasDarkBackground={theme === "standard"}>
          <Anchor anchor={componentData.anchor}>
            <NewsWallTeaserBlock
              data={componentData as NewsWallListingBlockData}
              className={props.className}
              theme={theme}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/LocaleSelectorBlock":
      return (
        <Anchor anchor={componentData.anchor}>
          <LocaleOverlay
            data={componentData as LocaleSelectorBlockData}
            className={props.className}
          />
        </Anchor>
      );
    case "Block/QuickLinksBlock":
      return (
        <TopMarginWrapper hasDarkBackground={theme === "homepage"}>
          <Anchor anchor={componentData.anchor}>
            <QuickLinksBlock
              data={componentData as QuickLinksBlockData}
              className={props.className}
              theme={theme}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/FeaturedPagesBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <FeaturedPagesBlock
              data={componentData as FeaturedPagesBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/NewsletterSignupTeaserBlock":
      const ThemedNewsletterSignupTeaserBlock = () => (
        <Anchor anchor={componentData.anchor}>
          <NewsletterBlock
            data={componentData as NewsletterSignupTeaserBlockData}
            className={props.className}
            theme={theme}
          />
        </Anchor>
      );
      return theme === "navigation" || theme === "filters" ? (
        <ThemedNewsletterSignupTeaserBlock />
      ) : (
        <TopMarginWrapper hasDarkBackground={true}>
          <ThemedNewsletterSignupTeaserBlock />
        </TopMarginWrapper>
      );
    case "Block/KeyVisualWithSchemaBlock":
      return (
        <Anchor anchor={componentData.anchor}>
          <KeyVisualHomepage
            data={componentData as KeyVisualWithSchemaBlockData}
            className={props.className}
          />
        </Anchor>
      );
    case "Block/VideoBlockCloudinary":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <VideoBlock
              data={componentData as VideoBlockCloudinaryData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/ImageTeaserBlock":
      const ThemedImageTeaserBlock = () => (
        <Anchor anchor={componentData.anchor}>
          <ImageTeaserBlock
            data={componentData as ImageTeaserBlockData}
            className={props.className}
            theme={theme}
          />
        </Anchor>
      );

      return theme === "navigation" || theme === "filters" ? (
        <ThemedImageTeaserBlock />
      ) : (
        <TopMarginWrapper hasDarkBackground={false}>
          <ThemedImageTeaserBlock />
        </TopMarginWrapper>
      );
    case "Block/TextTeaserBlock": {
      const ThemedTextTeaserBlock = () => (
        <Anchor anchor={componentData.anchor}>
          <TextTeaserBlock
            data={componentData as TextTeaserBlockData}
            className={props.className}
            theme={theme}
          />
        </Anchor>
      );

      return theme === "navigation" || theme === "filters" ? (
        <ThemedTextTeaserBlock />
      ) : (
        <TopMarginWrapper hasDarkBackground={false}>
          <ThemedTextTeaserBlock />
        </TopMarginWrapper>
      );
    }
    case "Block/ImageBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <ImageBlock
              data={componentData as ImageBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/ImageGalleryBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <ImageGalleryBlock
              data={componentData as ImageGalleryBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/HdiGlobalForm":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <FormContainerBlock
              data={componentData as any}
              className={props.className}
              isComponent={true}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/ContactListBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <ContactListBlock
              data={componentData as ContactListBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/ContactExpertBlock":
      return (
        <TopMarginWrapper hasDarkBackground={false}>
          <Anchor anchor={componentData.anchor}>
            <ContactExpertBlock
              data={componentData as ContactExpertBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/DownloadBlock":
      return (
        <TopMarginWrapper hasDarkBackground={true}>
          <Anchor anchor={componentData.anchor}>
            <DownloadBlockWrapper
              data={componentData as DownloadBlockData}
              className={props.className}
              context={props.context}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/FactsAndFiguresBlock":
      return (
        <TopMarginWrapper hasDarkBackground={true}>
          <Anchor anchor={componentData.anchor}>
            <FactsAndFigures
              data={componentData as FactsAndFiguresBlockData}
              className={props.className}
            />
          </Anchor>
        </TopMarginWrapper>
      );
    case "Block/DownloadCenterCategoryBlock":
      return (
        <Anchor anchor={componentData.anchor}>
          <DownloadCenterCategoryBlock
            data={componentData as DownloadCenterCategoryBlockData}
            className={props.className}
          />
        </Anchor>
      );
    case "Block/DownloadCenterSubcategoryBlock":
      return (
        <Anchor anchor={componentData.anchor}>
          <DownloadCenterSubcategoryBlock
            data={componentData as DownloadCenterSubcategoryBlockData}
            className={props.className}
          />
        </Anchor>
      );
    case "Block/ProductDownloadBlock":
      return (
        <Anchor anchor={componentData.anchor}>
          <DownloadItem
            {...(componentData as ProductDownloadBlockData).masterFile
              .expandedValue}
            localizedFiles={
              (componentData as ProductDownloadBlockData).localizedFiles
                .expandedValue
            }
            className={props.className}
            context={props.context}
          />
        </Anchor>
      );
    case "Media/DocumentFile":
      return (
        <DownloadItem
          {...(componentData as DocumentFileData)}
          context={props.context}
          className={props.className}
        />
      );
    case "Page/ContentPage":
      return (
        <ContentPage
          data={componentData as ContentPageData}
          className={props.className}
        />
      );
    case "Page/JobListingPage":
      return (
        <JobListingPage
          data={componentData as any}
          className={props.className}
        />
      );
    case "Page/NewsWallPage":
      return (
        <NewsWallPage
          data={componentData as NewsWallPageData}
          className={props.className}
        />
      );
    case "Page/NewsArticlePage":
      return (
        <NewsArticlePage
          data={componentData as NewsArticlePageData}
          className={props.className}
        />
      );
    case "Page/ProductPage":
      return (
        <ProductPage
          data={componentData as ProductPageData}
          className={props.className}
        />
      );
    case "Page/LocationsOverviewPage":
      return (
        <LocationsOverviewPage
          data={componentData as LocationsOverviewPageData}
          className={props.className}
        />
      );
    case "Page/ProxyPage":
      return (
        <ProxyPage
          data={componentData as ProxyPageData}
          className={props.className}
        />
      );
    case "Page/SectionLandingPage":
      return (
        <SectionLandingPage
          data={componentData as SectionLandingPageData}
          className={props.className}
        />
      );
    case "Page/SearchPage":
      return (
        <SearchPage
          data={componentData as SearchPageData}
          className={props.className}
        />
      );
    case "Page/StartPage":
      return (
        <StartPage
          data={componentData as StartPageData}
          className={props.className}
        />
      );
    case "Page/PressSectionPage":
      return (
        <PressSectionPage
          data={componentData as PressSectionPageData}
          className={props.className}
        />
      );
    case "Page/DownloadCenterPage":
      return (
        <DownloadCenterPage
          data={componentData as any}
          className={props.className}
        />
      );
    case "Page/UnsubscribeConfirmationPage":
      return (
        <UnsubscribeConfirmationPage
          data={componentData as UnsubscribeConfirmationPageData}
          className={props.className}
        />
      );

    default:
      return null;
  }
}

const useStyles = createUseStyles({
  anchor: {
    position: "relative",
    "& > span": {
      position: "absolute",
      top: -100,
    },
  },
});

function TopMarginWrapper(props: {
  hasDarkBackground: boolean;
  children: React.ReactNode;
  hasCustomTopMargin?: boolean;
}) {
  return <>{props.children}</>;
}

function Anchor(props: { anchor?: StringProperty; children: React.ReactNode }) {
  const styles = useStyles();
  return props.anchor?.value ? (
    <div className={styles.anchor}>
      <span id={props.anchor?.value} />
      {props.children}
    </div>
  ) : (
    <>{props.children}</> || null
  );
}
