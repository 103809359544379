import React, { useContext, useState, useEffect, ReactNode } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Shared/Footer";
import { NewsWallPageProps } from "../../models/content/NewsWallPageData";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import TaxonomyBaseData from "../../models/content/TaxonomyBaseData";
import { Container, Row, Col, onBreakpoint, rem } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { customTypography, typography } from "../../basics/typography";
import cn from "classnames";
import { namePropertyValue } from "../../models/IContent";
import { Spinner } from "../Spinner/Spinner";
import { TagBadge } from "../TagBadge/TagBadge";
import { Button, isFile, isExternal } from "../Button/Button";
import {
  AnimatedRenderProperty,
  RenderProperty,
} from "../../views/RenderProperty";
import ContentLink from "../../models/ContentLink";
import { AdaptiveImageComponent } from "../AdaptiveImageComponent/AdaptiveImageComponent";
import {
  LocalizedLabel,
  localizedLabelString,
  LocalizationContext,
} from "../../hooks/LocalizationContext";
import { ContactExpert } from "../ContactExpert/ContactExpert";
import { AnimatedRevealBlock } from "../AnimatedReveal/AnimatedReveal";
import { RadioButton } from "../RadioButton/RadioButton";
import { CloudinaryVideoPlayer } from "../CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import {
  isDeviceResOrHigher,
  isDeviceResOrLower,
  useDeviceType,
} from "../../hooks/useDeviceType";
import { DownloadBlock, DownloadProps } from "../Block/DownloadBlock";
import { Accordion, AccordionItem } from "../Accordion/Accordion";
import { useDebounce } from "../../hooks/useDebounce";
import { getCurrentLocale } from "../../basics/locales";
import { ContentAreaProperty } from "../../models/Property";
import LanguageList from "../../models/LanguageList";

const PAGE_SIZE = 30;

export type NewsWallTag = {
  Name: string;
  ContentLink: string;
};

export type NewsWallApiHit = {
  Id: number;
  Title: string | null;
  Description: string | null;
  Url: string;
  Tags: Array<NewsWallTag> | null;
  Categories: Array<NewsWallTag> | null;
} & (
  | {
      Type: "Article";
      ImageUrlLarge: string | null;
      ImageUrlMedium: string | null;
      ImageUrlSmall: string | null;
      ImageAlt: string | null;
      Date: string | null;
      Location: string | null;
      Author: string | null;
    }
  | {
      Type: "Event";
      DateFrom: string;
      DateTo: string | null;
      Location: string;
      Link: string | null;
      LinkText: string | null;
    }
  | {
      Type: "CrossReference";
      ImageUrlLarge: string | null;
      ImageUrlMedium: string | null;
      ImageUrlSmall: string | null;
      ImageAlt: string | null;
      Link: string | null;
      LinkText: string | null;
    }
  | {
      Type: "Video";
      ImageUrlLarge: string | null;
      ImageUrlMedium: string | null;
      ImageUrlSmall: string | null;
      ImageAlt: string | null;
      CloudinaryVideoPublicID: string | null;
      StartAction: string | null;
      EndAction: string | null;
      ShowControls: boolean | null;
    }
);

export type NewsWallApiResponse = {
  Hits: Array<NewsWallApiHit>;
  TotalCount: number;
  Filter: {
    RootContentLink: string;
    Language: string | null;
    Categories: Array<NewsWallTag> | null;
    Sort?: string;
    SortDirection: string;
    Page: number;
    PageSize: number;
  };
  UpcomingEvents: Array<NewsWallApiHit & { Type: "Event" }>;
};

export function getNewsWallData(
  page: number,
  contentLink: ContentLink | undefined,
  language: string | undefined,
  filters: Array<TaxonomyBaseData["contentLink"]["id"]>,
  tags: Array<NewsWallTag["ContentLink"]>
): Promise<NewsWallApiResponse> {
  const queryParameters = new URLSearchParams();
  queryParameters.set("rootContentLink", contentLink?.id.toString() || "");
  queryParameters.set("language", language || "");
  queryParameters.set("page", page.toString());
  queryParameters.set("size", PAGE_SIZE.toString());
  queryParameters.set(
    "category",
    [...filters.map((i) => i.toString()), ...tags].join(",")
  );

  return fetch(`/api/hdiglobal/news-wall/items?${queryParameters}`).then((r) =>
    r.json()
  );
}

const useNewsWallStyles = createUseStyles({
  newsWall: {
    paddingTop: rem(spacings.m),
    paddingBottom: rem(spacings.l),
    ...onBreakpoint("sm", {
      paddingTop: rem(spacings.l),
      paddingBottom: rem(spacings.xl),
    }),
    ...onBreakpoint("md", {
      paddingTop: rem(spacings.xl),
      paddingBottom: rem(spacings.xxl),
    }),
  },
  headline: typography.h1,
  mobileOnly: {
    display: "block",
    ...onBreakpoint("sm", {
      display: "none",
    }),
  },
  tabletOnly: {
    display: "none",
    ...onBreakpoint("sm", {
      display: "block",
    }),
    ...onBreakpoint("md", {
      display: "none",
    }),
  },
  desktopOnly: {
    display: "none",
    ...onBreakpoint("md", {
      display: "block",
    }),
  },
  cardsContainer: {
    marginBottom: `-${spacings.sam}`,
  },
  eventsHeadline: {
    ...customTypography(
      { ...typography.h4 },
      {},
      {
        marginBottom: rem(spacings.sam),
      }
    ),
  },
  moreButton: {
    display: "flex",
    justifyContent: "center",
    paddingTop: rem(spacings.m),
  },
  noResults: {
    ...typography.textDefault,
    textAlign: "center",
  },
});

export function NewsWallPage(props: NewsWallPageProps) {
  const DESKTOP_NUMBER_OF_COLUMNS = 3;
  const TABLET_NUMBER_OF_COLUMNS = 3;
  const initialQueryParametrs = new URLSearchParams(window.location.search);
  const dataFilters = (props.data.filters.expandedValue ||
    []) as Array<TaxonomyBaseData>;

  const { getStartPageData, getImmediateStartPageData, getWebsiteData } =
    useContext(GlobalContentStoreContext);
  const styles = useNewsWallStyles();
  const websiteData = getWebsiteData();
  const localizationCtx = useContext(LocalizationContext);
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const [newsWallData, setNewsWallData] = useState<NewsWallApiResponse | null>(
    null
  );

  const [isLoading, setLoading] = useState(false);

  const [localeSelection, setLocaleSelection] = useState<string | undefined>(
    getCurrentLocale(websiteData, initialQueryParametrs)
  );

  const [activeFilter, setActiveFilter] = useState<
    TaxonomyBaseData["contentLink"]["id"] | null
  >(
    initialQueryParametrs.has("category")
      ? Number(initialQueryParametrs.get("category") || "")
      : null
  );

  const [activeTags, setActiveTags] = useState<Set<NewsWallTag["ContentLink"]>>(
    new Set(
      initialQueryParametrs.has("tags")
        ? decodeURI(initialQueryParametrs.get("tags") || "")
            .split(",")
            .filter(Boolean)
        : []
    )
  );

  const [page, setPage] = useState<number>(0);
  const { debouncePromise } = useDebounce();

  function loadItems(
    requestedPage?: number
  ): Promise<NewsWallApiResponse | null> {
    const apiPage = requestedPage || page + 1;
    setLoading(true);
    const apiTags = Array.from(activeTags.values()).map((key) => key);

    return debouncePromise(() =>
      getNewsWallData(
        apiPage,
        props.data.contentLink,
        localeSelection,
        activeFilter
          ? [activeFilter]
          : dataFilters.map((f) => f.contentLink.id),
        apiTags
      ).then((response) => {
        setPage(apiPage);
        setLoading(false);
        return response;
      })
    );
  }

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams();
    const qsTags = Array.from(activeTags.values()).map((key) => key);

    activeFilter && queryParameters.set("category", String(activeFilter));
    qsTags.length > 0 &&
      queryParameters.set("tags", encodeURI(qsTags.join(",")));
    localeSelection && queryParameters.set("locale", localeSelection);

    history.replaceState(
      {},
      "",
      `${window.location.pathname}?${queryParameters}`
    );
    setNewsWallData(null);
    loadItems(1).then((response) => response && setNewsWallData(response));
  }, [startPageData, activeFilter, activeTags, localeSelection]);

  if (!startPageData) return null;

  const dataTags =
    (newsWallData &&
      new Map<NewsWallTag["ContentLink"], NewsWallTag>(
        newsWallData.Filter.Categories?.map((item) => [item.ContentLink, item])
      )) ||
    new Map();

  const cards = (newsWallData && newsWallData.Hits) || [];

  const contactExpertData = props.data.contactsUseDefaultContent?.value
    ? startPageData
    : props.data;

  return (
    <>
      <Header {...startPageData} />
      <main data-testid="NewsWallPage">
        <Container className={styles.newsWall}>
          <Row>
            <Col xs={4} sm={4} md={3}>
              <aside>
                <h1 className={styles.headline}>
                  <AnimatedRenderProperty
                    value={props.data.metaTitle}
                    fallbackValue={props.data.name}
                  />
                </h1>
                <FiltersElement
                  headline={localizedLabelString({
                    section: "NewsWallPage",
                    label: "Filters",
                    localizationCtx,
                  })}
                  defaultFilter={localizedLabelString({
                    section: "NewsWallPage",
                    label: "AllItems",
                    localizationCtx,
                  })}
                  filters={dataFilters}
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                  activeTags={Array.from(activeTags.values()).map((tagId) =>
                    dataTags.get(tagId)
                  )}
                  removeActiveTag={(tagId) =>
                    setActiveTags((s) => {
                      const newState = new Set(s);
                      newState.delete(tagId);
                      return newState;
                    })
                  }
                  localeSelection={localeSelection}
                  setLocaleSelection={setLocaleSelection}
                  localeOptions={props.data.existingLanguages || []}
                  teaserContent={props.data.teaserContent}
                  enableLocalFilter={props.data.enableLocalFilter?.value}
                >
                  {newsWallData && newsWallData.UpcomingEvents.length > 0 && (
                    <div className={styles.desktopOnly}>
                      <span className={styles.eventsHeadline}>
                        <LocalizedLabel
                          section="NewsWallPage"
                          label="UpcomingEvents"
                        />
                      </span>
                      {newsWallData.UpcomingEvents.map((item, idx) => (
                        <Event {...item} key={idx} />
                      ))}
                    </div>
                  )}
                </FiltersElement>
              </aside>
              {newsWallData && (
                <div className={cn(styles.tabletOnly, styles.cardsContainer)}>
                  {cards
                    .filter((_, cidx) => cidx % TABLET_NUMBER_OF_COLUMNS === 0)
                    .map((card, cidx) => (
                      <Card
                        {...card}
                        key={cidx}
                        startPageData={startPageData}
                        onTagClick={(tagId) =>
                          !activeTags.has(tagId) &&
                          setActiveTags((s) => new Set(s).add(tagId))
                        }
                      />
                    ))}
                </div>
              )}
            </Col>
            {newsWallData ? (
              cards.length > 0 ? (
                <>
                  {new Array(DESKTOP_NUMBER_OF_COLUMNS)
                    .fill(0)
                    .map((_, idx) => (
                      <Col
                        md={3}
                        className={cn(
                          styles.desktopOnly,
                          styles.cardsContainer
                        )}
                        key={idx}
                      >
                        {cards
                          .filter(
                            (_, cidx) =>
                              cidx % DESKTOP_NUMBER_OF_COLUMNS === idx
                          )
                          .map((card, cidx) => (
                            <Card
                              {...card}
                              key={cidx}
                              startPageData={startPageData}
                              onTagClick={(tagId) =>
                                !activeTags.has(tagId) &&
                                setActiveTags((s) => new Set(s).add(tagId))
                              }
                            />
                          ))}
                      </Col>
                    ))}
                  {new Array(TABLET_NUMBER_OF_COLUMNS - 1)
                    .fill(0)
                    .map((_, idx) => (
                      <Col
                        sm={4}
                        className={cn(styles.tabletOnly, styles.cardsContainer)}
                        key={idx}
                      >
                        {cards
                          .filter(
                            (_, cidx) =>
                              cidx % TABLET_NUMBER_OF_COLUMNS === idx + 1
                          )
                          .map((card, cidx) => (
                            <Card
                              {...card}
                              key={cidx}
                              startPageData={startPageData}
                              onTagClick={(tagId) =>
                                !activeTags.has(tagId) &&
                                setActiveTags((s) => new Set(s).add(tagId))
                              }
                            />
                          ))}
                      </Col>
                    ))}
                  <Col
                    xs={4}
                    className={cn(styles.mobileOnly, styles.cardsContainer)}
                  >
                    {cards.map((card, idx) => (
                      <Card
                        {...card}
                        key={idx}
                        startPageData={startPageData}
                        revealDelay={-1}
                        onTagClick={(tagId) =>
                          !activeTags.has(tagId) &&
                          setActiveTags((s) => new Set(s).add(tagId))
                        }
                      />
                    ))}
                  </Col>
                </>
              ) : (
                <Col xs={4} sm={12} md={9} className={styles.noResults}>
                  <LocalizedLabel
                    section="NewsWallPage"
                    label="NoResultsFound"
                  />
                </Col>
              )
            ) : (
              <Col xs={4} sm={12} md={9}>
                <Spinner />
              </Col>
            )}
          </Row>
          {newsWallData && newsWallData.TotalCount > page * PAGE_SIZE && (
            <Row>
              <Col className={styles.moreButton}>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Button
                    type="secondary"
                    onClick={() => {
                      loadItems().then(
                        (response) =>
                          response &&
                          setNewsWallData((s) =>
                            s
                              ? { ...s, Hits: [...s.Hits, ...response.Hits] }
                              : response
                          )
                      );
                    }}
                  >
                    <LocalizedLabel
                      section="NewsWallPage"
                      label="MoreInsights"
                    />
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </Container>
        <RenderProperty value={props.data.additionalContent} />
        {(props.data.downloadsGlobal?.value ||
          props.data.downloadsLocal?.value) && (
          <DownloadBlock
            downloadItems={[
              ...((props.data.downloadsLocal
                ?.expandedValue as DownloadProps[]) || []),
              ...((props.data.downloadsGlobal
                ?.expandedValue as DownloadProps[]) || []),
            ]}
          />
        )}
        {props.data.contactCta?.value === "enabled" && (
          <ContactExpert {...contactExpertData} />
        )}
      </main>
      <Footer {...startPageData} />
    </>
  );
}

const useCardStyles = createUseStyles({
  newsItem: {
    position: "relative",
    backgroundColor: colors.gray10,
    paddingBottom: rem(spacings.m),
    marginBottom: rem(spacings.sam),
  },
  newsItemSection: {
    paddingTop: rem(spacings.m),
    paddingLeft: rem(spacings.m),
    paddingRight: rem(spacings.m),
  },
  h4: {
    ...customTypography(
      {
        ...typography.h4,
        wordBreak: "break-word",
      },
      {},
      {
        marginBottom: rem(spacings.m),
      }
    ),
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
  },
  category: {
    ...typography.caption,
    display: "flex",
    flexDirection: "column",
    marginBottom: rem(spacings.xs),
  },
  spacing: {
    paddingTop: rem(spacings.sam),
    marginBottom: rem(spacings.m),
  },
  nonBreakingSpan: {
    whiteSpace: "nowrap",
    //fix for Firefox
    "@supports (-moz-appearance:none)": {
      whiteSpace: "normal",
    },
    //fix for Safari
    "@media not all and (min-resolution:.001dpcm)": {
      whiteSpace: "normal",
    },
  },
});

type SupportedNewsWallCardTypes =
  | "Article"
  | "CrossReference"
  | "Event"
  | "Video";
function Card(
  props: NewsWallApiHit & { Type: SupportedNewsWallCardTypes } & {
    startPageData: StartPageData;
    onTagClick: (tagId: NewsWallTag["ContentLink"]) => void;
    revealDelay?: number;
  }
) {
  const styles = useCardStyles();
  const [forceVideoPlay, setForceVideoPlay] = useState(false);
  const revealDelay =
    props.revealDelay === undefined
      ? Math.floor(300 * Math.random())
      : props.revealDelay;

  const isAutoplay = props.Type === "Video" && props.StartAction === "autoplay";
  const isLoop = props.Type === "Video" && props.EndAction === "loop_video";
  const resetOnEnd =
    props.Type === "Video" && props.EndAction === "reset_to_thumbnail";
  const defaultPosterUrl =
    props.Type === "Video" &&
    (props.ImageUrlLarge || props.ImageUrlMedium || props.ImageUrlSmall);

  return (
    <AnimatedRevealBlock
      className={styles.newsItem}
      data-testid="NewsWallCard"
      revealDelay={revealDelay}
    >
      {(props.Type === "Article" || props.Type === "CrossReference") &&
        (props.ImageUrlLarge ||
          props.ImageUrlMedium ||
          props.ImageUrlSmall) && (
          <a
            href={
              (props.Type === "Article" ? props.Url : props.Link) || undefined
            }
          >
            <AdaptiveImageComponent
              large={props.ImageUrlLarge}
              medium={props.ImageUrlMedium}
              small={props.ImageUrlSmall}
              alt={props.ImageAlt}
            />
          </a>
        )}

      {props.Type === "Video" && (
        <CloudinaryVideoPlayer
          publicId={props.CloudinaryVideoPublicID || ""}
          logoImageUrl={props.startPageData.logo.value?.url || ""}
          showControls={false}
          autoplay={isAutoplay}
          loop={isLoop}
          resetVideo={resetOnEnd}
          thumbnailUrl={defaultPosterUrl || undefined}
          withOverlay={true}
          forcePlay={forceVideoPlay}
          setForcePlay={setForceVideoPlay}
        />
      )}

      <div className={styles.newsItemSection}>
        {(props.Type === "Article" || props.Type === "CrossReference") && (
          <span className={styles.category}>{props.Categories?.[0]?.Name}</span>
        )}

        {props.Type === "Event" && (
          <span className={styles.category}>
            {props.DateTo ? (
              <>
                <span>
                  <span className={styles.nonBreakingSpan}>
                    {props.DateFrom}
                  </span>
                  <span className={styles.nonBreakingSpan}>
                    {" "}
                    - {props.DateTo}
                  </span>
                </span>
                <span>{props.Location}</span>
              </>
            ) : (
              <span>
                {props.DateFrom} | {props.Location}
              </span>
            )}
          </span>
        )}

        <h4 className={styles.h4}>{props.Title}</h4>
        {(props.Tags?.length || 0) > 0 && (
          <div className={styles.tags}>
            {props.Tags?.map((tag, idx) => (
              <TagBadge
                isReadOnly
                label={tag.Name}
                onBadgeClick={() => props.onTagClick(tag.ContentLink)}
                key={idx}
              />
            ))}
          </div>
        )}
        {props.Type === "Article" && (
          <Button type="link" href={props.Url}>
            <LocalizedLabel section="Global" label="ReadMore" />
          </Button>
        )}
        {(props.Type === "CrossReference" || props.Type === "Event") &&
          props.Link && (
            <Button
              small={true}
              type={
                isFile(props.Link) && !isExternal(props.Link)
                  ? "primary"
                  : "secondary"
              }
              href={props.Link}
              title={props.LinkText || ""}
              target="_blank"
            >
              {props.LinkText}
            </Button>
          )}
        {props.Type === "Video" && (
          <Button
            type="secondary"
            onClick={() => setForceVideoPlay(true)}
            icon="play"
          >
            <LocalizedLabel section="Global" label="PlayVideo" />
          </Button>
        )}
      </div>
    </AnimatedRevealBlock>
  );
}

const useFiltersStyle = createUseStyles({
  element: {
    marginBottom: rem(spacings.xs),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.s),
    }),
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    ...onBreakpoint("sm", {
      marginTop: rem(spacings.s),
    }),
  },
  accordeonElement: {
    marginBottom: 0,
    marginTop: 0,
  },
  mainElement: {
    marginBottom: rem(spacings.sam),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.m),
    }),
  },
  teaserContentItem: {
    marginTop: rem(spacings.sam),
    ...onBreakpoint("sm", {
      marginTop: rem(spacings.m),
    }),
    "& > div": {
      marginBottom: rem(spacings.sam),
      ...onBreakpoint("sm", {
        marginBottom: rem(spacings.m),
      }),
    },
  },
});

export function FiltersElement(props: {
  headline: string;
  filters: Array<TaxonomyBaseData>;
  defaultFilter: string;
  activeFilter: TaxonomyBaseData["contentLink"]["id"] | null;
  setActiveFilter: (
    filtersId: TaxonomyBaseData["contentLink"]["id"] | null
  ) => void;
  activeTags?: Array<NewsWallTag | undefined>;
  removeActiveTag?: (tagId: NewsWallTag["ContentLink"]) => void;
  localeSelection: string | undefined;
  setLocaleSelection: (props: string) => void;
  localeOptions: LanguageList;
  teaserContent: ContentAreaProperty;
  children?: ReactNode;
  enableLocalFilter: boolean | null;
}) {
  const localizationCtx = useContext(LocalizationContext);
  const numberOfActiveTags = props.activeTags?.length || 0;
  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");
  const tabletAtLeast = isDeviceResOrHigher(deviceType, "tablet");

  const styles = useFiltersStyle();
  const activeTags = numberOfActiveTags > 0;
  return (
    <div className={styles.element}>
      <div className={styles.mainElement}>
        <Accordion noOuterBorder={isMobile} className={styles.accordeonElement}>
          <AccordionItem
            headline={props.headline}
            initialIsActive={tabletAtLeast}
          >
            <div data-testid="NewsWallPageFilters">
              <RadioButton
                onCheck={() => props.setActiveFilter(null)}
                checked={!props.activeFilter}
                label={props.defaultFilter}
              />
              {props.filters.map((item) => (
                <RadioButton
                  key={item.contentLink.id}
                  onCheck={() => props.setActiveFilter(item.contentLink.id)}
                  checked={props.activeFilter === item.contentLink.id}
                  label={namePropertyValue(item.name) || ""}
                />
              ))}
            </div>
          </AccordionItem>
          {props.enableLocalFilter && (
            <AccordionItem
              headline={localizedLabelString({
                section: "Search",
                label: "Locales",
                localizationCtx,
              })}
            >
              {props.localeOptions.map((option, idx) => (
                <RadioButton
                  key={idx}
                  label={option.displayName}
                  onCheck={() => props.setLocaleSelection(option.name)}
                  checked={props.localeSelection === option.name}
                />
              ))}
            </AccordionItem>
          )}
        </Accordion>
        {activeTags && (
          <div className={styles.tags} data-testid="NewsWallPageFilterTags">
            {props.activeTags
              ?.filter((i): i is NewsWallTag => i !== undefined)
              .map((item) => (
                <TagBadge
                  label={item.Name}
                  onCloseClick={() => props.removeActiveTag?.(item.ContentLink)}
                  key={item.ContentLink}
                />
              ))}
          </div>
        )}
      </div>
      {props.children}
      <RenderProperty
        value={props.teaserContent}
        theme="filters"
        className={styles.teaserContentItem}
      />
    </div>
  );
}

const useEventStyles = createUseStyles({
  event: {
    display: "flex",
    flexDirection: "column",
    marginBottom: rem(spacings.sam),
    borderBottom: `3px solid ${colors.gray10}`,
  },
  date: {
    ...typography.caption,
    marginBottom: rem(spacings.xs),
    display: "flex",
    flexDirection: "column",
  },
  headline: {
    ...customTypography(
      typography.h4,
      {},
      {
        marginBottom: rem(spacings.sam),
      }
    ),
  },
  nonBreakingSpan: {
    whiteSpace: "nowrap",
    //fix for Firefox
    "@supports (-moz-appearance:none)": {
      whiteSpace: "normal",
    },
    //fix for Safari
    "@media not all and (min-resolution:.001dpcm)": {
      whiteSpace: "normal",
    },
  },
});

export function Event(
  props: NewsWallApiHit & { Type: "Event"; className?: string }
) {
  const styles = useEventStyles();
  return (
    <div
      className={cn(styles.event, props.className)}
      data-testid="NewsWallPageEvents"
    >
      <div className={styles.date}>
        {props.DateTo ? (
          <>
            <span>
              <span className={styles.nonBreakingSpan}>{props.DateFrom}</span>
              <span className={styles.nonBreakingSpan}> - {props.DateTo}</span>
            </span>
            <span>{props.Location}</span>
          </>
        ) : (
          <span>
            {props.DateFrom} | {props.Location}
          </span>
        )}
      </div>
      <h4 className={styles.headline}>{props.Title}</h4>
    </div>
  );
}
