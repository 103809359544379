import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { spacings } from "../../basics/spacings";
import { colors } from "../../basics/colors";
import { Container, onBreakpoint, onBefore, rem } from "../../basics/layout";
import { fonts } from "../../basics/fonts";
import { Button } from "../Button/Button";
import { Select, SelectOption } from "../Select/Select";
import { FAIcon } from "../FAIcon/FAIcon";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import LocaleSelectorBlockData from "../../models/content/LocaleSelectorBlockData";
import { typography } from "../../basics/typography";
import {
  LocalizationContext,
  LocalizedLabel,
  localizedLabelString,
} from "../../hooks/LocalizationContext";
import Cookies from "js-cookie";
import { setLocaleChosen } from "../LocaleOverlay/LocaleOverlay";
import StartPageData from "../../models/content/StartPageData";
import { nonLocaleCompare } from "../../basics/nonLocaleCompare";

const useStyles = createUseStyles({
  outerContainer: {
    padding: `12px 0`,
    background: colors.lightBlue50,
  },
  innerContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    ...onBreakpoint("sm", {
      flexWrap: "nowrap",
    }),
  },
  message: {
    width: "100%",
    ...fonts.sansRegular,
    fontSize: "0.875rem",
    color: colors.white,
    marginBottom: rem(spacings.s),
    textAlign: "center",
    ...onBreakpoint("sm", {
      width: "auto",
      marginBottom: 0,
      textAlign: "left",
      marginRight: rem(spacings.xl),
    }),
  },
  controlsWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...onBreakpoint("sm", {
      justifyContent: "flex-start",
    }),
  },
  languagesSelect: {
    width: "50%",
    flexGrow: 1,
    marginRight: rem(spacings.s),

    borderBottom: `2px solid ${colors.gray10} !important`,
    ...onBreakpoint("sm", {
      width: "320px",
      marginLeft: "auto",
      flexGrow: 0,
    }),
    ...onBreakpoint("md", {
      marginLeft: 0,
    }),
    "& > div": {
      marginTop: 0,
    },
  },
  acceptButton: {
    lineHeight: 1.5,
  },
  cancelButton: {
    fontSize: "1.35rem",
    lineHeight: 1,
    padding: `9px ${spacings.s}`,
    background: "none",
    border: 0,
    color: colors.white,
    ...onBefore("md", {
      marginRight: `-${spacings.s}`,
    }),
  },
  flagIcon: {
    height: "0.95rem",
  },
  visuallyHidden: {
    ...typography.visuallyHidden,
  },
});

type LocaleSelectOption = SelectOption & { url: string };

/*
 * If the user closes the suggestion bar we remember the page language
 * he was on so that we don't suggest him to change it anymore
 */
const ACCEPTABLE_FOREIGN_LANGS_KEY = "acceptableForeignLangs";

export function getAcceptableUrlPrefixes(): string[] {
  const acceptableLangsString = Cookies.get(ACCEPTABLE_FOREIGN_LANGS_KEY) || "";

  return acceptableLangsString ? acceptableLangsString.split(",") : [];
}

export function addAcceptableUrlPrefix(languageCode: string) {
  const acceptableLangs = getAcceptableUrlPrefixes();

  if (!acceptableLangs.find((item) => item === languageCode)) {
    acceptableLangs.push(languageCode);
  }

  Cookies.set(ACCEPTABLE_FOREIGN_LANGS_KEY, acceptableLangs.join(","));
}

export function LocaleSuggestion() {
  const styles = useStyles();

  const { getStartPageData, getImmediateStartPageData, getWebsiteData } =
    useContext(GlobalContentStoreContext);
  const websiteData = getWebsiteData();

  function getLocalesOptions(
    localeSelector: LocaleSelectorBlockData
  ): Array<LocaleSelectOption> {
    const localesOptions: Array<LocaleSelectOption> =
      localeSelector?.section.value
        ?.flatMap((region) => region.locales)
        .sort((a, b) => nonLocaleCompare(a.label, b.label))
        .filter((c) => c.languageCode !== "en")
        .map((country) => ({
          value: country.languageCode,
          label: country.label,
          icon: (
            <img src={country.flag?.url} alt="" className={styles.flagIcon} />
          ),
          url: country.externalUrl
            ? country.externalUrl
            : `/${country.languageCode}`,
        })) || [];

    localesOptions.unshift({
      value: "international",
      label: "International website",
      icon: "globe",
      url: "/",
    });

    return localesOptions;
  }

  function acceptSelectedLanguage() {
    const localeOption = localesOptions.find(
      (item) => item.value === selectedUrlPrefix
    );

    if (localeOption) {
      setLocaleChosen();
      addAcceptableUrlPrefix(localeOption.value);
      window.location.href = localeOption.url;
    } else {
      close();
    }
  }

  function close() {
    if (currentPageLanguageCode) {
      setLocaleChosen();
      addAcceptableUrlPrefix(currentPageLanguageCode);
    }

    setShouldDisplay(false);
  }

  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );

  const basePreferredUrlPrefix = window?.__LOCALE__?.toLowerCase() || "";
  const stateLocalesOptions = getLocalesOptions(
    startPageData?.localeSelector?.expandedValue as LocaleSelectorBlockData
  );
  const isBasePrefferedUrlPrefixIncorrect = !stateLocalesOptions.find(
    (item) =>
      item.value.toLocaleLowerCase() ===
      basePreferredUrlPrefix.toLocaleLowerCase()
  );
  const preferredUrlPrefix = isBasePrefferedUrlPrefixIncorrect
    ? "international"
    : basePreferredUrlPrefix;

  const currentUrlPrefix = websiteData?.currentLanguage?.link?.toLowerCase();
  const isCurrentUrlPrefixInternational =
    websiteData?.currentLanguage.isMasterLanguage;
  const isCurrentUrlPrefixAcceptable = getAcceptableUrlPrefixes().find(
    (item) =>
      item.toLowerCase() === currentUrlPrefix ||
      (item === "international" && isCurrentUrlPrefixInternational)
  );

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  useEffect(() => {
    setCurrentPageLanguageCode(
      isCurrentUrlPrefixInternational
        ? "international"
        : currentUrlPrefix || null
    );
    setLocalesOptions(stateLocalesOptions);
    setSelectedUrlPrefix(preferredUrlPrefix);
    setShouldDisplay(
      stateLocalesOptions.length > 0 &&
        preferredUrlPrefix !== currentUrlPrefix &&
        !(
          preferredUrlPrefix === "international" &&
          isCurrentUrlPrefixInternational
        ) &&
        !isCurrentUrlPrefixAcceptable
    );
  }, [startPageData]);

  const [currentPageLanguageCode, setCurrentPageLanguageCode] = useState<
    string | null
  >(
    isCurrentUrlPrefixInternational ? "international" : currentUrlPrefix || null
  );
  const [shouldDisplay, setShouldDisplay] = useState<boolean>(
    stateLocalesOptions.length > 0 &&
      preferredUrlPrefix !== currentUrlPrefix &&
      !(
        preferredUrlPrefix === "international" &&
        isCurrentUrlPrefixInternational
      ) &&
      !isCurrentUrlPrefixAcceptable
  );
  const [localesOptions, setLocalesOptions] =
    useState<Array<LocaleSelectOption>>(stateLocalesOptions);
  const [selectedUrlPrefix, setSelectedUrlPrefix] =
    useState<string>(preferredUrlPrefix);
  const localizationCtx = useContext(LocalizationContext);

  return shouldDisplay ? (
    <aside className={styles.outerContainer}>
      <Container className={styles.innerContainer}>
        <div className={styles.message}>
          <LocalizedLabel section="LocaleSuggestion" label="Message" />
        </div>
        <div className={styles.controlsWrapper}>
          <Select
            className={styles.languagesSelect}
            label={localizedLabelString({
              localizationCtx: localizationCtx,
              section: "LocaleSuggestion",
              label: "SelectLabel",
            })}
            options={localesOptions}
            selected={selectedUrlPrefix}
            setSelected={(props: string) =>
              setSelectedUrlPrefix(props as string)
            }
          />
          <Button
            size="xs"
            className={styles.acceptButton}
            onClick={() => acceptSelectedLanguage()}
          >
            OK
          </Button>
          <button className={styles.cancelButton} onClick={() => close()}>
            <span className={styles.visuallyHidden}>
              <LocalizedLabel section="Global" label="Cancel" />
            </span>
            <FAIcon icon="times" />
          </button>
        </div>
      </Container>
    </aside>
  ) : null;
}
