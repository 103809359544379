import React, { useState } from "react";
import { Container, Row, Col, onBreakpoint, rem } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { fonts } from "../../basics/fonts";
import StartPageData from "../../models/content/StartPageData";
import { RenderProperty } from "../../views/RenderProperty";
import { LanguagePicker } from "../LanguagePicker/LanguagePicker";
import FooterImageLinksBlockData from "../../models/content/FooterImageLinksBlockData";
import { Link } from "./Link";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { animations } from "../../basics/animations";

const useStyles = createUseStyles({
  container: {
    backgroundColor: colors.gray20,
    ...fonts.sansRegular,
    ...onBreakpoint("sm", {
      paddingTop: 70,
    }),
  },
  desktopVector: {
    display: "none",
  },
  upperContainer: {
    marginBottom: rem(spacings.l),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.xxl),
    }),
    ...onBreakpoint("md", {
      marginBottom: 0,
    }),
  },
  bottomContainerLinks: {
    paddingTop: rem(spacings.l),
    paddingBottom: rem(spacings.l),
    ...onBreakpoint("sm", {
      paddingBottom: rem(spacings.xl),
    }),
    ...onBreakpoint("md", {
      paddingTop: rem(spacings.xl),
    }),
  },
  horizontalLine: {
    borderBottom: `1px solid ${colors.gray10}`,
    ...onBreakpoint("sm", {
      borderBottom: `none`,
    }),
  },
  cards: {
    marginTop: rem(spacings.xxl),
    ...onBreakpoint("sm", {
      marginTop: rem(spacings.xl.minus(spacings.l)),
    }),
    ...onBreakpoint("md", {
      marginTop: 0,
    }),
  },
  card: {
    backgroundColor: colors.white,
    marginBottom: rem(spacings.s),
    display: "flex",
    alignItems: "center",
    color: colors.gray70,
    transition: "color 0.3s ease",
    "&:hover": {
      color: colors.gray100,
      transition: "color 0.3s ease",
    },
    "&:hover $cardIcon": {
      transform: "scale(1)",
      transition: "transform 0.4s ease",
    },
  },
  cardImageWrapper: {
    width: 64,
    height: 64,
    marginRight: rem(spacings.s),
    overflow: "hidden",
  },
  cardImage: {
    width: "100%",
    height: "100%",
  },
  cardIcon: {
    transform: "scale(0.85)",
    transition: "transform 0.4s ease",
    ...FAIconStyles({
      color: colors.gray40,
      width: 22,
      height: 22,
    }),
    marginLeft: "auto",
    marginRight: rem(spacings.s),
    flexShrink: 0,
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
  },
  legalLinks: {
    display: "flex",
    flexDirection: "column",
    marginTop: rem(spacings.l),
    fontSize: 14,
    ...onBreakpoint("sm", {
      flexDirection: "row",
      alignItems: "center",
      marginTop: 0,
    }),
  },
  legalLink: {
    display: "flex",
    flexDirection: "column",
    ...onBreakpoint("sm", {
      flexDirection: "row",
      alignItems: "center",
    }),
    "& a": {
      display: "block",
      marginBottom: rem(spacings.s),
      ...animations.linkChevronEffect,
      ...onBreakpoint("sm", {
        marginBottom: 0,
        marginRight: rem(spacings.sam),
      }),
      ...onBreakpoint("md", {
        marginRight: rem(spacings.xxl),
      }),
    },
  },
  mobileOnly: {
    display: "block",
    ...onBreakpoint("sm", {
      display: "none",
    }),
  },
  desktopAndTabletOnly: {
    display: "none",
    ...onBreakpoint("sm", {
      display: "block",
    }),
  },
  languagePicker: {
    display: "none",
    ...onBreakpoint("md", {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "flex-end",
      marginTop: rem(spacings.sam.minus(spacings.xl)),
    }),
  },
} as any);

export const Footer = (props: {
  column1: StartPageData["column1"];
  column2: StartPageData["column2"];
  column3: StartPageData["column3"];
  column4: StartPageData["column4"];
  socialMedia: StartPageData["socialMedia"];
  legalLinks: StartPageData["legalLinks"];
  footerLanguagePicker: StartPageData["footerLanguagePicker"];
}) => {
  const styles = useStyles();
  const [openSection, setOpenSection] = useState("");
  return (
    <footer className={styles.container} data-testid="footer" id="footer">
      <Container className={styles.upperContainer}>
        <Row>
          <Col sm={4} md={3} className={styles.desktopAndTabletOnly}>
            <RenderProperty value={props.column1} />
          </Col>
          <Col
            sm={4}
            smStart={4}
            md={3}
            mdStart={3}
            className={styles.desktopAndTabletOnly}
          >
            <RenderProperty value={props.column2} />
          </Col>

          <Col
            sm={4}
            smStart={8}
            md={3}
            mdStart={6}
            className={styles.desktopAndTabletOnly}
          >
            <RenderProperty value={props.column3} />
          </Col>

          <Col className={styles.mobileOnly}>
            <div className={styles.horizontalLine}>
              <RenderProperty
                value={props.column1}
                context={{ setOpenSection, openSection, uncollapsed: true }}
              />
            </div>
            <RenderProperty
              value={props.column2}
              context={{ setOpenSection, openSection }}
            />
            <RenderProperty
              value={props.column3}
              context={{ setOpenSection, openSection }}
            />
          </Col>

          <Col sm={12} md={3} mdStart={9}>
            <Container isFluid={true} className={styles.cards}>
              <Row>
                {props.column4.expandedValue?.map((item, idx) => (
                  <FooterCard
                    {...(item as FooterImageLinksBlockData)}
                    key={idx}
                  />
                ))}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className={styles.bottomContainerLinks}>
          <Col sm={4} md={3}>
            <RenderProperty
              value={props.socialMedia}
              className={styles.socialIcons}
            />
          </Col>
          <Col
            sm={8}
            smStart={4}
            md={7}
            mdStart={3}
            className={styles.legalLinks}
          >
            <RenderProperty
              value={props.legalLinks}
              className={styles.legalLink}
            />
          </Col>
          <Col md={2} mdStart={10} className={styles.languagePicker}>
            {props.footerLanguagePicker.value && <LanguagePicker />}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const FooterCard = (props: FooterImageLinksBlockData) => {
  const styles = useStyles();
  const link = props.link.value?.[0];

  return (
    <Col xs={4} sm={6} md={12}>
      <Link
        href={link?.href || ""}
        title={link?.title}
        target={link?.target}
        className={styles.card}
      >
        <div className={styles.cardImageWrapper}>
          <img
            className={styles.cardImage}
            src={props.image.value?.url || ""}
            title={props.label.value || ""}
            alt={props.label.value || ""}
            loading="lazy"
          />
        </div>
        {props.label.value}
        <FAIcon icon="external-link" className={styles.cardIcon} />
      </Link>
    </Col>
  );
};
