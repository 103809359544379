import React, { useState, useContext, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { Col, Container, onBreakpoint, rem, Row } from "../../basics/layout";
import { customTypography, typography } from "../../basics/typography";
import { AnimatedRenderProperty } from "../../views/RenderProperty";
import { Button } from "../Button/Button";
import { spacings } from "../../basics/spacings";
import { PrivacyOverlay } from "../PrivacyOverlay/PrivacyOverlay";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { ConsentBlockProps } from "../../models/content/ConsentBlockData";
import { PrivacyContext } from "../../hooks/PrivacyStore";
import { ConsentBarButton } from "../../models/Property";
import { useLocationHash } from "../../hooks/useLocationHash";

const useStyles = createUseStyles({
  privacyNote: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: colors.gray10,
    borderTop: `1px solid ${colors.gray20}`,
    padding: `${spacings.m} 0`,
    zIndex: 100,
  },
  text: {
    ...customTypography(
      {
        ...typography.textSmall,
        marginBottom: 0,
        paddingLeft: rem(spacings.s),
        paddingRight: rem(spacings.s),
        display: "flex",
        alignItems: "flex-start",
      },
      {},
      {
        paddingLeft: 0,
        paddingRight: 0,
      },
      {
        alignItems: "center",
        flex: 1,
      }
    ),
  },
  openOverlaySpan: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  infoIcon: {
    ...FAIconStyles({
      width: 60,
      height: 60,
      color: colors.lightGreen40,
      marginRight: rem(spacings.sam),
    }),
  },
  consentButton: {
    maxHeight: 46,
    flexBasis: "100%",
    justifyContent: "center",
    marginLeft: 0,
    marginBottom: rem(spacings.s),
    "&:last-child": {
      marginBottom: 0,
    },
    ...onBreakpoint("md", {
      flexBasis: "auto",
      marginLeft: rem(spacings.sam),
      marginBottom: 0,
    }),
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    paddingLeft: rem(spacings.s),
    paddingRight: rem(spacings.s),
    paddingTop: rem(spacings.m),
    backgroundColor: colors.gray10,
    width: "100%",
    ...onBreakpoint("sm", {
      width: "auto",
      justifyContent: "flex-start",
      paddingLeft: `calc(1em + ${spacings.sam})`,
      paddingRight: 0,
      paddingTop: 0,
    }),
    ...onBreakpoint("md", {
      justifyContent: "flex-end",
      paddingLeft: 0,
      paddingTop: 0,
    }),
  },
  flexibleBar: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    ...onBreakpoint("sm", {
      flexWrap: "nowrap",
    }),
  },
});

export function PrivacyNote(props: ConsentBlockProps) {
  const privacyContext = useContext(PrivacyContext);
  const hash = useLocationHash();
  const showNotificationBar = props.data.useNotificationBar.value;
  const forceShow = hash === "#open-privacy-overlay";
  const stripEndingSlash = (url: string) => url.replace(/(.*?)\/+$/g, "$1");
  const isOnPrivacyPage =
    stripEndingSlash(window.location.pathname) ===
    stripEndingSlash(props.data.privacyPage.value?.[0]?.href || "");

  const [overlay, setOverlay] = useState(
    !isOnPrivacyPage && !showNotificationBar
  );
  const styles = useStyles();

  const [show, setShow] = useState(forceShow);

  useEffect(() => {
    isOnPrivacyPage &&
      window.addEventListener(
        "hashchange",
        () => {
          setShow(!show);
        },
        false
      );
    return () => {
      isOnPrivacyPage &&
        window.removeEventListener(
          "hashchange",
          () => {
            setShow(!show);
          },
          false
        );
    };
  });

  if (!privacyContext || !privacyContext.isInitialized) return null;

  const { showPrivacyNote, allowRequired, allowAll } = privacyContext;

  const actionMap = new Map<ConsentBarButton["function"], () => void>([
    ["accept_necessary", allowRequired],
    ["open_overlay", () => setOverlay(true)],
    ["accept_all", allowAll],
  ]);

  return (
    ((showPrivacyNote() || show) && (
      <>
        {showNotificationBar && showPrivacyNote() && (
          <div className={styles.privacyNote} data-testid="PrivacyNote">
            <Container>
              <Row>
                <Col md={12} className={styles.flexibleBar}>
                  <div className={styles.text}>
                    <div>
                      <FAIcon icon="info-circle" className={styles.infoIcon} />
                    </div>
                    <div>
                      <AnimatedRenderProperty value={props.data.barText} />
                    </div>
                  </div>
                  <div className={styles.buttons}>
                    {props.data.barButtons.value?.map((button, idx) => (
                      <Button
                        key={idx}
                        type={button.buttonType || "secondary"}
                        small
                        onClick={actionMap.get(button.function)}
                        className={styles.consentButton}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

        <PrivacyOverlay
          isVisible={overlay || show}
          close={() => {
            setOverlay(false);
            if (show) window.location.hash = "";
          }}
          {...props.data}
        />
      </>
    )) ||
    null
  );
}
