import React, { useContext, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { DownloadCenterSubcategoryBlockProps } from "../../models/content/DownloadCenterSubcategoryBlockData";
import { DownloadCenterContext } from "../../hooks/DownloadCenterContext";
import { typography } from "../../basics/typography";
import { RenderProperty } from "../../views/RenderProperty";
import cn from "classnames";
import { colors } from "../../basics/colors";

const useStyles = createUseStyles({
  button: {
    ...typography.textDefault,
    width: "100%",
    background: "none",
    textAlign: "left",
    transition: "color 0.3s ease",
    color: colors.gray100,
    "&:hover": {
      color: colors.black,
      transition: "color 0.3s ease",
    },
  },
  active: {
    fontWeight: "bold",
  },
});

export function DownloadCenterSubcategoryBlock(
  props: DownloadCenterSubcategoryBlockProps
) {
  const { activeSubcategory, setActiveSubcategory, setDownloads } = useContext(
    DownloadCenterContext
  );
  const [isActive, setActive] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const styles = useStyles();

  useEffect(() => {
    if (activeSubcategory === props.data.contentLink.id) {
      setDownloads({
        downloadsLocal: props.data.downloadsLocal,
        downloadsGlobal: props.data.downloadsGlobal,
      });
      setActive(true);
    } else {
      setActive(false);
    }
  }, [activeSubcategory]);

  return (
    <button
      onClick={() => {
        setActiveSubcategory(props.data.contentLink.id);
      }}
      className={cn(styles.button, { [styles.active]: isActive })}
      ref={buttonRef}
      onMouseUp={() => buttonRef.current && buttonRef.current.blur()}
    >
      <RenderProperty value={props.data.subCategoryLabel} />
    </button>
  );
}
