import React from "react";
import { RenderProperty, showPropertyOnEdit } from "../../views/RenderProperty";
import {
  Row,
  Col,
  rem,
  onBreakpoint,
  Container,
  MARGIN_OF_CONTAINER,
} from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { customTypography, typography } from "../../basics/typography";
import { spacings } from "../../basics/spacings";
import { colors } from "../../basics/colors";
import { FeaturedFactsBlockProps } from "../../models/content/FeaturedFactsBlockData";
import { Fact } from "../../models/Property";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { AnimatedRevealBlock } from "../AnimatedReveal/AnimatedReveal";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";

const useStyles = createUseStyles({
  element: {
    marginBottom: rem(spacings.m),
    paddingTop: rem(spacings.m),
    paddingBottom: rem(spacings.m),
    position: "relative",
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
      paddingTop: rem(spacings.l),
      paddingBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
      paddingTop: rem(spacings.xxl),
      paddingBottom: rem(spacings.xxl),
    }),
  },
  background: {
    position: "absolute",
    backgroundColor: colors.gray10,
    height: "100%",

    top: 0,
    width: `calc(2 * ${MARGIN_OF_CONTAINER} + 100%)`,
    left: `calc(-1 * ${MARGIN_OF_CONTAINER})`,
  },
  textContainer: {
    marginBottom: rem(spacings.s),
  },
  headline: {
    ...typography.h2,
    textAlign: "center",
  },
  description: customTypography(
    { ...typography.textDefault, textAlign: "center" },
    { marginBottom: rem(spacings.s) },
    { marginBottom: rem(spacings.sam) },
    { marginBottom: rem(spacings.m) }
  ),
  fullHeight: {
    height: "100%",
  },
});

export function FeaturedFactsBlock(props: FeaturedFactsBlockProps) {
  const styles = useStyles(props.data.facts.value?.length);
  const factsLength = props.data.facts.value?.length || 0;

  function isCenteredInXS(idx: number) {
    return idx === factsLength - 1 && idx % 2 === 0 && factsLength !== 1;
  }

  return (
    <Container className={styles.element} dataTestid="FeaturedFactsBlock">
      <div className={styles.background} />
      {(props.data.headline?.value || props.data.description?.value) && (
        <Row>
          <Col
            sm={10}
            smStart={1}
            md={6}
            mdStart={3}
            className={styles.textContainer}
          >
            {showPropertyOnEdit(props.data.headline) && (
              <SemanticHeader
                className={styles.headline}
                headerSize={props.data.headerSize?.value}
              >
                <RenderProperty value={props.data.headline} />
              </SemanticHeader>
            )}
            {showPropertyOnEdit(props.data.description) && (
              <div className={styles.description}>
                <RenderProperty value={props.data.description} />
              </div>
            )}
          </Col>
        </Row>
      )}
      <Row>
        {props.data.facts.value?.map((item, idx) => (
          <Col
            xs={factsLength > 1 ? 2 : 4}
            xsStart={isCenteredInXS(idx) ? 1 : undefined}
            sm={factsLength > 3 ? 3 : 4}
            smStart={
              (idx === 0
                ? Math.max((12 - 4 * factsLength) / 2, 0)
                : null) as Parameters<typeof Col>[0]["smStart"]
            }
            key={idx}
          >
            <AnimatedRevealBlock
              key={idx}
              revealDelay={idx * 200}
              className={styles.fullHeight}
            >
              <Card {...item} />
            </AnimatedRevealBlock>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

const useCardStyles = createUseStyles({
  card: {
    backgroundColor: colors.white,
    padding: `${spacings.m}`,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginBottom: rem(spacings.s),
    height: `calc(100% - ${spacings.s})`,
    ...onBreakpoint("sm", {
      marginBottom: 0,
      height: "100%",
    }),
    boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
  },
  cardIcon: {
    ...FAIconStyles({
      height: 56,
      width: 56,
    }),
    alignSelf: "center",
    color: colors.lightGreen40,
    marginBottom: rem(spacings.sam),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.s),
    }),
    ...onBreakpoint("md", {
      ...FAIconStyles({
        height: 88,
        width: 88,
      }),
      marginBottom: rem(spacings.sam),
    }),
  },
  labelContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
  label: {
    ...customTypography(
      {
        ...typography.h4,
        textAlign: "center",
      },
      {},
      {
        marginBottom: rem(spacings.s),
      }
    ),
  },
  description: {
    ...customTypography(
      {
        ...typography.textDefault,
        marginBottom: 0,
        textAlign: "center",
      },
      {},
      {
        marginBottom: 0,
      }
    ),
  },
});

function Card(props: Fact) {
  const styles = useCardStyles();
  return (
    <div className={styles.card} data-testid="FeaturedFactsCard">
      <FAIcon
        icon={(props.icon || undefined) as IconProp}
        className={styles.cardIcon}
      />
      <h4 className={styles.labelContainer}>
        <RenderProperty value={props.label} className={styles.label} />
      </h4>

      <RenderProperty
        value={props.description}
        className={styles.description}
      />
    </div>
  );
}
