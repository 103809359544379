import React, { useState, useEffect } from "react";
import ConsentBlockData from "../models/content/ConsentBlockData";
import { ConsentOption } from "../models/Property";

type ConsentOptionPropertyWithStatus = ConsentOption & {
  status: boolean;
};

type PIWIKConsentsResponse = {
  consents: { [key: string]: { status: number } };
};

export function usePrivacyStore(
  initialConsentData?: ConsentBlockData | null | undefined
) {
  const [retryCount, setRetryCount] = useState(0);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [consents, setConsents] = useState<Map<string, ConsentOption> | null>(
    null
  );
  const [consentsStatuses, setConsentsStatuses] = useState<
    Map<string, boolean>
  >(new Map());
  const ppms = (window as any)["ppms"];
  const ppmsInitedCorrectly = window.__PIWIK_LOADED__;

  useEffect(() => {
    if (typeof ppmsInitedCorrectly === "undefined" && retryCount < 3) {
      const timerId = window.setTimeout(() => setRetryCount((c) => c + 1), 500);
      return () => window.clearTimeout(timerId);
    }

    if (!initialConsentData || !ppms || !ppmsInitedCorrectly) {
      setIsInitialized(true);
      return;
    }

    const settingsPromise = new Promise<PIWIKConsentsResponse>(
      (resolve, reject) =>
        ppms.cm.api(
          "getComplianceSettings",
          (response: PIWIKConsentsResponse) => {
            resolve(response);
          },
          (error: any) => {
            reject(error);
          }
        )
    );
    const typesSettings = new Promise<Array<string>>((resolve, reject) =>
      ppms.cm.api(
        "getComplianceTypes",
        (response: Array<string>) => {
          resolve(response);
        },
        (error: any) => {
          reject(error);
        }
      )
    );

    let promisesCancelled = false;
    Promise.all([settingsPromise, typesSettings])
      .then(([settings, types]) => {
        const piwikConsents = new Set(types);
        const epiConsents = initialConsentData.consentOptions.value?.map(
          (consentOption) => consentOption.consentId
        );
        const commonConsents = new Set(
          epiConsents?.filter(
            (key: string | null) => key && piwikConsents.has(key)
          )
        );
        if (promisesCancelled) return;

        setConsents(
          new Map(
            (initialConsentData.consentOptions.value || [])
              .filter((consentOption) =>
                commonConsents.has(consentOption.consentId || "")
              )
              .map((consentOption) => [
                consentOption.consentId || "",
                consentOption,
              ])
          )
        );
        setConsentsStatuses(
          new Map(
            Object.entries(settings.consents)
              .filter(([key, _]) => commonConsents.has(key))
              .filter(([_, value]) => value.status !== -1)
              .map(([key, value]) => [key, value.status === 1])
          )
        );
      })
      .catch((reason: any) => console.error(reason))
      .then(() => !promisesCancelled && setIsInitialized(true));
    return () => {
      promisesCancelled = true;
    };
  }, [ppmsInitedCorrectly, retryCount]);

  const getConsentStatus = (consentName: string): boolean => {
    return !!consentsStatuses.get(consentName);
  };
  const getAllConsents = (): Array<ConsentOptionPropertyWithStatus> => {
    return (
      (consents &&
        Array.from(consents.entries()).map(([key, value]) => ({
          ...value,
          status: !!consentsStatuses.get(key),
        }))) ||
      []
    );
  };

  const showPrivacyNote = (): boolean => {
    return (
      (consents &&
        Array.from(consents.keys()).some(
          (key) => consentsStatuses.get(key) === undefined
        )) ||
      false
    );
  };

  const setUserConsentStatuses = (consents: Map<string, boolean>): void => {
    setConsentsStatuses((s) =>
      Array.from(consents.entries()).reduce(
        (acc, [key, value]) => acc.set(key, value),
        new Map(s)
      )
    );
    ppms.cm.api(
      "setComplianceSettings",
      {
        consents: Array.from(consents.entries()).reduce(
          (acc, [key, value]) => ({ ...acc, [key]: { status: value ? 1 : 0 } }),
          {}
        ),
      },
      () => {
        return;
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const allowRequired = () => {
    consents &&
      setUserConsentStatuses(
        new Map(
          Array.from(consents.entries()).map(([key, value]) => [
            key,
            value.isRequired || false,
          ])
        )
      );
  };

  const allowSelected = setUserConsentStatuses;

  const allowAll = () => {
    consents &&
      setUserConsentStatuses(
        new Map(Array.from(consents.keys()).map((key) => [key, true]))
      );
  };

  return {
    getConsentStatus,
    getAllConsents,
    showPrivacyNote,
    allowRequired,
    allowSelected,
    allowAll,
    isInitialized,
  };
}

export const PrivacyContext = React.createContext<{
  getConsentStatus: (consentName: string) => boolean;
  getAllConsents: () => Array<ConsentOptionPropertyWithStatus>;
  showPrivacyNote: () => boolean;
  allowRequired: () => void;
  allowSelected: (consents: Map<string, boolean>) => void;
  allowAll: () => void;
  isInitialized: boolean;
} | null>(null);
