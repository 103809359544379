import { useState, useEffect } from "react";
import Slider from "react-slick";

export function createSlider() {
  const [slider, setSlider] = useState<typeof Slider | null>(null);

  useEffect(() => {
    import(/* webpackChunkName: "zza.react-slick" */ "react-slick").then(
      (exportObject) => setSlider(() => exportObject.default)
    );
  }, []);

  return slider;
}
