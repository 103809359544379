import React from "react";
import { RenderProperty, showPropertyOnEdit } from "../../views/RenderProperty";
import { IconWithTextListBlockProps } from "../../models/content/IconWithTextListBlockData";
import {
  Container,
  Row,
  Col,
  rem,
  onBreakpoint,
  BREAKPOINTS,
} from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { RichTextWithTypographyRenderer } from "./RichTextBlock";
import { customTypography, typography } from "../../basics/typography";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import cn from "classnames";
import { spacings } from "../../basics/spacings";
import { ImageWithRichText } from "../../models/Property";
import { colors } from "../../basics/colors";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";

const useStyles = createUseStyles({
  element: {
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  headline: {
    ...typography.h2,
    textAlign: "center",
  },
  description: customTypography(
    { ...typography.textDefault, textAlign: "center" },
    { marginBottom: rem(spacings.s) },
    { marginBottom: rem(spacings.sam) },
    { marginBottom: rem(spacings.m) }
  ),

  item: {
    marginBottom: rem(spacings.xs),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.s),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.sam),
    }),
    alignItems: "center",
  },
  singleRow: {
    "& [class*='col']:last-of-type $item": {
      marginBottom: 0,
    },
  },
  richText: {
    "& :last-child": {
      marginBottom: 0,
    },
  },
  iconColumn: {
    alignSelf: "baseline",
    textAlign: "center",
  },
  icon: {
    ...FAIconStyles({
      color: colors.gray60,
      height: "100%",
      width: "100%",
    }),
  },
  iconSmall: {
    maxHeight: 100,
    ...onBreakpoint("sm", {
      maxHeight: 100,
    }),
  },
  iconBig: {
    maxHeight: 140,
    ...onBreakpoint("sm", {
      maxHeight: 140,
    }),
  },
  iconTwoColumnSmall: {
    maxHeight: 100,
    ...onBreakpoint("sm", {
      maxHeight: 140,
    }),
  },
  iconTwoColumnBig: {
    maxHeight: 140,
    ...onBreakpoint("sm", {
      maxHeight: 200,
    }),
  },
  textColumn: {
    alignSelf: "center",
  },
  grayBg: {
    backgroundColor: (isGrayBg) => isGrayBg && colors.gray10,
    padding: `${spacings.m} ${spacings.sam} ${spacings.m}`,
    ...onBreakpoint("sm", {
      padding: `${spacings.l} ${spacings.m} ${spacings.l}`,
    }),
    ...onBreakpoint("md", {
      padding: `${spacings.xxl} ${spacings.l} ${spacings.xxl}`,
    }),
  },
  bgRow: {
    maxWidth: BREAKPOINTS["xl"],
    margin: "0 auto",
  },
});

export function IconWithTextListBlock(props: IconWithTextListBlockProps) {
  const isSingleColumn = props.data.layout.value === "single-column";
  const isIconSmall = props.data.iconSize.value === "icon-small";

  const isGrayBg = props.data.backgroundColor?.value === "gray";

  const styles = useStyles(isGrayBg);

  return (
    <Container
      dataTestid="IconWithTextListBlock"
      isFluid={isGrayBg}
      className={cn(styles.element, isGrayBg ? styles.grayBg : undefined)}
    >
      <Row className={isGrayBg ? styles.bgRow : undefined}>
        {showPropertyOnEdit(props.data.headline) && (
          <Col>
            <SemanticHeader
              className={styles.headline}
              headerSize={props.data.headerSize?.value}
            >
              <RenderProperty value={props.data.headline} />
            </SemanticHeader>
          </Col>
        )}
        {showPropertyOnEdit(props.data.description) && (
          <Col sm={10} smStart={1} md={6} mdStart={3}>
            <div className={styles.description}>
              <RenderProperty value={props.data.description} />
            </div>
          </Col>
        )}
      </Row>

      <Row
        className={cn(
          isGrayBg ? styles.bgRow : undefined,
          isSingleColumn && styles.singleRow
        )}
      >
        {isSingleColumn ? (
          <>
            {props.data.items.value?.map((item, idx) => (
              <SingleColumnLayoutItem
                key={idx}
                item={item}
                isIconSmall={isIconSmall}
              />
            ))}
          </>
        ) : (
          <Col md={10} mdStart={1} lg={8} lgStart={2}>
            <Row>
              {props.data.items.value?.map((item, idx) => (
                <TwoColumnLayoutItem
                  key={idx}
                  item={item}
                  isIconSmall={isIconSmall}
                />
              ))}
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
}

function SingleColumnLayoutItem(props: {
  item: ImageWithRichText;
  isIconSmall: boolean;
}) {
  const styles = useStyles();

  return (
    <Col>
      <Row className={styles.item}>
        <Col
          xs={1}
          sm={props.isIconSmall ? 1 : 2}
          smStart={1}
          mdStart={2}
          className={styles.iconColumn}
        >
          <div>
            <FAIcon
              icon={(props.item.image || "") as IconName}
              className={cn(
                styles.icon,
                props.isIconSmall ? styles.iconSmall : styles.iconBig
              )}
            />
          </div>
        </Col>
        <Col
          xs={3}
          sm={props.isIconSmall ? 9 : 8}
          smStart={props.isIconSmall ? 2 : 3}
          md={props.isIconSmall ? 7 : 6}
          mdStart={props.isIconSmall ? 3 : 4}
          className={styles.textColumn}
        >
          <RichTextWithTypographyRenderer
            value={props.item.richText || ""}
            className={styles.richText}
          />
        </Col>
      </Row>
    </Col>
  );
}

function TwoColumnLayoutItem(props: {
  item: ImageWithRichText;
  isIconSmall: boolean;
}) {
  const styles = useStyles();
  return (
    <Col sm={6} md={6}>
      <Row className={styles.item}>
        <Col
          xs={1}
          sm={props.isIconSmall ? 3 : 4}
          className={styles.iconColumn}
        >
          <div>
            <FAIcon
              icon={(props.item.image || "") as IconName}
              className={cn(
                styles.icon,
                props.isIconSmall
                  ? styles.iconTwoColumnSmall
                  : styles.iconTwoColumnBig
              )}
            />
          </div>
        </Col>
        <Col
          xs={3}
          sm={props.isIconSmall ? 9 : 7}
          smStart={props.isIconSmall ? 3 : 4}
          className={styles.textColumn}
        >
          <RichTextWithTypographyRenderer
            value={props.item.richText || ""}
            className={styles.richText}
          />
        </Col>
      </Row>
    </Col>
  );
}
