export function convertPascalCaseToCamelCase<T>(obj: T): T {
  function camalize(str: string) {
    return str
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function ($1) {
        return $1.toLowerCase();
      });
  }

  return Array.from(Object.entries(obj))
    .map(([key, value]) => [
      camalize(key),
      typeof value === "object" && value !== null
        ? Array.isArray(value)
          ? value.map((item) =>
              typeof item === "object" && item !== null
                ? convertPascalCaseToCamelCase(item)
                : item
            )
          : convertPascalCaseToCamelCase(value)
        : value,
      value,
    ])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) as T;
}
