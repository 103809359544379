import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Container,
  onBefore,
  onBreakpoint,
  rem,
  Row,
} from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { customTypography, typography } from "../../basics/typography";
import { spacings } from "../../basics/spacings";
import { colors } from "../../basics/colors";
import { Button, ButtonWrapper } from "../Button/Button";
import DocumentFileData from "../../models/content/DocumentFileData";
import { RenderProperty } from "../../views/RenderProperty";
import ProductDownloadBlockData from "../../models/content/ProductDownloadBlockData";
import {
  LocalizedLabel,
  localizedLabelString,
  LocalizationContext,
} from "../../hooks/LocalizationContext";
import { DownloadBlockProps } from "../../models/content/DownloadBlockData";
import { StringProperty, ButtonListProperty } from "../../models/Property";
import cn from "classnames";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import { DownloadItem } from "../DownloadItem/DownloadItem";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";

const downloadStyles = createUseStyles({
  downloadsBackground: ({ isFullWidth }) => ({
    backgroundColor: isFullWidth ? colors.white : colors.gray10,
  }),
  downloads: {
    marginBottom: rem(spacings.m),
    paddingTop: rem(spacings.m),
    paddingBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
      paddingTop: rem(spacings.l),
      paddingBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
      paddingTop: rem(spacings.xxl),
      paddingBottom: rem(spacings.xxl),
    }),
  },
  infoSection: {
    ...onBefore("md", {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      marginBottom: rem(spacings.xl),
    }),
  },
  headline: {
    ...typography.h2,
    wordBreak: "break-all",
  },
  description: {
    ...customTypography(
      { ...typography.textDefault },
      {},
      {
        marginBottom: rem(spacings.m),
      }
    ),
  },
});

export type DownloadProps = DocumentFileData & {
  masterFile: ProductDownloadBlockData["masterFile"];
  localizedFiles: ProductDownloadBlockData["localizedFiles"];
};

export function DownloadBlockWrapper(
  props: DownloadBlockProps & { context?: { disableFeaturedOption?: boolean } }
) {
  return (
    <DownloadBlock
      downloadItems={props.data.downloads.expandedValue as Array<DownloadProps>}
      headline={props.data.headline}
      headerSize={props.data.headerSize}
      description={props.data.description}
      buttons={props.data.buttons}
      disableFeaturedOption={props.context?.disableFeaturedOption}
    />
  );
}

export function DownloadBlock(props: {
  downloadItems?: Array<DownloadProps>;
  headline?: StringProperty;
  headerSize?: StringProperty;
  description?: StringProperty;
  buttons?: ButtonListProperty;
  isFullWidth?: boolean;
  disableFeaturedOption?: boolean;
}) {
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  function getFeaturedSortedItems() {
    const featuredLocal =
      (props.downloadItems
        ?.filter((di) => di.contentType[1] === "DocumentFile")
        .filter((mf) => mf?.isFeatured?.value) as Array<DownloadProps>) || [];

    const standardLocal =
      (props.downloadItems
        ?.filter((di) => di.contentType[1] === "DocumentFile")
        .filter((mf) => !mf?.isFeatured?.value) as Array<DownloadProps>) || [];

    const featuredGlobal =
      (props.downloadItems
        ?.filter((di) => di.contentType[1] === "ProductDownloadBlock")
        .filter(
          (mf) => mf.masterFile?.expandedValue?.isFeatured?.value
        ) as Array<DownloadProps>) || [];

    const standardGlobal =
      (props.downloadItems
        ?.filter((di) => di.contentType[1] === "ProductDownloadBlock")
        .filter(
          (mf) => !mf.masterFile?.expandedValue?.isFeatured?.value
        ) as Array<DownloadProps>) || [];

    return [
      ...featuredLocal,
      ...featuredGlobal,
      ...standardLocal,
      ...standardGlobal,
    ];
  }

  const sortedItems = props.disableFeaturedOption
    ? props.downloadItems
    : getFeaturedSortedItems();

  const styles = downloadStyles({ isFullWidth: props.isFullWidth });
  const localizationCtx = useContext(LocalizationContext);

  if (!startPageData) return null;

  const downloadCenterUrl = startPageData?.downloadCenter.value?.url;

  return (
    <Container
      className={cn(
        styles.downloadsBackground,
        !props.isFullWidth && styles.downloads
      )}
      isFluid
    >
      <Container>
        <Row>
          {!props.isFullWidth && (
            <Col md={4}>
              <Row>
                <Col
                  sm={8}
                  smStart={2}
                  md={12}
                  mdStart={null}
                  className={styles.infoSection}
                >
                  <SemanticHeader
                    className={styles.headline}
                    headerSize={props.headerSize?.value}
                  >
                    <RenderProperty
                      value={props.headline}
                      fallbackValue={localizedLabelString({
                        section: "Download",
                        label: "Headline",
                        localizationCtx,
                      })}
                    />
                  </SemanticHeader>
                  <span className={styles.description}>
                    <RenderProperty
                      value={props.description}
                      fallbackValue={localizedLabelString({
                        section: "Download",
                        label: "Description",
                        localizationCtx,
                      })}
                    />
                  </span>
                  {props.buttons ? (
                    <>
                      {props.buttons.value?.map((b, idx) => (
                        <ButtonWrapper {...b} key={idx} />
                      ))}
                    </>
                  ) : (
                    downloadCenterUrl && (
                      <Button type="secondary" href={downloadCenterUrl}>
                        <LocalizedLabel
                          section="Download"
                          label="CenterButton"
                        />
                      </Button>
                    )
                  )}
                </Col>
              </Row>
            </Col>
          )}
          <Col md={props.isFullWidth ? 12 : 8}>
            {sortedItems?.map((di, idx) => {
              const contentType = di.contentType.join("/");
              return contentType === "Block/ProductDownloadBlock" ? (
                <DownloadItem
                  {...(di as ProductDownloadBlockData).masterFile.expandedValue}
                  localizedFiles={
                    (di as ProductDownloadBlockData).localizedFiles
                      .expandedValue
                  }
                  context={{
                    disableFeaturedOption: props.disableFeaturedOption,
                  }}
                  key={idx}
                />
              ) : (
                <DownloadItem
                  {...(di as DocumentFileData)}
                  context={{
                    disableFeaturedOption: props.disableFeaturedOption,
                  }}
                  key={idx}
                />
              );
            })}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
