import React, { useState } from "react";
import { ImageBlockProps } from "../../models/content/ImageBlockData";
import {
  BREAKPOINTS,
  Col,
  Container,
  onBreakpoint,
  rem,
  Row,
} from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { RenderProperty } from "../../views/RenderProperty";
import { customTypography, typography } from "../../basics/typography";
import { spacings } from "../../basics/spacings";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { colors } from "../../basics/colors";
import { ModalWithCarousel } from "../ModalWithCarousel/ModalWithCarousel";
import cn from "classnames";
import { SemanticHeader } from "../SemanticHeader/SematicHeader";

const imageStyles = createUseStyles({
  imageBlock: {
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      display: "block",
      marginBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xxl),
    }),
  },
  textContainer: {
    marginBottom: rem(spacings.s),
  },
  headline: {
    ...typography.h2,
    textAlign: "center",
  },
  description: customTypography(
    { ...typography.textDefault, textAlign: "center" },
    { marginBottom: rem(spacings.s) },
    { marginBottom: rem(spacings.sam) },
    { marginBottom: rem(spacings.m) }
  ),
  image: {
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
    background: "none",
    border: 0,
    padding: 0,
    width: "100%",
    ...onBreakpoint("sm", {
      "&:after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        transition: "opacity 0.3s ease",
        backgroundColor: colors.darkGreen100,
      },
      "&:hover, &:focus": {
        "&:after": {
          opacity: 0.2,
          transition: "opacity 0.3s ease",
        },
        "& $zoomWatermark": {
          opacity: 1,
          transform: "translate(-50%, -50%)",
        },
        "& $imageInner": {
          transform: "scale(1.04)",
        },
      },
    }),
    "& img": {
      width: "100%",
      display: "block",
    },
  },
  imageInner: {
    width: "100%",
    height: "100%",
    transform: "scale(1)",
    transition: "transform 0.3s ease",
  },
  imageCaption: {
    marginTop: rem(spacings.xxs),
    "& p": {
      ...typography.textXSmall,
      color: colors.gray60,
    },
  },
  zoomWatermark: {
    ...FAIconStyles({
      position: "absolute",
      color: colors.gray10,
      opacity: 0,
      width: 96,
      height: 96,
      transition: "transform 0.3s ease, opacity 0.3s ease",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -25%)",
      zIndex: 1,
    }),
  },
  grayBg: {
    backgroundColor: (isGrayBg) => isGrayBg && colors.gray10,
    padding: `${spacings.m} ${spacings.sam} ${spacings.m}`,
    ...onBreakpoint("sm", {
      padding: `${spacings.l} ${spacings.m} ${spacings.l}`,
    }),
    ...onBreakpoint("md", {
      padding: `${spacings.xxl} ${spacings.l} ${spacings.xxl}`,
    }),
  },
  bgRow: {
    maxWidth: BREAKPOINTS["xl"],
    margin: "0 auto",
  },
});

export function ImageBlock(props: ImageBlockProps) {
  const { image, imageCaption, title, description } = props.data;
  const [modalOpened, setModalOpened] = useState(false);

  const isGrayBg = props.data.backgroundColor?.value === "gray";

  const styles = imageStyles(isGrayBg);

  const closeModal = () => {
    setModalOpened(false);
  };

  return (
    <>
      <Container
        isFluid={isGrayBg}
        className={cn(styles.imageBlock, isGrayBg ? styles.grayBg : undefined)}
      >
        {(title?.value || description?.value) && (
          <Row className={isGrayBg ? styles.bgRow : undefined}>
            <Col
              sm={10}
              smStart={1}
              md={8}
              mdStart={2}
              className={styles.textContainer}
            >
              <SemanticHeader
                className={styles.headline}
                headerSize={props.data.headerSize?.value}
              >
                <RenderProperty value={title} />
              </SemanticHeader>
              <div className={styles.description}>
                <RenderProperty value={description} />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={8} smStart={2} md={6} mdStart={3}>
            <button
              className={styles.image}
              onClick={() => setModalOpened(true)}
            >
              <RenderProperty className={styles.imageInner} value={image} />
              <FAIcon icon="search-plus" className={styles.zoomWatermark} />
            </button>

            <RenderProperty
              value={imageCaption}
              className={styles.imageCaption}
            />
          </Col>
        </Row>
      </Container>
      <ModalWithCarousel
        images={[
          {
            image: image.value,
            description: imageCaption.value || "",
          },
        ]}
        modalOpened={modalOpened}
        closeModal={closeModal}
      />
    </>
  );
}
