import React from "react";
import { rem } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { fonts } from "../../basics/fonts";

const useStyles = createUseStyles({
  element: {
    marginRight: rem(spacings.xs),
    marginBottom: rem(spacings.s),
    padding: `${spacings.xs} ${spacings.xs} ${spacings.xs} ${spacings.s}`,
    backgroundColor: colors.gray60,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
  },
  text: {
    ...fonts.sansRegular,
    fontSize: "1rem",
    color: colors.white,
    paddingRight: rem(spacings.xs),
    textAlign: "left",
  },
  button: {
    fill: colors.white,
    padding: 0,
    background: "none",
    display: "inline-flex",
    alignItems: "center",
  },
  icon: FAIconStyles({
    width: 24,
    height: 24,
  }),
});

export function TagBadge(props: {
  label: string;
  onBadgeClick?: () => void;
  onCloseClick?: () => void;
  href?: string;
  isReadOnly?: boolean;
}) {
  const styles = useStyles();
  const Element = props.href
    ? (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
        <a {...props}>{props.children}</a>
      )
    : (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
        <button role="button" {...props}>
          {props.children}
        </button>
      );

  return (
    <Element
      className={styles.element}
      onClick={props.onBadgeClick}
      href={props.href}
    >
      <span className={styles.text}>
        <b>{props.label}</b>
      </span>
      {!props.isReadOnly && (
        <button
          role="button"
          className={styles.button}
          onClick={props.onCloseClick}
        >
          <FAIcon icon="times" className={styles.icon} />
        </button>
      )}
    </Element>
  );
}
