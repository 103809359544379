import { useState, useLayoutEffect } from "react";
import { isCypressTest } from "../basics/isCypressTest";
import { useDebounce } from "./useDebounce";

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  const { debounce } = useDebounce();
  useLayoutEffect(() => {
    function updateSize() {
      const innerWidth = window.innerWidth;
      if (innerWidth === width) return;
      isCypressTest()
        ? setWidth(innerWidth)
        : debounce(() => setWidth(innerWidth));
    }
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return width;
}
