import { Validator } from "./Elements/common";

export function getValidators(
  validators: Validator[]
): (value: any) => true | string[] {
  if (validators.length === 0) return (_) => true;

  function requiredValidator(value: any, message: string) {
    return value === null || value === "" ? message : true;
  }

  function regexValidator(
    value: any,
    message: string,
    pattern: string | undefined
  ) {
    if (!pattern) return true;
    const regex = new RegExp(pattern);
    return value && regex.test(String(value))
      ? true
      : message.replace("{0}", pattern);
  }

  return (value: any) =>
    validators
      .map((validator) => {
        switch (validator.type) {
          case "RequiredValidator":
            return requiredValidator(value, validator.errorMsg);
          case "RegularExpressionValidator":
          case "EmailValidator":
          case "IntegerValidator":
          case "PositiveIntegerValidator":
          case "DateDDMMYYYYValidator":
          case "DateMMDDYYYYValidator":
          case "DateYYYYMMDDValidator":
            return regexValidator(
              value,
              validator.errorMsg,
              validator.model?.jsPattern
            );
          default:
            return true;
        }
      })
      .reduce<true | string[]>(
        (acc, validatorResult) =>
          validatorResult === true
            ? acc
            : Array.isArray(acc)
            ? [...acc, validatorResult]
            : [validatorResult],
        true
      );
}
