import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, rem, Row } from "../../../basics/layout";
import { typography } from "../../../basics/typography";
import { spacings } from "../../../basics/spacings";
import { ContactListOverlay } from "./ContactListOverlay";
import { ContactListBlockProps } from "../../../models/content/ContactListBlockData";
import ContactPersonBlockData from "../../../models/content/ContactPersonBlockData";
import { RenderProperty } from "../../../views/RenderProperty";
import { PersonCard } from "./PersonCard";
import { LocationCard } from "./LocationCard";
import ContactGenericBlockData from "../../../models/content/ContactGenericBlockData";
import ContactLocationBlockData from "../../../models/content/ContactLocationBlockData";
import { StringProperty } from "../../../models/Property";
import { SemanticHeader } from "../../SemanticHeader/SematicHeader";

export type ContactCardsBlock = ContactPersonBlockData &
  ContactLocationBlockData &
  ContactGenericBlockData;

const contactStyles = createUseStyles({
  contactList: {
    marginBottom: rem(spacings.s),
    ...onBreakpoint("sm", {
      display: "block",
      marginBottom: rem(spacings.sam),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.m),
    }),
  },
  headline: {
    ...typography.h2,
    textAlign: "center",
  },
});

export function ContactListBlock(props: ContactListBlockProps) {
  return (
    <ContactListBlockComponent
      headline={props.data.headline}
      headerSize={props.data.headerSize}
      contacts={
        (props.data.contacts?.expandedValue as ContactCardsBlock[]) || null
      }
    />
  );
}

export function ContactListBlockComponent(props: {
  headline?: StringProperty;
  headerSize?: StringProperty;
  contacts: ContactCardsBlock[] | null;
  isWide?: boolean;
}) {
  const isLocationCard = props.contacts
    ?.map((c) => c.contentType[1])
    .includes("ContactLocationBlock");

  const styles = contactStyles(isLocationCard);
  const contactsAmount = props.contacts?.filter(
    (c) =>
      c.contentType[1] === "ContactPersonBlock" ||
      c.contentType[1] === "ContactGenericBlock"
  ).length;

  const [modalOpened, setModalOpened] = useState(false);
  const [slide, setSlide] = useState<number>(0);

  const closeModal = () => {
    setModalOpened(false);
  };

  function openModal(slide: number) {
    setModalOpened(true);
    setSlide(slide);
  }

  return (
    <>
      <Container className={styles.contactList}>
        {props.headline && (
          <Row>
            <Col sm={10} smStart={1} md={8} mdStart={2}>
              <SemanticHeader
                headerSize={props.headerSize?.value}
                className={styles.headline}
              >
                <RenderProperty value={props.headline} />
              </SemanticHeader>
            </Col>
          </Row>
        )}
        <Row>
          {props.contacts?.map((c, idx) =>
            c.contentType[1] === "ContactPersonBlock" ||
            c.contentType[1] === "ContactGenericBlock" ? (
              <PersonCard
                {...c}
                key={idx}
                contactsAmount={contactsAmount || 0}
                itemNumber={idx}
                openModal={openModal}
                isWide={props.isWide}
              />
            ) : (
              <LocationCard
                {...c}
                key={idx}
                itemNumber={idx}
                inContactList
                openModal={openModal}
              />
            )
          )}
        </Row>
      </Container>
      {props.contacts && (
        <ContactListOverlay
          contacts={props.contacts}
          modalOpened={modalOpened}
          closeModal={closeModal}
          initialSlide={slide}
        />
      )}
    </>
  );
}
