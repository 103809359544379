import { spacings } from "./spacings";
import { onBreakpoint, rem } from "./layout";
import { fonts } from "./fonts";
import { colors } from "./colors";

export const overridedTypography = {
  hero: {
    ...fonts.sansBold,
    fontSize: "2.5rem",
    lineHeight: 1.2,
    color: colors.gray100,
    letterSpacing: 0.76,
    marginTop: 0,
    marginBottom: rem(spacings.s),
    ...onBreakpoint("md", {
      fontSize: "4.75rem",
      lineHeight: 1.05,
      marginBottom: rem(spacings.l),
    }),
  },

  h1: {
    ...fonts.sansBold,
    fontSize: "3rem",
    lineHeight: 1.2,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.s),
    ...onBreakpoint("sm", {
      fontSize: "4rem",
      lineHeight: 1.13,
      marginBottom: rem(spacings.m),
    }),

    ...onBreakpoint("md", {
      fontSize: "4.75rem",
      lineHeight: 1.05,
      marginBottom: rem(spacings.l),
    }),
  },

  h2: {
    ...fonts.sansBold,
    fontSize: "1.875rem",
    lineHeight: 1.07,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.s),
    ...onBreakpoint("sm", {
      fontSize: "3rem",
      lineHeight: 1.17,
      marginBottom: rem(spacings.m),
    }),

    ...onBreakpoint("md", {
      fontSize: "3.5rem",
      lineHeight: 1.14,
      marginBottom: rem(spacings.l),
    }),
  },

  h3: {
    ...fonts.sansBold,
    fontSize: "1.5rem",
    lineHeight: 1,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.s),
    ...onBreakpoint("sm", {
      fontSize: "2.5rem",
      lineHeight: 1.2,
      marginBottom: rem(spacings.m),
    }),
  },

  h4: {
    ...fonts.sansBold,
    fontSize: "1.25rem",
    lineHeight: 1.2,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.s),
    ...onBreakpoint("sm", {
      fontSize: "1.5rem",
      lineHeight: 1.33,
      marginBottom: rem(spacings.m),
    }),

    ...onBreakpoint("md", {
      fontSize: "1.75rem",
      lineHeight: 1.14,
    }),
  },

  h5: {
    ...fonts.sansBold,
    fontSize: "1.125rem",
    lineHeight: 1.33,
    color: colors.gray100,
    marginTop: 0,
    marginBottom: rem(spacings.s),
    display: "block",
    ...onBreakpoint("sm", {
      fontSize: "1.5rem",
      lineHeight: 1.2,
      marginBottom: rem(spacings.m),
    }),

    ...onBreakpoint("md", {
      lineHeight: 1.33,
    }),
  },

  textLarge: {
    ...fonts.sansRegular,
    fontSize: "1.25rem",
    lineHeight: 1.6,
    color: colors.gray100,
    fontWeight: "normal",
    marginTop: 0,
    marginBottom: rem(spacings.m),
    ...onBreakpoint("md", {
      fontSize: "1.5rem",
      lineHeight: 1.33,
    }),
  },

  textSmall: {
    ...fonts.sansRegular,
    fontSize: "1rem",
    lineHeight: 1.5,
    color: colors.gray100,
    marginTop: 0,
    paddingBottom: 0,
    marginBottom: rem(spacings.m),
    "& a": {
      color: colors.lightGreen50,
      "&:hover": {
        color: colors.lightGreen40,
        textDecoration: "underline",
      },
    },
  },

  textDefault: {
    ...fonts.sansRegular,
    fontSize: "1rem",
    lineHeight: 1.5,
    color: colors.gray100,
    marginTop: 0,
    paddingBottom: 0,
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      fontSize: "1.125rem",
      lineHeight: 1.78,
    }),

    ...onBreakpoint("md", {
      fontSize: "1.25rem",
      lineHeight: 1.6,
    }),

    "& a": {
      color: colors.lightGreen50,
      "&:hover": {
        color: colors.lightGreen40,
        textDecoration: "underline",
      },
    },
  },

  caption: {
    ...fonts.sansRegular,
    fontSize: "1rem",
    lineHeight: 2,
    color: colors.gray60,
    letterSpacing: 1.8,
    textTransform: "uppercase",
    marginTop: 0,
    marginBottom: rem(spacings.m),
    ...onBreakpoint("md", {
      fontSize: "1.25rem",
      lineHeight: 1.6,
    }),
  },

  uList: {
    paddingLeft: rem(spacings.l),
    paddingTop: 0,
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
    }),

    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xl),
    }),
    "& > ul": {
      paddingLeft: 0,
    },
    "& li": {
      ...fonts.sansRegular,
      fontSize: "1rem",
      lineHeight: "1.5 !important",
      color: colors.gray100,
      listStyle: "none",
      marginTop: 0,
      padding: "0 !important",
      display: "block",
      marginBottom: `${spacings.s} !important`,
      position: "relative",
      ...onBreakpoint("sm", {
        fontSize: "1.125rem",
        lineHeight: "1.78em !important",
      }),

      ...onBreakpoint("md", {
        fontSize: "1.25rem",
        lineHeight: "1.6em !important",
      }),
      "&:last-child": {
        marginBottom: "0 !important",
      },
      "&:before": {
        position: "absolute",
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f178"',
        fontSize: "1.6rem",
        fontWeight: 300,
        left: `-${spacings.l}`,
        color: colors.gray40,
        backgroundColor: "transparent",
        top: 0,
      },
      "& > *": {
        display: "inline",
      },
      "& a": {
        ...fonts.sansRegular,
        fontSize: "1rem",
        lineHeight: 1.5,
        color: colors.lightGreen50,
        ...onBreakpoint("sm", {
          fontSize: "1.125rem",
          lineHeight: 1.78,
        }),

        ...onBreakpoint("md", {
          fontSize: "1.25rem",
          lineHeight: 1.6,
        }),
        "&:hover": {
          color: colors.lightGreen40,
          textDecoration: "underline",
        },
      },

      "& p": {
        marginBottom: 0,
      },
    },
  },

  oList: {
    counterReset: "counter",
    paddingLeft: rem(spacings.l),
    paddingTop: 0,
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
    }),

    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xl),
    }),
    "& li": {
      counterIncrement: "counter",
      ...fonts.sansRegular,
      fontSize: "1rem",
      lineHeight: 1.5,
      color: colors.gray100,
      marginTop: 0,
      padding: 0,
      listStyle: "none",
      marginBottom: rem(spacings.s),
      position: "relative",
      display: "block",
      ...onBreakpoint("sm", {
        fontSize: "1.125rem",
        lineHeight: 1.78,
      }),

      ...onBreakpoint("md", {
        fontSize: "1.25rem",
        lineHeight: 1.6,
      }),
      "&:last-child": {
        marginBottom: "0 !important",
      },
      "&:before": {
        position: "absolute",
        content: "counter(counter) '. '",
        left: `-${spacings.m}`,
        color: colors.gray40,
      },
      "& > *": {
        display: "inline",
      },
      "& a": {
        ...fonts.sansRegular,
        fontSize: "1rem",
        lineHeight: 1.5,
        color: colors.lightGreen50,
        ...onBreakpoint("sm", {
          fontSize: "1.125rem",
          lineHeight: 1.78,
        }),

        ...onBreakpoint("md", {
          fontSize: "1.25rem",
          lineHeight: 1.6,
        }),
        "&:hover": {
          color: colors.lightGreen40,
          textDecoration: "underline",
        },
      },

      "& p": {
        marginBottom: 0,
      },
    },
  },
};
