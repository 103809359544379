import React, { useEffect, useState } from "react";
import { Container, onBreakpoint, rem } from "../../../basics/layout";
import { createUseStyles } from "react-jss";
import { FactsAndFiguresLayout } from "./FactsAndFiguresLayout";
import { FactsAndFiguresBlockProps } from "../../../models/content/FactsAndFiguresBlockData";
import { spacings } from "../../../basics/spacings";

type ElementType =
  | {
      type: "headline" | "text" | "icon";
      value: string;
    }
  | {
      type: "number";
      value: number;
      prefix?: string;
      suffix?: string;
      decimalSeparator?: string;
      thousandsSeparator?: string;
    };

export type FactsAndFigureElement = {
  elements: Array<ElementType>;
  schema?: "lightGreen" | "darkGreen" | "lightGreenAlt" | "darkGreenAlt";
  backgroundImage?: string;
};

export type FactsAndFiguresProps = {
  layout: "1" | "2" | "3" | "4";
  blocks: Array<FactsAndFigureElement>;
};

const useStyles = createUseStyles({
  factsAndFigures: {
    marginBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.l),
    }),
    display: "flex",
    flexWrap: "wrap",
    ...onBreakpoint("md", {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridTemplateRows: "repeat(2, 1fr)",
      gridColumnGap: 24,
      gridRowGap: 24,
      marginBottom: rem(spacings.xxl),
    }),
  },
});

export function FactsAndFigures(props: FactsAndFiguresBlockProps) {
  const { jsonRaw } = props.data;

  const [factsAndFiguresData, setFactsAndFiguresData] =
    useState<FactsAndFiguresProps>();

  useEffect(() => {
    try {
      const data = JSON.parse(jsonRaw.value || "") as FactsAndFiguresProps;
      setFactsAndFiguresData(data);
    } catch {
      console.error("No json file.");
    }
  }, []);

  const styles = useStyles();
  return (
    <Container className={styles.factsAndFigures}>
      <FactsAndFiguresLayout
        blocks={factsAndFiguresData?.blocks || []}
        layout={factsAndFiguresData?.layout || "1"}
      />
    </Container>
  );
}
