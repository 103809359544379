import React, { useState, useEffect } from "react";
import { Col, Container, onBreakpoint, rem, Row } from "../../basics/layout";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { typography } from "../../basics/typography";
import { createUseStyles } from "react-jss";
import ReactImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import {
  CloudinaryVideoProps,
  CloudinaryVideoPlayer,
} from "../CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import { AdaptiveSingleImage } from "../../models/Property";
import { animations } from "../../basics/animations";
import { Modal, ModalProps } from "../Modal/Modal";
import { Spinner } from "../Spinner/Spinner";

export type ImageGalleryItem = {
  image: AdaptiveSingleImage | null;
  description: string;
};

interface ModalWithCarouselProps extends ModalProps {
  images?: Array<ImageGalleryItem>;
  video?: CloudinaryVideoProps;
}

const modalStyles = createUseStyles({
  ...animations.grow(0.75),
  imageCarousel: {
    width: "100%",
    margin: "0 auto",
    "& .image-gallery-slide": {
      outline: "none",
      "&.center": {
        opacity: 1,
        transform: "scale(1) !important",
        transition: "all 0.8s ease !important",
      },
      "&.left, &.right": {
        opacity: 0,
        transform: "scale(0.8) !important",
        transition: "all 0.4s ease !important",
      },
      "& > div": {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    "& .image-gallery-image": {
      width: "auto",
      maxWidth: "100%",
      objectFit: "scale-down",
      filter: "drop-shadow(0px 0px 10px rgba(0,0,0,.4))",
      animation: "$grow 0.3s ease",
    },
    "& .image-gallery-description": {
      ...typography.textSmall,
      display: "block",
      width: "100%",
      marginTop: rem(spacings.xs),
      marginBottom: 0,
      padding: 0,
      position: "static",
      backgroundColor: "transparent",
      ...onBreakpoint("md", {
        marginTop: rem(spacings.sam),
      }),
    },
    "& .image-gallery-icon": {
      opacity: 0.85,
      transition: "opacity 0.3s ease",
      color: colors.white,
      padding: "0 10px",
      filter: "none",
      "&:hover": {
        opacity: 1,
        transition: "opacity 0.3s ease",
      },
      "&:after": {
        fontFamily: "Font Awesome\\ 5 Pro",
        fontSize: "3.9rem",
        lineHeight: 1,
      },
    },
    "& .image-gallery-left-nav": {
      left: -40,
      "&:after": {
        content: '"\\f104"',
      },
    },
    "& .image-gallery-right-nav": {
      right: -40,
      "&:after": {
        content: '"\\f105"',
      },
    },
    "& .image-gallery-left-nav, & .image-gallery-right-nav": {
      outline: "none",
      border: `1px solid transparent`,
      "&:focus": {
        border: `1px solid ${colors.white}`,
        borderRadius: "5px",
      },
    },
    "& .image-gallery-thumbnails-wrapper, & .image-gallery-svg": {
      display: "none",
    },
  },
  videoElementWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  videoElement: {
    width: "min(calc(80vh*16/9), 100%)",
    filter: "drop-shadow(0px 0px 10px rgba(0,0,0,.4))",
  },
  imageDescription: {
    color: colors.white,
    userSelect: "text",
  },
});

export function ModalWithCarousel(props: ModalWithCarouselProps) {
  const styles = modalStyles({
    isOpened: props.modalOpened,
  });

  function transformImages(
    images: Array<ImageGalleryItem>
  ): Array<ReactImageGalleryItem> {
    return images.map((img) => ({
      original:
        img.image?.urlOriginal ||
        img.image?.urlLarge ||
        img.image?.urlMedium ||
        img.image?.urlSmall ||
        "",
      description: (
        <div
          className={styles.imageDescription}
          dangerouslySetInnerHTML={{ __html: img.description }}
        />
      ) as any,
    }));
  }

  const [ImageGallery, setImageGallery] = useState<
    typeof ReactImageGallery | null
  >(null);

  useEffect(() => {
    import(
      /* webpackChunkName: "zza.react-image-gallery" */ "react-image-gallery"
    ).then((exportObject) =>
      setImageGallery(() => exportObject.default as any)
    );
  }, []);

  return (
    <Modal {...props}>
      <Container>
        <Row>
          <Col>
            {props.images && (
              <>
                {ImageGallery ? (
                  <ImageGallery
                    items={transformImages(props.images)}
                    slideDuration={200}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    additionalClass={styles.imageCarousel}
                  />
                ) : (
                  <Spinner />
                )}
              </>
            )}
            {props.video && (
              <div className={styles.videoElementWrapper}>
                <div className={styles.videoElement}>
                  <CloudinaryVideoPlayer
                    {...props.video}
                    thumbnailUrl={undefined}
                  />
                </div>
              </div>
            )}
            {props.children}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
