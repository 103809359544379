import React, { RefObject } from "react";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import cn from "classnames";
import { fonts } from "../../basics/fonts";
import { spacings } from "../../basics/spacings";
import { rem } from "../../basics/layout";
import { BackendButton } from "../../models/Property";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const useStyles = createUseStyles({
  button: {
    ...fonts.sansBold,
    display: "inline-flex",
    alignItems: "center",
    textAlign: "center",
    border: "2px solid",
    position: "relative",
    outline: "none",
    flexShrink: "0",
    transition: "background-color 0.3s ease",
    "&:hover": {
      transition: "background-color 0.3s ease",
    },
    "&:hover $icon, &:focus $icon": {
      transform: `scale(1)`,
      transition: "transform 0.3s ease",
    },
    "&:hover $iconInternalLink, &:focus $iconInternalLink": {
      transform: `scale(1) translateX(${spacings.xs})`,
    },
    "&:disabled": {
      pointerEvents: "none",
      cursor: "not-allowed",
    },
  },
  primary: {
    color: colors.white,
    border: "2px solid transparent",
    backgroundColor: colors.webGreen20,
    "&:after, &:before": {
      display: "none",
    },
    "&:hover": {
      backgroundColor: colors.webGreen40,
    },
    "&:disabled": {
      color: colors.gray60,
      backgroundColor: colors.gray20,
    },
  },

  secondary: {
    color: colors.gray100,
    backgroundColor: colors.white,
    borderColor: colors.gray90,
    "&:hover": {
      backgroundColor: colors.gray10,
    },
    "&:disabled": {
      color: colors.gray60,
      backgroundColor: colors.white,
      borderColor: colors.gray60,
    },
  },

  link: {
    color: colors.gray100,
    background: "none",
    border: "none",
    padding: 0,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "left",
    "&:hover": {
      background: "none",
    },
    "&:after, &:before": {
      display: "none",
    },
  },

  icon: {
    verticalAlign: "middle",
    marginLeft: rem(spacings.s),
    color: (iconWhite) => (iconWhite ? colors.white : colors.gray100),
    transform: `scale(0.95) translateX(0px)`,
    transition: "transform 0.3s ease",
  },

  iconInternalLink: {
    marginLeft: rem(spacings.xs),
  },

  iconMedium: {
    ...FAIconStyles({
      width: 27,
      height: 27,
    }),
  },

  iconSmall: {
    ...FAIconStyles({
      width: 21,
      height: 21,
    }),
  },

  xs: {
    fontSize: "1rem",
    lineHeight: 1,
    "&:not($link)": {
      padding: `${rem(spacings.xs)} ${rem(spacings.s)}`,
    },
  },

  s: {
    fontSize: "1.1875rem",
    lineHeight: 1.263,
    "&:not($link)": {
      padding: "12px 22px",
    },
  },

  m: {
    fontSize: "1.1875rem",
    lineHeight: 1.263,
    "&:not($link)": {
      padding: "14px 22px",
    },
  },
});

export const isExternal = (url: string) => {
  const urlObject = new URL(url, window.location.origin);
  return urlObject.host !== window.location.host;
};
export const isFile = (url: string) => {
  const urlObject = new URL(url, window.location.origin);
  return Boolean(urlObject.pathname.match(/^.*\.([a-z0-9]+)$/gim));
};

export function Button(props: {
  type?: "primary" | "secondary" | "link";
  size?: "xs" | "s" | "m";
  small?: boolean; // @deprecated | Use props.size instead
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  href?: string;
  title?: string;
  target?: string;
  className?: string;
  icon?: string | null;
  trackingFunction?: () => true;
  buttonRef?: RefObject<HTMLButtonElement>;
  dataTestid?: string;
}) {
  const styles = useStyles(props.type === "primary" || undefined);
  const Element = props.href
    ? (elemProps: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
        <a {...elemProps}>{elemProps.children}</a>
      )
    : (elemProps: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
        <button role="button" {...elemProps} ref={props.buttonRef}>
          {elemProps.children}
        </button>
      );

  const customIcon = typeof props.icon !== "undefined";

  // For backwards-compatibility
  const size =
    props.size === undefined && props.small === true ? "s" : props.size || "m";
  const clickFunction =
    props.onClick || props.trackingFunction
      ? () => {
          props.trackingFunction?.();
          props.onClick?.();
        }
      : undefined;

  return (
    <Element
      className={cn(
        styles.button,
        (props.type === "primary" || props.type === undefined) &&
          styles.primary,
        props.type === "secondary" && styles.secondary,
        props.type === "link" && styles.link,
        styles[size],
        props.className
      )}
      onClick={clickFunction}
      href={props.href}
      title={props.title}
      target={(props.target === "_blank" && "_blank") || undefined}
      rel={(props.target === "_blank" && "noreferer noopener") || undefined}
      disabled={props.disabled}
      data-testid={props.dataTestid}
    >
      {props.children}
      {props.type === "link" && !customIcon && (
        <FAIcon
          className={cn(
            styles.icon,
            styles.iconMedium,
            styles.iconInternalLink
          )}
          icon="long-arrow-right"
        />
      )}
      {props.type !== "link" &&
        props.href &&
        isExternal(props.href) &&
        !customIcon && (
          <FAIcon
            className={cn(styles.icon, styles.iconSmall)}
            icon="external-link"
          />
        )}

      {props.type !== "link" &&
        props.href &&
        !isExternal(props.href) &&
        isFile(props.href) &&
        !customIcon && (
          <FAIcon
            className={cn(styles.icon, styles.iconSmall)}
            icon="arrow-to-bottom"
          />
        )}
      {props.icon && customIcon && (
        <FAIcon
          className={cn(styles.icon, styles.iconSmall)}
          icon={props.icon as IconProp}
        />
      )}
    </Element>
  );
}

export function ButtonWrapper(
  props: BackendButton & {
    className?: string;
    trackingFunction?: () => true;
    size?: "xs" | "s" | "m";
  }
) {
  const link = props.link?.[0];
  const icon =
    props.buttonIconType === "custom"
      ? props.buttonIcon
      : props.buttonIconType === "none"
      ? null
      : undefined;
  return (
    <Button
      type={props.buttonType || undefined}
      href={link?.href || undefined}
      target={link?.target || undefined}
      title={link?.title || undefined}
      className={props.className}
      icon={icon}
      trackingFunction={props.trackingFunction}
      size={props.size}
    >
      {link?.text}
    </Button>
  );
}
