import React, { useState } from "react";
import cn from "classnames";
import VideoBlockCloudinaryData from "../../../models/content/VideoBlockCloudinaryData";
import StartPageData from "../../../models/content/StartPageData";
import {
  default as FeaturedContentBlockData,
  FeaturedContentBlockProps,
} from "../../../models/content/FeaturedContentBlockData";
import {
  default as FeaturedVideoBlockData,
  FeaturedVideoBlockProps,
} from "../../../models/content/FeaturedVideoBlockData";
import { CloudinaryVideoPlayer } from "../../CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import {
  AnimatedBlockRenderProperty,
  RenderProperty,
} from "../../../views/RenderProperty";
import { AnimatedRevealBlock } from "../../AnimatedReveal/AnimatedReveal";
import {
  Container,
  Row,
  Col,
  CONTAINER_OFFSET_WIDTHS,
  MARGIN_OF_CONTAINER_LIMITED,
  rem,
  onBreakpoint,
  getGridColumnWidth,
  onBefore,
} from "../../../basics/layout";
import { LocalizedLabel } from "../../../hooks/LocalizationContext";
import { Button } from "../../Button/Button";
import { colors } from "../../../basics/colors";
import { customTypography, typography } from "../../../basics/typography";
import { spacings } from "../../../basics/spacings";
import { createUseStyles } from "react-jss";
import { SemanticHeader } from "../../SemanticHeader/SematicHeader";
import { fonts } from "../../../basics/fonts";

const useStyles = createUseStyles({
  textImageContainer: {
    marginTop: `calc(100vw / 16 * 10 - ${spacings.l})`,
  },
  textVideoContainer: {
    marginTop: `calc(100vw / 16 * 9 - ${spacings.l})`,
  },
  textContainer: {
    ...onBreakpoint("sm", {
      marginTop: 0,
      position: "absolute",
      height: `100%`,
      width: "100%",
      top: `calc(${getGridColumnWidth("sm")})`,
    }),
    ...onBreakpoint("md", {
      top: `calc(${getGridColumnWidth("md")})`,
    }),
  },
  itemSectionColumn: {
    gridRow: 1,
    width: "100%",
    gridColumnStart: (isEven: boolean) => (isEven ? 1 : undefined),
  },
  leftItem: {
    ...onBreakpoint("sm", {
      marginLeft: -CONTAINER_OFFSET_WIDTHS["big"],
    }),
    ...onBreakpoint("md", {
      // INFO(mkarol): CSS Calc magic here :) We are moving to the left by value of margin:auto of container minus single padding
      marginLeft: `calc(-1 * ${MARGIN_OF_CONTAINER_LIMITED} - ${CONTAINER_OFFSET_WIDTHS["big"]}px)`,
    }),
  },
  itemSection: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.gray10,
    padding: `${spacings.l} ${spacings.sam}`,
    ...onBreakpoint("sm", {
      backgroundColor: "transparent",
      padding: (isEven: boolean) =>
        isEven
          ? `${spacings.l} calc(${getGridColumnWidth("sm")}) ${spacings.l} ${
              spacings.m
            }`
          : `${spacings.l} ${spacings.m} ${
              spacings.l
            } calc(${getGridColumnWidth("sm")})`,
    }),
    ...onBreakpoint("md", {
      padding: (isEven: boolean) =>
        isEven
          ? `${spacings.xxl} calc(${getGridColumnWidth("md")}) ${
              spacings.xxl
            } 0`
          : `${spacings.xxl} 0 ${spacings.xxl} calc(${getGridColumnWidth(
              "md"
            )})`,
    }),
  },
  emptyBackgroundColumn: {
    gridRow: 1,
    display: "none",
    ...onBreakpoint("sm", {
      display: "block",
    }),
    gridColumnStart: (isEven: boolean) => (isEven ? 1 : undefined),
  },
  emptyBackground: {
    backgroundColor: colors.gray10,
    height: "100%",
    // INFO(mkarol): CSS Calc magic here :) We are adding the size of the margin:auto of container to the width plus single padding
    width: `calc(${MARGIN_OF_CONTAINER_LIMITED} + ${CONTAINER_OFFSET_WIDTHS["big"]}px + 100%)`,
    position: "absolute",
  },
  h4: {
    ...typography.h4,
    wordBreak: "break-word",
  },
  textDefault: customTypography(
    {
      ...typography.textDefault,
      ...typography.grayBackgroundFix,
      ...fonts.sansLight,
      position: "absolute",
      height: `100%`,
      overflow: "hidden",
      "& p": {
        ...fonts.sansLight,
      },
    },
    { marginBottom: 0 },
    { marginBottom: 0 },
    { marginBottom: 0 }
  ),
  teaserItem: {
    ...onBefore("sm", {
      position: "absolute",
      width: `100vw`,
      marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
    }),
    ...onBreakpoint("sm", {
      width: `calc(${MARGIN_OF_CONTAINER_LIMITED} + ${CONTAINER_OFFSET_WIDTHS["big"]}px + 100%)`,
    }),
  },
  button: {
    alignSelf: "flex-start",
  },
  icon: {
    marginLeft: rem(spacings.s),
  },
  fullHeight: {
    height: "100%",
  },
  descriptionBox: {
    display: "none",
    position: "relative",
    flex: 1,
    ...onBreakpoint("sm", {
      display: "block",
      marginBottom: rem(spacings.m),
    }),
    ...onBreakpoint("md", {
      marginBottom: rem(spacings.xl),
    }),

    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: rem(spacings.m.times(2)),
      background: `linear-gradient(to bottom, ${colors.gray10}00, ${colors.gray10}FF 100%)`,
    },
  },
});

export function FeaturedContent(
  props: (FeaturedContentBlockProps | FeaturedVideoBlockProps) & {
    startPageData: StartPageData;
    isVideo?: boolean;
  }
) {
  const isVideo = (
    dataBlock: FeaturedContentBlockData | FeaturedVideoBlockData
  ): dataBlock is FeaturedVideoBlockData =>
    typeof (dataBlock as any).videoBlockArea !== "undefined";

  const [forceVideoPlay, setForceVideoPlay] = useState(false);

  const isRight =
    (isVideo(props.data)
      ? props.data.videoAlignment.value
      : props.data.imageAlignment.value) === "right";
  const styles = useStyles(isRight);

  const link = !isVideo(props.data) ? props.data.link.value?.[0] : undefined;

  const videoBlock =
    isVideo(props.data) && props.data.videoBlockArea.expandedValue?.[0]
      ? (props.data.videoBlockArea.expandedValue[0] as VideoBlockCloudinaryData)
      : undefined;

  const isAutoplay = videoBlock?.startAction?.value === "autoplay";
  const isLoop = videoBlock?.endAction?.value === "loop_video";
  const resetOnEnd = videoBlock?.endAction?.value === "reset_to_thumbnail";
  const defaultPosterUrl =
    videoBlock?.thumbnail.value?.urlLarge ||
    videoBlock?.thumbnail.value?.urlMedium ||
    videoBlock?.thumbnail.value?.urlSmall;

  return (
    <>
      <Container>
        <Row>
          <Col sm={9} smStart={isRight ? 3 : undefined}>
            {isVideo(props.data) ? (
              <div
                className={cn(styles.teaserItem, !isRight && styles.leftItem)}
              >
                <CloudinaryVideoPlayer
                  publicId={videoBlock?.cloudinaryVideoPublicID?.value || ""}
                  logoImageUrl={props.startPageData.logo.value?.url || ""}
                  showControls={false}
                  autoplay={isAutoplay}
                  loop={isLoop}
                  resetVideo={resetOnEnd}
                  thumbnailUrl={defaultPosterUrl || undefined}
                  withOverlay={true}
                  forcePlay={forceVideoPlay}
                  setForcePlay={setForceVideoPlay}
                />
              </div>
            ) : (
              <div
                className={cn(styles.teaserItem, !isRight && styles.leftItem)}
              >
                <AnimatedBlockRenderProperty value={props.data.imageAdaptive} />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <AnimatedRevealBlock
        className={cn(
          styles.textContainer,
          isVideo(props.data)
            ? styles.textVideoContainer
            : styles.textImageContainer
        )}
        revealDelay={100}
      >
        <Container className={styles.fullHeight}>
          <Row className={styles.fullHeight}>
            <Col
              xs={4}
              sm={4}
              smStart={isRight ? undefined : 8}
              className={cn(styles.emptyBackgroundColumn, styles.fullHeight)}
            >
              <div
                className={cn(
                  styles.emptyBackground,
                  isRight && styles.leftItem
                )}
              />
            </Col>
            <Col
              xs={4}
              sm={4}
              smStart={isRight ? undefined : 8}
              className={cn(styles.itemSectionColumn, styles.fullHeight)}
            >
              <div className={cn(styles.itemSection, styles.fullHeight)}>
                <SemanticHeader
                  className={styles.h4}
                  headerSize={props.data.headerSize?.value}
                >
                  <RenderProperty
                    value={props.data.headline}
                    fallbackValue={videoBlock?.headline}
                  />
                </SemanticHeader>
                <div className={styles.descriptionBox}>
                  <p className={styles.textDefault}>
                    <RenderProperty
                      value={props.data.descriptionRichText}
                      fallbackValue={videoBlock?.description}
                    />
                  </p>
                </div>
                {isVideo(props.data) ? (
                  <Button
                    type="secondary"
                    onClick={() => setForceVideoPlay(true)}
                    className={styles.button}
                    icon="play"
                  >
                    <LocalizedLabel section="Global" label="PlayVideo" />
                  </Button>
                ) : (
                  link && (
                    <Button
                      type="secondary"
                      href={link.href}
                      title={link.title}
                      target={link.target}
                      className={styles.button}
                    >
                      {link.text}
                    </Button>
                  )
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </AnimatedRevealBlock>
    </>
  );
}
