import React, { Key, ReactNode } from "react";
import { useInView } from "react-intersection-observer";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { isCypressTest } from "../../basics/isCypressTest";
import {
  getFirefoxVersion,
  isFirefox,
  isNonChromiumEdge,
} from "../../basics/browserDetection";

const useStyles = createUseStyles({
  wrapper: ({ inView, delay, fade, movement }) => ({
    opacity: inView || delay < 0 || !fade ? 1 : 0,
    transform: inView || delay < 0 || !movement ? "none" : "translateY(-1em)",
    transition: "opacity 0.65s ease, transform 0.65s ease",
    transitionDelay: delay + "ms",
  }),
});

export interface AnimatedRevealProps {
  children?: ReactNode;
  key?: Key;
  className?: any;
  revealDelay?: number;
  fade?: boolean;
  movement?: boolean;
  "data-testid"?: string;
}

function makeAnimatedReveal(type: "inline" | "block") {
  function AnimatedReveal({
    children,
    className,
    revealDelay,
    fade,
    movement,
    "data-testid": dataTestId,
  }: AnimatedRevealProps) {
    const { ref, inView } = useInView({
      threshold: 0.3,
      triggerOnce: true,
      delay: 100,
      rootMargin: "50px",
    });

    const styles = useStyles({
      inView,
      fade,
      movement,
      delay: Number(revealDelay) || 0,
    });

    const disableAnimations =
      isCypressTest() ||
      isNonChromiumEdge() ||
      (isFirefox() && getFirefoxVersion() < 79) ||
      (inView && document.readyState !== "complete");

    if (type === "block") {
      return (
        <div
          ref={ref}
          className={cn(!disableAnimations && styles.wrapper, className)}
          data-testid={dataTestId}
        >
          {children}
        </div>
      );
    } else {
      return (
        <span
          ref={ref}
          className={cn(!disableAnimations && styles.wrapper, className)}
          data-testid={dataTestId}
        >
          {children}
        </span>
      );
    }
  }

  AnimatedReveal.defaultProps = {
    fade: true,
    movement: true,
  };

  return AnimatedReveal;
}

export const AnimatedRevealInline = makeAnimatedReveal("inline");
export const AnimatedRevealBlock = makeAnimatedReveal("block");
