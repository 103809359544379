import React, { useState, useRef, useEffect } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import cn from "classnames";
import { Container, onBefore, onBreakpoint, rem } from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import StartPageData from "../../models/content/StartPageData";
import { LocalizedLabel } from "../../hooks/LocalizationContext";
import { animations } from "../../basics/animations";
import { LocaleSuggestion } from "../LocaleSuggestion/LocaleSuggestion";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { MonsterNavigation } from "./MonsterNavigation/MonsterNavigation";
import { LanguagePicker } from "../LanguagePicker/LanguagePicker";
import Search from "./Search/Search";
import { pseudoSelectorShadow } from "../../basics/mixins";
import VisualHeaderCarousel from "../Block/VisualHeaderCarousel/VisualHeaderCarousel";
import HeaderCarouselElementBlockData from "../../models/content/HeaderCarouselElementBlockData";
import SearchBlockData from "../../models/content/SearchBlockData";
import { customTypography, typography } from "../../basics/typography";

const HEADER_TOP_CONTENT_HEIGHT = 48;

const useStyles = createUseStyles({
  header: ({ liftHeaderUpByPx }) => ({
    position: "sticky",
    top: 0,
    zIndex: 99,
    transition: "transform .3s ease",
    transform: `translateY(-${liftHeaderUpByPx}px)`,
  }),
  headerMixins: {
    ...onBefore("sm", {
      transform: "none !important",
    }),
  },
  headerTop: {
    backgroundColor: colors.gray20,
    display: "none",
    ...onBreakpoint("sm", {
      display: "block",
    }),
  },
  headerTopContent: {
    height: HEADER_TOP_CONTENT_HEIGHT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& ul": {
      display: "flex",
      padding: 0,
      "& li": {
        listStyle: "none",
        margin: `0 ${spacings.sam.divide(2)}`,
        ...onBreakpoint("md", {
          margin: `0 ${spacings.sam}`,
        }),

        "& a": {
          ...customTypography(
            {
              ...typography.textXSmall,
              marginBottom: 0,
              color: colors.gray100,
              transition: "color 0.3s ease",
              "&:hover": {
                color: colors.black,
              },
              ...animations.linkChevronEffect,
            },
            {},
            {
              marginBottom: 0,
            }
          ),
        },
      },
    },
  },
  navLeft: {
    display: "flex",
    alignItems: "center",
    "& li:first-child": {
      marginLeft: 0,
    },
  },
  navRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headerBottom: {
    display: "flex",
    alignItems: "center",
    height: 150,
    backgroundColor: "transparent",
    position: "absolute",
    width: "100%",
    ...onBreakpoint("sm", {
      height: 130,
      transition: "all .3s",
    }),
    ...onBreakpoint("md", {
      height: 180,
    }),
    "&:after": {
      ...pseudoSelectorShadow("0 4px 6px 0 rgba(0, 0, 0, 0.09)"),
      height: 0,
      opacity: ({ openedMenu, openedSearch }) =>
        openedMenu || openedSearch ? 0 : 1,
      transition: "all .3s",
    },
  },
  collapsed: {
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.gray20}`,
    height: 64,
    "&:after": {
      height: 12,
    },
    ...onBreakpoint("sm", {
      borderBottom: 0,
    }),
    ...onBreakpoint("md", {
      height: 80,
    }),
    "& $logo": {
      left: 0,
      "& img": {
        width: 42,
        height: 17,
        padding: 0,
        backgroundColor: "transparent",
        ...onBreakpoint("sm", {
          width: 60,
          height: 24,
        }),
        ...onBreakpoint("md", {
          width: 72,
          height: 29,
        }),
      },
    },
    "& $mainMenu span": {
      color: colors.gray100,
    },
    "& $hamburgerIcon div": {
      background: colors.black,
    },
    "& $search": {
      color: colors.black,
    },
  },
  headerBottomContent: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  logo: {
    display: "flex",
    position: "absolute",
    left: `calc((max((-1 * (1920px - min(100vw, 1504px))), (-1 * (100vw - min(100vw, 1504px)))) / 2) - 33px)`,

    ...onBreakpoint("sm", {
      transition: "all .3s",
    }),
    "& img": {
      width: 119,
      height: 59,
      padding: "19px 26px 16px 31px",
      objectFit: "contain",
      backgroundColor: colors.white,
      ...onBreakpoint("sm", {
        width: 195,
        height: 93,
        padding: "26px 49px 22px 55px",
        transition: "all .3s",
      }),
      ...onBreakpoint("md", {
        width: 247,
        height: 114,
        padding: "36px 62px 32px 72px",
      }),
    },
  },
  search: {
    color: colors.white,
    marginLeft: "auto",
    marginRight: 36,
    cursor: "pointer",
    ...onBreakpoint("sm", {
      transition: "all .3s",
    }),
    ...FAIconStyles({
      width: 25,
      height: 25,
    }),
    ...onBefore("md", {
      marginRight: 24,
      ...FAIconStyles({
        width: 20,
        height: 20,
      }),
    }),
  },
  mainMenu: {
    padding: 0,
    border: "none",
    font: "inherit",
    color: "inherit",
    textAlign: "inherit",
    background: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& span": {
      ...typography.textLarge,
      color: colors.white,
      marginBottom: 0,
      display: "none",
      ...onBreakpoint("sm", {
        marginRight: rem(spacings.sam.divide(2)),
        display: "block",
        transition: "all .3s",
        fontSize: 20,
      }),
      ...onBreakpoint("md", {
        fontSize: 24,
      }),
    },
    "& svg": {
      color: colors.white,
    },
  },
  hamburgerIcon: ({ openedMenu }) => ({
    width: 21,
    height: 17,
    position: "relative",

    cursor: "pointer",
    "& div": {
      position: "absolute",
      height: 2,
      width: "100%",
      background: colors.white,
      opacity: 1,
      left: 0,
      ...onBreakpoint("sm", {
        transition: "all .3s",
      }),

      "&:nth-child(1)": {
        top: openedMenu ? 7 : 0,
        left: openedMenu ? "50%" : 0,
        width: openedMenu ? "0%" : "100%",
      },
      "&:nth-child(2)": {
        top: 7,
        transform: openedMenu ? "rotate(45deg)" : "rotate(0deg)",
      },
      "&:nth-child(3)": {
        top: 7,
        transform: openedMenu ? "rotate(-45deg)" : "rotate(0deg)",
        opacity: openedMenu ? 1 : 0,
      },
      "&:nth-child(4)": {
        top: openedMenu ? 7 : 14,
        left: openedMenu ? "50%" : 0,
        width: openedMenu ? "0%" : "100%",
      },
    },
  }),
  contactUsButtonWrapper: {
    marginRight: rem(spacings.m),
    opacity: 0,
    transition: "opacity 0.65s ease",
    pointerEvents: "none",
    ...onBefore("sm", {
      display: "none",
    }),
  },
  contactUsButtonWrapperShow: {
    opacity: 1,
    pointerEvents: "auto",
  },
});

export function StartPageHeader(props: {
  metaNavigationLeft: StartPageData["metaNavigationLeft"];
  metaNavigationRight: StartPageData["metaNavigationRight"];
  logo: StartPageData["logo"];
  logoLink: StartPageData["logoLink"];
  userLogin: StartPageData["userLogin"];
  languagePicker: StartPageData["languagePicker"];
  searchField: StartPageData["searchField"];
  searchPage: StartPageData["searchPage"];
  homepageCarousel: StartPageData["homepageCarousel"];
  homepageSearch: StartPageData["homepageSearch"];
}) {
  const [liftHeaderUpByPx, setLiftHeaderUpByPx] = useState(0);

  const menuToggler = useRef<HTMLButtonElement>(null);
  const localeSuggestionWrapperRef = useRef<HTMLDivElement>(null);

  const scrollY = useRef(0);
  useScrollPosition(
    ({ currPos, prevPos }) => {
      if (currPos.y === prevPos.y) {
        return;
      }

      const newVisibilityStatus: boolean =
        currPos.y < prevPos.y || currPos.y < 0 || prevPos.y < 0;

      const localeSuggestionWrapperHeight =
        localeSuggestionWrapperRef?.current?.getBoundingClientRect()?.height ||
        0;

      setLiftHeaderUpByPx(
        newVisibilityStatus
          ? 0
          : localeSuggestionWrapperHeight + HEADER_TOP_CONTENT_HEIGHT
      );

      scrollY.current = currPos.y;

      setHeaderElementToggle((s) => ({
        openedMenu: s.openedMenu,
        openedSearch: s.openedSearch,
        menuCollapse:
          document.body.style.position === "static"
            ? currPos.y > 0
            : s.menuCollapse,
      }));
    },
    [liftHeaderUpByPx],
    undefined,
    true,
    0
  );

  const [headerElementToggle, setHeaderElementToggle] = useState({
    openedMenu: false,
    openedSearch: false,
    menuCollapse: false,
  });

  function menuToggle() {
    setHeaderElementToggle((s) => ({
      openedSearch: false,
      openedMenu: !s.openedMenu,
      menuCollapse:
        scrollY.current === 0 && !headerElementToggle.openedSearch
          ? !s.menuCollapse
          : s.menuCollapse,
    }));
  }

  function searchToggle() {
    setHeaderElementToggle((s) => ({
      openedSearch: !s.openedSearch,
      openedMenu: false,
      menuCollapse:
        scrollY.current === 0 && !headerElementToggle.openedMenu
          ? !s.menuCollapse
          : s.menuCollapse,
    }));
  }

  useEffect(() => {
    headerElementToggle.openedMenu || headerElementToggle.openedSearch
      ? (document.body.style.position = "fixed")
      : (document.body.style.position = "static");
  });

  const searchRef = useRef<HTMLDivElement>(null);

  function handleClickOutside(event: any) {
    if (menuToggler.current && menuToggler.current.contains(event.target)) {
      setHeaderElementToggle((s) => ({
        openedMenu: s.openedMenu,
        openedSearch: false,
        menuCollapse: s.menuCollapse,
      }));
    } else if (searchRef.current && !searchRef.current.contains(event.target)) {
      headerElementToggle.openedSearch &&
        setHeaderElementToggle((s) => ({
          openedMenu: s.openedMenu,
          openedSearch: false,
          menuCollapse: false,
        }));
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const openedMenu = headerElementToggle.openedMenu;
  const openedSearch = headerElementToggle.openedSearch;

  const styles = useStyles({ liftHeaderUpByPx, openedMenu, openedSearch });

  return (
    <>
      <header
        className={cn(styles.header, styles.headerMixins)}
        data-testid="header"
      >
        <div ref={localeSuggestionWrapperRef}>
          <LocaleSuggestion />
        </div>
        <div className={styles.headerTop}>
          <Container>
            <div className={styles.headerTopContent}>
              <nav className={styles.navLeft}>
                <ul>
                  {props.metaNavigationLeft.value?.map((l, idx) => (
                    <li key={idx}>
                      <a href={l.href} title={l.title} target={l.target}>
                        {l.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>

              <nav className={styles.navRight}>
                <ul>
                  {props.metaNavigationRight.value?.map((l, idx) => (
                    <li key={idx}>
                      <a href={l.href} title={l.title} target={l.target}>
                        {l.text}
                      </a>
                    </li>
                  ))}
                  {props.userLogin.value && (
                    <li>
                      <a href="#">
                        <LocalizedLabel section="Global" label="Login" />
                      </a>
                    </li>
                  )}
                </ul>
                {props.languagePicker.value && <LanguagePicker />}
              </nav>
            </div>
          </Container>
        </div>

        <div
          className={cn(
            styles.headerBottom,
            headerElementToggle.menuCollapse ? styles.collapsed : undefined
          )}
        >
          <Container>
            <div className={styles.headerBottomContent}>
              <a
                href={props.logoLink.value ? props.logoLink.value : undefined}
                className={styles.logo}
              >
                <img src={props.logo.value?.url || undefined} alt="logo" />
              </a>

              {props.searchField.value && headerElementToggle.openedSearch ? (
                <FAIcon
                  icon="times"
                  className={styles.search}
                  onClick={searchToggle}
                  aria-controls="search"
                />
              ) : (
                <FAIcon
                  icon="search"
                  className={styles.search}
                  onClick={searchToggle}
                  aria-controls="search"
                />
              )}

              <button
                aria-controls="main-menu"
                className={styles.mainMenu}
                ref={menuToggler}
                onClick={menuToggle}
                onMouseUp={() =>
                  menuToggler.current && menuToggler.current.blur()
                }
              >
                <span>Menu</span>
                <div className={styles.hamburgerIcon} aria-hidden={true}>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </button>
            </div>
          </Container>
        </div>

        <MonsterNavigation
          isOpened={headerElementToggle.openedMenu}
          languagePicker={props.languagePicker}
          userLogin={props.userLogin}
          isStartPage
        />
        <div ref={searchRef}>
          <Search
            isOpened={headerElementToggle.openedSearch}
            searchPage={props.searchPage}
            homepageSearch={
              props.homepageSearch?.expandedValue as SearchBlockData
            }
            isStartPage
          />
        </div>
      </header>
      <VisualHeaderCarousel
        items={
          props.homepageCarousel
            ?.expandedValue as Array<HeaderCarouselElementBlockData>
        }
        menuCollapsed={headerElementToggle.menuCollapse}
      />
    </>
  );
}
