import "./wdyr";
import "intersection-observer";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App, SSRApp } from "./App";
import * as serviceWorker from "./serviceWorker";
import { isClientSide } from "./basics/isClientSide";

global.SSRApp = SSRApp;

if (isClientSide()) {
  const renderingFunction = window.__SSR__ ? ReactDOM.hydrate : ReactDOM.render;
  renderingFunction(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root"),
    () => {
      const ssrStyles = document.getElementById("ssr-jss-styles");
      if (ssrStyles) ssrStyles.parentNode?.removeChild(ssrStyles);
    }
  );
  serviceWorker.unregister();
}
