import React, { useContext, useState, useEffect, useRef } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Shared/Footer";
import { GlobalContentStoreContext } from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import {
  Container,
  Row,
  Col,
  onBreakpoint,
  rem,
  onBefore,
  CONTAINER_OFFSET_WIDTHS,
} from "../../basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { typography, customTypography } from "../../basics/typography";
import { Button, ButtonWrapper } from "../Button/Button";
import { RenderProperty } from "../../views/RenderProperty";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import {
  LocalizedLabel,
  localizedLabelString,
  LocalizationContext,
} from "../../hooks/LocalizationContext";
import { useDeviceType, isDeviceResOrLower } from "../../hooks/useDeviceType";
import { Input } from "../Input/Input";
import { Checkbox } from "../Checkbox/Checkbox";
import { Spinner } from "../Spinner/Spinner";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ContentLink from "../../models/ContentLink";
import { JobListingPageProps } from "../../models/content/JobListingPageData";
import { Select } from "../Select/Select";
import { DownloadBlock, DownloadProps } from "../Block/DownloadBlock";
import { useDebounce } from "../../hooks/useDebounce";
import JobInfoBannerBlockData from "../../models/content/JobInfoBannerBlockData";
import { AccordionItem, Accordion } from "../Accordion/Accordion";
import { ContactExpert } from "../ContactExpert/ContactExpert";
import { getCurrentLocale } from "../../basics/locales";
import { OptionsType } from "../../basics/commonTypes";
import { ContentAreaProperty } from "../../models/Property";
import { Helmet } from "react-helmet";
import cn from "classnames";
import { RichTextWithTypographyRenderer } from "../Block/RichTextBlock";
import LanguageList from "../../models/LanguageList";

const PAGE_SIZE = 30;

export type JobListingApiHit = {
  CareerLevel: string | null;
  WorkTime: string | null;
  StartingDate: string | null;
  LocationEnglish: string;
  LocationLocal: string;
  JobPosting: string;
  JobTitleEnglish: string | null;
  JobTitleLocal: string | null;
  JobDescriptionEnglish: string;
  JobDescriptionLocal: string;
  PostingDate: string | null;
  CountryFlag: string | null;
  ValidThrough: string | null;
  Address: {
    City: string | null;
    Country: string | null;
    PostalCode: string | null;
    Region: string | null;
    Street: string | null;
  };
  SalaryBase: string | null;
  SalaryCurrency: string | null;
  SalaryPeriodicity: string | null;
};

export type JobListingApiResponse = {
  Hits: Array<JobListingApiHit> | null;
  TotalCount: number;
  Filter: {
    Q: string;
  };
};

type CareerLevelType =
  | "graduates"
  | "professionals"
  | "trainees"
  | "working-students";

type WorkTimeType = "full-time" | "full-part-time" | "part-time";

const LOCALE_ALL_VALUE = "en";

export function getJobData(
  page: number,
  language: string | undefined,
  contentLink: ContentLink | undefined,
  query: string,
  careerLevel: Set<CareerLevelType>,
  workTime: Set<WorkTimeType>,
  pageLanguage: string | undefined
): Promise<JobListingApiResponse> {
  const queryParameters = new URLSearchParams();
  query && queryParameters.set("q", query);
  queryParameters.set("rootContentLink", contentLink?.id.toString() || "");
  language &&
    language !== LOCALE_ALL_VALUE &&
    queryParameters.set("language", language);
  queryParameters.set("page", page.toString());
  queryParameters.set("size", PAGE_SIZE.toString());
  queryParameters.set(
    "career_level",
    Array.from(careerLevel).filter(Boolean).join(",")
  );
  queryParameters.set(
    "work_time",
    Array.from(workTime).filter(Boolean).join(",")
  );

  pageLanguage && queryParameters.set("pagelanguage", pageLanguage);

  return fetch(`/api/hdiglobal/job-listing/results?${queryParameters}`).then(
    (r) => r.json()
  );
}

function switchSetOption<T>(set: Set<T>, option: T) {
  if (!set.has(option)) return new Set(set).add(option);
  const newSet = new Set(set);
  newSet.delete(option);
  return newSet;
}

const useStyles = createUseStyles({
  element: {
    paddingTop: rem(spacings.m),
    paddingBottom: rem(spacings.l),
    ...onBreakpoint("sm", {
      paddingTop: rem(spacings.l),
      paddingBottom: rem(spacings.xl),
    }),
    ...onBreakpoint("md", {
      paddingTop: rem(spacings.xl),
      paddingBottom: rem(spacings.xxl),
    }),
  },
  header: typography.h1,

  results: {
    marginTop: rem(spacings.m),
  },
  resultsText: {
    ...customTypography(typography.textLarge, { marginBottom: 0 }),
    flex: 3,
  },
  autocorrectionText: typography.textDefault,
  autocorrectionLink: {
    textDecoration: "underline",
  },
  contentRow: onBreakpoint("sm", {
    gridTemplateRows: "auto auto 1fr",
  }),
  searchBarColumn: {
    gridRow: 2,
  },
  articleCardsColumn: {
    gridRow: 4,
    ...onBreakpoint("sm", {
      gridRow: 3,
    }),
  },
  filtersColumn: {
    gridRow: 3,
    ...onBreakpoint("sm", {
      gridRow: 1,
      gridRowEnd: "span 3",
    }),
  },
  flexBar: {
    display: "flex",
    alignItems: "center",
  },
  queryBar: {
    ...onBefore("sm", { marginTop: rem(spacings.m) }),
    display: "flex",
  },
  filtersBackground: onBefore("sm", {
    zIndex: -1,
    position: "absolute",
    height: "100%",
    width: "100vw",
    backgroundColor: colors.gray10,
    marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
  }),
  moreButton: {
    display: "flex",
    justifyContent: "center",
    paddingTop: rem(spacings.m),
  },
  infoBannerColumn: {
    gridRow: 1,
  },
  infoBanner: {
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.alertsOrange40,
    padding: `${spacings.s}`,
    marginBottom: rem(spacings.sam),
    ...onBreakpoint("md", {
      padding: rem(spacings.m),
      marginBottom: rem(spacings.m),
    }),
  },
  infoBannerContent: {
    marginLeft: rem(spacings.s),
    ...onBreakpoint("sm", {
      marginLeft: rem(spacings.sam),
    }),
    "& *": {
      color: `${colors.white} !important`,
      marginBottom: "0 !important",
    },
  },
  infoBannerButton: {
    background: "none",
    marginLeft: "auto",
    alignSelf: "flex-start",
    padding: 0,
  },
  infoBannerButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: rem(spacings.sam),
    "& > *": {
      marginBottom: `${rem(spacings.xs)} !important`,
      "&:last-child": {
        marginBottom: "0 !important",
      },
    },
  },
  infoBannerIcon: FAIconStyles({
    height: 60,
    width: 60,
    color: colors.white,
    ...onBefore("sm", {
      display: "none",
    }),
  }),
  infoBannerClose: FAIconStyles({
    height: 32,
    width: 32,
    color: colors.white,
  }),
  visuallyHidden: {
    ...typography.visuallyHidden,
  },
});

export function JobListingPage(props: JobListingPageProps) {
  const initialQueryParametrs = new URLSearchParams(window.location.search);

  const { getStartPageData, getImmediateStartPageData, getWebsiteData } =
    useContext(GlobalContentStoreContext);
  const localizationCtx = useContext(LocalizationContext);

  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const websiteData = getWebsiteData();
  const [jobListingData, setJobListingData] =
    useState<JobListingApiResponse | null>(null);

  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(0);

  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");

  const CAREER_LEVEL_OPTIONS: OptionsType<CareerLevelType> = [
    {
      label: localizedLabelString({
        section: "JobListing",
        label: "Graduates",
        localizationCtx,
      }),
      value: "graduates",
    },
    {
      label: localizedLabelString({
        section: "JobListing",
        label: "Professionals",
        localizationCtx,
      }),
      value: "professionals",
    },
    {
      label: localizedLabelString({
        section: "JobListing",
        label: "Trainees",
        localizationCtx,
      }),
      value: "trainees",
    },
    {
      label: localizedLabelString({
        section: "JobListing",
        label: "WorkingStudent",
        localizationCtx,
      }),
      value: "working-students",
    },
  ];
  const WORK_TIME_OPTIONS: OptionsType<WorkTimeType> = [
    {
      label: localizedLabelString({
        section: "JobListing",
        label: "FullTime",
        localizationCtx,
      }),
      value: "full-time",
    },
    {
      label: localizedLabelString({
        section: "JobListing",
        label: "FullPartTime",
        localizationCtx,
      }),
      value: "full-part-time",
    },
    {
      label: localizedLabelString({
        section: "JobListing",
        label: "PartTime",
        localizationCtx,
      }),
      value: "part-time",
    },
  ];

  const LOCALE_OPTIONS = [
    {
      displayName: localizedLabelString({
        section: "JobListing",
        label: "ShowAll",
        localizationCtx,
      }),
      name: LOCALE_ALL_VALUE,
      countryDisplayName: "",
    },
    ...(props.data.existingLanguages?.filter(
      (co) => co.name !== LOCALE_ALL_VALUE
    ) || []),
  ];

  const [careerLevels, setCareerLevels] = useState<Set<CareerLevelType>>(
    new Set(
      initialQueryParametrs.has("careerlevel")
        ? decodeURI(initialQueryParametrs.get("careerlevel") || "").split(",")
        : []
    ) as Set<CareerLevelType>
  );
  const [workTimeModels, setWorkTimeModels] = useState<Set<WorkTimeType>>(
    new Set(
      initialQueryParametrs.has("worktimemodel")
        ? decodeURI(initialQueryParametrs.get("worktimemodel") || "").split(",")
        : []
    ) as Set<WorkTimeType>
  );
  const [localeSelection, setLocaleSelection] = useState<string | undefined>(
    getCurrentLocale(websiteData, initialQueryParametrs)
  );

  const pageLanguage =
    (websiteData && websiteData.currentLanguage.link) || undefined;

  const styles = useStyles();

  //Regarding ticket HGEPI-1156
  const FILTERED_LOCALE_OPTIONS = LOCALE_OPTIONS.filter(
    (l) => l.displayName !== "Ireland"
  ).map((l) =>
    l.displayName === "United Kingdom"
      ? {
          ...l,
          displayName: "UK and Ireland",
          name: localeSelection === "en-gb" ? "en-gb" : "en-ie",
        }
      : l
  );

  const [query, setQuery] = useState(
    decodeURI(initialQueryParametrs.get("query") || "")
  );
  const queryInputRef = useRef<HTMLInputElement>(null);
  const { debouncePromise } = useDebounce();

  function loadItems(
    requestedPage?: number
  ): Promise<JobListingApiResponse | null> {
    const apiPage = requestedPage || page + 1;
    setLoading(true);
    return debouncePromise(() =>
      getJobData(
        apiPage,
        localeSelection,
        props.data.contentLink,
        query,
        careerLevels,
        workTimeModels,
        pageLanguage
      ).then((response) => {
        setPage(apiPage);
        setLoading(false);
        return response;
      })
    );
  }

  function searchFunction() {
    setQuery(queryInputRef.current?.value || "");
  }

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((startPageResolved) => {
        setStartPageData(startPageResolved);
      });
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams();
    careerLevels.size &&
      queryParameters.set(
        "careerlevel",
        encodeURI(Array.from(careerLevels.values()).join(","))
      );
    workTimeModels.size &&
      queryParameters.set(
        "worktimemodel",
        encodeURI(Array.from(workTimeModels.values()).join(","))
      );
    localeSelection && queryParameters.set("locale", localeSelection);
    queryParameters.set("query", encodeURI(query));
    websiteData &&
      queryParameters.set(
        "pagelanguage",
        websiteData.currentLanguage.link || ""
      );

    setJobListingData(null);
    history.replaceState(
      {},
      "",
      `${window.location.pathname}?${queryParameters}`
    );
    loadItems(1).then((response) => response && setJobListingData(response));
  }, [
    startPageData,
    careerLevels,
    workTimeModels,
    localeSelection,
    query,
    pageLanguage,
  ]);

  const maybeInfoBanner = props.data.infoBanner
    .expandedValue as JobInfoBannerBlockData;
  const [isInfoBannerClosed, setInfoBannerClosed] = useState(false);

  const isUserRetention = props.data.contactCta?.value === "enabled";

  if (!startPageData || !websiteData) return null;

  const contactExpertData = props.data.contactsUseDefaultContent?.value
    ? startPageData
    : props.data;

  const isInternational = localeSelection === LOCALE_ALL_VALUE;

  function buildJobDescription(
    job: JobListingApiHit,
    isInternational: boolean
  ) {
    const jobDescriptionLabel = localizedLabelString({
      section: "JobListing",
      label: "JobDescriptionLabel",
      localizationCtx,
    });
    const jobDescriptionLinkTitle = localizedLabelString({
      section: "JobListing",
      label: "JobDescriptionLinkTitle",
      localizationCtx,
    });
    const jobDescription =
      (isInternational ? job.JobDescriptionEnglish : job.JobDescriptionLocal) ??
      "";

    return `<p>${jobDescription}<br />${jobDescriptionLabel}<a href="https://www.hdi.global${job.JobPosting}" title="${jobDescriptionLinkTitle}">${jobDescriptionLinkTitle}</a></p>`;
  }

  return (
    <>
      {jobListingData && (
        <Helmet>
          <script type="application/ld+json">
            {`
            ${JSON.stringify(
              jobListingData?.Hits?.map((result) => ({
                "@context": "https://schema.org/",
                "@type": "JobPosting",
                title: isInternational
                  ? result.JobTitleEnglish
                  : result.JobTitleLocal,
                description: buildJobDescription(result, isInternational),
                jobLocation: {
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: result.Address.Street,
                    addressLocality: result.Address.City,
                    addressRegion: result.Address.Region,
                    postalCode: result.Address.Region,
                    addressCountry: result.Address.Country,
                  },
                },
                validThrough: result.ValidThrough,
                hiringOrganization: {
                  "@type": "Organization",
                  name: "HDI Global SE",
                  sameAs: "https://www.hdi.global",
                  logo: `https://www.hdi.global/${startPageData.logo.value?.url}`,
                  address: {
                    addressCountry: "Germany",
                    postalCode: "30659",
                    streetAddress: "HDI-Platz 1",
                    addressLocality: "Hannover",
                  },
                },
                baseSalary: {
                  "@type": "MonetaryAmount",
                  currency: result.SalaryCurrency,
                  value: {
                    "@type": "QuantitativeValue",
                    value: result.SalaryBase,
                    unitText: result.SalaryPeriodicity,
                  },
                },
                ...(result.WorkTime
                  ? {
                      workHours: WORK_TIME_OPTIONS.find(
                        (i) => i.value === result.WorkTime
                      )?.label,
                    }
                  : {}),
                ...(result.StartingDate
                  ? { jobStartDate: result.StartingDate }
                  : {}),
                ...(result.CareerLevel
                  ? {
                      employmentType: CAREER_LEVEL_OPTIONS.find(
                        (i) => i.value === result.CareerLevel
                      )?.label,
                    }
                  : {}),
                ...(result.PostingDate
                  ? { datePosted: result.PostingDate }
                  : {}),
              }))
            )}
          `}
          </script>
        </Helmet>
      )}
      <Header {...startPageData} />
      <main data-testid="JobListingPage">
        <Container className={styles.element}>
          <Row>
            <Col sm={7} md={8}>
              <h1 className={styles.header}>
                <RenderProperty
                  value={localizedLabelString({
                    section: "JobListing",
                    label: "JobPostings",
                    localizationCtx,
                  })}
                />
              </h1>
            </Col>
          </Row>

          <Row className={styles.contentRow}>
            <Col xs={4} sm={4} md={3} className={styles.filtersColumn}>
              <div className={styles.filtersBackground} />
              <FiltersElement
                careerLevelSelections={careerLevels}
                setCareerLevelSelections={(
                  changeFunc: (state: Set<string>) => Set<string>
                ) =>
                  setCareerLevels(
                    (state) => changeFunc(state) as Set<CareerLevelType>
                  )
                }
                workTimeModelsSelections={workTimeModels}
                setWorkTimeModelsSelections={(
                  changeFunc: (state: Set<string>) => Set<string>
                ) =>
                  setWorkTimeModels(
                    (state) => changeFunc(state) as Set<WorkTimeType>
                  )
                }
                localeSelection={localeSelection}
                setLocaleSelection={setLocaleSelection}
                careerLevelOptions={CAREER_LEVEL_OPTIONS}
                workTimeModelOptions={WORK_TIME_OPTIONS}
                localeOptions={FILTERED_LOCALE_OPTIONS}
                teaserContent={props.data.teaserContent}
                enableLocalFilter={props.data.enableLocalFilter?.value}
              />
            </Col>

            <Col
              sm={8}
              smStart={4}
              md={9}
              mdStart={3}
              className={styles.infoBannerColumn}
            >
              {maybeInfoBanner && !isInfoBannerClosed && (
                <div className={styles.infoBanner}>
                  <FAIcon
                    icon="info-circle"
                    className={styles.infoBannerIcon}
                  />
                  <div className={styles.infoBannerContent}>
                    <RenderProperty
                      value={
                        localeSelection === LOCALE_ALL_VALUE
                          ? maybeInfoBanner.bannerTextEnglish
                          : maybeInfoBanner.bannerTextLocal
                      }
                    />
                    <div className={styles.infoBannerButtons}>
                      {maybeInfoBanner.buttons.value?.map((b, idx) => (
                        <ButtonWrapper {...b} key={idx} buttonType="link" />
                      ))}
                    </div>
                  </div>
                  <button
                    className={styles.infoBannerButton}
                    onClick={() => setInfoBannerClosed(true)}
                  >
                    <span className={styles.visuallyHidden}>
                      (click to close)
                    </span>
                    <FAIcon icon="times" className={styles.infoBannerClose} />
                  </button>
                </div>
              )}
            </Col>

            <Col
              xs={4}
              sm={8}
              smStart={4}
              md={6}
              mdStart={3}
              className={styles.searchBarColumn}
            >
              <div className={styles.filtersBackground} />

              <div className={styles.queryBar}>
                <Input
                  label={localizedLabelString({
                    section: "Search",
                    label: "Search",
                    localizationCtx,
                  })}
                  initialValue={query}
                  inputRef={queryInputRef}
                  onSubmit={searchFunction}
                  theme={isMobile ? "white" : "gray"}
                />
                <Button type="primary" onClick={searchFunction}>
                  <LocalizedLabel section="Search" label="Search" />
                </Button>
              </div>
            </Col>
            <Col
              xs={4}
              sm={8}
              smStart={4}
              md={9}
              mdStart={3}
              className={styles.articleCardsColumn}
            >
              <div className={styles.results}>
                {isLoading && <Spinner />}
                {jobListingData && (
                  <>
                    <div className={styles.flexBar}>
                      <p className={styles.resultsText}>
                        <strong>{jobListingData?.TotalCount}</strong>&nbsp;
                        <LocalizedLabel section="Search" label="Results" />
                        {jobListingData?.Filter.Q && (
                          <>
                            &nbsp;
                            <LocalizedLabel section="Search" label="ForQuery" />
                            &nbsp;
                            <strong>
                              &quot;{jobListingData?.Filter.Q}&quot;
                            </strong>
                          </>
                        )}
                      </p>
                    </div>

                    {jobListingData?.Hits && (
                      <AdSearchResults
                        items={jobListingData.Hits}
                        careerLevelOptions={CAREER_LEVEL_OPTIONS}
                        workTimeOptions={WORK_TIME_OPTIONS}
                        isInternational={isInternational}
                        careerLevelSelections={careerLevels}
                        setCareerLevelSelections={(
                          changeFunc: (state: Set<string>) => Set<string>
                        ) =>
                          setCareerLevels(
                            (state) => changeFunc(state) as Set<CareerLevelType>
                          )
                        }
                        workTimeModelsSelections={workTimeModels}
                        setWorkTimeModelsSelections={(
                          changeFunc: (state: Set<string>) => Set<string>
                        ) =>
                          setWorkTimeModels(
                            (state) => changeFunc(state) as Set<WorkTimeType>
                          )
                        }
                      />
                    )}
                  </>
                )}
              </div>
            </Col>
            {jobListingData && jobListingData.TotalCount > page * PAGE_SIZE && (
              <Col className={styles.moreButton}>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Button
                    type="secondary"
                    onClick={() => {
                      loadItems().then(
                        (response) =>
                          response &&
                          setJobListingData((s: any) =>
                            s
                              ? {
                                  ...s,
                                  Hits: [...s.Hits, ...(response.Hits || [])],
                                }
                              : response
                          )
                      );
                    }}
                  >
                    <LocalizedLabel section="Search" label="LoadMore" />
                  </Button>
                )}
              </Col>
            )}
          </Row>
        </Container>
        <RenderProperty value={props.data.additionalContent} />
        {(props.data.downloadsGlobal?.value ||
          props.data.downloadsLocal?.value) && (
          <DownloadBlock
            downloadItems={[
              ...((props.data.downloadsLocal
                ?.expandedValue as DownloadProps[]) || []),
              ...((props.data.downloadsGlobal
                ?.expandedValue as DownloadProps[]) || []),
            ]}
          />
        )}
        {isUserRetention && <ContactExpert {...contactExpertData} />}
      </main>
      <Footer {...startPageData} />
    </>
  );
}

const useFiltersStyles = createUseStyles({
  mainElement: {
    marginBottom: rem(spacings.sam),
    ...onBreakpoint("sm", {
      marginBottom: rem(spacings.m),
    }),
  },
  teaserContentItem: {
    "& > div": {
      marginBottom: rem(spacings.sam),
      ...onBreakpoint("sm", {
        marginBottom: rem(spacings.m),
      }),
    },
  },
});

function FiltersElement(props: {
  careerLevelSelections: Set<string>;
  setCareerLevelSelections: (
    stateChangeFunc: (state: Set<string>) => Set<string>
  ) => void;
  careerLevelOptions: OptionsType<CareerLevelType>;

  workTimeModelsSelections: Set<string>;
  setWorkTimeModelsSelections: (
    stateChangeFunc: (state: Set<string>) => Set<string>
  ) => void;
  workTimeModelOptions: OptionsType<WorkTimeType>;

  localeSelection: string | undefined;
  setLocaleSelection: (props: string) => void;
  localeOptions: LanguageList;
  teaserContent: ContentAreaProperty;
  enableLocalFilter: boolean | null;
}) {
  const localizationCtx = useContext(LocalizationContext);
  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");
  const styles = useFiltersStyles();

  const localeOptions = props.localeOptions.map((lo) => ({
    label: lo.displayName,
    value: lo.name,
  }));

  return (
    <>
      <Accordion noOuterBorder={isMobile} className={styles.mainElement}>
        {props.enableLocalFilter && (
          <AccordionItem
            headline={localizedLabelString({
              section: "JobListing",
              label: "Country",
              localizationCtx,
            })}
            initialIsActive={!isMobile}
          >
            <Select
              label={localizedLabelString({
                section: "JobListing",
                label: "Country",
                localizationCtx,
              })}
              options={localeOptions}
              selected={props.localeSelection || ""}
              setSelected={props.setLocaleSelection}
            />
          </AccordionItem>
        )}
        <AccordionItem
          headline={localizedLabelString({
            section: "JobListing",
            label: "CareerLevel",
            localizationCtx,
          })}
          initialIsActive={!isMobile}
        >
          {props.careerLevelOptions.map((option) => (
            <Checkbox
              key={option.value}
              onCheck={() =>
                props.setCareerLevelSelections((s) =>
                  switchSetOption(s, option.value)
                )
              }
              checked={props.careerLevelSelections.has(option.value)}
              label={option.label}
            />
          ))}
        </AccordionItem>
        <AccordionItem
          headline={localizedLabelString({
            section: "JobListing",
            label: "WorkTimeModel",
            localizationCtx,
          })}
          initialIsActive={!isMobile}
        >
          {props.workTimeModelOptions.map((option) => (
            <Checkbox
              key={option.value}
              onCheck={() =>
                props.setWorkTimeModelsSelections((s) =>
                  switchSetOption(s, option.value)
                )
              }
              checked={props.workTimeModelsSelections.has(option.value)}
              label={option.label}
            />
          ))}
        </AccordionItem>
      </Accordion>
      <RenderProperty
        value={props.teaserContent}
        theme="filters"
        className={styles.teaserContentItem}
      />
    </>
  );
}

const useAdStyles = createUseStyles({
  itemList: {
    ...onBreakpoint("md", {
      "& $pageItem:first-child": {
        borderTop: "none",
      },
    }),
  },
  singleItem: {
    display: "flex",
    padding: rem(spacings.sam),
    borderTop: `2px solid ${colors.gray20}`,
  },
  h4: customTypography(
    typography.h4,
    { marginBottom: rem(spacings.sam) },
    { marginBottom: rem(spacings.sam) },
    { marginBottom: rem(spacings.sam) }
  ),
  description: typography.textDefault,
  caption: typography.caption,

  fullWidth: {
    width: "100%",
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: rem(spacings.s),
  },
  topLine: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  locationWrapper: {
    display: "flex",
    alignItems: "center",
  },
  countryFlag: {
    width: 24,
    height: 18,
    marginRight: rem(spacings.s),
  },
});

function AdSearchResults(props: {
  items: Array<JobListingApiHit>;
  careerLevelOptions: OptionsType<CareerLevelType>;
  workTimeOptions: OptionsType<WorkTimeType>;
  isInternational: boolean;
  careerLevelSelections: Set<string>;
  setCareerLevelSelections: (
    stateChangeFunc: (state: Set<string>) => Set<string>
  ) => void;

  workTimeModelsSelections: Set<string>;
  setWorkTimeModelsSelections: (
    stateChangeFunc: (state: Set<string>) => Set<string>
  ) => void;
}) {
  const styles = useAdStyles();
  return (
    <div className={styles.itemList}>
      {props.items.map((item, idx) => {
        const location = props.isInternational
          ? item.LocationEnglish
          : item.LocationLocal;
        const title = props.isInternational
          ? item.JobTitleEnglish
          : item.JobTitleLocal;
        const description = props.isInternational
          ? item.JobDescriptionEnglish
          : item.JobDescriptionLocal;
        const careerLevel = props.careerLevelOptions.find(
          (opt) => opt.value === item.CareerLevel
        )?.label;
        const workTimeModel = props.workTimeOptions.find(
          (opt) => opt.value === item.WorkTime
        )?.label;
        return (
          <Container
            key={idx}
            className={styles.singleItem}
            dataTestid="AdSearchResultCard"
          >
            <Row className={styles.fullWidth}>
              <Col>
                <h4 className={styles.h4}>{title}</h4>
                {description && (
                  <RichTextWithTypographyRenderer
                    className={styles.description}
                    value={description}
                  />
                )}
                {(item.CareerLevel || item.WorkTime || item.StartingDate) && (
                  <div className={styles.tags}>
                    {item.CareerLevel && careerLevel && (
                      <WhiteTagBadge
                        icon="user-tag"
                        label={careerLevel}
                        onClick={() =>
                          props.setCareerLevelSelections((s) =>
                            switchSetOption(s, item.CareerLevel || "")
                          )
                        }
                      />
                    )}
                    {item.WorkTime && workTimeModel && (
                      <WhiteTagBadge
                        icon="clock"
                        label={workTimeModel}
                        onClick={() =>
                          props.setWorkTimeModelsSelections((s) =>
                            switchSetOption(s, item.WorkTime || "")
                          )
                        }
                      />
                    )}
                    {item.StartingDate && (
                      <WhiteTagBadge
                        icon="calendar-plus"
                        label={item.StartingDate}
                      />
                    )}
                  </div>
                )}
                <div className={styles.topLine}>
                  <div className={styles.locationWrapper}>
                    {item.CountryFlag && (
                      <img
                        className={styles.countryFlag}
                        src={item.CountryFlag}
                        alt="Country flag"
                      />
                    )}
                    <span className={styles.caption}>{location}</span>
                  </div>
                  <Button type="link" href={item.JobPosting} target="_blank">
                    <LocalizedLabel
                      section="JobListing"
                      label="ExplorePosition"
                    />
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        );
      })}
    </div>
  );
}

const useWhiteTagStyles = createUseStyles({
  element: {
    marginRight: rem(spacings.xs),
    marginBottom: rem(spacings.xs),
    padding: `${spacings.xxs} ${spacings.s} ${spacings.xxs} ${spacings.s}`,
    backgroundColor: colors.white,
    borderRadius: 30,
    border: `1px solid ${colors.gray70}`,
    display: "flex",
    alignItems: "center",
    color: colors.gray70,
    textAlign: "left",
    cursor: "default",
  },
  icon: FAIconStyles({
    width: 24,
    height: 24,
    marginRight: rem(spacings.xs),
  }),
  label: {
    ...customTypography(
      {
        ...typography.textSmall,
        whiteSpace: "nowrap",
        marginBottom: 0,
        color: "inherit",
      },
      {},
      {
        marginBottom: 0,
      }
    ),
  },
  hoverable: {
    "&$element:hover": {
      border: `1px solid ${colors.black}`,
      color: colors.black,
      cursor: "pointer",
    },
  },
});

export function WhiteTagBadge(props: {
  icon: string;
  label: string;
  onClick?: () => void;
}) {
  const styles = useWhiteTagStyles();
  const isHoverable = props.onClick;

  return (
    <button
      className={cn(styles.element, isHoverable && styles.hoverable)}
      onClick={props.onClick}
      disabled={!isHoverable}
    >
      <FAIcon icon={props.icon as IconProp} className={styles.icon} />
      <b className={styles.label}>{props.label}</b>
    </button>
  );
}
