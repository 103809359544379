import React from "react";
import { StartPageProps } from "../../models/content/StartPageData";
import { Footer } from "../Shared/Footer";
import {
  AnimatedRenderProperty,
  RenderProperty,
} from "../../views/RenderProperty";
import { ContactExpert } from "../ContactExpert/ContactExpert";
import { isCypressTest } from "../../basics/isCypressTest";
import { StartPageHeader } from "../Header/StartPageHeader";

export function StartPage(props: StartPageProps) {
  return (
    <>
      {!isCypressTest() && <StartPageHeader {...props.data} />}
      <main data-testid="StartPage">
        {/*<AnimatedRenderProperty value={props.data.homepageKeyVisual} />*/}
        <AnimatedRenderProperty
          value={props.data.quickLinks}
          theme={"homepage"}
        />
        <RenderProperty value={props.data.mainContent} theme={"homepage"} />
        <RenderProperty value={props.data.additionalContent} />
        {props.data.contactCta?.value === "enabled" && (
          <ContactExpert {...props.data} />
        )}
      </main>
      <Footer {...props.data} />
    </>
  );
}
