export function useTracking() {
  return {
    event: (
      category: string,
      action: string,
      name?: string,
      value?: string
    ): true => {
      window._paq?.push(["trackEvent", category, action, name, value]);
      return true;
    },
  };
}
