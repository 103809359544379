import React from "react";
import { DownloadCenterCategoryBlockProps } from "../../models/content/DownloadCenterCategoryBlockData";
import { RenderComponent } from "../../views/RenderComponent";

export function DownloadCenterCategoryBlock(
  props: DownloadCenterCategoryBlockProps
) {
  return (
    <>
      {props.data.subcategories.expandedValue?.map((item, idx) => (
        <RenderComponent key={idx} contentLink={item.contentLink} />
      ))}
    </>
  );
}
