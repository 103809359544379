import { FooterImageLinksBlockProps } from "../../models/content/FooterImageLinksBlockData";
import { Link } from "../Shared/Link";
import React from "react";
import { createUseStyles } from "react-jss";
import { rem } from "../../basics/layout";
import { spacings } from "../../basics/spacings";

const useStyles = createUseStyles({
  socialIcon: {
    display: "block",
    marginRight: rem(spacings.s),
    filter: "brightness(0%)",
    transition: "filter 0.3s ease",
    "&:hover": {
      filter: "brightness(100%)",
      transition: "filter 0.3s ease",
    },
  },
  socialIconImage: {
    height: 40,
    width: 40,
    pointerEvents: "none",
  },
} as any);

export function FooterIconBlock(props: FooterImageLinksBlockProps) {
  const styles = useStyles();
  const link = props.data.link.value?.[0];

  return (
    <Link
      href={link?.href || ""}
      className={styles.socialIcon}
      title={link?.title}
      target={link?.target}
    >
      <img
        src={props.data.image.value?.url || ""}
        className={styles.socialIconImage}
        title={props.data.label.value || ""}
        alt={props.data.label.value || ""}
        loading="lazy"
      />
    </Link>
  );
}
