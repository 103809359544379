import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const easeOutQuad = (t: number) => t * (2 - t);

const countDecimalPlaces = (value: string) =>
  value.split(/[\.,]/g)[1]?.length || 0;

function getLocaleDecimalSeparator() {
  const separator = (1.1).toLocaleString().substr(1, 1);

  return separator === "0" ? "" : separator;
}

function getLocaleThousandsSeparator() {
  const separator = (1000).toLocaleString().substr(1, 1);

  return separator === "0" ? "" : separator;
}

function formatNumber(
  value: number,
  decimalPlaces: number,
  decimalSeparator?: string,
  thousandsSeparator?: string
) {
  decimalSeparator =
    typeof decimalSeparator === "string"
      ? decimalSeparator
      : getLocaleDecimalSeparator();

  thousandsSeparator =
    typeof thousandsSeparator === "string"
      ? thousandsSeparator
      : getLocaleThousandsSeparator();

  const parts = value.toFixed(decimalPlaces).toString().split(/[\.,]/g);

  return (
    parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator) +
    (parts[1] ? decimalSeparator + parts[1] : "")
  );
}

export function CountUpAnimation(props: {
  children: string | number;
  decimalSeparator?: string;
  thousandsSeparator?: string;
  duration?: number;
}) {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
    delay: 100,
    rootMargin: "50px",
  });

  const frameDuration = 1000 / 60;
  const duration = props.duration || 1000;
  const countTo = parseFloat(String(props.children));

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (inView) {
      let frame = 0;
      const totalFrames = Math.round(duration / frameDuration);
      const counter = setInterval(() => {
        frame++;
        const progress = easeOutQuad(frame / totalFrames);
        setCount(countTo * progress);

        if (frame === totalFrames) {
          clearInterval(counter);
        }
      }, frameDuration);
    }
  }, [inView]);

  return (
    <span ref={ref}>
      {inView
        ? formatNumber(
            count,
            countDecimalPlaces(String(props.children)),
            props.decimalSeparator,
            props.thousandsSeparator
          )
        : props.children}
    </span>
  );
}
