import React, { useState, KeyboardEvent, RefObject } from "react";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { colors } from "../../basics/colors";
import { spacings } from "../../basics/spacings";
import { rem } from "../../basics/layout";
import { FAIcon, FAIconStyles } from "../FAIcon/FAIcon";
import { customTypography, typography } from "../../basics/typography";

export type InputType = "text" | "number" | "email";
export type InputTheme = "gray" | "white" | "transparent";

export interface InputThemeConfig {
  backgroundColor: string;
}

const THEMES: { [key in InputTheme]: InputThemeConfig } = {
  gray: {
    backgroundColor: colors.gray10,
  },
  white: {
    backgroundColor: colors.white,
  },
  transparent: {
    backgroundColor: "transparent",
  },
};

const useStyles = createUseStyles({
  inputWrapper: ({ isFocused, theme }) => ({
    marginRight: rem(spacings.sam),
    position: "relative",
    overflow: "hidden",
    padding: "10px 40px 10px 12px",
    backgroundColor: THEMES[theme as InputTheme].backgroundColor,
    width: "100%",
    "&:after, &:before": {
      content: "''",
      position: "absolute",
      display: "block",
      bottom: 0,
      left: 0,
      zIndex: 2,
      transform: isFocused ? "translate(0)" : "translate(-100%)",
      transition: "transform 0.2s ease",
      width: "100%",
      height: 2,
      backgroundColor: colors.webGreen20,
    },
    "&:before": {
      backgroundColor: colors.gray80,
      zIndex: 1,
      transform: "none",
    },
    "& svg": {
      color: colors.gray100,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: rem(spacings.sam.divide(2)),
      pointerEvents: "none",
    },
  }),
  inputWrapperTypography: {
    ...customTypography(
      {
        ...typography.textDefault,
        lineHeight: `1 !important`,
        fontWeight: "normal",
        marginBottom: 0,
      },
      {},
      {
        marginBottom: 0,
      }
    ),
  },
  input: {
    marginBottom: 0,
    width: "100%",
    height: "100%",
    background: "none",
    border: 0,
    outline: "none",
    fontFamily: "inherit",
    fontSize: "inherit",
    fontWeight: "inherit",
    lineHeight: "inherit",
  },
  labelText: {
    ...typography.visuallyHidden,
  },
  submitButton: {
    position: "absolute",
    top: 0,
    right: 0,
    border: 0,
    background: "none",
    cursor: "pointer",
    width: `calc(${rem(spacings.sam)} + 20px)`,
    height: "100%",
    pointerEvents: ({ displaySubmitIcon }) =>
      displaySubmitIcon ? "all" : "none",
    opacity: ({ displaySubmitIcon }) => (displaySubmitIcon ? 1 : 0),
  },
  primaryIcon: {
    opacity: ({ displaySubmitIcon }) => (displaySubmitIcon ? 0 : 1),
  },
  icon: {
    ...FAIconStyles({
      width: 20,
      height: 20,
    }),
  },
  visuallyHidden: {
    ...typography.visuallyHidden,
  },
});

export function Input(props: {
  label: string;
  inputRef?: RefObject<HTMLInputElement>;
  initialValue?: string;
  type?: InputType;
  theme?: InputTheme;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  icon?: IconProp | null;
  onSubmit?: (value: string) => void;
  onClick?: () => void;
  getValue?: (value: any) => void;
}) {
  const [isFocused, setFocus] = useState(false);
  const [value, setValue] = useState(props.initialValue || "");
  const displaySubmitIcon = props.onSubmit && value;
  const styles = useStyles({
    isFocused,
    theme: props.theme || "gray",
    displaySubmitIcon: displaySubmitIcon,
  });

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13 && props.onSubmit) {
      props.onSubmit(value);
    }
  }

  function onChange(event: any) {
    setValue(event.target.value);
    props.getValue && props.getValue(event.target.value);
  }

  return (
    <label
      className={cn(
        styles.inputWrapper,
        styles.inputWrapperTypography,
        props.className
      )}
      onClick={() => props.onClick && props.onClick()}
    >
      <span className={styles.labelText}>{props.label}</span>
      <input
        className={styles.input}
        ref={props.inputRef}
        type={props.type || "text"}
        disabled={props.disabled}
        value={value}
        placeholder={props.placeholder}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onKeyDown={(e) => onKeyDown(e)}
        onChange={onChange}
      />
      {props.icon && !displaySubmitIcon && (
        <FAIcon
          icon={props.icon}
          className={cn(styles.icon, styles.primaryIcon)}
        />
      )}
      {displaySubmitIcon && (
        <button
          className={styles.submitButton}
          onClick={() => props.onSubmit && props.onSubmit(value)}
        >
          <span className={styles.visuallyHidden}>Submit</span>
          <FAIcon icon="sign-in" className={styles.icon} />
        </button>
      )}
    </label>
  );
}
