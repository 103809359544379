import React, { useContext, useState, useEffect } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Shared/Footer";
import {
  getUniqueId,
  GlobalContentStoreContext,
} from "../../hooks/GlobalContentStore";
import StartPageData from "../../models/content/StartPageData";
import { createUseStyles } from "react-jss";
import {
  Col,
  Container,
  CONTAINER_OFFSET_WIDTHS,
  onBefore,
  onBreakpoint,
  rem,
  Row,
} from "../../basics/layout";
import { RenderProperty } from "../../views/RenderProperty";
import { typography } from "../../basics/typography";
import { spacings } from "../../basics/spacings";
import { Accordion, AccordionItem } from "../Accordion/Accordion";
import { DownloadCenterPageProps } from "../../models/content/DownloadCenterPageData";
import { RenderComponent } from "../../views/RenderComponent";
import {
  DownloadCenterContext,
  Downloads,
} from "../../hooks/DownloadCenterContext";
import DownloadCenterCategoryBlockData from "../../models/content/DownloadCenterCategoryBlockData";
import { isDeviceResOrLower, useDeviceType } from "../../hooks/useDeviceType";
import { colors } from "../../basics/colors";
import { ContactExpert } from "../ContactExpert/ContactExpert";

const useStyles = createUseStyles({
  downloadCenter: {
    paddingTop: rem(spacings.m),
    paddingBottom: rem(spacings.m),
    ...onBreakpoint("sm", {
      paddingTop: rem(spacings.l),
      paddingBottom: rem(spacings.l),
    }),
    ...onBreakpoint("md", {
      paddingTop: rem(spacings.xl),
      paddingBottom: rem(spacings.xxl),
    }),
  },
  headline: typography.h1,

  downloadItemsWrapper: {
    ...onBreakpoint("sm", {
      marginLeft: rem(spacings.m),
    }),
  },
  accordion: onBefore("sm", {
    paddingTop: spacings.xs,
    paddingBottom: spacings.xs,
  }),
  leftMenuCol: onBefore("sm", {
    zIndex: 1,
    marginBottom: rem(spacings.sam),
  }),
  leftMenuBackground: onBefore("sm", {
    zIndex: -1,
    position: "absolute",
    height: "100%",
    width: "100vw",
    backgroundColor: colors.gray10,
    marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
  }),
});

export function DownloadCenterPage(props: DownloadCenterPageProps) {
  const queryParams = new URLSearchParams(window.location.search);
  const history = window.history;
  const { getStartPageData, getImmediateStartPageData } = useContext(
    GlobalContentStoreContext
  );
  const [startPageData, setStartPageData] = useState<StartPageData | null>(
    getImmediateStartPageData()
  );
  const categories = props.data.downloads
    .expandedValue as Array<DownloadCenterCategoryBlockData>;
  const [downloads, setDownloads] = useState<Downloads>({});
  const [activeSubcategory, setActiveSubcategory] = useState<number | null>(
    queryParams.has("subcategory")
      ? Number(queryParams.get("subcategory"))
      : categories?.[0]?.subcategories?.expandedValue?.[0]?.contentLink.id ||
          null
  );

  const deviceType = useDeviceType();
  const isMobile = isDeviceResOrLower(deviceType, "mobile");

  useEffect(() => {
    const queryParams = new URLSearchParams();
    queryParams.set("subcategory", String(activeSubcategory));
    history.replaceState({}, "", `${window.location.pathname}?${queryParams}`);
  }, [activeSubcategory]);

  useEffect(() => {
    !startPageData &&
      getStartPageData().then((response) => {
        setStartPageData(response);
      });
  }, []);

  const downloadItems = [
    ...(downloads.downloadsLocal?.expandedValue || []),
    ...(downloads.downloadsGlobal?.expandedValue || []),
  ];
  const styles = useStyles();

  if (!startPageData) return null;

  const isUserRetention = props.data.contactCta?.value === "enabled";

  const contactExpertData = props.data.contactsUseDefaultContent?.value
    ? startPageData
    : props.data;

  return (
    <>
      <Header {...startPageData} />
      <main data-testid="DownloadCenterPage">
        <Container className={styles.downloadCenter}>
          <Row>
            <Col sm={7} md={8}>
              <h1 className={styles.headline}>
                <RenderProperty
                  value={props.data.headline}
                  fallbackValue={props.data.name}
                />
              </h1>
            </Col>
          </Row>
          <Row>
            <DownloadCenterContext.Provider
              value={{
                downloads,
                setDownloads,
                activeSubcategory,
                setActiveSubcategory,
              }}
            >
              <Col xs={4} sm={4} md={3} className={styles.leftMenuCol}>
                <div className={styles.leftMenuBackground} />
                <Accordion
                  noOuterBorder={isMobile}
                  className={styles.accordion}
                >
                  {(
                    props.data.downloads
                      .expandedValue as Array<DownloadCenterCategoryBlockData>
                  )?.map((item, idx) => (
                    <AccordionItem
                      key={idx}
                      headline={<RenderProperty value={item.categoryLabel} />}
                      initialIsActive={
                        item.subcategories.expandedValue?.find(
                          (sc) => sc.contentLink.id === activeSubcategory
                        )?.contentLink.id === activeSubcategory
                      }
                    >
                      <RenderComponent
                        key={idx}
                        contentLink={item.contentLink}
                      />
                    </AccordionItem>
                  ))}
                </Accordion>
              </Col>
              <Col xs={4} sm={8} md={9}>
                <div className={styles.downloadItemsWrapper}>
                  {downloadItems.map((item) => (
                    <RenderComponent
                      key={getUniqueId(item)}
                      contentLink={item.contentLink}
                      context={{ disableFeaturedOption: true }}
                    />
                  ))}
                </div>
              </Col>
            </DownloadCenterContext.Provider>
          </Row>
        </Container>
        <RenderProperty value={props.data.additionalContent} />
        {isUserRetention && <ContactExpert {...contactExpertData} />}
      </main>
      <Footer {...startPageData} />
    </>
  );
}
